/** This file defines the input node editor React component. The input node editor allows you 
 *  to configure the application, device, interface inputs for a runbook.
 *  @module */
import React, { useState } from "react";
import { SimpleNodeEditorProps } from "../simple/SimpleNodeEditor";
import { HELP, STRINGS } from "app-strings";
import { UniversalNode } from "../../UniversalNode";
import { InputType } from "../../types/GraphTypes";
import { HTMLSelect, Radio, RadioGroup } from "@blueprintjs/core";
import { InputNodeUtils, INPUT_NODE_EDIT_PROPS } from "./InputNodeUtils";
import { InlineHelp } from "components/common/layout/inline-help/InlineHelp";
import { SHOW_CONTEXT } from "components/enums/QueryParams";
import { RunbookContextSummary } from "../RunbookContextSummary";
import { NodeLibraryNode } from "pages/create-runbook/views/create-runbook/NodeLibrary";
import { AutocompleteControl } from "components/hyperion/controls/autocomplete/AutocompleteControl";
import { FIELDS, SearchItem, SuggestItem } from "utils/services/SearchApiService";

/** this interface defines the properties that are used to store the nodes configurable parameters. */
interface InputNodeProperties {
    triggerType?: string;
    /** an enum with the time reference which is one of primary indicator or runbook executin. */
    timeReference?: TimeReferenceOptions;
    /** integer with the time offset in seconds. */
    timeOffset?: number;
    /** the input entitities if any. */
    entities?: any;
}

const offsetOptions = [
    { value: 0, label: STRINGS.runbookEditor.nodeEditor.offsetOptions.noOffset },
    { value: 15 * 60, label: STRINGS.runbookEditor.nodeEditor.offsetOptions.fifteenMins },
    { value: 30 * 60, label: STRINGS.runbookEditor.nodeEditor.offsetOptions.thirtyMins },
    { value: 45 * 60, label: STRINGS.runbookEditor.nodeEditor.offsetOptions.fortyfiveMins },
    { value: 60 * 60, label: STRINGS.runbookEditor.nodeEditor.offsetOptions.sixtyMins }
];

/** an enum that specifies the possible time reference options. */
export enum TimeReferenceOptions {
    /** the enumerated value for primary indicator option. */
    PRIMARY_INDICATOR = "PRIMARY_INDICATOR",
    /** the enumerated value for runbook execution option. */
    RUNBOOK_EXECUTION = "RUNBOOK_EXECUTION",
}

/** Component for editing the properties of a input node
 *  @param selectedNode - Currently selected active input node
 *  @param libraryNode- Selected input node's meta data.
 *  @param graphDef - Graphdef object that defines the entire runbook. Provides a way to access all the nodes in the graph 
 *  @returns a JSX component with the input node editor. */
export const InputNodeEditor = React.forwardRef(({ selectedNode, libraryNode, graphDef }: SimpleNodeEditorProps, ref: any): JSX.Element => {
    const [curProperties, setCurProperties] = useState<InputNodeProperties>({
        triggerType: selectedNode?.getProperty("triggerType") ? selectedNode?.getProperty("triggerType") : InputType.INTERFACE,
        timeReference: selectedNode?.getProperty(INPUT_NODE_EDIT_PROPS.TIME_REFERENCE) ? selectedNode?.getProperty(INPUT_NODE_EDIT_PROPS.TIME_REFERENCE) as TimeReferenceOptions : TimeReferenceOptions.RUNBOOK_EXECUTION,
        timeOffset: selectedNode?.getProperty(INPUT_NODE_EDIT_PROPS.TIME_OFFSET) ? selectedNode?.getProperty(INPUT_NODE_EDIT_PROPS.TIME_OFFSET) : 900,
        entities: selectedNode?.getProperty(INPUT_NODE_EDIT_PROPS.ENTITIES) ? selectedNode?.getProperty(INPUT_NODE_EDIT_PROPS.ENTITIES) : undefined,
    });

    /**
     * Update the selected node properties. This is invoked when done button is clicked
     * on the node editor dialog.
     * @param properties -  Properties in selected node that need to updated
     * @param selectedNode - node being editied
     * @param libraryNode - object that specifies all the editable properties for a given node.
     */
    function updateNode(properties: InputNodeProperties, selectedNode: UniversalNode | undefined, libraryNode: NodeLibraryNode | undefined) {
        if (!selectedNode || !libraryNode || !properties) {
            console.warn("updateNode has invlaid inputs. Node update failed");
            return;
        }
        selectedNode.setProperty(INPUT_NODE_EDIT_PROPS.TIME_REFERENCE, curProperties?.timeReference);
        selectedNode.setProperty(INPUT_NODE_EDIT_PROPS.TIME_OFFSET, curProperties?.timeOffset);
        selectedNode.setProperty(INPUT_NODE_EDIT_PROPS.ENTITIES, curProperties?.entities);
    }

    ref.current = {
        updateNode: () => {
            updateNode(curProperties, selectedNode, libraryNode);
        },
        validate: () => {
            const errorMessages = new Array<string>();
            InputNodeUtils.validateNodeProperties(
                curProperties,
                errorMessages,
            );
            return errorMessages;
        }
    };

    let primaryField: FIELDS | undefined = undefined;
    let primaryReportByField: FIELDS | undefined = undefined;
    let primaryDefaultSelectedItem: any = undefined;
    let secondaryField: FIELDS | undefined = undefined;
    let secondaryReportByField: FIELDS | undefined = undefined;
    let secondaryDefaultSelectedItem: any = undefined;
    let primaryInputLabel = "", secondaryInputLabel = "";
    let primaryInputPlaceholder = "", secondaryInputPlaceholder = "";
    switch (curProperties.triggerType) {
        case InputType.INTERFACE:
            primaryField = FIELDS.network_interface;
            primaryReportByField = FIELDS.ifcReportedBy;
            primaryInputLabel = STRINGS.viewRunbooks.inputInterfaceLabel;
            primaryInputPlaceholder = STRINGS.viewRunbooks.inputInterfacePlaceholder;
            primaryDefaultSelectedItem = curProperties[INPUT_NODE_EDIT_PROPS.ENTITIES] ? curProperties[INPUT_NODE_EDIT_PROPS.ENTITIES] : undefined;
            break;
        case InputType.DEVICE:
            primaryField = FIELDS.network_device;
            primaryReportByField = FIELDS.devReportedBy;
            primaryInputLabel = STRINGS.viewRunbooks.inputDeviceLabel;
            primaryInputPlaceholder = STRINGS.viewRunbooks.inputDevicePlaceholder;
            primaryDefaultSelectedItem = curProperties[INPUT_NODE_EDIT_PROPS.ENTITIES] ? curProperties[INPUT_NODE_EDIT_PROPS.ENTITIES] : undefined;
            break;
        case InputType.APPLICATION_LOCATION:
            primaryField = FIELDS.application;
            primaryReportByField = FIELDS.appReportedBy;
            secondaryField = FIELDS.location;
            secondaryReportByField = FIELDS.locReportedBy;
            primaryInputLabel = STRINGS.viewRunbooks.inputApplicationLabel;
            secondaryInputLabel = STRINGS.viewRunbooks.inputLocationLabel;
            primaryInputPlaceholder = STRINGS.viewRunbooks.inputApplicationPlaceholder;
            secondaryInputPlaceholder = STRINGS.viewRunbooks.inputLocationPlaceholder;
            primaryDefaultSelectedItem = curProperties[INPUT_NODE_EDIT_PROPS.ENTITIES]?.application ? {application: curProperties[INPUT_NODE_EDIT_PROPS.ENTITIES].application}  : undefined;
            secondaryDefaultSelectedItem = curProperties[INPUT_NODE_EDIT_PROPS.ENTITIES]?.location ? {location: curProperties[INPUT_NODE_EDIT_PROPS.ENTITIES].location} : undefined;
            break;
        case InputType.LOCATION:
            primaryField = FIELDS.location;
            primaryReportByField = FIELDS.locReportedBy;
            primaryInputLabel = STRINGS.viewRunbooks.inputLocationLabel;
            primaryInputPlaceholder = STRINGS.viewRunbooks.inputLocationPlaceholder;
            primaryDefaultSelectedItem = curProperties[INPUT_NODE_EDIT_PROPS.ENTITIES] ? curProperties[INPUT_NODE_EDIT_PROPS.ENTITIES] : undefined;
            break;
        case InputType.APPLICATION:
            primaryField = FIELDS.application;
            primaryReportByField = FIELDS.appReportedBy;
            primaryInputLabel = STRINGS.viewRunbooks.inputApplicationLabel;
            primaryInputPlaceholder = STRINGS.viewRunbooks.inputApplicationPlaceholder;
            primaryDefaultSelectedItem = curProperties[INPUT_NODE_EDIT_PROPS.ENTITIES]?.application ? {application: curProperties[INPUT_NODE_EDIT_PROPS.ENTITIES].application}  : undefined;
            break;
    }

    let primarySearchItem: SuggestItem | SearchItem | string | undefined = undefined;
    let secondarySearchItem: SuggestItem | SearchItem | string | undefined = undefined;

    return (
        <>
            <tr key="primary-autocomplete">
                <td className="p-1"><label>{primaryInputLabel}</label></td> 
                <td className="p-1">
                    <AutocompleteControl field={primaryField!} reportedBy={primaryReportByField!} showHighlightedText={true}
                        allowCreateItem={true} 
                        defaultSelectedItem={primaryDefaultSelectedItem}
                        onItemSelected={(item) => {
                            primarySearchItem = item as SearchItem;
                            console.log(primarySearchItem);
                            const entities = curProperties?.entities ? curProperties.entities : {};
                            switch (curProperties.triggerType) {
                                case InputType.INTERFACE:
                                    if (typeof item === "string") {
                                        // User did not select an autocomplete item, just typed in a string
                                        let name = item;
                                        let ipaddr = item;
                                        let ifindex = 0;
                                        if (item.includes(":")) {
                                            const tokens = item.split(":");
                                            if (tokens.length === 2) {
                                                ipaddr = tokens[0];
                                                ifindex = parseInt(tokens[1]);
                                            }
                                        }
                                        entities.network_interface = {name, ipaddr, ifindex};
                                    } else {
                                        entities.network_interface = (item as any).network_interface;
                                    }
                                    break;
                                case InputType.DEVICE:
                                    if (typeof item === "string") {
                                        // User did not select an autocomplete item, just typed in a string
                                        entities.network_device = {name: item, ipaddr: item};
                                    } else {
                                        entities.network_device = (item as any).network_device;
                                    }
                                    break;
                                case InputType.APPLICATION_LOCATION:
                                    if (typeof item === "string") {
                                        // User did not select an autocomplete item, just typed in a string
                                        entities.application = {name: item};
                                    } else {
                                        entities.application = (item as any).application;
                                    }
                                    break;
                                case InputType.LOCATION:
                                    if (typeof item === "string") {
                                        // User did not select an autocomplete item, just typed in a string
                                        entities.location = {name: item};
                                    } else {
                                        entities.location = (item as any).location;
                                    }
                                    break;                        
                                case InputType.APPLICATION:
                                    if (typeof item === "string") {
                                        // User did not select an autocomplete item, just typed in a string
                                        entities.application = {name: item};
                                    } else {
                                        entities.application = (item as any).application;
                                    }
                                    break;                        
                            }
                            setCurProperties({ ...curProperties, [INPUT_NODE_EDIT_PROPS.ENTITIES]: entities });
                        }} 
                        placeholder={primaryInputPlaceholder}
                        className="input-width"
                        key={(curProperties.triggerType ? curProperties.triggerType : "") + "primary-ac"}
                    />
                </td>
            </tr>
            {curProperties.triggerType === InputType.APPLICATION_LOCATION && <tr key="secondary-autocomplete">
                <td className="p-1"><label>{secondaryInputLabel}</label></td> 
                <td className="p-1">
                    <AutocompleteControl field={secondaryField!} reportedBy={secondaryReportByField!} showHighlightedText={true}
                        allowCreateItem={true} 
                        defaultSelectedItem={secondaryDefaultSelectedItem}
                        onItemSelected={(item) => {
                            secondarySearchItem = item as SearchItem;
                            console.log(secondarySearchItem);
                            const entities = curProperties?.entities ? curProperties.entities : {};
                            if (typeof item === "string") {
                                // User did not select an autocomplete item, just typed in a string
                                entities.location = {name: item};
                            } else {
                                entities.location = (item as any).location;
                            }
                            setCurProperties({ ...curProperties, [INPUT_NODE_EDIT_PROPS.ENTITIES]: entities });
/*                            
                            if (props.onRunbookInputChange) {
                                //props.onRunbookInputChange(generateRunbookInputs(
                                //    runbookConfig, primarySelectedItem, primaryEntities, secondarySelectedItem, 
                                //    secondaryEntities, endTime, props.invocationType
                                //));
                                props.onRunbookInputChange(generateRunbookInputsUsingSearch(
                                    runbookConfig, primarySearchItem, secondarySearchItem, endTime, props.invocationType,
                                    triggerMetricConfig, webhookConfig
                                ));
                            }
*/
                        }} 
                        placeholder={secondaryInputPlaceholder}
                        className="input-width"
                        key={(curProperties.triggerType ? curProperties.triggerType : "") + "secondary-ac"}
                    />
                </td>
            </tr>}
            <tr>
                <td className="display-7 font-weight-bold pt-2 pb-3" colSpan={2}>
                    <InlineHelp 
                        helpMapping={HELP.RunbookNodeCategory.Trigger[selectedNode?.getProperty('triggerType')?.replace('.', '_')]?.DQRefTime}>
                        {STRINGS.runbookEditor.nodeEditor.dataQueryReferenceTime}
                    </InlineHelp>
                </td>
            </tr>
            <tr>
                <td colSpan={2}>
                    <em>{STRINGS.runbookEditor.nodeLibrary.propertyLabels.queryNodes}</em>{STRINGS.runbookEditor.nodeLibrary.propertyLabels.referenceQueryBasedOn}
                </td>
            </tr>
            <tr>
                <td className="pt-2" colSpan={2}>
                    <RadioGroup
                        name="time_control"
                        onChange={(event) => {
                            const updateTimeReference = {
                                timeReference: event.currentTarget.value as TimeReferenceOptions,
                                timeOffset: event.currentTarget.value === TimeReferenceOptions.RUNBOOK_EXECUTION ? 900 : curProperties.timeOffset
                            }
                            setCurProperties({ ...curProperties, ...updateTimeReference });
                        }}
                        selectedValue={curProperties?.timeReference}
                        inline={true}
                        className="pl-4 align-self-center"
                    >
                        <Radio
                            label={STRINGS.runbookEditor.nodeLibrary.propertyLabels.primaryIndicatorStart}
                            value={TimeReferenceOptions.PRIMARY_INDICATOR}
                            disabled={curProperties.triggerType === InputType.WEBHOOK}
                            className="mb-0 mr-2"
                        />
                        <span className="mr-2">+</span>
                        <HTMLSelect
                            name="time_interval"
                            disabled={curProperties.timeReference === TimeReferenceOptions.RUNBOOK_EXECUTION}
                            options={offsetOptions}
                            value={curProperties?.timeOffset}
                            onChange={(event) => {
                                const updateTimeOffset = {
                                    timeOffset: parseInt(event.currentTarget.value),
                                }
                                setCurProperties({ ...curProperties, ...updateTimeOffset });
                            }}>
                        </HTMLSelect>
                        <Radio
                            label={STRINGS.runbookEditor.nodeLibrary.propertyLabels.runbookExecution}
                            value={TimeReferenceOptions.RUNBOOK_EXECUTION}
                            className="mb-0 pt-2 position-relative d-block"
                        />
                    </RadioGroup>
                </td>
            </tr>
            {SHOW_CONTEXT && <RunbookContextSummary 
                currentProperties={JSON.parse(JSON.stringify(curProperties))} 
                node={graphDef.nodes.find(node => node.id === selectedNode?.getId())!} graphDef={graphDef} 
                showOutputExample={true} showInputExample={true}
            />}
        </>
    )
});
