/** This file defines the PieChart React component.  The PieChart React component renders a
 *  a basic pie chart with n-groups and one metric.  The pie chart component can also render
 *  a donut chart.
 *  @module */
import React, { useCallback, useRef, useState } from 'react';
import { Dialog } from '@blueprintjs/core';
import { Classes } from '@tir-ui/react-components';
import { CHART_COLORS } from 'components/enums';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import NoDataToDisplay from 'highcharts/modules/no-data-to-display';
import { DEFAULT_PIE_CHART_OPTIONS } from 'components/reporting/charts/defaults/HighchartDefaults';
import { cloneDeep, merge } from 'lodash';
import { CHART_SERIES_COLORS } from 'components/enums';
import { Unit } from 'reporting-infrastructure/types/Unit.class';
import { precise, scaleMetric } from 'reporting-infrastructure/utils/formatters';
import { THEME, ThemeContext } from 'utils/themes';
import { BaseChartProps, GroupMetricEvent, GroupMetricSource } from '../chart-base/ChartBase';
import { STRINGS } from 'app-strings';
import { ChartToolbar, ChartType, LegendPosition, PieChartSettings, PieStyle, ToolbarAction } from '../chart-base/ChartToolbar';
import 'components/common/chart-base/ChartBase.css'

// This is needed to enable the highcharts no data functionality
NoDataToDisplay(Highcharts);

// These are the default options that all pie charts should use as a starting point
const defaultOptions = DEFAULT_PIE_CHART_OPTIONS;

/** an interface that describes the properties that can be passed in to the pie chart component.*/
export interface PieChartProps extends BaseChartProps {
	/** an array of BarData with the data for each bar. */
	pieData: Array<PieData>;
	/** Title to display in the chart. */
	title: string;
	/** an array with the units for each metric. */
	unit: Unit;
    /** a string with the metric id. */
    metric?: string;
    /** the PieChartSetings object with the basic settings for the chart such as the style and legend position. */
    settings?: PieChartSettings;
    /** the suffix to use in the legend when displaying comparison data. */
    comparisonSuffix?: string;
}

/** an interface that describes the pie data format. */
export interface PieData {
	/** the label for the bar. */
	label: string;
	/** the values for the metrics. */
	value: number;
    /** the optional comparison value. */
    compValue?: number;
    /** the data that is passed when there is a selection. */
    group?: any;
}

/** Creates the the pie chart view.
 *  @param props an object with the properties passed to the pie chart view.
 *  @returns JSX with the pie chart component.*/
export const PieChart = (props: PieChartProps): JSX.Element => {
    const chartRef = useRef<HighchartsReact.RefObject>(null);
	const [isOpen, setIsOpen] = useState(false);
	const handleOpen = useCallback(() => setIsOpen(!isOpen), [isOpen]);
	const handleClose = useCallback(() => setIsOpen(false), []);

    const [settings, setSettings] = useState<PieChartSettings>(props.settings || {});

	let seriesData: Array<any> = [];
	if (props.pieData) {
		let series = {
			type: 'pie',
			data: [] as any,
			borderColor: 'transparent',
		};
		seriesData.push(series);
		for (let index = 0; index < props.pieData.length; index++) {
			const chartColor = CHART_SERIES_COLORS[index] ? CHART_SERIES_COLORS[index] : CHART_SERIES_COLORS[0];

			let slice: any = {
				color: chartColor,
				name: props.pieData[index].label,
				y: props.pieData[index].value,
                groupData: props.pieData[index].group,
                metricData: props.metric
			};

            if (props.comparisonSuffix) {
                slice.comparisonSuffix = props.comparisonSuffix;
                slice.comparisonValue = props.pieData[index].compValue;
            }

			series.data.push(slice);
		}
	}

	const getChart = (popup: boolean = false) => {
		return (
			<ThemeContext.Consumer>
				{(ctx) => (
					<div aria-label="pieChart card"
						className={
							popup ? Classes.DIALOG_BODY : 'flex pieChart' + (props.transparent ? '' : ' bg-light') + (props.transparent || props.hideShadow ? '' : ' shadow') + (props.className ? ' ' + props.className : '')
						}
					>
                        {props.enableFullScreen && !popup && <ChartToolbar chartType={ChartType.pie}
                            controls={props.controls || []} settings={settings} notifyToolbarAction={(type: ToolbarAction, value: any) => {
                                switch (type) {
                                    case ToolbarAction.SHOW_FULL_SCREEN:
                                        handleOpen();
                                        break;
                                    case ToolbarAction.SETTINGS_CHANGED:
                                        setSettings(value);
                                        break;
                                }
                            }}
                        />}
						<HighchartsReact
							highcharts={Highcharts} immutable={true}
							options={getChartOptions(
                                seriesData, props.title, props.unit, settings, ctx.theme === THEME.dark, props.options, props.onGroupMetricSelection
                            )}
							containerProps={{
								style: {
									width: props.width ? props.width : '100%',
									height: popup ? (0.9 * window.innerHeight - 40) + "px" : props.height ? props.height : '100%',
									padding: popup ? '10px' : '',
								},
							}}
                            ref={chartRef}
						/>
					</div>
				)}
			</ThemeContext.Consumer>
		);
	};

	return (
		<>
			<Dialog title={props.fullScreenTitle ? props.fullScreenTitle : ''} isOpen={isOpen} autoFocus={true} canEscapeKeyClose={true} 
                canOutsideClickClose={true} enforceFocus={true} usePortal={true} onClose={handleClose} 
                style={{ width: (0.75 * window.innerWidth), height: 0.9 * window.innerHeight }}
			>
				{getChart(true)}
			</Dialog>
			{getChart(false)}
		</>
	);
};

/** returns the pie chart options for the specified series.
 *  @param series the data series to put in the pie chart.
 *  @param title a String with the metric name.
 *  @param unit a String with the unit.
 *  @param settings the BarChartSettings object with some of the settings for the chart like the style and legend position.
 *  @param darkMode a boolean which specifies whether dark mode is enabled.
 *  @param options additional options that should be merged into the chart options.
 *  @param onGroupMetricSelection the handler for selection change events.
 *  @returns the chart options for the specified series.*/
function getChartOptions(
	series: any,
	title: string,
	unit: Unit,
    settings: PieChartSettings,
	darkMode: boolean = true,
	options: Highcharts.Options | undefined,
    onGroupMetricSelection?: (event: GroupMetricEvent) => void
): Highcharts.Options {
    const {	
        style = PieStyle.pie, showPercentage = true, showValue = false, showLegend = false, legendPosition = LegendPosition.top
    } = settings;

	let optionsCopy: Highcharts.Options = cloneDeep(defaultOptions);

    let legendLayout = "horizontal";
    let legendAlign = "left";
    let legendVerticalAlign = "top";
    switch (legendPosition) {
        case LegendPosition.top:
            // Defaults are set for top, nothing to do
            break;
        case LegendPosition.bottom:
            legendVerticalAlign = "bottom";
            break;
        case LegendPosition.left:
            legendLayout = "vertical";
            break;
        case LegendPosition.right:
            legendLayout = "vertical";
            legendAlign = "right";
            break;
    }

	merge(optionsCopy, {
		chart: {
			type: 'pie',
		},
		title: {
			text: title,
			style: {
				color: darkMode ? CHART_COLORS.LEGEND_DARKMODE : CHART_COLORS.LEGEND_DEFAULT,
			},
		},
		legend: {
			enabled: showLegend,
			lineHeight: 8,
			symbolRadius: 0,
			layout: legendLayout,
			align: legendAlign,
			verticalAlign: legendVerticalAlign,
			floating: false,
			x: 0, //85
			y: 0,
			labelFormatter: function (this: any) {
				if (this && this.name) {
                    return getLabelText(this.name, showPercentage ? this.percentage : undefined, showValue ? this.y : undefined, unit);
				}
			},
			itemStyle: {
				color: darkMode ? CHART_COLORS.LEGEND_DARKMODE : CHART_COLORS.LEGEND_DEFAULT,
			},
			itemHoverStyle: {
				color: darkMode ? CHART_COLORS.LEGEND_DARKMODE : CHART_COLORS.LEGEND_DEFAULT,
			},
		},
		tooltip: {
			enabled: true,
			shared: true,
			split: false,
			useHTML: true,
			formatter: function (this: any) {
                const compText = getComparisonText(this.y, this.point, unit);
				const symbol = '&#9632;';
				const toolTip =
					'<div><span style="font-size:16px;color:' +
					this.color +
					'">' +
					symbol +
					'</span>' +
					'<b><span> ' +
					this.key +
					'</span></b> : <b>' +
					scaleMetric(this.y, unit).formatted +
					'</b>' + compText + '</div>';
				return toolTip;
			},
		},
		series: series,
		plotOptions: {
			pie: {
                allowPointSelect: true,
				dataLabels: {
                    enabled: !showLegend,
                    formatter: function(this: any) {
                        return getLabelText(this.point.name, showPercentage ? this.point.percentage : undefined, showValue ? this.point.y : undefined, unit);
                    },
					style: {
						color: darkMode ? CHART_COLORS.LABEL_DARKMODE : CHART_COLORS.LABEL_DEFAULT,
                        textOutline: 'none'
					},
				},
			},
            series: {
                allowPointSelect: true,
                point: {
                    events: {
                        click: function(event) {
                            if (onGroupMetricSelection) {
                                event.target.point.slice();
                                const selected = !event.point.selected;
                                onGroupMetricSelection({
                                    source: GroupMetricSource.SERIES,
                                    selected,
                                    groups: [event.point.groupData],
                                    metrics: [event.point.metricData],
                                });
                            }
                        },
                        mouseOver: function (event) {
                            if (!onGroupMetricSelection) {
                                event.target.slice();
                            }
                        },
                        mouseOut: function (event) {
                            if (!onGroupMetricSelection) {
                                event.target.slice();
                            }
                        }
                    }
                }
            }
		},
	});
	if (style === "donut" && optionsCopy?.plotOptions?.pie) {
        optionsCopy.plotOptions.pie = {
            ...optionsCopy.plotOptions.pie,
            size: '100%',
            innerSize: '75%',
        };
    }
	if (options) {
		merge(optionsCopy, options);
	}
	return optionsCopy;
}

/** a String with the label for the pie slice or legend item.
 *  @param name the name of the pie chart slice.
 *  @param percentage the percentage for that slice.
 *  @param value the value for that slice.
 *  @param unit the unit for the metric.
 *  @returns a String with the label for the pie slice.*/
function getLabelText(name: string, percentage: number | undefined, value: number | undefined, unit: Unit): string {
    let label = name;
    if (percentage !== undefined || value !== undefined) {
        const data: Array<string | number> = [];
        if (percentage !== undefined) {
            data.push(precise(percentage) + " %");
        }
        if (value !== undefined) {
            data.push(scaleMetric(value, unit).formatted);
        }
        label += " (" + data.join(", ") + ")";
    }
    return label;
}

/** returns a string with the html that contains the comparison text.
 *  @param value the value of slice.
 *  @param point the highcharts point object.
 *  @param unit the Unit for the value.
 *  @returns a String with the comparison text or empty string if none. */
function getComparisonText(value: any, point: any, unit: Unit): string {
    const compSuffix = point.comparisonSuffix;
    const compValue = point.comparisonValue;
    let compText = "";
    let changeText = "";
    if (compValue !== null && compValue !== undefined) {
        compText = '<br /><b><span>' + compSuffix + '</span></b> : <b>' + scaleMetric(compValue, unit).formatted + '</b>';
        if (
            value !== undefined && compValue !== undefined &&
            !Number.isNaN(value) && !Number.isNaN(compValue)
        ) {
            let pctChange: number | undefined = undefined;
            if (compValue !== 0) {
                pctChange = 100 * ((value - compValue) / compValue);
            } else if (value !== 0) {
                // We have number / 0 which is infinity
                pctChange = value > 0 ? Number.POSITIVE_INFINITY : Number.NEGATIVE_INFINITY;
            } else if (compValue === 0 && value === 0) {
                // We have 0 / 0, that is undefined, but that really is no change
                pctChange = 0;
            }
            if (pctChange !== undefined) {
                const arrow = pctChange > 0 ? '&uarr;' : pctChange < 0 ? '&darr;' : '';
                pctChange = pctChange < 0 ? -1.0 * pctChange : pctChange; 
                pctChange = Math.min(pctChange, 1000);
                changeText = '<br /><b><span>' + 
                    STRINGS.incidents.runbookOutputs.changeValueTooltipLabel + 
                    '</span></b> : <b>' + arrow + ' ' + (pctChange >= 1000 ? "&gt; " : "") + precise(pctChange) + ' %</b>';    
            }
        }
    }
    return compText + changeText;
}
