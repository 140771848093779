/** This file defines the set primitive variables editor React component.
 *  @module */
import React, { useCallback, useContext, useState, useEffect } from "react";
import { SimpleNodeEditorProps } from "../simple/SimpleNodeEditor";
import { HELP, STRINGS } from "app-strings";
import { UniversalNode } from "../../UniversalNode";
import { SetVariableElement } from "./SetVariableElement";
import { Tab, TabbedSubPages } from "components/common/layout/tabbed-sub-pages/TabbedSubPages";
import { SHOW_CONTEXT } from "components/enums/QueryParams";
import { RunbookContextSummary } from "../RunbookContextSummary";
import { Button, Callout, Icon, Intent } from "@blueprintjs/core";
import { Classes, IconNames, useStateSafePromise } from "@tir-ui/react-components";
import { generateRandomID } from "components/common/condition-tree-builder/condition/ConditionUtils";
import { SetSimpleVariablesNodeUtils, SET_SIMPLE_VARIABLES_NODE_EDIT_PROPS } from "./SetSimpleVariablesNodeUtils";
import { COLUMN_TYPE } from "pages/riverbed-advisor/views/runbook-view/Runbook.type";
import { GLOBAL_SCOPE, INCIDENT_SCOPE, RUNTIME_SCOPE, PrimitiveVariable, PrimitiveVariableType } from "utils/runbooks/VariablesUtils";
import { VariableContext } from "utils/runbooks/VariableContext";
import { InlineHelp } from "components/common/layout/inline-help/InlineHelp";
import { NodeLibraryNode } from 'pages/create-runbook/views/create-runbook/NodeLibrary';
import { BasicDialog, DialogState, updateDialogState } from "components/common/basic-dialog/BasicDialog";
import { LiquidTemplateEditor } from "../transform/LiquidTemplateEditor";
import { 
    InputType, LIFECYCLE_TRIGGER_TYPES, VARIANTS_WITH_GLOBAL_VARS, VARIANTS_WITH_INCIDENT_VARS, 
    VARIANTS_WITH_RUNTIME_BUILTIN_VARS 
} from "../../types/GraphTypes";
import { GenericKey } from "utils/runbooks/NodeUtil";
import { getNodeFromGraphDef } from "utils/runbooks/RunbookUtils";
import { Context, RunbookContext } from "utils/runbooks/RunbookContext.class";
import { DataOceanMetadata } from "../data-ocean/DataOceanMetadata.type";
import { DataOceanUtils } from "../data-ocean/DataOceanUtils";
import { DataLoadFacade } from "components/reporting/data-load-facade/DataLoadFacade";
import { CustomPropertyContext } from "pages/create-runbook/views/create-runbook/CustomPropertyTypes";
import './SimpleVariablesEditor.scss';

/** this interface defines the object that returns all the primitive variable definitions for each scope. */
export interface PrimitiveVariablesByScope {
    /** the primitive variables for the runtime scope. */
    [RUNTIME_SCOPE]: Array<PrimitiveVariable>;
    /** the primitive variables for the incident scope. */
    [INCIDENT_SCOPE]: Array<PrimitiveVariable>;
    /** the primitive variables for the global scope. */
    [GLOBAL_SCOPE]: Array<PrimitiveVariable>;
}

/** this interface defines the properties that are used to store the nodes configurable parameters. */
interface SetSimpleVariablesNodeProperties {
    /** a string with the transform template. */
    transformTemplate?: string;
    /** an array with the variables that are being set by this node */
    variables?: VariableUIProperties[];
}

/** Interface that describes the variable's properties */
export interface VariableUIProperties {
    id: string;
    /** the name of the variable */
    name: string;
}

interface VariableProperties {
    /** the name of the variable */
    name: string;
}

export const VARIABLES_LIMIT = 100;

/** Component for editing the properties of a set primitive variables node
 *  @param selectedNode - Currently selected active set primitive variables node
 *  @param libraryNode- Selected set primitive variables node's meta data.
 *  @param graphDef - Graphdef object that defines the entire runbook. Provides a way to access all the nodes in the graph 
 *  @returns a JSX component with the trigger node editor. */
export const SetSimpleVariablesEditor = React.forwardRef(({ selectedNode, libraryNode, graphDef, variant }: SimpleNodeEditorProps, ref: any): JSX.Element => {
    // Component states
    const [curProperties, setCurProperties] = useState<SetSimpleVariablesNodeProperties>({
        transformTemplate: selectedNode?.getProperty(SET_SIMPLE_VARIABLES_NODE_EDIT_PROPS.TRANSFORM_TEMPLATE),
        variables: (selectedNode?.getProperty(SET_SIMPLE_VARIABLES_NODE_EDIT_PROPS.VARIABLES) as Array<VariableUIProperties>)?.map(item => { return { ...item, id: generateRandomID() }; }),
    });
    const {getVariables} = useContext(VariableContext);

    const [primitiveVariablesOptionList] = useState<PrimitiveVariablesByScope>({
        runtime: getVariables(RUNTIME_SCOPE, VARIANTS_WITH_RUNTIME_BUILTIN_VARS.includes(variant)).primitiveVariables.filter(
            (item) => !item.isReadOnly && ![
                ...[PrimitiveVariableType.ALLUVIO_EDGE, PrimitiveVariableType.AUTH_PROFILE, PrimitiveVariableType.CONNECTOR]
            ].includes(item.type)
        ),
        incident: VARIANTS_WITH_INCIDENT_VARS.includes(variant) ? 
            getVariables(INCIDENT_SCOPE, true).primitiveVariables.filter((item) => !item.isReadOnly) : [],
        global: VARIANTS_WITH_GLOBAL_VARS ? 
            getVariables(GLOBAL_SCOPE, true).primitiveVariables.filter((item) => !item.isReadOnly) : []
    } as PrimitiveVariablesByScope);

    const [variablesList, setVariablesList] = useState(curProperties?.variables && curProperties.variables.length > 0 ? curProperties?.variables : [{ id: generateRandomID(), name: "" }]);

    const toolbarVariables = getVariables(RUNTIME_SCOPE, VARIANTS_WITH_RUNTIME_BUILTIN_VARS.includes(variant)).primitiveVariables.concat(
        VARIANTS_WITH_INCIDENT_VARS.includes(variant) ? getVariables(INCIDENT_SCOPE, true).primitiveVariables : []
    ).concat(
        VARIANTS_WITH_GLOBAL_VARS.includes(variant) ? getVariables(GLOBAL_SCOPE, true).primitiveVariables : []
    );

    const [objMetricMetaData, setObjMetricMetaData] = useState<DataOceanMetadata>();
    const [executeSafely] = useStateSafePromise();

    const [dialogState, setDialogState] = useState<any>({showDialog: false, loading: true, title: STRINGS.viewRunbooks.inputDialogTitle, dialogContent: null, dialogFooter: null});

    const fetchData = useCallback(
        () => {
            return executeSafely(DataOceanUtils.init()).then((response: any) => {
                setObjMetricMetaData(response);
            }, error => {
                console.error(error);
            });
        },
        [executeSafely]
    );

    useEffect(() => {
        // Fetch Meta data on load.
        fetchData();
    }, [fetchData]);

    const customProperties = useContext(CustomPropertyContext);

    /**
     * Update the selected node properties. This is invoked when done button is clicked
     * on the node editor dialog.
     * @param properties -  Properties in selected node that need to updated
     * @param selectedNode - node being editied
     * @param libraryNode - object that specifies all the editable properties for a given node.
     */
    function updateNode(properties: SetSimpleVariablesNodeProperties, selectedNode: UniversalNode | undefined, libraryNode: NodeLibraryNode | undefined) {
        if (!selectedNode || !libraryNode || !properties) {
            console.warn("updateNode has invalid inputs. Node update failed");
            return;
        }

        selectedNode.setProperty(SET_SIMPLE_VARIABLES_NODE_EDIT_PROPS.TRANSFORM_TEMPLATE, curProperties?.transformTemplate);
        let variables: Array<VariableProperties> = curProperties?.variables || [];
        variables = variables?.map((item) => {
            return { name: item.name };
        });
        selectedNode.setProperty(SET_SIMPLE_VARIABLES_NODE_EDIT_PROPS.VARIABLES, variables);
    }

    function getNodeProperties(properties, selectedNode: UniversalNode | undefined, libraryNode: NodeLibraryNode | undefined) {
        if (!selectedNode || !libraryNode || !properties) {
            console.warn("updateNode has invlaid inputs. Node update failed");
            return;
        }

        const outputProperties = {};
        outputProperties[SET_SIMPLE_VARIABLES_NODE_EDIT_PROPS.TRANSFORM_TEMPLATE] = curProperties?.transformTemplate;
        return outputProperties;
    }

    ref.current = {
        updateNode: () => {
            updateNode(curProperties, selectedNode, libraryNode);
        },
        validate: () => {
            const errorMessages = new Array<string>();
            SetSimpleVariablesNodeUtils.validateNodeProperties(
                curProperties,
                errorMessages,
            );
            return errorMessages;
        }
    };

    /**
     * Partially update the state of the current properties
     * @param partialProps -  set primitive variables node properties that need to be updated
     */
    function syncProperties(partialProps: SetSimpleVariablesNodeProperties): void {
        const updatedProperties = { ...curProperties, ...partialProps };
        setCurProperties(updatedProperties);
    }

    const onRemoveSimpleVariableElementClicked = useCallback((elementId: string) => {
        const updatedOutputsList: any[] = [];
        if (variablesList) {
            for (const output of variablesList) {
                if (output.id !== elementId) {
                    updatedOutputsList.push(output);
                }
            }
        }
        setVariablesList(updatedOutputsList);
        syncProperties({ variables: updatedOutputsList })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [variablesList]);

    function handleVariableChange(event) {
        let variables: Array<any> = curProperties?.variables || [];
        variables = ([] as Array<any>).concat(variables);
        let varDef: any = variables.find((variable) => variable.id === event.id);
        if (!variables.length) {
            varDef = { id: event.id, name: event.name };
            variables.push(varDef);
            let tempVar = variablesList.find(variable => variable.id === event.id);
            if (tempVar) {
                tempVar.name = event.name;
            }
        } else {
            varDef.name = event.name;
        }
        setVariablesList(variables);
        syncProperties({ variables: variables });
    }

    const [triggerKeys, setTriggerKeys] = useState<GenericKey[]>([]);
    const [triggerMetrics, setTriggerMetrics] = useState<GenericKey[]>([]);
    const [parentKeys, setParentKeys] = useState<GenericKey[]>([]);
    const [parentMetrics, setParentMetrics] = useState<GenericKey[]>([]);
    useEffect(() => {
        if (selectedNode?.node) {
            let selNodeId = selectedNode?.getId();
            selNodeId = selNodeId ? selNodeId : "";
            let nodeDefObj = getNodeFromGraphDef(selNodeId, graphDef);
            if (nodeDefObj && objMetricMetaData) {
                let runbookContext = new RunbookContext(nodeDefObj, graphDef, objMetricMetaData, customProperties, {forLiquid: true});
                const nodeContexts: Context[] = runbookContext.getNodeContexts();
                let nodeContext: Context | undefined =  nodeContexts?.length ? nodeContexts[nodeContexts.length - 1] : undefined;
                if (nodeContext) {
                    setParentKeys(nodeContext.expandedKeys || []);
                    setParentMetrics(nodeContext.metrics || []);
                }
                if (runbookContext && runbookContext.getTriggerContext()) {
                    setTriggerKeys((runbookContext.getTriggerContext()?.expandedKeys || []).map((key) => {
                        return {...key, triggerType: runbookContext.getTriggerType(), liquid_key: runbookContext.getTriggerContext()?.liquid_key};
                    }));
                    if ([...LIFECYCLE_TRIGGER_TYPES, InputType.WEBHOOK].includes(runbookContext.getTriggerType() as InputType)) {
                        setTriggerMetrics([]);
                    } else {
                        setTriggerMetrics(runbookContext.getTriggerContext()?.metrics || []);
                    }
                }
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedNode, objMetricMetaData])

    let outputDataText = "";
    let fakeData: Object = {};
    if (curProperties?.variables?.length) {
        let varSet: Array<any>;
        varSet = curProperties?.variables?.map((variable) => {
            const newVariable = primitiveVariablesOptionList?.[RUNTIME_SCOPE]?.find(variableDef => variableDef?.name === variable.name)
                || primitiveVariablesOptionList?.[INCIDENT_SCOPE]?.find(variableDef => variableDef?.name === variable.name)
                || primitiveVariablesOptionList?.[GLOBAL_SCOPE]?.find(variableDef => variableDef?.name === variable.name);
            return newVariable;
        });
        for (const variableDef of varSet) {
            let maxValue = 2000;
            switch (variableDef?.type) {
                case COLUMN_TYPE.FLOAT:
                    if (variableDef.unit && (variableDef.unit === "%" || variableDef.unit === "pct" || variableDef.unit === "percent")) {
                        maxValue = 100;
                    }
                    fakeData[variableDef?.name] = String(Math.random() * maxValue);
                    break;
                case COLUMN_TYPE.INTEGER:
                    fakeData[variableDef?.name] = String(Math.round(Math.random() * maxValue));
                    break;
                case COLUMN_TYPE.BOOLEAN:
                    fakeData[variableDef?.name] = String(true);
                    break;
                case COLUMN_TYPE.STRING:
                    fakeData[variableDef?.name] = "valueOfVariable";
                    break;
                case COLUMN_TYPE.JSON:
                    fakeData[variableDef?.name] = {key: "value"};
                    break;
                default:
                    fakeData[variableDef?.name] = "2540.3";
            }
        }
    }
    outputDataText = JSON.stringify(fakeData, null, 4);

    function updateVariableTemplate() {
        let updatedTransformTemplateValue = curProperties?.transformTemplate || '';
        setDialogState({
            showDialog: true,
            doNotAllowOutsideClick: true,
            title: STRINGS.runbookEditor.nodeEditor.variables.primitiveVariable.templateVars,
            dialogContent: <>
                <TabbedSubPages renderActiveTabPanelOnly={false}>
                    <Tab id="template" title={STRINGS.runbookEditor.nodeEditor.templateTabName}>
                        <LiquidTemplateEditor
                            data-testid="template_modal"
                            placeholder={STRINGS.runbookEditor.nodeEditor.variables.primitiveVariable.templatePlaceholder}
                            value={curProperties?.transformTemplate}
                            style={{ resize: "both", width: "100%", height: "180px", fontFamily: "monospace", fontSize: "small", borderColor: "#999" }}
                            className="bg-white text-black mt-3"
                            variables={toolbarVariables}
                            triggerExpandedKeys={triggerKeys} triggerMetrics={triggerMetrics}
                            parentExpandedKeys={parentKeys} parentMetrics={parentMetrics}
                            onChange={value => {
                                updatedTransformTemplateValue = value;
                            }}
                            variant={variant}
                        />
                    </Tab>
                    <Tab id="example" title={STRINGS.runbookEditor.nodeEditor.exampleTemplateTabName}>
                        <textarea
                            autoFocus
                            value={outputDataText}
                            disabled={true}
                            style={{resize: "both", width: "100%", height: "180px", fontFamily: "monospace", fontSize: "small", borderColor: "#999" }}
                            className="bg-white text-black mt-3"
                        ></textarea>
                    </Tab>
                </TabbedSubPages>
            </>,
            closeable: true,
            dialogFooter: <div className="d-flex justify-content-between flex-grow-1">
                <Button
                    className="ml-0"
                    text={STRINGS.runbookEditor.discardAndCloseBtn}
                    intent={Intent.DANGER}
                    onClick={() => {
                        setDialogState({ ...dialogState, showDialog: false, closeable: true });
                    }}
                />
                <Button
                    icon={IconNames.SAVED}
                    text={STRINGS.runbookEditor.saveAndCloseBtn}
                    intent={Intent.SUCCESS}
                    onClick={() => {
                        const updatedPartialProperties = {
                            transformTemplate: updatedTransformTemplateValue,
                        }
                        syncProperties(updatedPartialProperties);
                        setDialogState({ ...dialogState, showDialog: false, closeable: true });
                    }}
                />
            </div>,
        } as DialogState);
    }

    // Wait for api call to complete before rendering the editor
    if (!objMetricMetaData) {
        return <tr><td colSpan={3}><DataLoadFacade loading /></td></tr>;
    }

    return (
        <>
            <BasicDialog dialogState={dialogState} className="variable-template-dialog" onClose={() => {
                setDialogState(updateDialogState(dialogState, false, false, []));
            }} />
            <tr>
                <td className="display-9 pb-2" colSpan={3}>
                    <Callout intent={Intent.PRIMARY}>
                        {STRINGS.runbookEditor.nodeEditor.variables.primitiveVariable.infoMessage}
                    </Callout>
                </td>
            </tr>
            <tr>
                <td className="display-7 font-weight-bold pt-2 pb-3">
                    <InlineHelp helpMapping={HELP.RunbookNodeCategory.Variables.parse_dataset_variable.variableset}>
                        {STRINGS.runbookEditor.nodeEditor.variables.primitiveVariable.variablesToSet}
                    </InlineHelp>
                </td>
            </tr>
            <tr>
                <td colSpan={3}>{variablesList.map((item) => (
                    <SetVariableElement key={"id-" + item.id}
                        onRemoveSimpleVariableElementClicked={onRemoveSimpleVariableElementClicked}
                        onChange={handleVariableChange}
                        simpleVariableElementDefinition={item}
                        selectedVariables={variablesList.map(item => {return item.name})}
                        isFirstItem={variablesList.indexOf(item) === 0}
                        optionsList={primitiveVariablesOptionList}
                        nodeType={libraryNode?.type}
                        variant={libraryNode?.uiAttrs?.variant}
                    />))}
                </td>
            </tr>
            <tr>
                <td colSpan={3} >
                    <div className="card p-2 mb-4 add-output-control">
                        <Button
                            aria-label="add-variable-element"
                            icon={IconNames.ADD}
                            minimal
                            disabled={variablesList.length >= VARIABLES_LIMIT}
                            text={STRINGS.runbookEditor.nodeEditor.variables.primitiveVariable.addVariable}
                            onClick={() => {
                                const newVariable = { id: generateRandomID(), name: "" }
                                setVariablesList([...variablesList, newVariable]);
                                syncProperties({ variables: [...variablesList, newVariable] });
                            }}
                        />
                    </div>
                </td>
            </tr>
            <tr>
                <td className="display-7 font-weight-bold pt-3">
                    <InlineHelp helpMapping={HELP.RunbookNodeCategory.Variables.parse_dataset_variable.templateassign}>
                        {STRINGS.runbookEditor.nodeEditor.variables.primitiveVariable.templateVars}
                    </InlineHelp>
                </td>
                <td className="display-7 pt-3 float-right">
                    <Button
                        aria-label="transform-template-button"
                        className={Classes.MINIMAL}
                        icon={<Icon icon="fullscreen"/>}
                        onClick={() => updateVariableTemplate()}
                    />
                </td>
            </tr>
            <tr>
                <td className="display-7 font-weight-bold pt-2" colSpan={3}>
                    <TabbedSubPages renderActiveTabPanelOnly={false} className="set-simple-variables-editor-tabs">
                        <Tab id="template" title={STRINGS.runbookEditor.nodeEditor.templateTabName}>
                            <LiquidTemplateEditor
                                data-testid="template"
                                placeholder={STRINGS.runbookEditor.nodeEditor.variables.primitiveVariable.templatePlaceholder}
                                value={curProperties?.transformTemplate}
                                style={{ width: "100%", height: "180px", fontFamily: "monospace", fontSize: "small", borderColor: "#999" }}
                                className="bg-white text-black mt-3"
                                variables={toolbarVariables}
                                triggerExpandedKeys={triggerKeys} triggerMetrics={triggerMetrics}
                                parentExpandedKeys={parentKeys} parentMetrics={parentMetrics}
                                onChange={value => {
                                    const updatedPartialProperties = {
                                        transformTemplate: value,
                                    }
                                    syncProperties(updatedPartialProperties);
                                }}
                                variant={variant}
                            />
                        </Tab>
                        <Tab id="example" title={STRINGS.runbookEditor.nodeEditor.exampleTemplateTabName}>
                            <textarea
                                autoFocus
                                value={outputDataText}
                                disabled={true}
                                style={{ width: "100%", height: "180px", fontFamily: "monospace", fontSize: "small", borderColor: "#999" }}
                                className="bg-white text-black mt-3"
                            ></textarea>
                        </Tab>
                    </TabbedSubPages>
                </td>
            </tr>
            {SHOW_CONTEXT && <RunbookContextSummary
                currentProperties={getNodeProperties(curProperties, selectedNode, libraryNode)}
                node={graphDef.nodes.find(node => node.id === selectedNode?.getId())!} graphDef={graphDef}
                showOutputExample={true} showInputExample={true}
            />}
        </>
    )
});
