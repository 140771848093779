import React, { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { getRoutes } from "config";
import { EdgeGatewaysView } from "pages/edge-configuration/views/EdgeGatewaysView";
import { PageWithHeader } from "components/sdwan/layout/page-with-header/PageWithHeader";
import AutoUpdateControl, {
    AutoUpdateState,
    TimeType,
} from "components/common/auto-update/AutoUpdateControl";
import {
    Tab,
    TabbedSubPages,
} from "components/common/layout/tabbed-sub-pages/TabbedSubPages";
import AtternitySaaSView from "./views/AtternitySaaSView";
import CloudIMLegal from "../cloudim/views/cloudim-legal/CloudIMLegal";
import CloudIMDataSourceView from "../cloudim/views/cloudim-config/CloudIMDataSourceView";
import { tenantFF } from "utils/stores/FeatureFlagStore";

import { HELP, STRINGS } from "app-strings";

const initAutoUpdate: AutoUpdateState = {
    enabled: true,
    interval: 1,
    lastUpdate: 60 * Math.floor(new Date().getTime() / (60 * 1000)),
    sequenceNumber: 0,
};

export const AutoUpdateContext = React.createContext(initAutoUpdate);

const EdgeConfigPage = (): JSX.Element => {
    const [autoUpdate, setAutoUpdate] = useState<any>(initAutoUpdate);
    const history = useHistory();
    const location = useLocation();
    /** feature flag */
    const ffObj = tenantFF();

    useEffect(() => {
        // NOTE: will need to include new tabs as they are added, if you need to
        // change the struct feel free to do so.
        const validTabs = ["tab-edge", "tab-aternity", "tab-cloudim"];
        // if feature flag cloudim is not explicitly disabled, pop the cloudim tab
        if (ffObj.cloudim !== true) {
            validTabs.pop();
        }
        const queryParams = new URLSearchParams(location.search);
        const tab = queryParams.get("tab");

        // redirect if the tab is not valid
        if (tab && !validTabs.includes(tab)) {
            history.push("/edge-configuration");
        }
    }, [location, history, ffObj.cloudim]);

    return (
        <PageWithHeader
            name={EdgeConfigPage.name}
            addPadding={true}
            showTimeBar={false}
            showGlobalFilters={false}
            {...getRoutes()["edge-configuration"]}
            title={STRINGS.DATA_SOURCES.pageTitle}
            rightAlignedControls={
                <AutoUpdateControl
                    autoUpdate={autoUpdate}
                    timeType={TimeType.DURATION}
                    showPlayPauseButton={true}
                    onAutoUpdateChanged={(autoUpdate) =>
                        setAutoUpdate(autoUpdate)
                    }
                    showRefreshButton={!autoUpdate.enabled}
                    className="d-none d-md-block"
                />
            }
            helpInfo={HELP.AlluvioEdgeConfiguration}
        >
            <TabbedSubPages className="pt-2">
                <Tab id="tab-edge" title={"Edges"}>
                    <AutoUpdateContext.Provider value={autoUpdate}>
                        <EdgeGatewaysView />
                    </AutoUpdateContext.Provider>
                </Tab>
                <Tab id="tab-aternity" title={"ATERNITY SaaS"}>
                    <AtternitySaaSView />
                </Tab>
                {ffObj.cloudim ? (
                    <Tab id="tab-cloudim" title={"CloudIM"}>
                        <CloudIMLegal />
                        <CloudIMDataSourceView />
                    </Tab>
                ) : null}
            </TabbedSubPages>
        </PageWithHeader>
    );
};

export { EdgeConfigPage };
