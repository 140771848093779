/** This module contains the RunbookApiService that can be used to query runbook data
 *  @module
 */
import { ApiService, EtagResult } from 'utils/services/ApiService';
import { InputType, Variant } from 'components/common/graph/types/GraphTypes';
import { VariableCollection } from 'utils/runbooks/VariablesUtils';

/** the latest version of the runbook orchestrator service. */
const VERSION = "1.0";

// The URL for the API server.
export const RUNBOOK_URL = '/api/affogato/runbooks/';

/** this type defines a RunbookConfig. */
export type RunbookConfig = {
    /** a String with the id of the runbook. */
    id?: string;
    /** a String with the type of the runbook. */
    type?: string;
    /** a String with the label for the runbook.  This is used in NodeRed. */
    label?: string;
    /** a String with the label for the runbook.  This is set in the azure function back-end. */
    name?: string;
    /** a boolean value true if disabled, false otherwise.  This is set in NodeRed. */
    disabled?: boolean;
    /** a boolean value true if iaReady, false otherwise.  This is set in azure function back-end. */
    isReady?: boolean;
    /** a String with the runbook description.  This is set in NodeRed. */
    into?: string;
    /** a String with the runbook description.  This is set in the azure function back-end. */
    description?: string;
    /**  the type of input the runbook will accept, for Alpha this can be interface, device, or application. */
    triggerType?: InputType;
    /** the variant of runbook, "incident" or "lifecycle". */
    variant?: Variant;
    /** a string with the last update time in seconds. */
    lastUpdate?: string;
    /** a boolean value that should be true if the runbook has been validated, and false otherwise. */
    isValidated?: boolean;
    /** an array of nodes in the runbook. */
    nodes?: Array<RunbookNode>;
    /** the array of configs in the runbook. */
    configs?: Array<any>;
    /** the array of subflows in the runbook. */
    subflows?: Array<any>;
    /** the etag is placed in the object when the API is returning an array of items. */
    eTag?: string;
    /** runtime variables definition. */
    runtimeVariables?: VariableCollection;
    /** subflow variables definitions. */
    subflowVariables?: VariableCollection;
    /** if the runbook is scheduled */
    isScheduled?: boolean;
    /** the current version, potentially after user changes. */
    version?: string;
    /** the series id that identifies all the versions of a particular subflow. */
    seriesId?: string;
    /** a structure with all the other versions of this particular runbook or subflow. */
    otherVersions?: RunbookVersionInfo[];
    
    /** a catch-all for any additional properies of the runbook. */
    [anyOtherKey: string]: any;
};

/** this type defines the information about the runbook versions. */
export type RunbookVersionInfo = {
    /** a String with the id of the runbook. */
    id?: string;
    /** a String with the label for the runbook.  This is set in the azure function back-end. */
    name?: string;
    /** a String with the runbook description.  This is set in the azure function back-end. */
    description?: string;
    /** a String with the version. */
    version?: string;
}

/** this type defines a RunbookNode. */
export type RunbookNode = {
    /** a String with the id of the node. */
    id: string;
    /** a String with the type of the node. */
    type: string;
    /** a String with the label for the node. */
    label?: string;
    /** a String with the name for the node instance. */
    name?: string;
    /** a boolean value true if disabled, false otherwise. */
    disabled?: boolean;
    /** a String with the node description. */
    info?: string;
    /** the x-coordinate of the node. */
    x?: string | number;
    /** the y-coordinate of the node. */
    y?: string | number;
    /** the id of the runbook the node is in. */
    z?: string;
    /** the nodes this node's outputs are connected to. */
    wires?: any;
    /** a string with the name of the category. This is used in subflows. */
    category?: string;
    /** a string with the hex color for the node. This is used in subflows. */
    color?: string;
    /** an array of node red environment settings. */
    env?: Array<RunbookEnvSetting>;
    /** an array with the input settings.  */
    in?: Array<any>;
    /** an array with the input labels. */
    inputLabels?: Array<string>;
    /** an array with the output settings.  */
    out?: Array<any>;
    /** an array with the output labels.  */
    outputLabels?: Array<string>;
    /** a catch-all for any additional properies of the node. */
    [anyOtherKey: string]: any;
};

/** this type defines a node red environment setting. */
export type RunbookEnvSetting = {
    /** a String with the name for the environment setting. */
    name: string;
    /** a String with the type of the environment setting. */
    type: string;
    /** the value of the environment setting. */
    value?: any;
};

/** This type defines the runbook input format. */
export type RunbookInputs = {
    /** the tenant id. */
    utid: string;
    /** the incident id. */
    incidentId: string;
    /** the detection information. */
    detection: RunbookInputDetection;
    /** a string with the trigger id. */
    triggerId: string;
    /** the trigger mode.  For the UI it is always manual. */
    triggerMode: {
        /** a boolean value, true if a manual run through the UI, false if from correlation engine. */
        manualRun: boolean;
        /** the source of the trigger, correlationEngine, test, or onDemand. */
        source: INVOCATION_TYPE;
        /** an optional string with the feature that is being used. */
        feature?: string;
    },
    /** the id of the runbook that should be run. */
    runbookId?: string;
    /** a string with the id of the data source that is the vantage point or undefined if it is not set. */
    vantagePoint?: string;
    /** the duration. */
    duration?: string;
    /** an optional string in the format of seconds.nanoseconds that specifies what end time
     *  to use when running the runbook.  If it isn't specified the runbook orchestrator will 
     *  use now. */
    endTime?: string;
    /** a JSON object with the variable overrides. */
    variableOverrides?: {[x: string]: any};
    /** a JSON object with the variable overrides types. */
    variableTypes?: {[x: string]: any};
    /** An object containing the edges and auth profiles, which is used to extract the friendly edge or auth profile name and display it in the scheduled runbook wizard review step */
    edgesAndAuthProfilesForScheduled?: { edges: Array<any> | undefined, authProfiles: Array<any> | undefined}
    /** if there were any errors that happened when creating the runbook inputs object, they will be placed here. */
    errors?: string[];
};

/** the type of invocation, from the correlation engine, a test, or on demand. */
export enum INVOCATION_TYPE {
    /** the invocation came from the correlation engine. */
    correlationEngine   = "correlationEngine",
    /** the invocation came from a test. */
    test                = "test",
    /** the invocation came from an on-demand request. */
    onDemand            = "onDemand"
}

/** This type defines the runbook input detection format. */
export type RunbookInputDetection = {
    /** a string with the detection/lead/trigger id. */
    id: string;
    /** the entity object with the inputs to the runbook orchestrator. */
    entity?: RunbookInputEntity;
    /** the incident primary indicator object if it exists. */
    primaryIndicator?: RunbookInputPrimaryIndicator;
    /** a RunbookInputInputs object with the inputs that are passed to the runbook orchestrator. */
    inputs?: RunbookInputInputs;
    /** a RunbookInputObject which specifies which object is triggering the runbook. */
    object?: RunbookInputObject;
    /** the indicators that are used to specify multiple devices in the Multi-Device Down trigger.  This will be used for
     *  apps and other entities at some point. */
    indicators?: any;
};

/** This type defines the runbook input entity format. */
export type RunbookInputEntity = {
    /** a string with the entity kind. */
    kind: string;
    /** an object with the entity attributes. */
    attributes: RunbookEntityAttributes;
}

/** This type defines the runbook entity attributes format. */
export type RunbookEntityAttributes = {
    /** a string with the entity uuid.  This should be the uuid from the metadata service. */
    uuid?: string;
    /** looks like the uuid changed to id.  For backwards compatibility supply both. */
    id?: string;
    /** the name of the object, for example for an interface it would be "1.1.1.1:2001". */
    name?: string;
    /** the ip address of the device or interface. */
    ipaddr?: string;
    /** the ifindex for the interface. */
    ifindex?: number;
    /** the ifalias attribute from the interface metadata. */
    ifalias?: string | null;
    /** the ifdescr attribute from the interface metadata. */
    ifdescr?: string | null;    
    /** the uuid of the location. */
    location_uuid?: string;
    /** a string with the application name. */
    application?: string;
    /** a string with the location name. */
    location?: string;
    /** the metadata object. */
    metadata?: RunbookMetadata;
    /** the id of the data source if any. */
    source_id?: string;
    /** a string with the request body. */
    requestBody?: any;
    /** a string with the request headers. */
    requestHeaders?: Record<string, string[]>;
    /** a string with the request URL. */
    requestPath?: string;
    /** a string with the request query parameters. */
    requestQueryParameters?: Record<string, string>;
    /** the time at which the incident had its earliest indicator in seconds.nanoseconds. */
    earliestIndicator?: string;
    /** the time at which the incident had its latest indicator in seconds.nanoseconds. */
    latestIndicator?: string;
}

/** This type defines the runbook trigger. */
export type RunbookTrigger = {
    /** a string with the trigger id. */
    id: string;
    /** a String with the runbook instance id. */
    runbookInstanceId?: string;
    /** an optional String with the last updated time in ISO format. */
    createdAt?: string;
    /** an optional String with the current runbook status. */
    runbookStatus?: string;
}

/** This type defines the runbook input impact analysis. */
export type RunbookImpactAnalysis = {
    /** an array of ImpactedItems with the users that were impacted. */
    impactedUsers: ImpactedItem[];
    /** an array of ImpactedItems with the locations that were impacted. */
    impactedLocations: ImpactedItem[];
    /** an array of ImpactedItems with the applications that were impacted. */
    impactedApplications: ImpactedItem[];
}

/** This type defines an impacted user, location or application. */
export type ImpactedItem = {
    /** a String with the name. */
    name: string;
}

/** This type defines the runbook input primary indicator format. */
export type RunbookInputPrimaryIndicator = {
    /** an object with the primary indicator data. */
    data: {indicator: {metric: string, details?: {actual_value: string, acceptable_low_value: string, acceptable_high_value: string}}};
}


/** This type defines the runbook entity attributes metadata format. */
export type RunbookMetadata = {
    /** a string with the entity uuid.  This should be the uuid from the metadata service. */
    uuid?: string;
    /** the name of the object, for example for an interface it would be "1.1.1.1:2001". */
    name?: string;
    /** the ip address of the device or interface. */
    ipaddr?: string;
    /** the ifindex for the interface. */
    ifindex?: number;
    /** the ifalias attribute from the interface metadata. */
    ifalias?: string | null;
    /** the ifdescr attribute from the interface metadata. */
    ifdescr?: string | null;    
    /** a String with the element type, this only applies for a device or interface. */
    elementType?: string;
    /** a String with the type, this only applies for a device or interface. */
    type?: string;
    /** a String with the device hostname, this only applies for a device. */
    hostname?: string;
    /** a String with the device model, this only applies for a device. */
    model?: string;
    /** a String with the device serial number, this only applies for a device. */
    serial_number?: string;
    /** a String with the device OS, this only applies for a device. */
    os_version?: string;
    /** a String with the device vendor, this only applies for a device. */
    vendor?: string;
    /** an object which has the application information when the metadata is for an application. */
    application?: RunbookMetadataApplication;
    /** an object which has the location information. */
    location?: RunbookMetadataLocation;
    /** an object which has the server information. */
    network_server?: RunbookMetadataServer;
    /** an object which has the geo information. */
    geo?: RunbookMetadataGeo;
    /** an integer with the inbound speed, this will only be included when the metadata object is an interface. */
    inbound_speed?: number;
    /** an integer with the outbound speed, this will only be included when the metadata object is an interface. */
    outbound_speed?: number;
    /** a String with the userId. */
    userId?: string;
    /** a String with the note contents, if any. */
    content?: string;    
    /** an optional String with the creation time in ISO format. */
    createdAt?: string;
    /** an optional String with the current incident status. */
    status?: string;
    /** an optional String with the old incident status. */
    oldStatus?: string;
    /** an optional String with the last updated time in ISO format. */
    lastUpdatedAt?: string;
    /** a String with the userId. */
    lastUpdatedByUser?: string;
    /** a boolean value, true if the incident is ongoing, false otherwise. */
    isOngoing?: boolean;
    /** an optional String with the incident end time in ISO format. */
    endTime?: string;
    /** an array of RunbookTriggers with the trigger information for the input. */
    runbookTriggers?: RunbookTrigger[];
}

/** This type defines the runbook entity attributes metadata application format. */
export type RunbookMetadataApplication = {
    /** a string with the application uuid. */
    uuid: string;
    /** a string with the application name. */
    name: string;
}

/** This type defines the runbook entity attributes metadata server format. */
export type RunbookMetadataServer = {
    /** a string with the server uuid. */
    uuid: string;
    /** a string with the server IP address. */
    ipaddr: string;
}

/** This type defines the runbook entity attributes metadata location format. */
export type RunbookMetadataLocation = {
    /** a string with the location uuid. */
    uuid: string;
    /** a string with the location name. */
    name: string;
    /** a string with the type of location, for example "business" or "physical". */
    type?: string;
}

/** This type defines the runbook entity attributes metadata geo format. */
export type RunbookMetadataGeo = {
    /** a string with the city name. */
    city: string;
    /** a string with the country code. */
    country_code: string;
    /** a float with the latitude of the geo location. */
    latitude: number;
    /** a float with the longitude of the geo location. */
    longitude: number;
}

/** This type defines the runbook input inputs format. */
export type RunbookInputInputs = {
    /** the configuration for an interface input into the runbook. */
    networkInterface?: RunbookNetworkInterfaceInput;
    /** the configuration for an device input into the runbook. */
    networkDevice?: RunbookNetworkDeviceInput;
    /** the configuration for an application input into the runbook. */
    application?: RunbookApplicationInput;
    /** the configuration for an location input into the runbook. */
    location?: RunbookLocationInput;
};

/** This type defines the network interface format. */
export type RunbookNetworkInterfaceInput = {
    /** a string with the device IP. */
    ipaddr: string;
    /** a string with the interface ifindex. */
    ifindex: number;
    /** the ifalias attribute from the interface metadata. */
    ifalias?: string | null;
    /** the ifdescr attribute from the interface metadata. */
    ifdescr?: string | null;
    /** the uuid of the entity, if available from DAL. */
    uuid?: string;
};

/** This type defines the network device format. */
export type RunbookNetworkDeviceInput = {
    /** a string with the device IP. */
    ipaddr: string;
    /** the uuid of the entity, if available from DAL. */
    uuid?: string;
};

/** This type defines the network application format. */
export type RunbookApplicationInput = {
    /** a string with the application name. */
    name: string;
    /** the uuid of the entity, if available from DAL. */
    uuid?: string;
};

/** This type defines the location format. */
export type RunbookLocationInput = {
    /** a string with the location name. */
    name: string;
    /** the uuid of the entity, if available from DAL. */
    uuid?: string;
};

/** This type defines the runbook input object format. */
export type RunbookInputObject = {
    /** the name of the object, for example for an interface it would be "1.1.1.1:2001". */
    name: string;
    /** a string with the object kind, for example for an interface it would be "network_interface" */
    kind: string;
};

/** we have two runbook services, this service and node red, this interface defines the common
 *  functions that the two services implement. */
export interface RunbookServiceIfc {
    /** returns all the runbooks.
     *  @param variant a String with the runbook variant, either incident or lifecycle.
     *  @param all a boolean value, true if all runbooks should be returned.  If not present only the 
     *      latest runbooks are returned.
     *  @returns a Promise which resolves to the returned runbooks.*/
    getRunbooks: (variant: string, all?:boolean) => Promise<RunbookNode[]>;
    
    /** returns the runbook with the specified id.
     *  @param id a String with the id.
     *  @returns a Promise which resolves to the returned runbook.*/
    getRunbook: (id: string) => Promise<RunbookConfig>;
    
    /** saves a new runbook.
     *  @param runbook the new runbook to be saved.
     *  @returns a Promise which resolves to the newly created runbook.*/
    saveRunbook: (runbook: RunbookConfig) => Promise<RunbookConfig>;
    
    /** updates an existing runbook.
     *  @param id a String with the id of the runbook.
     *  @param runbook the existing runbook to be saved.
     *  @param etag a String with the etag.
     *  @returns a Promise which resolves to the id of the created runbook.*/
    updateRunbook: (id: string, runbook: RunbookConfig, etag: string | undefined) => Promise<string>;
    
    /** deletes an existing runbook.
     *  @param id a String with the id of the runbook.
     *  @returns a Promise which resolves to the id of the created runbook.*/
    deleteRunbook: (id: string) => Promise<string>;
    
    /** bulk deletes multiple runbooks.
     *  @param ids a String array with the list of runbook ids.
     *  @returns a Promise which resolves to OK.*/
    deleteRunbooks: (ids: Array<string>) => Promise<string>;
    
    /** returns the runbook data with the specified url.
     *  @param url the URL set in the http in node in node red.
     *  @returns a Promise which resolves to the returned runbook data.*/
    getRunbookData: (url: string) => Promise<any>;
}

/** this class defines the RunbookApiService. */
class RunbookApiService extends ApiService implements RunbookServiceIfc {
    /** the constructor for the class. */
    constructor() {
        const baseUri = RUNBOOK_URL;
        super(baseUri);
    }

    /** returns the base uri, this can be overridden in subclasses to allow the uri to change
     *      after construction.
     *  @returns a String with the base uri. */
    protected getBaseUri(): string {
        if (ApiService.USE_REGION) {
            const region = ApiService.AUTH_SERVICE.getRegion();
            return `/api/iq/${region}/runbooks/`;    
        } else {
            return this.baseApiUri;
        }
    }

    /** returns all the runbooks.
     *  @param variant a String with the runbook variant, either incident or lifecycle.
     *  @param all a boolean value, true if all runbooks should be returned.  If not present only the 
     *      latest runbooks are returned.
     *  @returns a Promise which resolves to the returned runbooks.*/
    getRunbooks(variant: string, all?: boolean): Promise<RunbookNode[]> {
        const tenantId = ApiService.AUTH_SERVICE.getTenantId();
        return new Promise((resolve, reject) => {
            super.getWithEtag<RunbookNode[]>(`${VERSION}/tenants/${tenantId}/runbooks?variant=${variant}` + (all ? '&allVersions=true' : '')).then((results: EtagResult<RunbookNode[]>) => {
                resolve(results.data);
            }, err => {
                reject(err);
                console.error(err);
            });
        });
    }

    /** returns the runbook with the specified id.
     *  @param id a String with the id.
     *  @returns a Promise which resolves to the returned runbook.*/
    getRunbook(id: string): Promise<RunbookConfig> {
        const tenantId = ApiService.AUTH_SERVICE.getTenantId();
        return new Promise((resolve, reject) => {
            super.getWithEtag<RunbookConfig>(`${VERSION}/tenants/${tenantId}/runbooks/${id}`).then((results: EtagResult<RunbookConfig>) => {
                if (results.data) {
                    results.data.eTag = results.etag;
                }
                resolve(results.data);
            }, err => {
                reject(err);
                console.error(err);
            });
        });
    }

    /** saves a new runbook.
     *  @param runbook the new runbook to be saved.
     *  @returns a Promise which resolves to the newly created runbook.*/
    saveRunbook(runbook: RunbookConfig): Promise<RunbookConfig> {
        return new Promise((resolve, reject) => {
            const tenantId = ApiService.AUTH_SERVICE.getTenantId();
            super.post<RunbookConfig>(`${VERSION}/tenants/${tenantId}/runbooks`, runbook).then((response: RunbookConfig) => {
                resolve(response);
            }, err => {
                reject(err);
                console.error(err);
            });
        });
    }

    /** updates an existing runbook.
     *  @param id a String with the id of the runbook.
     *  @param runbook the existing runbook to be saved.
     *  @param etag a String with the etag.
     *  @returns a Promise which resolves to the id of the created runbook.*/
    updateRunbook(id: string, runbook: RunbookConfig, etag: string | undefined): Promise<string> {
        return new Promise((resolve, reject) => {
            const tenantId = ApiService.AUTH_SERVICE.getTenantId();
            super.put<string>(`${VERSION}/tenants/${tenantId}/runbooks/${id}`, runbook, undefined, undefined, etag).then((response: any) => {
                if (response === "") {
                    // Node red returns the id of the runbook, for consistency let's do that here as well
                    response = {id};
                }
                resolve(response);
            }, err => {
                reject(err);
                console.error(err);
            });
        });
    }

    /** deletes an existing runbook.
     *  @param id a String with the id of the runbook.
     *  @returns a Promise which resolves to the id of the created runbook.*/
    deleteRunbook(id: string): Promise<string> {
        return new Promise((resolve, reject) => {
            const tenantId = ApiService.AUTH_SERVICE.getTenantId();
            super.delete<string>(`${VERSION}/tenants/${tenantId}/runbooks/${id}`, false).then((response: string) => {
                // runbooks request returns all elements in all runbooks.
                // Filtering them out and returning just the tab items which are for each runbook
                resolve(response);
            }, err => {
                reject(err);
                console.error(err);
            });
        });
    }
    
    /** bulk deletes multiple runbooks.
     *  @param ids a String array with the list of runbook ids.
     *  @returns a Promise which resolves to OK.*/
    deleteRunbooks(ids: Array<string>): Promise<any> {
        return new Promise((resolve, reject) => {
            const tenantId = ApiService.AUTH_SERVICE.getTenantId();

            const bulkDeletePayload = {
                operation: "delete",
                data: {
                    items: [...ids]
                }
            };

            super.post<RunbookConfig>(`${VERSION}/tenants/${tenantId}/bulk/runbooks`, bulkDeletePayload).then((response: any) => {
                resolve(response);
            }, err => {
                reject(err);
                console.error(err);
            });
        });
    }

    /** returns the runbook data with the specified url.
     *  @param url the URL set in the http in node in node red.
     *  @returns a Promise which resolves to the returned runbook data.*/
    getRunbookData(url: string): Promise<any> {
        return new Promise((resolve, reject) => {
            super.get<any>(url).then((results: any) => {
                resolve(results);
            }, err => {
                reject(err);
                console.error(err);
            });
        });
    }    

    /** connects to the runbook orchestrator and passes it the set of inputs and starts the runbook.
     *  @param inputs the runbook inputs that are required to start the runbook.
     *  @returns a Promise which resolves to the returned meta data from the orchestrator.*/
    runRunbook(inputs: RunbookInputs): Promise<any> {
        return new Promise((resolve, reject) => {
            super.post<any>(`/orchestrator/Start`, inputs).then((results: any) => {
                resolve(results);
            }, err => {
                reject(err);
                console.error(err);
            });
        });
    }    
}

const RunbookService = new RunbookApiService();
export { RunbookService };
