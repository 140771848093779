/** This module contains the implementation for a runbook test page.  Right now this page comes 
 *  up when you are editing a Runbook and click on the eye icon.  At some point in time we need
 *  a proper debug page with more functionality, but right now this page allows us to test our 
 *  runbooks.
 * 
 *  This page behaves differently if you come in from a node red runbook where there is a URL 
 *  in the parameters and the component will then use that URL to query nodered for the data and
 *  the nodered API call will synchronously return the data. 
 *  If we are using the azure back-end then there is no url and you come in with just the runbook
 *  id and runbook name and the UI will then call the runbook orchestrator to start the runbook.
 *  Right now the status API is not accessible to the UI so the UI runs the runbook query from DAL
 *  on a timer and when that returns data it then clears the data loading facade and displays the 
 *  runbook.
 *  Finally, if you pass the incident id and trigger id in the URL the page assumes you have a 
 *  runbook that has already run that you want to test and it runs it immediately. 
 * 
 *  Here is an example that can be used for the input:
 *  { 
 *    "utid": "44964771-5e5e-44c7-a99a-74de1db30056",
 *    "incident": "7313415e-1c39-10ec-86b6-0242ac100405",
 *    "detection": {
 *      "id": "7313a15e-1c39-10ec-86b6-0242ac100409",
 *      "inputs": {
 *        "network_interface": {
 *          "ip": "10.38.128.8",
 *          "ifindex": 44
 *        }
 *      },
 *      "object": {
 *        "name": "10.103.0.1:20101",
 *        "kind": "network_interface"
 *      }
 *    }    
 *  }
 *  @module
 */
import React, { useState, useEffect, useRef } from "react";
import Markdown from "markdown-to-jsx";
import { ShowToaster, Table, TableColumnDef, Icon, IconNames } from "@tir-ui/react-components";
import { useHistory } from "react-router";
import { loader } from "graphql.macro";
import { Query } from "reporting-infrastructure/types/Query";
import { useQuery, FILTER_NAME, useStateSafePromise, useUserPreferences, setUserPreferences, parseTimeFromDAL } from "utils/hooks";
import { SEVERITY, GENERAL_COLORS, SIZE, TIME_FORMAT, PRIORITY, PRIORITY_COLORS } from "components/enums";
import { APP_ICONS, SDWAN_ICONS } from "components/sdwan/enums";
import { OneColumnContainer } from "components/common/layout/containers/one-column-container/OneColumnContainer";
import { useQueryParams } from "utils/hooks";
import { RunbookView } from "pages/riverbed-advisor/views/runbook-view/RunbookView";
import { runbookService, processDataset, logicalNodes, decisionNodes, showTitleIcons } from 'utils/runbooks/RunbookUtils';
import { createData, createImpactedUsers, createImpactedSites, createImpactedApps, createTriggerRow } from 'utils/runbooks/RunbookFakeDataUtils';
import { RunbookOutput, RUNBOOK_STATUS, RUNBOOK_STATUS_PROPS, PriorityReason } from "pages/riverbed-advisor/views/runbook-view/Runbook.type";
import { STRINGS } from "app-strings";
import { DataLoadFacade } from "components/reporting/data-load-facade/DataLoadFacade";
import { Button, Classes, Intent, Label, Menu, MenuItem, NumericInput, Position, Radio, RadioGroup, TextArea } from "@blueprintjs/core";
import { INVOCATION_TYPE, RunbookConfig, RunbookInputs, RunbookNode } from "utils/services/RunbookApiService";
import { BasicDialog, updateDialogState } from "components/common/basic-dialog/BasicDialog";
import { PARAM_NAME, INCIDENT_DETAILS_STYLE, IS_EMBEDDED } from "components/enums/QueryParams";
import { InputType, NamesByTriggerType, Variant } from "components/common/graph/types/GraphTypes";
import { getURLPath } from "config";
import { getURL } from "utils/hooks/useQueryParams";
import { CardsHolder } from "components/common/layout/cards-holder/CardsHolder";
import { UsersCard } from "pages/incident-details/views/impact-summary/cards/UsersCard";
import { SitesCard } from "pages/incident-details/views/impact-summary/cards/SitesCard";
import { ApplicationsCard } from "pages/incident-details/views/impact-summary/cards/ApplicationsCard";
import { IconTitle } from "components/common/icon-title/IconTitle";
import { formatToLocalTimestamp } from "reporting-infrastructure/utils/formatters";
import { Popover2, Popover2InteractionKind } from "@blueprintjs/popover2";
import { BladeContainer } from "components/common/layout/containers/blade-container/BladeContainer";
import { PriorityLEDFormatter } from "reporting-infrastructure/utils/formatters/priority-led-formatter/PriorityLEDFormatter";
import { ElapsedTimeFormatter } from "reporting-infrastructure/utils/formatters/elapsed-time-formatter/elapsed-time-formatter";
import { ErrorDialogContent } from "pages/incident-details/views/runbook-outputs-tab/ErrorDialogContent";
import { ENTITY_KIND } from "components/enums/EntityKinds";
import { InitialValues, InputConfig, RunbookInputsForm, generateRunbookInputsUsingSearch } from "pages/runbook-invocations/views/runbook-invocations-list/RunbookInputsForm";
import { PriorityReasonsView } from "pages/incident-details/views/priority-reason/PriorityReasonsView";
import { Card, DetailsPanel } from "components/reporting";
import { ViewCollection } from "components/common/layout/view-collection/ViewCollection";
import { getEntityDescriptionForRunbook } from "utils/runbooks/EntityUtils";
import { DebugDialogContent } from "pages/incident-details/views/runbook-outputs-tab/DebugDialogContent";
import { DataOceanMetadata } from "components/common/graph/editors/data-ocean/DataOceanMetadata.type";
import { DataOceanUtils } from "components/common/graph/editors/data-ocean/DataOceanUtils";
import { LifecycleRunbookView } from "./LifecycelRunbookView";
import { CustomProperty } from "pages/create-runbook/views/create-runbook/CustomPropertyTypes";
import { useCustomProperties } from "utils/hooks/useCustomProperties";
import { RunbookInputsPreference } from "utils/services/UserPrefsTypes";
import { getArDataSources } from "utils/stores/GlobalDataSourceTypeStore";
import { mergeWith } from 'lodash';
import { openRunbookNodesTraversedDialog } from "./openRunbookNodesTraversedDialog";
import './ViewRunbookView.scss';

// This constant is set at runtime and specifies whether or not to include runbook development only features
const runConfig = window["runConfig"];
const INCLUDE_RUNBOOK_DEV = runConfig?.INCLUDE_RUNBOOK_DEV === undefined ? false : runConfig.INCLUDE_RUNBOOK_DEV;

/** these variables get the environment and help URI from the runtime config. */
let { ENV } = window["runConfig"] || {};
const ROLLUP_ENVS: string[] = ["dev", "staging", "prod"];

// Specifies whether or not to use the metadata (true) or the indicators (false) to get the suggested 
// interfaces, devices or apps.  Note you must change the query below when you change this value
//const useMetaData = true;

// This is the time to wait between status checks in milliseconds, if you want to know the total length of time 
// that we will wait for a runbook to generate it is maxStatusChecks*statusTimeIncrement.
const statusTimeIncrement = 2 * 1000;

// The number of times to check to see if the runbook has completed
const initMaxStatusChecks = 150;

/** this type defines an internal status object that is used to display debug information below the runbook. */
type RunbookStatus = {
    /** a string with the current status of the runbook run. */
    status: string;
    /** an enum with the current state. */
    statusValue: STATUS_VALUE;
    /** a string with the information passed back from the orchestrator when the runbook runs. */
    orchestratorInfo: string | null;
    /** a string with any errors encountered while invoking the orchestrator or null if none. */
    orchestratorError: string| null;
}

/** an enum with the supported status values. */
enum STATUS_VALUE {
    STARTING        = "STARTING",
    INITIALIZING    = "INITIALIZING",
    RUNNING         = "RUNNING",
    ERROR           = "ERROR",
    SUCCEEDED       = "SUCCEEDED",
    NO_DATA         = "NO_DATA",
    TIMEOUT         = "TIMEOUT"
}

/** This interface defines the properties passed into the view runbook React component.*/
interface ViewRunbookViewProps {
    /** the runbook configuration that is to be previewed or tested. */
    runbook?: RunbookConfig;
    /** the list of subflows. */
    subflows?: RunbookNode[];
}

/** Renders the view runbook page.  This page is a test page for Desso to test the integration between the 
 *  data model he is using and the UI.
 *  @param props the properties passed in.
 *  @returns JSX with the view runbook page component.*/
export default function ViewRunbookView (props: ViewRunbookViewProps): JSX.Element {
    const userPreferences = useUserPreferences({listenOnlyTo: {runbookInputs: {types: {}, runbooks: {}}}});

    const PANEL_ANCHOR_ELEMENT_ID = "panel-anchor-element";
    enum BladeContent {
        /** the constant for the priority reasons blade. */
        PRIORITY_REASONS = "PRIORITY_REASONS"
    }
    const history = useHistory();
    let { params, setQueryParams, clearQueryParams } = useQueryParams({ 
        listenOnlyTo: [
            PARAM_NAME.rbViewMode, PARAM_NAME.rbConfigId, PARAM_NAME.rbConfigNm, 
            PARAM_NAME.urlTrigger, PARAM_NAME.incidentId, 
            PARAM_NAME.runbookId, PARAM_NAME.debug, PARAM_NAME.variant,
            PARAM_NAME.runTestDataId
        ] 
    });
    const [showBlade, setShowBlade] = useState<boolean>(false);
    const [bladeContent, setBladeContent] = useState<BladeContent>(BladeContent.PRIORITY_REASONS);
    const [priorityReasonsDetails, setPriorityReasonsDetails] = useState<{icon: string, title: string, data: PriorityReason[]} | undefined>(undefined);
    const [activeRunbook] = useState<any>(params[PARAM_NAME.rbConfigId] && params[PARAM_NAME.rbConfigNm] && params[PARAM_NAME.variant] ? { id: params[PARAM_NAME.rbConfigId], label: params[PARAM_NAME.rbConfigNm], variant: params[PARAM_NAME.variant] } : null);
    const [runbookConfig, setRunbookConfig] = useState<RunbookConfig | null>(null);
    const [runbookOutput, setRunbookOutput] = useState<RunbookOutput | undefined>(undefined);
    const [impactedUsers, setImpactedUsers] = useState<Array<string>>([]);
    const [impactedApps, setImpactedApps] = useState<Array<string>>([]);
    const [impactedSites, setImpactedSites] = useState<Array<string>>([]);
    const [triggerRow, setTriggerRow] = useState<Record<string, any>>({});
    const [flowData, setFlowData] = useState<Array<any> | null>(null);
    const [loading, setLoadingState] = useState<boolean>((params[PARAM_NAME.rbConfigId] && params[PARAM_NAME.rbConfigNm] && params[PARAM_NAME.urlTrigger] ? true : false));

    const [status, setStatus] = useState<RunbookStatus>({status: STRINGS.viewRunbooks.startingStatus, statusValue: STATUS_VALUE.STARTING, orchestratorInfo: null, orchestratorError: null});

    // Only show the input dialog if we are using the azure backend and there is no incident id or trigger id passed in.
    const showDialog = !params[PARAM_NAME.incidentId] && params[PARAM_NAME.rbViewMode] !== "preview";
    const [dialogState, setDialogState] = useState<any>({showDialog: showDialog && params[PARAM_NAME.runTestDataId] === undefined, loading: true, title: STRINGS.viewRunbooks.inputDialogTitle, dialogContent: null, dialogFooter: null});
    const [inputs, setInputs] = useState<RunbookInputs | null>(null);
    const [statusCount, setStatusCount] = useState<number>(0);
    const [customProperties, setCustomProperties] = useState<CustomProperty[] | undefined>(undefined);
    const customPropertiesQuery = useCustomProperties({});
    useEffect(
        () => {
            setCustomProperties(customPropertiesQuery.data);
        },
        [customPropertiesQuery.data]
    );

    // Get the runbook configuration
    useEffect(
        () => {
            async function fetchMyAPI() {
                try {
                    const newStatus = Object.assign({}, status);
                    newStatus.status = STRINGS.viewRunbooks.retrievingStatus;
                    newStatus.statusValue = STATUS_VALUE.INITIALIZING;
                    setStatus(newStatus);
                    const runbook = await runbookService.getRunbook(activeRunbook!.id);
                    if (runbook) {
                        setRunbookConfig(runbook);
                    }
                } catch (error) {
                    console.error(error);
                }
            }
            if (activeRunbook) {
                if (props.runbook && params[PARAM_NAME.rbViewMode] === "preview") {
                    const newStatus = Object.assign({}, status);
                    newStatus.status = STRINGS.viewRunbooks.retrievingStatus;
                    newStatus.statusValue = STATUS_VALUE.INITIALIZING;
                    setStatus(newStatus);
                    setRunbookConfig(props.runbook);
                } else {
                    fetchMyAPI();
                }
            }
        }, 
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [activeRunbook]
    );

    const [executeSafely] = useStateSafePromise();
    const [objMetricMetaData, setObjMetricMetaData] = useState<DataOceanMetadata>();
    useEffect(() => {
        executeSafely(DataOceanUtils.init()).then(
            (response: DataOceanMetadata) => {
                setObjMetricMetaData(response);
            },
            (error) => {
                console.error(error);
            }
        );
    }, [executeSafely]);

    // Query to get the list of entities when we are going to show the input dialog
/* Comment out this query since it is no longer used.
    const primaryEntityListQuery = useQuery({
        query: new Query(loader("./entity-metadata-list.graphql")),
        queryVariables: { limit: 5000 },
        skipGlobalFilters: true,
        timeNotRequired: false,
        filters: {
            [FILTER_NAME.kinds]: runbookConfig ? getPrimaryEntityKindsForTrigger(runbookConfig) : []
        },
        lazy: true,
    });
    const secondaryEntityListQuery = useQuery({
        query: new Query(loader("./entity-metadata-list.graphql")),
        queryVariables: { limit: 5000 },
        skipGlobalFilters: true,
        timeNotRequired: false,
        filters: {
            [FILTER_NAME.kinds]: runbookConfig ? getSecondaryEntityKindsForTrigger(runbookConfig) : []
        },
        lazy: true,
    });
    useEffect(
        () => {
            if (runbookConfig && showDialog) {
                if (!primaryEntityListQuery.loading && !primaryEntityListQuery.data) {
                    let timeRangeToFetchIncidentsFor = durationToRoundedTimeRange(DURATION.DAY_1);
                    primaryEntityListQuery.run({
                        queryVariables: {
                            limit: 5000,
                            "startTime": timeRangeToFetchIncidentsFor.startTime,
                            "endTime": timeRangeToFetchIncidentsFor.endTime
                        }
                    });    
                }
                if (runbookConfig.triggerType === InputType.APPLICATION_LOCATION && !secondaryEntityListQuery.loading && !secondaryEntityListQuery.data) {
                    let timeRangeToFetchIncidentsFor = durationToRoundedTimeRange(DURATION.DAY_1);
                    secondaryEntityListQuery.run({
                        queryVariables: {
                            limit: 5000,
                            "startTime": timeRangeToFetchIncidentsFor.startTime,
                            "endTime": timeRangeToFetchIncidentsFor.endTime
                        }
                    });    
                }
                let queriesFinished = (primaryEntityListQuery.data || primaryEntityListQuery.error) &&
                    (runbookConfig.triggerType !== InputType.APPLICATION_LOCATION || secondaryEntityListQuery.data || secondaryEntityListQuery.error);
                if (showDialog && runbookConfig && queriesFinished) {
                    let primaryEntities: Array<any> = primaryEntityListQuery?.data?.entities?.nodes || [];
                    if (!useMetaData && primaryEntityListQuery?.data?.indicators?.nodes.length > 0) {
                        primaryEntities = primaryEntityListQuery.data.indicators.nodes.map((item) => item.entity);
                    }
                    let secondaryEntities: Array<any> = secondaryEntityListQuery?.data?.entities?.nodes || [];
                    if (!useMetaData && secondaryEntityListQuery?.data?.indicators?.nodes.length > 0) {
                        secondaryEntities = secondaryEntityListQuery.data.indicators.nodes.map((item) => item.entity);
                    }
                    showInputDialog(runbookConfig, primaryEntities, secondaryEntities, maxStatusChecks, setInputs, setDialogState, setTriggerRow);
                }
            }
        }, 
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [
            runbookConfig, showDialog, 
            primaryEntityListQuery.loading, primaryEntityListQuery.data, primaryEntityListQuery.error,
            secondaryEntityListQuery.loading, secondaryEntityListQuery.data, secondaryEntityListQuery.error
        ]
    );
*/

    const [testData, setTestData] = useState<InitialValues>();
    useEffect(
        () => {
            if (params[PARAM_NAME.runTestDataId]) {
                window.addEventListener("message", (e) => {
                    if (e.data.message === "testData" && e.data.testData) {
                        setTestData(e.data.testData as {requestBody?: string, requestHeaders?: string, requestUrl?: string, requestParams?: string});
                    }
                }, false);
                const parentOrigin = window.location?.ancestorOrigins ? window.location.ancestorOrigins[0] : document.referrer;
                window.parent.postMessage({
                    message: "getTestParameters",
                    id: params[PARAM_NAME.runTestDataId]
                }, parentOrigin);    
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    );

    useEffect(
        () => {
            if (runbookConfig && showDialog && (!params[PARAM_NAME.runTestDataId] || testData)) {
                // Since the completion of the query above used to initiate the input dialog, just initiate it when both variables are set.
                if (!testData) {
                    showInputDialog(runbookConfig, [], [], maxStatusChecks, setInputs, setDialogState, setTriggerRow, userPreferences?.runbookInputs, testData);
                } else {
                    // Run the test immediately
                    if (testData?.timeout) {
                        maxStatusChecks.current = (testData.timeout * 60 * 1000) / statusTimeIncrement
                    }
                    const inputConfig = {
                        current: {
                            primarySearchItem: undefined,
                            secondarySearchItem: undefined,
                            triggerMetricConfig: undefined,
                            webhook: {
                                requestBody: testData?.requestBody ? JSON.stringify(testData.requestBody) : "", 
                                requestHeaders: testData?.requestHeaders ? JSON.stringify(testData.requestHeaders) : "", 
                                requestUrl: testData?.requestUrl ? JSON.stringify(testData.requestUrl) : "", 
                                requestParams: testData?.requestParams ? JSON.stringify(testData.requestParams) : ""
                            }, 
                            incidentVariables: "",
                            mimicMultipleDeviceOrApplication: false,
                            mimicMultipleLocation: false,
                            runbookAnalysisConfig: {status: "Succeeded", time: 0},
                            incidentStatusConfig: {oldStatus: "New", newStatus: "New", time: 0},
                            noteConfig: {content: "", time: 0},
                            indicatorsConfig: {count: 0},
                            ongoingStatusConfig: {time: 0},
                            vantagePointConfig: {vantagePoint: undefined},
                            endTime: 0
                        }
                    };
                    let jsonInput: RunbookInputs | undefined = generateRunbookInputsUsingSearch(
                        runbookConfig, INVOCATION_TYPE.test, inputConfig
                    );
            
                    let entityName = "";
                    let description = "";
                    if (jsonInput) {
                        const kind = jsonInput?.detection.entity?.kind || "";
                        switch (kind) {
                            case ENTITY_KIND.DEVICE:
                                description = STRINGS.fakeData.deviceRunbookName;
                                entityName = `${jsonInput?.detection.entity?.attributes.ipaddr}`;
                                break;
                            case ENTITY_KIND.INTERFACE:
                                description = STRINGS.fakeData.interfaceRunbookName;
                                entityName = `${jsonInput?.detection.entity?.attributes.ipaddr}:${jsonInput?.detection.entity?.attributes.ifindex}`;
                                break;
                            case ENTITY_KIND.APPLICATION_LOCATION:
                            case ENTITY_KIND.APPLICATION_SERVER:
                                description = STRINGS.fakeData.applicationLocationRunbookName;
                                entityName = `${jsonInput?.detection.entity?.attributes.application}`;
                                break;
                            case ENTITY_KIND.LOCATION:
                                description = STRINGS.fakeData.locationRunbookName;
                                entityName = `${jsonInput?.detection.entity?.attributes.location}`;
                                break;
                            case ENTITY_KIND.APPLICATION:
                                description = STRINGS.fakeData.applicationRunbookName;
                                entityName = `${jsonInput?.detection.entity?.attributes.application}`;
                                break;
                        }    
                        setInputs(jsonInput || null);
                        setTriggerRow({entityName, description, generated: new Date(), indicatorsCount: 0});    
                    }
                }
            }
        }, 
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [runbookConfig, showDialog, testData]
    );
    
    // Query to get the data
    useEffect(
        () => {
            async function fetchMyAPI() {
                try {
                    let data;
                    if (inputs) {
                        // This is the call to nodered to post the inputs, uncomment if you need to test this
                        //data = await runbookService.postRunbookData(params[PARAM_NAME.urlTrigger], inputs);
                        // Run the API call that starts the runbook orchestrator, for the Azure backend we will
                        // always have inputs, there is no way to start the orchestrator without the inputs
                        const newStatus = Object.assign({}, status);
                        newStatus.status = STRINGS.viewRunbooks.runningStatus;
                        newStatus.statusValue = STATUS_VALUE.RUNNING;
                        setStatus(newStatus);
                        data = await (runbookService as any).runRunbook(inputs);
                    } else if (params[PARAM_NAME.rbViewMode] === "preview") {
                        if (runbookConfig) {
                            startPreview(
                                runbookConfig, true, 1, dialogState, setDialogState, 
                                setRunbookOutput, setImpactedUsers, setImpactedSites, setImpactedApps,
                                setTriggerRow, setFlowData, setLoadingState, objMetricMetaData!, customProperties!,
                                props.subflows || []
                            );
                        }
                    } else {
                        // For nodered we make the API call that runs the runbook using its URL
                        data = await runbookService.getRunbookData(params[PARAM_NAME.urlTrigger]);
                    }
                    if (data && runbookConfig) {
                        /* istanbul ignore else */
                        if (inputs) {
                            // We are using Azure so, now that we have started the runbook, pull the incident id and 
                            // trigger id in the URL and set the loading state to true.  It might take 5 to 10 
                            // minutes for the runbook to run.
                            setQueryParams({ [PARAM_NAME.incidentId]: inputs.incidentId }, true);
                            setLoadingState(true);
                            const newStatus = Object.assign({}, status);
                            newStatus.status = STRINGS.viewRunbooks.runningStatus;
                            newStatus.statusValue = STATUS_VALUE.RUNNING;
                            newStatus.orchestratorInfo = JSON.stringify(data, null, 4);
                            setStatus(newStatus);
                        } else {
                            // We are using nodered and the data was passed back immediately via the API call, process 
                            // the data and pass it to the runbook view.
                            const runbook = processRunbookData(data, runbookConfig);
                            setRunbookOutput(runbook);
                            setFlowData(data);
                            setLoadingState(false);    
                        }
                    }
                } catch (error) {
                    console.log(error);
                    const newStatus = Object.assign({}, status);
                    newStatus.status = STRINGS.viewRunbooks.errorStatus;
                    newStatus.statusValue = STATUS_VALUE.ERROR;
                    newStatus.orchestratorError = (error && (error as any).message ? (error as any).message : typeof error === "string" ? error : "Unknown Error");
                    setStatus(newStatus);
                    setLoadingState(false);
                }
            }
            if (runbookConfig && objMetricMetaData && customProperties && (!showDialog || inputs)) {
                if (!params[PARAM_NAME.incidentId]) {
                    fetchMyAPI();
                }
            }
        }, 
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [runbookConfig, inputs, objMetricMetaData, customProperties]
    );

    // This block is only used with the new Azure back-end.  When the orchestrator start function is 
    // called it returns immediately and we have no way of knowing when the runbook completes.  Since
    // this page right now is for our own internal use, for simplicity just run the runbook graphql
    // query on demand in a loop and when it gets data, exit out of the loop and drop the loading 
    // indicator and show the runbook view, passing it the incident id and trigger id so it can 
    // render it.  Technically we have the runbook data, but let's not make this complicated and 
    // just let the runbook view take the incident id and trigger id and query the data like it normally would do

    // When we are running our status checks for the runbook, this constant specifies the maximum number of times it will 
    // do the check.
    const maxStatusChecks = useRef<number>(initMaxStatusChecks);
    const errorMsg = useRef<string | undefined>(undefined);
    const stackTrace = useRef<Array<string>>([]);
    const queryParams = useQuery({
        query: new Query(loader("../../../riverbed-advisor/views/runbook-view/runbooks-status.graphql")),
        requiredFilters: [FILTER_NAME.incidentIds],
        filters: {
            [FILTER_NAME.incidentIds]: (params[PARAM_NAME.incidentId] ? [params[PARAM_NAME.incidentId]] : undefined)
        },
        skipGlobalFilters: true,
        timeNotRequired: true,
        lazy: true,
    });
    const queryParamsCache = useRef<any>(queryParams);
    queryParamsCache.current = queryParams;
    const timeoutId = useRef<number>(0);
    const runbookStatus = useRef<RUNBOOK_STATUS>(RUNBOOK_STATUS.UNKNOWN);
    const isRunAnotherTestBtnVisible = !IS_EMBEDDED && !isRunningQuery(status) && !dialogState.showDialog && params[PARAM_NAME.rbViewMode] !== "preview" ;
    
    useEffect(
        () => {
            if (loading && statusCount < maxStatusChecks.current) {
                queryParamsCache.current.run({
                    filters: {
                        [FILTER_NAME.incidentIds]: [params[PARAM_NAME.incidentId]]
                    },
                    // Always fetch runbook data fresh because it could've changed since the last pull
                    fetchPolicy: "network-only",
                });
                timeoutId.current = setTimeout(() => {
                    timeoutId.current = 0;
                    let hasData = false, hasFailure = false;
                    if (queryParamsCache.current.error) {
                        hasFailure = true;
                        errorMsg.current = undefined;
                        stackTrace.current = [];
                    } else if (!queryParamsCache.current.loading && queryParamsCache.current.data && queryParamsCache.current.data?.runbooks?.nodes?.length > 0 ) {
                        for (const runbook of queryParamsCache.current.data.runbooks.nodes) {
                            if (runbook.status) {
                                if (
                                    runbook.status === RUNBOOK_STATUS.DONE || runbook.status === RUNBOOK_STATUS.SUCCEEDED || 
                                    runbook.status === RUNBOOK_STATUS.SUCCEEDED_WITH_ERRORS ||
                                    runbook.status === RUNBOOK_STATUS.FAILED || runbook.status === RUNBOOK_STATUS.CANCELED
                                ) {
                                    runbookStatus.current = runbook.status;
                                    hasData = true;
                                    break;
                                } else if (runbook.status === RUNBOOK_STATUS.FAILED || runbook.status === RUNBOOK_STATUS.CANCELED) {
                                    hasFailure = true;
                                    if (runbook.debug) {
                                        errorMsg.current = runbook.debug.error ? runbook.debug.error : undefined;
                                        stackTrace.current = runbook.debug.stackTrace ? [runbook.debug.stackTrace] : [];
                                    }        
                                    break;
                                }
                            }
                        }
                    }
                    setStatusCount(statusCount + 1);
                    if (statusCount >= (maxStatusChecks.current - 1) || hasData || hasFailure) {
                        const newStatus = Object.assign({}, status);
                        newStatus.status = STRINGS.viewRunbooks.timeoutStatus;
                        newStatus.statusValue = STATUS_VALUE.TIMEOUT;
                        if (hasData) {
                            newStatus.status = STRINGS.viewRunbooks.dataReceivedStatus;
                            newStatus.statusValue = STATUS_VALUE.SUCCEEDED;
                            // Would be nice if the orchestrator returned us the runbook id, so if there is 
                            // more than one runbook we know which one is the one we need to look at.
                            setQueryParams({ [PARAM_NAME.runbookId]: queryParamsCache.current.data.runbooks.nodes[0].id }, true);
                        } else if (hasFailure) {
                            newStatus.status = STRINGS.viewRunbooks.errorStatus;
                            newStatus.statusValue = STATUS_VALUE.ERROR;
                            /* This should come from DAL, I am just using this for testing
                            errorMsg.current = "Error sending request";
                            stackTrace.current = [
                                "---(exception: KeyError 'application.traffic')---\n",
                                "  File \"/home/site/wwwroot/shared/controllers/runbooks.py\", line 31, in on_runbook_msg\n    await request_report(utid, uuid, query, meta)\n",
                                "  File \"/home/site/wwwroot/shared/app.py\", line 145, in request_report\n    client = await get_product_client(utid, uuid, query, meta)  # type: ProductBase\n",
                                "  File \"/home/site/wwwroot/shared/app.py\", line 103, in get_product_client\n    product = await find_product_for(utid, uuid, query)\n",
                                "  File \"/home/site/wwwroot/shared/app.py\", line 77, in find_product_for\n    ptype = get_product_type(query)\n",
                                "  File \"/home/site/wwwroot/shared/app.py\", line 71, in get_product_type\n    ptype = Objects.precedence(query[\"obj_type\"], query[\"metrics\"])\n",
                                "  File \"/home/site/wwwroot/shared/objects.py\", line 391, in precedence\n    sources = Objects.ALL_OBJECTS[objtype][\"sources\"]\n"
                            ];
                            */
                        }
                        setStatus(newStatus);
                        setLoadingState(false);
                    }
                }, statusTimeIncrement) as any;
            }
            return () => {
                clearAutoUpdateTimeout(timeoutId);
            }    
        }, 
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [loading, statusCount]
    );

    // Create the debug panel which displays various status and error information
    const showDebugInformation = (INCLUDE_RUNBOOK_DEV || params[PARAM_NAME.debug] === "true") && inputs;
    const [showDebug, setShowDebug] = useState<boolean>(false);
    const [runbookData, setRunbookData] = useState<any>(null);
    const debugJsx: JSX.Element = createDebugPanel(showDebug, setShowDebug, runbookData, status, statusCount, inputs);

    // Create the column definitions for the fake trigger table
    const triggerTableColumns: TableColumnDef[] = [
        {
            id: "entityName", Header: "", accessor: "entityName",
        }, {
            id: "description", Header: "", accessor: "description",
        }, {
            id: "generated", Header: "", accessor: "generated",
            formatter: row => {
                return <ElapsedTimeFormatter time={row.generated} prefix={STRINGS.incidents.elapsedPrefixStarted} suffix={STRINGS.incidents.elapsedSuffix} showOriginal/>
            },
        }, {
            id: "indicatorsCount", Header: "", accessor: "indicatorsCount",
            formatter: row => row.indicatorsCount + " " + STRINGS.incidents.columns.indicators,
        },
    ];

    return (<>
        <div className="position-absolute h-100 d-flex flex-row justify-content-end absolute-scrollbar-space">
            <div className={"d-none bg-light shadow border-left priority-reasons-side-panel" + (showBlade ? " d-flex" : "")}
                id={PANEL_ANCHOR_ELEMENT_ID}>
            </div>
        </div>
        {showBlade &&
            <DetailsPanel floating={true} anchorElement={PANEL_ANCHOR_ELEMENT_ID} notResizable={true}>
                {bladeContent === BladeContent.PRIORITY_REASONS &&
                    <BladeContainer
                        className="priority-reasons-blade"
                        status={PRIORITY[PRIORITY.UNKNOWN]}
                        isPriority={true}
                        icon={priorityReasonsDetails?.icon}
                        title={priorityReasonsDetails?.title}
                        onCloseClicked={() => {
                            setShowBlade(false);
                        }}
                        size={SIZE.s}
                        showIconWithoutBg={true}
                        noContentPadding
                    >
                        {priorityReasonsDetails?.data?.length &&
                            <div className="display-8 p-4">
                                <ul className={"priority-reasons-list"}>
                                    {priorityReasonsDetails.data.map((item, index) => {
                                        return <li className={"mb-3 priority-reason" + (item.priority ? " " + item.priority.toLocaleLowerCase() : "")} key={"priority-reason-" + index}><Markdown>{item.text}</Markdown></li>;
                                    })}
                                </ul>
                            </div>}
                    </BladeContainer>
                }
            </DetailsPanel>
        }
        <BasicDialog dialogState={dialogState} className={"view-runbook-input-dialog" + (activeRunbook?.variant === Variant.ON_DEMAND ? " runbook-on-demand-inputs-dialog" : "")} onClose={() => {
            setDialogState(updateDialogState(dialogState, false, false, []));
        }} />
        <OneColumnContainer className="">
            {
            //This section shows a back button that can be used to get back to the create runbook page.  It is commented out
            //because we added the back button in the create runbook page itself.
            }
            {false && runbookConfig && <OneColumnContainer 
                showBackButton={runbookConfig !== null && runbookConfig !== undefined && !runbookConfig?.isFactory} 
                onBackClicked={() => {
                    history.push(
                        getURL(
                            getURLPath("create-runbook"),
                            { [PARAM_NAME.rbConfigId]: activeRunbook.id, [PARAM_NAME.rbConfigNm]: activeRunbook.label },
                            { replaceQueryParams: true }
                        )
                    );
                }}
            ></OneColumnContainer>}
            {
            // This section displays the title for the view runbook page when it is launched directly from the runbook list and 
            // there is no back button.
            }
            {!props.runbook && <div className="mb-3 mt-5">
                <span className="display-7 font-weight-500 text-black">{STRINGS.riverbedAdvisor.title}
                <sup className="display-9 font-weight-bold">{STRINGS.riverbedAdvisor.trademark}</sup> {STRINGS.riverbedAdvisor.details}</span>
                {runbookConfig && !runbookConfig.isFactory && <span className="display-7 font-weight-500 text-black"> (
                    <span onClick={(evt) => {
                        evt.preventDefault();
                        history.push(
                            getURL(
                                getURLPath("create-runbook"),
                                { 
                                  [PARAM_NAME.rbConfigId]: activeRunbook.id,
                                  [PARAM_NAME.rbConfigNm]: activeRunbook.label,
                                  [PARAM_NAME.variant]: activeRunbook.variant 
                                },
                                { replaceQueryParams: true }
                            )
                        );
                    }} style={{color: "#106ba3", cursor: "pointer"}}>{STRINGS.riverbedAdvisor.editRunbook}
                    </span>
                )</span>}
            </div>}
            {
            // This section displays the runbook when we are running a preview
            }
            {(params[PARAM_NAME.rbViewMode] === "preview" && (runbookOutput || loading)) && <DataLoadFacade loading={loading} data={runbookOutput} error={undefined} showContentsWhenLoading={true} className="runbook-view-data-load">
                <div className="d-flex flex-wrap" >
                    {activeRunbook?.variant !== Variant.ON_DEMAND && createImpactSummary(impactedUsers, impactedSites, impactedApps)}
                    {activeRunbook?.variant !== Variant.ON_DEMAND && INCIDENT_DETAILS_STYLE !== "table" &&
                    <PriorityReasonsView
                        showTitleIcons={showTitleIcons}
                        runbookOutput={runbookOutput}
                        showFooter={true}
                        onDetails={(icon: string, title: string, data: PriorityReason[]) => {
                            setShowBlade(true);
                            setBladeContent(BladeContent.PRIORITY_REASONS);
                            setPriorityReasonsDetails({icon, title, data});
                        }}
                        />}
                </div>
                {INCIDENT_DETAILS_STYLE !== "table" && <div className="mb-4">
                    <IconTitle icon={showTitleIcons ? SDWAN_ICONS.TRIGGER : undefined} 
                        title={STRINGS.incidents.runbookTitle} size={SIZE.m} className="mb-2 font-weight-500" 
                    />
                    {INCIDENT_DETAILS_STYLE !== "noTableOneCardForEachWidget" && <Card>
                        <ViewCollection activeView="runbook">
                            <div key="runbook" className="runbook-output-tab">
                                <CreateHeaderWithMoreButton runbookConfig={runbookConfig} status={RUNBOOK_STATUS.SUCCEEDED} runbookOutput={runbookOutput} setDialogState={setDialogState} openRunbookNodesTraversedDialog={openRunbookNodesTraversedDialog} />
                                <RunbookView runbook={processRunbookData(runbookOutput, runbookConfig || {})} key={runbookOutput?.id || "new_runbook"} showWidgetToolbar={false} 
                                    className="px-4 pb-4 fd-runbook-output-tab-runbook-bg" 
                                />
                            </div>
                        </ViewCollection>
                    </Card>}
                    {INCIDENT_DETAILS_STYLE === "noTableOneCardForEachWidget" &&
                        <ViewCollection activeView="runbook">
                            <div key="runbook" className="runbook-output-tab">
                                <CreateHeaderWithMoreButton runbookConfig={runbookConfig} status={RUNBOOK_STATUS.SUCCEEDED} runbookOutput={runbookOutput} setDialogState={setDialogState} openRunbookNodesTraversedDialog={openRunbookNodesTraversedDialog} />
                                <RunbookView runbook={processRunbookData(runbookOutput, runbookConfig || {})} key={runbookOutput?.id || "new_runbook"} showWidgetToolbar={false} 
                                    className="pb-4 fd-runbook-output-tab-runbook-bg" 
                                />
                            </div>
                        </ViewCollection>
                    }
                </div>}
                {INCIDENT_DETAILS_STYLE === "table" && createTriggerTable(
                    triggerTableColumns, triggerRow, runbookConfig, RUNBOOK_STATUS.SUCCEEDED, 
                    <RunbookView runbook={processRunbookData(runbookOutput, runbookConfig || {})} key={runbookOutput?.id || "new_runbook"} showWidgetToolbar={false} className="px-4 pb-4 fd-runbook-output-tab-runbook-bg" />,
                    runbookOutput, setDialogState
                )}
            </DataLoadFacade>}
            {
            // This section displays the runbook in test mode and relies on the incident id and detection id to query it via DAL.
            // You can get here by one of two ways, the user types in the incident id and detection id in the URL directly or the
            // user runs a test, which will poll for the data, once data is found it will update the URL with the incident id and 
            // detection id
            }
            {params[PARAM_NAME.incidentId] && <DataLoadFacade loading={loading} data={{}} error={undefined} fullScreen={false} showContentsWhenLoading={false} transparent={true} >
                {status.statusValue === STATUS_VALUE.TIMEOUT && getTimeoutContent()}
                {status.statusValue === STATUS_VALUE.ERROR && getErrorContent(errorMsg.current, stackTrace.current)}
                {status.statusValue !== STATUS_VALUE.TIMEOUT && status.statusValue !== STATUS_VALUE.ERROR && <>
                    {isLifecycleRunbook(runbookConfig) && <>
                        {createLifecycleHeader(runbookConfig, runbookStatus.current, runbookOutput, setDialogState)}
                        <LifecycleRunbookView incidentId={params[PARAM_NAME.incidentId]} 
                            runbookId={params[PARAM_NAME.runbookId]} onRunbookDataReceived={(data) => {
                                if (!runbookData) {
                                    setRunbookData(data);
                                }
                            }}
                            onRunbookReceived={(runbook) => {
                                if (!runbookOutput) {
                                    if (runbookStatus.current === RUNBOOK_STATUS.UNKNOWN) {
                                        runbookStatus.current = runbook.status || RUNBOOK_STATUS.UNKNOWN;
                                    }
                                    const impactedUsers: Array<string> = runbook?.impactedUsers?.map((item) => {
                                        return item?.name || item?.deviceName || item?.ipAddress || "";
                                    }) || [];
                                    const impactedLocations: Array<string> = runbook?.impactedLocations?.map((item) => {
                                        return item?.name || "";
                                    }) || [];
                                    const impactedApplications: Array<string> = runbook?.impactedApplications?.map((item) => {
                                        return item?.name || "";
                                    }) || [];
                                    setRunbookOutput(runbook);
                                    setImpactedUsers(impactedUsers);
                                    setImpactedApps(impactedApplications);
                                    setImpactedSites(impactedLocations);
                                }
                            }}
                        />
                    </>}
                    {!isLifecycleRunbook(runbookConfig) && <>
                        {!loading && !IS_EMBEDDED && <div className="d-flex flex-wrap">
                            {activeRunbook?.variant !== Variant.ON_DEMAND && createImpactSummary(impactedUsers, impactedSites, impactedApps)}
                            {activeRunbook.variant !== Variant.ON_DEMAND && INCIDENT_DETAILS_STYLE !== "table" && <PriorityReasonsView 
                                showTitleIcons={showTitleIcons} runbookOutput={runbookOutput} showFooter={true} 
                                onDetails={(icon: string, title: string, data: PriorityReason[]) => {
                                    setShowBlade(true);
                                    setBladeContent(BladeContent.PRIORITY_REASONS);
                                    setPriorityReasonsDetails({icon, title, data});
                                }}
                            />}
                        </div>}
                        {INCIDENT_DETAILS_STYLE !== "table" && <div className="mb-4">
                            <IconTitle icon={showTitleIcons ? SDWAN_ICONS.TRIGGER : undefined} 
                                title={STRINGS.incidents.runbookTitle} size={SIZE.m} className="mb-2 font-weight-500" 
                            />
                            {INCIDENT_DETAILS_STYLE !== "noTableOneCardForEachWidget" && <Card>
                                <ViewCollection activeView="runbook">
                                    <div key="runbook" className="runbook-output-tab">
                                        <CreateHeaderWithMoreButton runbookConfig={runbookConfig} status={runbookStatus.current} runbookOutput={runbookOutput} setDialogState={setDialogState} openRunbookNodesTraversedDialog={openRunbookNodesTraversedDialog} />
                                        <RunbookView incidentId={params[PARAM_NAME.incidentId]} 
                                            runbookId={params[PARAM_NAME.runbookId]} onRunbookDataReceived={(data) => {
                                                if (!runbookData) {
                                                    setRunbookData(data);
                                                }
                                            }}
                                            onRunbookReceived={(runbook) => {
                                                if (!runbookOutput) {
                                                    if (runbookStatus.current === RUNBOOK_STATUS.UNKNOWN) {
                                                        runbookStatus.current = runbook.status || RUNBOOK_STATUS.UNKNOWN;
                                                    }
                                                    const impactedUsers: Array<string> = runbook?.impactedUsers?.map((item) => {
                                                        return item?.name || item?.deviceName || item?.ipAddress || "";
                                                    }) || [];
                                                    const impactedLocations: Array<string> = runbook?.impactedLocations?.map((item) => {
                                                        return item?.name || "";
                                                    }) || [];
                                                    const impactedApplications: Array<string> = runbook?.impactedApplications?.map((item) => {
                                                        return item?.name || "";
                                                    }) || [];
                                                    setRunbookOutput(runbook);
                                                    setImpactedUsers(impactedUsers);
                                                    setImpactedApps(impactedApplications);
                                                    setImpactedSites(impactedLocations);
                                                }
                                            }}
                                            showWidgetToolbar={false} className="px-4 pb-4 fd-runbook-output-tab-runbook-bg"
                                        />
                                    </div>
                                </ViewCollection>
                            </Card>}
                            {INCIDENT_DETAILS_STYLE === "noTableOneCardForEachWidget" && 
                                <ViewCollection activeView="runbook">
                                    <div key="runbook" className="runbook-output-tab">
                                        <CreateHeaderWithMoreButton runbookConfig={runbookConfig} status={runbookStatus.current} runbookOutput={runbookOutput} setDialogState={setDialogState} openRunbookNodesTraversedDialog={openRunbookNodesTraversedDialog} />
                                        <RunbookView incidentId={params[PARAM_NAME.incidentId]}
                                            runbookId={params[PARAM_NAME.runbookId]} onRunbookDataReceived={(data) => {
                                                if (!runbookData) {
                                                    setRunbookData(data);
                                                }
                                            }}
                                            onRunbookReceived={(runbook) => {
                                                if (!runbookOutput) {
                                                    if (runbookStatus.current === RUNBOOK_STATUS.UNKNOWN) {
                                                        runbookStatus.current = runbook.status || RUNBOOK_STATUS.UNKNOWN;
                                                    }
                                                    const impactedUsers: Array<string> = runbook?.impactedUsers?.map((item) => {
                                                        return item?.name || item?.deviceName || item?.ipAddress || "";
                                                    }) || [];
                                                    const impactedLocations: Array<string> = runbook?.impactedLocations?.map((item) => {
                                                        return item?.name || "";
                                                    }) || [];
                                                    const impactedApplications: Array<string> = runbook?.impactedApplications?.map((item) => {
                                                        return item?.name || "";
                                                    }) || [];
                                                    setRunbookOutput(runbook);
                                                    setImpactedUsers(impactedUsers);
                                                    setImpactedApps(impactedApplications);
                                                    setImpactedSites(impactedLocations);
                                                }
                                            }}
                                            showWidgetToolbar={false} className="pb-4 fd-runbook-output-tab-runbook-bg"
                                        />
                                    </div>
                                </ViewCollection>
                            }
                        </div>}
                        {INCIDENT_DETAILS_STYLE === "table" && createTriggerTable(
                            triggerTableColumns, triggerRow, runbookConfig, runbookStatus.current, 
                            <RunbookView incidentId={params[PARAM_NAME.incidentId]}
                                runbookId={params[PARAM_NAME.runbookId]} onRunbookDataReceived={(data) => {
                                    if (!runbookData) {
                                        setRunbookData(data);
                                    }
                                }}
                                onRunbookReceived={(runbook) => {
                                    if (!runbookOutput) {
                                        if (runbookStatus.current === RUNBOOK_STATUS.UNKNOWN) {
                                            runbookStatus.current = runbook.status || RUNBOOK_STATUS.UNKNOWN;
                                        }
                                        const impactedUsers: Array<string> = runbook?.impactedUsers?.map((item) => {
                                            return item?.name || item?.deviceName || item?.ipAddress || "";
                                        }) || [];
                                        const impactedLocations: Array<string> = runbook?.impactedLocations?.map((item) => {
                                            return item?.name || "";
                                        }) || [];
                                        const impactedApplications: Array<string> = runbook?.impactedApplications?.map((item) => {
                                            return item?.name || "";
                                        }) || [];
                                        setRunbookOutput(runbook);
                                        setImpactedUsers(impactedUsers);
                                        setImpactedApps(impactedApplications);
                                        setImpactedSites(impactedLocations);
                                    }
                                }}
                                showWidgetToolbar={false} className="px-4 pb-4 fd-runbook-output-tab-runbook-bg"
                            />,
                            runbookOutput, setDialogState
                        )}
                    </>}
                </>}
            </DataLoadFacade>}
            {
            // This section contains the button to re-run a test runbook
            }
            {isRunAnotherTestBtnVisible && <div>
                <span onClick={(evt) => {
                    // Open the run test dialog again and start from scratch
                    errorMsg.current = undefined;
                    stackTrace.current = [];
                    clearQueryParams([PARAM_NAME.incidentId, PARAM_NAME.runbookId], true);
                    const newStatus = Object.assign({}, status);
                    newStatus.status = STRINGS.viewRunbooks.retrievingStatus;
                    newStatus.statusValue = STATUS_VALUE.INITIALIZING;
                    newStatus.orchestratorInfo = null;
                    newStatus.orchestratorError = null;
                    const newRunbookConfig = JSON.parse(JSON.stringify(runbookConfig));
                    setRunbookConfig(newRunbookConfig);
                    setStatus(newStatus);
                    setStatusCount(0);
                    setLoadingState(false);
                    setInputs(null);
                    setRunbookData(null);
                    setRunbookOutput(undefined);
                    setDialogState(updateDialogState(dialogState, true, true, []));
                }} style={{color: "#106ba3", cursor: "pointer"}} className="display-7 font-weight-500" >{STRINGS.viewRunbooks.runTestText}</span>
            </div>}
            {
            // This section has the button to re-run a preview
            }
            {objMetricMetaData && customProperties && !isRunningQuery(status) && !dialogState.showDialog && params[PARAM_NAME.rbViewMode] === "preview" && <div>
                <span onClick={(evt) => {
                    if (runbookConfig) {
                        startPreview(
                            runbookConfig, true, 1, dialogState, setDialogState, 
                            setRunbookOutput, setImpactedUsers, setImpactedSites, setImpactedApps, 
                            setTriggerRow, setFlowData, setLoadingState, objMetricMetaData, customProperties,
                            props.subflows || []
                        );
                    }
                }} style={{color: "#106ba3", cursor: "pointer"}} className="display-7 font-weight-500" >{STRINGS.viewRunbooks.runPreviewText}</span>
            </div>}
            {
            // This section displays the debug information
            }
            {showDebugInformation && debugJsx}
            {false && <span>{flowData ? JSON.stringify(flowData) : "No data queried!"}</span>}   
        </OneColumnContainer>
    </>);
};

/** returns whether or not a query is currently running.
 *  @param status the status object which has the current state.
 *  @returns true if there is a query running, false otherwise. */
function isRunningQuery(status: RunbookStatus): boolean {
    return status.statusValue === STATUS_VALUE.RUNNING;
}

/** returns the content that should be displayed when a runbook times out.
 *  @returns the JSX with the content that should be displayed. */
function getTimeoutContent(): JSX.Element {
    return <>
        <br />
        <div>
            <Icon icon={IconNames.WARNING_SIGN} iconSize={26} color={GENERAL_COLORS.warning} className="mr-3" />
            <span className="display-7">{STRINGS.viewRunbooks.timeout.msg}</span>
        </div>
        <div className="display-8 mt-2">
            {STRINGS.viewRunbooks.timeout.suggestionsText}
            <ol className="mt-2">
                <li>{STRINGS.viewRunbooks.timeout.sug1}</li>
                <li>{STRINGS.viewRunbooks.timeout.sug2}</li>
                <li>{STRINGS.viewRunbooks.timeout.sug3}</li>
            </ol>
        </div>
    </>;
}

/** returns the content that should be displayed when a runbook fails.
 *  @param errorMsg a string with the error message from the back-end.
 *  @param stackTrack a string with the stack trace information, if any.
 *  @returns the JSX with the content that should be displayed. */
function getErrorContent(errorMsg: string | undefined, stackTrace: Array<string>): JSX.Element {
    return <>
        <br />
        <div>
            <Icon icon={IconNames.ERROR} iconSize={26} color={GENERAL_COLORS.error} className="mr-3" />
            <span className="display-7">{STRINGS.viewRunbooks.error.msg}</span>
        </div>
        {errorMsg && errorMsg.length > 0 && <div className="display-8 mt-2">
            {STRINGS.formatString(STRINGS.viewRunbooks.error.errorText, errorMsg)}
        </div>}
        {stackTrace && stackTrace.length > 0 && formatStackTrace(stackTrace)}
        <div className="display-8 mt-2">
            {STRINGS.viewRunbooks.error.suggestionsText}
            <ol className="mt-2">
                <li>{STRINGS.viewRunbooks.error.sug1}</li>
                <li>{STRINGS.viewRunbooks.error.sug2}</li>
                <li>{STRINGS.viewRunbooks.error.sug3}</li>
            </ol>
        </div>
    </>;
}

/** formats the stack trace.
 *  @param stackTrack a string with the stack trace information, if any.
 *  @returns the JSX with the stack trace information. */
/* istanbul ignore next */
function formatStackTrace(stackTrace: Array<string>): JSX.Element {
    const traceLines: Array<JSX.Element> = [];
    let lineIndex = 1;
    for (const line of stackTrace) {
        const subLines = line.split("\n");
        for (const subLine of subLines) {
            const margin = getMargin(subLine);
            const settings = margin > 0 ? {className: "ml-" + margin} : {};
            traceLines.push(<li {...settings} key={"line" + lineIndex++}>{subLine}</li>)    
        }
    }

    return <div className="display-8 mt-2">
        {STRINGS.viewRunbooks.error.stackTraceText}
        <ul className="no-bulltes mt-2">
            {traceLines}
        </ul>
    </div>;
}

/** returns the margin for the stacktrace lines.
 *  @param line the String with the text for the line.
 *  @returns the margin size for the bootstrap "mr-" css value or 0 if there is no margin. */
function getMargin(line: string): number {
    if (line && line.startsWith(" ")) {
        let count = 0;
        while (count < line.length && line.charAt(count) === ' ') {
            count++;
        }
        return Math.min(5, count);
    }
    return 0;
}

/** processes the runbook data sent from Desso's test runbook.
 *  @param runbookData the data sent from http out in Desso's test runbook.
 *  @param runbook the RunbookConfig that is used for a stand-in for the template.  I don't want to 
 *      keep running JSON.stringify every time I change the runbook right now.
 *  @returns the runbook that was created from the data.*/
function processRunbookData(runbookData: any, runbook: RunbookConfig): RunbookOutput {
    const processedRunbook: RunbookOutput = {
        id: "0",
        targetId: "1",
        name: "Interface has high Utilization and is congested.  The VoIP application is degraded",
        severity: {value: SEVERITY.CRITICAL},
        priority: runbookData.priority,
        priorityReasons: runbookData.priorityReasons,
        datasets: []
    };

//    const template = runbook.nodes;
    let template = runbookData.template.nodes;
    try {
        template = JSON.parse(runbookData.template);
    } catch (e) {}

    if (runbookData?.datasets?.length > 0 && template) {
        for (const dataset of runbookData.datasets) {
            const processedDataset = processDataset(dataset, template.nodes);
            if (processedDataset) {
                processedRunbook.datasets.push(processedDataset);
            }
        }
    }

    return processedRunbook;
}

/** generates the debug panel that is displayed below the runbook.
 *  @param boolean a boolean value which specifies whether the debug information should be displayed to the user.
 *  @param runbookData the runbook data returned by DAL.
 *  @param status the status information to display in the panel.
 *  @param statusCount the current iteration that the status check is in.  The count starts at 0 and goes to maxStatusCount.
 *  @param inputs the inputs that are currently being used to run the runbook.
 *  @returns the JSX with the debug panel. */
function createDebugPanel(
    showDebug: boolean, setShowDebug: (boolean) => void, runbookData: any, status: RunbookStatus, statusCount: number,
    inputs: RunbookInputs | null
): JSX.Element {
    return <>
        <div className="clickable mt-5 my-2"
            onClick={() => {
                    if (showDebug) {
                        setShowDebug(false);
                    } else {
                        setShowDebug(true);
                    }
            }}
        >
            <Icon icon={showDebug ? APP_ICONS.SECTION_OPEN : APP_ICONS.SECTION_CLOSED}/>
            <span className="display-8">{STRINGS.viewRunbooks.debugText}</span>
        </div>
        {showDebug && <table className="display-8"><tbody>
            <tr>
                <td className="p-1 font-weight-800">{STRINGS.viewRunbooks.statusLabel}</td>
                <td className="p-1">{status.status}</td>
            </tr> 
            {statusCount > 0 && <tr>
                <td className="p-1 font-weight-800">{STRINGS.viewRunbooks.queryTimeLabel}</td>
                <td className="p-1">{statusCount * (statusTimeIncrement / 1000)} (s)</td>
            </tr>}
            {status.orchestratorError && <tr>
                <td className="p-1 font-weight-800" style={{verticalAlign: "top"}}>{STRINGS.viewRunbooks.orchestratorErrorLabel}</td>
                <td className="p-1">{status.orchestratorError}</td>
            </tr>}
            {inputs && <tr>
                <td className="p-1 font-weight-800" style={{verticalAlign: "top"}}>{STRINGS.viewRunbooks.inputsLabel}</td>
                <td className="p-1"><TextArea defaultValue={JSON.stringify(inputs, null, 4)} className="vrp-textarea"/></td>
            </tr>}
            {status.orchestratorInfo && <tr>
                <td className="p-1 font-weight-800" style={{verticalAlign: "top"}}>{STRINGS.viewRunbooks.orchestratorLabel}</td>
                <td className="p-1"><TextArea defaultValue={status.orchestratorInfo} className="vrp-textarea"/></td>
            </tr>}
            {runbookData && <tr>
                <td className="p-1 font-weight-800" style={{verticalAlign: "top"}}>{STRINGS.viewRunbooks.dataLabel}</td>
                <td className="p-1"><TextArea defaultValue={JSON.stringify(runbookData, null, 4)} className="vrp-textarea"/></td>
            </tr>}
        </tbody></table>}
    </>;
}

/** clears the timeout id.
 *  @param timeoutId the object returned by useRef that contains the timeout id. */
function clearAutoUpdateTimeout(timeoutId: {current: number}): void {
    if (timeoutId.current) {
        clearTimeout(timeoutId.current);
        timeoutId.current = 0;
    }
}

/** displays the inputs dialog.
 *  @param runbook the configuration of the runbook.
 *  @param primaryEntities the array of primary entities or an empty array.  The primary types
 *      are interface, device and application.  The application entity may also be paired with 
 *      a location.  In this case the location is the secondary entity.
 *  @param secondaryEntities the array of secondary entities.  Applications are paired with 
 *      locations so locations are considered the secondary entities.
 *  @param maxStatusChecks 
 *  @param setInputs the function to set the input state.
 *  @param setDialogState the function to set the dialog state.
 *  @param setTriggerRow the function to set the trigger row state.
 *  @param runbookPrefs the runbook prefs with the saved inputs for the runbook. 
 *  @param initialValues the optional initial values to use for the webhook trigger, these are 
 *      passed in by a message. */
function showInputDialog(
    runbook: RunbookConfig, primaryEntities: Array<any>, secondaryEntities: Array<any>, maxStatusChecks: any, 
    setInputs: (inputs: RunbookInputs | null) => void, setDialogState: (state: any) => void, 
    setTriggerRow: (data: Record<string, any>) => void, runbookPrefs: RunbookInputsPreference | undefined | null, 
    initialValues?: InitialValues
): void {
    const newDialogState: any = {showDialog: true, loading: false, title: STRINGS.viewRunbooks.inputDialogTitle};

    if (initialValues?.timeout) {
        maxStatusChecks.current = (initialValues.timeout * 60 * 1000) / statusTimeIncrement
    }

    // Add code to detect any trigger metric conditions
    const hasTriggerMetricConditions = true;

    let typesName: string = NamesByTriggerType[runbook!.triggerType!] || "";

    const timeoutInMinutes = statusTimeIncrement * maxStatusChecks.current / (60 * 1000);

    let jsonInput: RunbookInputs | undefined = undefined;
    let inputConfig: InputConfig | undefined = undefined;
    let newTimeout: number = timeoutInMinutes;
    const introText: string = STRINGS.formatString(STRINGS.viewRunbooks.inputRunbookText, typesName);
    newDialogState.dialogContent = <>
        {runbook.variant !== Variant.ON_DEMAND && <div className="mb-3"><span>{introText}</span></div>}
        {runbook.variant === Variant.ON_DEMAND ? <RunbookInputsForm 
            selectedRunbook={runbook.id} allowRunbookSelection={true} showTimeout={true} timeoutInMinutes={timeoutInMinutes}
            invocationType={INVOCATION_TYPE.test}
            onTimeoutChanged={(timeout: number) => {
                newTimeout = timeout;
            }}
            onRunbookInputChange={(inputs: RunbookInputs | undefined, inputConfigObj: InputConfig) => {
                jsonInput = inputs;
                inputConfig = inputConfigObj;
            }}
            runOnDemandRunbook={true}
        /> : <RunbookInputsForm 
            runbook={runbook} allowRunbookSelection={false} showTimeout={true}  timeoutInMinutes={timeoutInMinutes}
            invocationType={INVOCATION_TYPE.test}
            onTimeoutChanged={(timeout: number) => {
                newTimeout = timeout;
            }} 
            onRunbookInputChange={(inputs: RunbookInputs | undefined, inputConfigObj: InputConfig) => {
                jsonInput = inputs;
                inputConfig = inputConfigObj;
            }}
            showTriggerMetric={hasTriggerMetricConditions} showIncidentVariables={true}
            showRollupOptions={ROLLUP_ENVS.includes(ENV)}
            initialValues={initialValues}
            allowVantagePointSelection={(getArDataSources() || []).length > 0}
        />}
    </>;
    newDialogState.dialogFooter = <Button active={true} outlined={true} onClick={(evt) => {
        // Update the timeout
        if (newTimeout > 0 && newTimeout < 60) {
            maxStatusChecks.current = (newTimeout * 60 * 1000) / statusTimeIncrement;
        }

        let entityName = "";
        let description = "";
        if (jsonInput && !jsonInput.errors?.length) {
            const kind = jsonInput?.detection.entity?.kind || "";
            switch (kind) {
                case ENTITY_KIND.DEVICE:
                    description = STRINGS.fakeData.deviceRunbookName;
                    entityName = `${jsonInput?.detection.entity?.attributes.ipaddr}`;
                    break;
                case ENTITY_KIND.INTERFACE:
                    description = STRINGS.fakeData.interfaceRunbookName;
                    entityName = `${jsonInput?.detection.entity?.attributes.ipaddr}:${jsonInput?.detection.entity?.attributes.ifindex}`;
                    break;
                case ENTITY_KIND.APPLICATION_LOCATION:
                case ENTITY_KIND.APPLICATION_SERVER:
                    description = STRINGS.fakeData.applicationLocationRunbookName;
                    entityName = `${jsonInput?.detection.entity?.attributes.application}`;
                    break;
                case ENTITY_KIND.LOCATION:
                    description = STRINGS.fakeData.locationRunbookName;
                    entityName = `${jsonInput?.detection.entity?.attributes.location}`;
                    break;
                case ENTITY_KIND.APPLICATION:
                    description = STRINGS.fakeData.applicationRunbookName;
                    entityName = `${jsonInput?.detection.entity?.attributes.application}`;
                    break;
            }    
            setDialogState(updateDialogState(newDialogState, false, false, []));
            setInputs(jsonInput || null);
            setTriggerRow({entityName, description, generated: new Date(), indicatorsCount: 0});
            // Merge in the runbook inputs
            const newPrefs = JSON.parse(JSON.stringify(runbookPrefs || {types: {}, runbooks: {}}));
            if (runbook.triggerType) {
                let origInputPrefs = newPrefs.types[runbook.triggerType] || {};
                setNulls(origInputPrefs.primarySearchItem);
                setNulls(origInputPrefs.secondarySearchItem);
                let newInputPrefs = mergeWith(origInputPrefs, inputConfig, (objValue, srcValue) => {
                    if (Array.isArray(objValue) || Array.isArray(srcValue)) {
                        // By default mergeWith will merge arrays, we want to replace the array's contents
                        return srcValue;
                    }
                });
                newPrefs.types[runbook.triggerType] = newInputPrefs;
            }
            if (runbook.id) {
                let origInputPrefs = newPrefs.runbooks[runbook.id] || {};
                setNulls(origInputPrefs.primarySearchItem);
                setNulls(origInputPrefs.secondarySearchItem);
                newPrefs.runbooks[runbook.id] = runbook.variant === Variant.ON_DEMAND ? {
                    ...jsonInput, 
                    primarySearchItem: inputConfig?.primarySearchItem, 
                    secondarySearchItem: inputConfig?.secondarySearchItem
                } : mergeWith(origInputPrefs, inputConfig, (objValue, srcValue) => {
                    if (Array.isArray(objValue) || Array.isArray(srcValue)) {
                        // By default mergeWith will merge arrays, we want to replace the array's contents
                        return srcValue;
                    }
                });
            }
            setUserPreferences({runbookInputs: newPrefs});
        } else if (jsonInput && jsonInput.errors?.length) {
            setDialogState(updateDialogState(newDialogState, true, false, jsonInput.errors));
            return;
        } else {
            setDialogState(updateDialogState(newDialogState, true, false, [STRINGS.viewRunbooks.inputErrorText]));
            return;
        }
    }} text={STRINGS.viewRunbooks.okBtnText} />;
    setDialogState(newDialogState);
}

/** sets an object's keys to nulls. 
 *  @param obj the Object whose keys are to be set to null. */
function setNulls(obj: any): void {
    if (obj && typeof obj === "object") {
        for (const key of Object.keys(obj)) {
            /*
            if (typeof obj[key] === "object") {
                setNulls(obj[key]);
            } else if (typeof obj[key] !== "function" && typeof obj[key] !== "symbol") {
                obj[key] = null;
            }
            */
           obj[key] = null;
        }
    }
}

/** returns an array of strings with the entity kinds that can be used to supply the trigger.
 *  @param runbookConfig the configuration of the runbook.
 *  @returns an array of Strings with the entity kinds that can supply the trigger. */
/*
function getPrimaryEntityKindsForTrigger(runbookConfig: RunbookConfig): Array<string> {
    switch (runbookConfig.triggerType) {
        case InputType.INTERFACE:
            return ["network_interface"];
        case InputType.DEVICE:
            return ["network_device"];
        case InputType.APPLICATION_LOCATION:
            return ["application"];
        case InputType.LOCATION:
            return ["location"];
    }
    return [];
}
*/

/** returns an array of strings with the entity kinds that can be used to supply the trigger.
 *  @param runbookConfig the configuration of the runbook.
 *  @returns an array of Strings with the entity kinds that can supply the trigger. */
/*
function getSecondaryEntityKindsForTrigger(runbookConfig: RunbookConfig): Array<string> | undefined{
    switch (runbookConfig.triggerType) {
        case InputType.INTERFACE:
            return undefined;
        case InputType.DEVICE:
            return undefined;
        case InputType.APPLICATION_LOCATION:
            return ["location"];
        case InputType.LOCATION:
            return undefined;
    }
    return [];
}
*/

/** returns whether or not the runbook has a logical node.
 *  @param runbookConfig the runbook configuration.
 *  @returns a boolean value, true if the runbook has a logic node, false otherwise. */
function hasLogicNode(runbookConfig: RunbookConfig): boolean {
    if (runbookConfig && runbookConfig.nodes) {
        for (const node of runbookConfig.nodes) {
            if (logicalNodes.includes(node.type)) {
                return true;
            }
        }
    }
    return false;
}

/** returns whether or not the runbook has a decision node.
 *  @param runbookConfig the runbook configuration.
 *  @returns a boolean value, true if the runbook has a decision node, false otherwise. */
function hasDecisionNode(runbookConfig: RunbookConfig): boolean {
    if (runbookConfig && runbookConfig.nodes) {
        for (const node of runbookConfig.nodes) {
            if (decisionNodes.includes(node.type)) {
                return true;
            }
        }
    }
    return false;
}

/** displays the preview dialog and then when the options are selected, it calls createData.
 *  @param runbook the configuration of the runbook.
 *  @param showTrue .
 *  @param decisionIndex .
 *  @param dialogState current dialog state.
 *  @param setDialogState the function to set the dialog state.
 *  @param setRunbookOutput the function to set the runbook output state.
 *  @param setImpactedUsers the function to set the impacted users state.
 *  @param setImpactedSites the function to set the impacted sites state.
 *  @param setImpactedApps the function to set the impacted apps state.
 *  @param setTriggerRow the function to set the trigger row state.
 *  @param setFlowData the function to set the dialog state.
 *  @param setLoadingState the function to set the loading state.
 *  @param dataOceanMetadata the DataOceanMetadata with the objects, metrics and keys for the data ocean. 
 *  @param customProperties the array of CustomProperty objects with the custom properties for all entity types. */
function startPreview(
    runbook: RunbookConfig, showTrue: boolean, decisionIndex: number, dialogState: any, setDialogState: Function, 
    setRunbookOutput: (runbook: RunbookOutput | undefined) => void, 
    setImpactedUsers: (users: Array<string>) => void, setImpactedSites: (sites: Array<string>) => void, 
    setImpactedApps: (apps: Array<string>) => void, setTriggerRow: (data: Record<string, any>) => void,
    setFlowData: Function, setLoadingState: Function, dataOceanMetadata: DataOceanMetadata, customProperties: CustomProperty[],
    subflows: RunbookNode[]
): void {
    if (!hasLogicNode(runbook) && !hasDecisionNode(runbook)) {
        const unprocessedRunbookOutput: RunbookOutput = createData(runbook, true, 1, dataOceanMetadata, customProperties, subflows);
        // Why did I do this?
        //const runbookOutput: RunbookOutput = processRunbookData(unprocessedRunbookOutput, runbook);
        const impactedUsers: Array<string> = createImpactedUsers();
        const impactedSites: Array<string> = createImpactedSites();
        const impactedApps: Array<string> = createImpactedApps();
        const triggerRow: Record<string, any> = createTriggerRow(runbook);
        setRunbookOutput(unprocessedRunbookOutput);
        setImpactedUsers(impactedUsers);
        setImpactedSites(impactedSites);
        setImpactedApps(impactedApps);
        setTriggerRow(triggerRow);
        setFlowData(unprocessedRunbookOutput);
        setLoadingState(false);
        return;
    }

    const newDialogState: any = {showDialog: true, loading: false, title: STRINGS.viewRunbooks.previewDialogTitle};

    let value: string = showTrue ? "true" : "false";
    let outputValue: number = decisionIndex;

    const logicalForm: JSX.Element = <RadioGroup onChange={(event: any) => {
        startPreview(
            runbook, event.currentTarget.value === "true", outputValue, dialogState, setDialogState, 
            setRunbookOutput, setImpactedUsers, setImpactedSites, setImpactedApps, 
            setTriggerRow, setFlowData, setLoadingState, dataOceanMetadata, customProperties, subflows
        );
    }} selectedValue={value}>
        <Radio label={"True Branch"} value={"true"} />
        <Radio label={"False Branch"} value={"false"} />
    </RadioGroup>;

    const decisionForm: JSX.Element = <Label className={Classes.INLINE}>{STRINGS.viewRunbooks.previewOutputLabel} 
        <NumericInput min={0} defaultValue={1} onValueChange={(valueAsNumber: number) => {
            outputValue = valueAsNumber;
    }}/>
    </Label>;

    newDialogState.dialogContent = <>
        {hasLogicNode(runbook) && <>
            <div className="mb-3"><span>{STRINGS.viewRunbooks.previewLogicalIntroText}</span></div>
            <div className="d-flex">
                {logicalForm}
            </div>
        </>}
        {hasDecisionNode(runbook) && <>
            <div className="mb-3"><span>{STRINGS.viewRunbooks.previewDecisionIntroText}</span></div>
            <div className="d-flex">
                {decisionForm}
            </div>
        </>}
    </>;
    newDialogState.dialogFooter = <Button active={true} outlined={true} onClick={(evt) => {
        try {
            setDialogState(updateDialogState(newDialogState, false, false, []));
            const unprocessedRunbookOutput: RunbookOutput = createData(runbook, value === "true", outputValue, dataOceanMetadata, customProperties, subflows);
            // Why did I do this?
            //const runbookOutput: RunbookOutput = processRunbookData(unprocessedRunbookOutput, runbook);
            const impactedUsers: Array<string> = createImpactedUsers();
            const impactedSites: Array<string> = createImpactedSites();
            const impactedApps: Array<string> = createImpactedApps();
            const triggerRow: Record<string, any> = createTriggerRow(runbook);
            setRunbookOutput(unprocessedRunbookOutput);
            setImpactedUsers(impactedUsers);
            setImpactedSites(impactedSites);
            setImpactedApps(impactedApps);
            setTriggerRow(triggerRow);
            setFlowData(unprocessedRunbookOutput);
            setLoadingState(false);    
        } catch (error) {
            setDialogState(updateDialogState(newDialogState, true, false, [STRINGS.viewRunbooks.previewErrorText]));
        }
    }} text={STRINGS.viewRunbooks.okBtnText} />;
    setDialogState(newDialogState);
}

/** creates the impact summary section at the top of the runbook output.
 *  @param impactedUsers a String array with the list of impacted users.
 *  @param impactedSites a String array with the list of impacted sites/locations.
 *  @param impactedApps a String array with the list of impacted applications.
 *  @returns the React component with the impact summary. */
function createImpactSummary(impactedUsers: Array<string>, impactedSites: Array<string>, impactedApps: Array<string>): JSX.Element {
    return <div className={"incident-impact-summary"}>
        <IconTitle icon={showTitleIcons ? SDWAN_ICONS.ALERT : undefined} 
            title={STRINGS.incidents.impactSummaryView.title} size={SIZE.m} className="mb-2 font-weight-500"
        />
        <CardsHolder className="pb-3 w-max-12">
            <UsersCard count={impactedUsers.length} data={impactedUsers} showFooter={false} />
            <SitesCard count={impactedSites.length} data={impactedSites} showFooter={false} />
            <ApplicationsCard count={impactedApps.length} data={impactedApps} showFooter={false} />
        </CardsHolder>
    </div>;
}

/** creates the trigger table that contains the trigger row with the runbook output.
 *  @param triggerTableColumns the column definitions for the trigger table.
 *  @param triggerRow an object that contains one row of data in the trigger table.
 *  @param runbookConfig the RunbookConfig object with the configuration of the runbook that is being previewed or tested.
 *  @param status the runbook status.
 *  @param runbookComponent this is the React component that displays the runbook output.  It is inserted into the table
 *      as a subrow.
 *  @param runbookOutput the unprocessed runbook output.
 *  @returns the React component with the trigger table. */
function createTriggerTable(
    triggerTableColumns: Array<TableColumnDef>, triggerRow: Record<string, any>, runbookConfig: RunbookConfig | null,
    status: RUNBOOK_STATUS, runbookComponent: JSX.Element, runbookOutput: RunbookOutput | undefined, 
    setDialogState: (dialogState: any) => void
): JSX.Element {
    return <>
        <IconTitle icon={showTitleIcons ? SDWAN_ICONS.TRIGGER : undefined} 
            title={STRINGS.incidents.incident_sources + " (1)"} size={SIZE.m} className="pb-3 font-weight-bold"
        />
        <Table
            id="fdRunbookOutputList"
            columns={triggerTableColumns}
            data={[{...triggerRow, subComponent: <div key="runbook" className="fd-runbook-output-tab">
                {createOneLineHeader(runbookConfig, status, runbookOutput, setDialogState)}
                {runbookComponent}
            </div>}]}
            interactive={false}
            //onRowClick={handleRowClick}
            sortBy={[{ id: "timestamp", desc: true }]}
            enablePagination={false}
            className={"fd-trigger-list-view mb-4"}
            expandOnlyOneRow
            expandedRows={["0"]}
            //onExpansionChange={handleExpansionChange}
        />
    </>;
}

/** returns the latest version of the header from Damien's mock-ups.
 *  @param runbookConfig the object with the runbook configuration.
 *  @param status the runbook status.
 *  @param runbookOutput the RunbookOutput object with the full runbook output data.
 *  @param setDialogState the function to set the dialog state.
 *  @returns a JSX.Element with the header content. */
/* istanbul ignore next */
function createOneLineHeader(
    runbookConfig: RunbookConfig | null, status: RUNBOOK_STATUS, runbookOutput: RunbookOutput | undefined, 
    setDialogState: (dialogState: any) => void
): JSX.Element {
    let selectedRunbookTime = formatToLocalTimestamp(new Date(), TIME_FORMAT.DISPLAY_TIME_FORMAT);
    const {hasError, hasWarning, hasVariables, hasInput, hasIndicators, hasDebug} = checkForErrorsAndDebug(runbookOutput);
    let runbookModifiedTime;
    let isActive = true, isOneOfTriggerActive = true;

    const runbookMenuItems: Array<JSX.Element> = [];
    runbookMenuItems.push(
        <MenuItem 
            text={<>
                <PriorityLEDFormatter priority={runbookOutput?.priority || PRIORITY.CRITICAL} showStatusAsBackground/>
                <span className="ml-2">{runbookConfig?.name + " - " + formatToLocalTimestamp(new Date(), TIME_FORMAT.DISPLAY_TIME_FORMAT)}</span>
            </>} 
            active={false} key={"runbookid"} onClick={() => {console.log("fake runbook selected");}} 
        />
    );

    return <div className="d-flex align-items-center runbook-output-tab-runbook-bg py-2 px-2 m-3 rounded">
        <div className="mr-4">
            <Popover2 minimal position={Position.BOTTOM_RIGHT} content={
                <Menu>{runbookMenuItems}</Menu>
            } >
                <Button rightIcon={IconNames.CHEVRON_DOWN} text={
                        <><PriorityLEDFormatter priority={runbookOutput?.priority || PRIORITY.CRITICAL} showStatusAsBackground/><span className="ml-2">{runbookConfig?.name + " - " + selectedRunbookTime}</span></>
                    } 
                    className="text-nowrap" small={true}
                />
            </Popover2>
        </div>
        <Button icon={<Icon icon={IconNames.REPEAT} iconSize={12}/>} small={true} intent={Intent.PRIMARY} disabled={false} minimal style={{color: "#4da3ff"}} className="mr-2" 
                onClick={() => {
                    ShowToaster({
                        message: STRINGS.viewRunbooks.rerunRunbookNotAvailable,
                        intent: Intent.WARNING
                    });
                }}
            >
            <span className="btn-link">{STRINGS.incidents.runbookOutputs.reRunRunbook}</span>
        </Button>
        <Button icon={<Icon icon={IconNames.FOLDER_OPEN} iconSize={12}/>} small={true} intent={Intent.PRIMARY} disabled={false} minimal style={{color: "#4da3ff"}} 
                onClick={() => {console.log("click called")
                    ShowToaster({
                        message: STRINGS.viewRunbooks.openRunbookNotAvailable,
                        intent: Intent.WARNING
                    });
                }}
            >
            <span className="btn-link">{STRINGS.incidents.runbookOutputs.openRunbook}</span>
        </Button>
        {(runbookModifiedTime || !isActive || !isOneOfTriggerActive) && <div className="d-flex flex-wrap justify-content-center flex-grow-1 align-items-center">
            <Icon icon={IconNames.WARNING_SIGN} iconSize={14} className="mr-2" style={{color: (!isOneOfTriggerActive ? PRIORITY_COLORS[PRIORITY.CRITICAL] : PRIORITY_COLORS[PRIORITY.MODERATE])}}/>
            {runbookModifiedTime && <span className="mr-2">{STRINGS.formatString(STRINGS.incidents.runbookOutputs.modifiedWarning, runbookModifiedTime)}</span>}
            {(!isActive && isOneOfTriggerActive) && <span className="mr-2">{STRINGS.incidents.runbookOutputs.switchWarning}</span>}
            {(!isActive && !isOneOfTriggerActive) && <span>{STRINGS.incidents.runbookOutputs.noActiveError1}
                <Button minimal disabled={false} className="" text={<span className="btn-link">{STRINGS.incidents.runbookOutputs.noActiveError2}</span>} 
                    onClick={() => console.log("view runbook")} 
                    style={{paddingLeft: "4px", paddingRight: "4px", paddingBottom: "8px"}}
                />
                {STRINGS.incidents.runbookOutputs.noActiveError3}
            </span>}
        </div>}
        <div className="d-flex flex-wrap justify-content-end flex-grow-1">
            {hasError && runbookOutput && <Icon className="mr-2" icon={IconNames.ERROR} intent={Intent.DANGER} onClick={() => {
                showErrorAndWarningDialog(runbookOutput, setDialogState, false);
            }} style={{cursor: "pointer"}} />}
            {hasWarning && !hasError && runbookOutput?.datasets?.length && <Icon className="mr-2" icon={IconNames.WARNING_SIGN} intent={Intent.WARNING} onClick={() => {
                showErrorAndWarningDialog(runbookOutput, setDialogState, false);
            }} style={{cursor: "pointer"}} />}
            {!hasWarning && !hasError && (hasVariables || hasInput || hasIndicators) && runbookOutput && <Icon className="mr-2" icon={IconNames.INFO_SIGN} intent={Intent.SUCCESS} onClick={() => {
                showErrorAndWarningDialog(runbookOutput, setDialogState, false);
            }} style={{cursor: "pointer"}} />}
            <span className="mr-2">{STRINGS.incidents.runbookOutputs.runbookLastRun}:</span>
            <span className="pr-2 font-weight-bold" >{RUNBOOK_STATUS_PROPS[status]?.label}</span>
            {hasDebug && runbookOutput?.datasets?.length && <Icon className="ml-2" icon={IconNames.DIAGNOSIS} onClick={() => {
                showDebugDialog(runbookOutput, setDialogState);
            }} style={{cursor: "pointer"}} />}
        </div>
    </div>;
}

/** returns the latest version of the header from Damien's mock-ups.
 *  @param runbookConfig the object with the runbook configuration.
 *  @param status the runbook status.
 *  @param runbookOutput the RunbookOutput object with the full runbook output data.
 *  @param setDialogState the function to set the dialog state.
 *  @param openRunbookNodesTraversedDialog the function to display the runbook nodes traversed dialog.
 *  @returns a JSX.Element with the header content. */
export function CreateHeaderWithMoreButton({
        runbookConfig, 
        status, 
        runbookOutput, 
        setDialogState,
        openRunbookNodesTraversedDialog
    } : {
        runbookConfig: RunbookConfig | null, 
        status: RUNBOOK_STATUS, 
        runbookOutput: RunbookOutput | undefined, 
        setDialogState: (dialogState: any) => void,
        openRunbookNodesTraversedDialog
    }
): JSX.Element {
    const [traversalDialogState, setTraversalDialogState] = useState<any>({ showDialog: false, title: "Runbook Nodes Traversed", loading: false, dialogContent: null, dialogFooter: null });
    let selectedRunbookTime = formatToLocalTimestamp(runbookOutput?.timestamp ? parseTimeFromDAL(runbookOutput.timestamp) : new Date(), TIME_FORMAT.DISPLAY_TIME_FORMAT);
    const {hasError, hasWarning, hasVariables, hasInput, hasIndicators, hasDebug} = checkForErrorsAndDebug(runbookOutput);
    let runbookModifiedTime;
    let isActive = true, isOneOfTriggerActive = true;
    const entity = runbookOutput?.entity;

    const runbookMenuItems: Array<JSX.Element> = [];
    runbookMenuItems.push(
        <MenuItem 
            text={<>
                <PriorityLEDFormatter priority={runbookOutput?.priority || PRIORITY.CRITICAL} showStatusAsBackground/>
                <span className="ml-2">{runbookConfig?.name + " - " + formatToLocalTimestamp(new Date(), TIME_FORMAT.DISPLAY_TIME_FORMAT)}</span>
            </>} 
            active={false} key={"runbookid"} onClick={() => {console.log("fake runbook selected");}} 
        />
    );

    const moreMenuItems: Array<JSX.Element> = [];
    moreMenuItems.push(
        <MenuItem disabled={false} text={STRINGS.incidents.runbookOutputs.reRunRunbook} active={false} key={"rerun"} icon={IconNames.REPEAT}
            onClick={async () => {
                ShowToaster({
                    message: STRINGS.viewRunbooks.rerunRunbookNotAvailable,
                    intent: Intent.WARNING
                });
            }} 
        />
    );
    /*
    moreMenuItems.push(
        <MenuItem disabled={false} text={STRINGS.incidents.runbookOutputs.openRunbook} active={false} key={"open"} icon={IconNames.FOLDER_OPEN}
            onClick={() => {
                ShowToaster({
                    message: STRINGS.viewRunbooks.openRunbookNotAvailable,
                    intent: Intent.WARNING
                });
            }}
        />
    );
    */

    moreMenuItems.push(
        <MenuItem disabled={false} text={STRINGS.incidents.runbookOutputs.seeNodesTraversed} active={false} key={"open"} icon={IconNames.GRAPH}
            onClick={() => {
                openRunbookNodesTraversedDialog(runbookConfig, runbookOutput, setTraversalDialogState);
            }}
        />
    );

    const entityText: JSX.Element = getEntityDescriptionForRunbook(entity, runbookOutput?.mapping?.triggerType);

    return <>
    <BasicDialog dialogState={traversalDialogState} className={"view-runbook-nodes-traversed-dialog"} onClose={() => {
            setTraversalDialogState(updateDialogState(traversalDialogState, false, false, []));
        }} />
    <div className={"d-flex align-items-center runbook-output-tab-runbook-bg " + (INCIDENT_DETAILS_STYLE === "noTableOneCardForEachWidget" ? " ml-1 mb-2" : " px-2 m-3 rounded")}>
        {entityText}
        {(runbookModifiedTime || !isActive || !isOneOfTriggerActive) && <div className="d-flex flex-wrap justify-content-center flex-grow-1 align-items-center">
            <Icon icon={IconNames.WARNING_SIGN} iconSize={14} className="mr-2" style={{color: (!isOneOfTriggerActive ? PRIORITY_COLORS[PRIORITY.CRITICAL] : PRIORITY_COLORS[PRIORITY.MODERATE])}}/>
            {runbookModifiedTime && <span className="mr-2">{STRINGS.formatString(STRINGS.incidents.runbookOutputs.modifiedWarning, runbookModifiedTime)}</span>}
            {(!isActive && isOneOfTriggerActive) && <span className="mr-2">{STRINGS.incidents.runbookOutputs.switchWarning}</span>}
            {(!isActive && !isOneOfTriggerActive) && <span>{STRINGS.incidents.runbookOutputs.noActiveError1}
                <Button minimal disabled={false} className="" text={<span className="btn-link">{STRINGS.incidents.runbookOutputs.noActiveError2}</span>} 
                    onClick={() => console.log("view runbook")} 
                    style={{paddingLeft: "4px", paddingRight: "4px", paddingBottom: "8px"}}
                />
                {STRINGS.incidents.runbookOutputs.noActiveError3}
            </span>}
        </div>}
        <div className="d-flex flex-wrap justify-content-end flex-grow-1 align-items-center">
            <div className="mr-2">
                <Popover2 minimal position={Position.BOTTOM_RIGHT} content={
                    <Menu>{runbookMenuItems}</Menu>
                } >
                    <Button rightIcon={IconNames.CHEVRON_DOWN} text={
                            <span className="ml-2">{runbookConfig?.name + " - " + selectedRunbookTime}</span>
                        } 
                        className="text-nowrap" small={true}
                    />
                </Popover2>
            </div>
            {hasError && runbookOutput && <Icon className="mr-4" icon={IconNames.ERROR} intent={Intent.DANGER} onClick={() => {
                showErrorAndWarningDialog(runbookOutput, setDialogState, false);
            }} style={{cursor: "pointer"}} />}
            {hasWarning && !hasError && runbookOutput?.datasets?.length && <Icon className="mr-4" icon={IconNames.WARNING_SIGN} intent={Intent.WARNING} onClick={() => {
                showErrorAndWarningDialog(runbookOutput, setDialogState, false);
            }} style={{cursor: "pointer"}} />}
            {!hasWarning && !hasError && (hasVariables || hasInput || hasIndicators) && runbookOutput && <Icon className="mr-4" icon={IconNames.INFO_SIGN} intent={Intent.SUCCESS} onClick={() => {
                showErrorAndWarningDialog(runbookOutput, setDialogState, false);
            }} style={{cursor: "pointer"}} />}
            <span className="mr-2">{STRINGS.incidents.runbookOutputs.runbookLastRun}:</span>
            <span className="pr-2 font-weight-bold" >{RUNBOOK_STATUS_PROPS[status]?.label}</span>
            {hasDebug && runbookOutput?.datasets?.length && <Icon className="ml-2" icon={IconNames.DIAGNOSIS} onClick={() => {
                showDebugDialog(runbookOutput, setDialogState);
            }} style={{cursor: "pointer"}} />}
            <div onClick={(e) => {e.stopPropagation();}}>
                <Popover2 position={Position.BOTTOM_RIGHT} 
                    interactionKind={Popover2InteractionKind.CLICK} 
                    content={
                        <Menu>{moreMenuItems}</Menu>
                } >
                    <Button aria-label="incident-details-runbook-output-more-button" 
                        icon={IconNames.MORE} minimal className="incident-details-runbook-output-action-icon ml-2" 
                        disabled={false} onClick={(e) => {}} 
                    />
                </Popover2>
            </div>
        </div>
    </div></>;
}

/** returns the lifecycle runbook header.
 *  @param runbookConfig the object with the runbook configuration.
 *  @param status the runbook status.
 *  @param runbookOutput the RunbookOutput object with the full runbook output data.
 *  @param setDialogState the function to set the dialog state.
 *  @returns a JSX.Element with the header content. */
function createLifecycleHeader(
    runbookConfig: RunbookConfig | null, status: RUNBOOK_STATUS, runbookOutput: RunbookOutput | undefined, 
    setDialogState: (dialogState: any) => void
): JSX.Element {
    const {hasError, hasWarning, hasVariables, hasInput, hasIndicators, hasDebug} = checkForErrorsAndDebug(runbookOutput);

    let summaryText: string = "";
    switch (runbookConfig?.triggerType) {
        case InputType.IMPACT_ANALYSIS_READY:
            summaryText = "Runbook analysis completed";
            break;
        case InputType.INCIDENT_STATUS_CHANGED:
            summaryText = "Incident status changed";
            break;
        case InputType.INCIDENT_ONGOING_CHANGED:
            summaryText = "Incident ongoing status changed";
            break;
        case InputType.INCIDENT_NOTE_ADDED:
            summaryText = "Note added to incident";
            break;
        case InputType.INCIDENT_NOTE_UPDATED:
            summaryText = "Incident note was updated";
            break;
        case InputType.INCIDENT_INDICATORS_UPDATED:
            summaryText = "Incident indicators have been updated";
            break;
    }

    return <div className="mb-4">
        <div className="mb-2">
            <span className="display-7 ml-1">{STRINGS.runbooks.lifecycleRunbook}</span>
        </div>
        <div className={"d-flex align-items-center runbook-output-tab-runbook-bg " + (INCIDENT_DETAILS_STYLE === "noTableOneCardForEachWidget" ? " ml-1 mb-2" : " px-2 m-3 rounded")}>
            <span>{summaryText}</span>
            <div className="d-flex flex-wrap justify-content-end flex-grow-1 align-items-center">
                {hasError && runbookOutput && <Icon className="mr-4" icon={IconNames.ERROR} intent={Intent.DANGER} onClick={() => {
                    showErrorAndWarningDialog(runbookOutput, setDialogState, false);
                }} style={{cursor: "pointer"}} />}
                {hasWarning && !hasError && runbookOutput?.datasets?.length && <Icon className="mr-4" icon={IconNames.WARNING_SIGN} intent={Intent.WARNING} onClick={() => {
                    showErrorAndWarningDialog(runbookOutput, setDialogState, false);
                }} style={{cursor: "pointer"}} />}
                {!hasWarning && !hasError && (hasVariables || hasInput || hasIndicators) && runbookOutput && <Icon className="mr-4" icon={IconNames.INFO_SIGN} intent={Intent.SUCCESS} onClick={() => {
                    showErrorAndWarningDialog(runbookOutput, setDialogState, false);
                }} style={{cursor: "pointer"}} />}
                <span className="mr-2">{STRINGS.incidents.runbookOutputs.runbookLastRun}:</span>
                <span className="pr-2 font-weight-bold" >{RUNBOOK_STATUS_PROPS[status]?.label}</span>
                {hasDebug && runbookOutput?.datasets?.length && <Icon className="ml-2" icon={IconNames.DIAGNOSIS} onClick={() => {
                    showDebugDialog(runbookOutput, setDialogState);
                }} style={{cursor: "pointer"}} />}
            </div>
        </div>
        {
            !!runbookConfig && 
            !!runbookOutput && 
                <div className="three-dots-menu-path-traversal">
                    <CreateHeaderWithMoreButton 
                        runbookConfig={runbookConfig} 
                        status={RUNBOOK_STATUS.SUCCEEDED} 
                        runbookOutput={runbookOutput} 
                        setDialogState={setDialogState} 
                        openRunbookNodesTraversedDialog={openRunbookNodesTraversedDialog} 
                    />
                </div>
        }
    </div>;
}

/** returns whether or not the runbook is a lifecycle runbook.
 *  @param runbookConfig the RunbookConfig object with the runbook definition.
 *  @returns a boolean true if the runbooks is a lifecycle runbook, false otherwise. */
function isLifecycleRunbook(runbookConfig: RunbookConfig | null): boolean {
    switch(runbookConfig?.triggerType) {
        case InputType.IMPACT_ANALYSIS_READY:
        case InputType.INCIDENT_STATUS_CHANGED:
        case InputType.INCIDENT_ONGOING_CHANGED:
        case InputType.INCIDENT_NOTE_ADDED:
        case InputType.INCIDENT_NOTE_UPDATED:
        case InputType.INCIDENT_NOTE_DELETED:
        case InputType.INCIDENT_INDICATORS_UPDATED:
            return true;
        default:
            return false;
    }
}

/** checks the runbook output for errors and debug information.
 *  @param runbookOutput the RunbookOutput object to check for errors.
 *  @returns an object with the hasWarning and hasError flags. */
export function checkForErrorsAndDebug(
    runbookOutput?: RunbookOutput
): {hasWarning: boolean, hasError: boolean, hasVariables: boolean, hasInput: boolean, hasIndicators: boolean, hasDebug: boolean} {
    let hasError = false, hasWarning = false, hasVariables = false/*true*/, hasInput = false, hasIndicators = false, hasDebug = false;
    if (runbookOutput) {
        if (runbookOutput.datasets) {
            for (const dataset of runbookOutput.datasets) {
                if (dataset.info?.error) {
                    hasError = true;
                }
                if (dataset.info?.warning) {
                    hasWarning = true;
                }
                if (dataset.debug) {
                    hasDebug= true;
                }
            }
        }
        if (runbookOutput.errors?.length) {
            hasError = true;
        }
        if (
            runbookOutput.variableResults && 
            (runbookOutput.variableResults.primitiveVariables?.length || runbookOutput.variableResults.structuredVariables?.length)
        ) {
            hasVariables = true;
        }
        if (runbookOutput.triggerInputs) {
            hasInput = true;
        }
        if (runbookOutput.indicators?.length) {
            hasIndicators = true;
        }
    }
    return {hasError, hasWarning, hasVariables, hasInput, hasIndicators, hasDebug}; 
}

/** Creates a popup that displays the runbook error information.
 *  @param runbookOutput the output from the runbook.
 *  @param setDialogState the set function from useState.  It should be called before exiting this function.
 *  @param debug a boolean value, if true show debug information, if false, do not. */
function showErrorAndWarningDialog(
    runbook: RunbookOutput, setDialogState: (dialogState: any) => void, debug: boolean
): void {
    const dialogState: any = {showDialog: true, loading: false, title: STRINGS.incidents.runbookOutputs.errorDialogTitle};
    const newDialogState = Object.assign({}, dialogState);
    newDialogState.showDialog = true;
    newDialogState.dialogContent = <ErrorDialogContent runbook={runbook} />;
    newDialogState.dialogFooter = <>
        <Button active={true} outlined={true}
            text={STRINGS.runbookOutput.okBtnText}
            onClick={async (evt) => {
                setDialogState(updateDialogState(newDialogState, false, false, []));
            }}
        />
    </>;
    setDialogState(newDialogState);
}

/** Creates a popup that displays the runbook debug information.
 *  @param runbookOutput the output from the runbook.
 *  @param setDialogState the set function from useState.  It should be called before exiting this function. */
function showDebugDialog(
    runbook: RunbookOutput | undefined, setDialogState: (dialogState: any) => void
): void {
    let json = undefined;
    const dialogState: any = {showDialog: true, loading: false, title: STRINGS.incidents.runbookOutputs.debugDialogTitle};
    const newDialogState = Object.assign({}, dialogState);
    newDialogState.showDialog = true;
    newDialogState.dialogContent = <DebugDialogContent runbook={runbook} onJsonOutputChanged={(jsOutput) => {json = jsOutput}}/>;
    newDialogState.dialogFooter = <>
        <Button active={true} outlined={true} 
            text={STRINGS.runbookOutput.copyBtnText} onClick={() => {
                navigator.clipboard.writeText(JSON.stringify(json));
                setDialogState(updateDialogState(newDialogState, false, false, []));
            }}
        />
        <Button active={true} outlined={true}
            text={STRINGS.runbookOutput.okBtnText}
            onClick={async (evt) => {
                setDialogState(updateDialogState(newDialogState, false, false, []));
            }}
        />
    </>;
    setDialogState(newDialogState);
}
