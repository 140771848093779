import React, { useState, ChangeEvent } from 'react';
import classNames from 'classnames';
import { Classes, Divider, FormGroup, MenuItem, Switch, Button } from '@blueprintjs/core';
import { Form, InputField, SelectField } from 'components/common/form';
import { LangEN, STRINGS } from 'app-strings';
import * as yup from 'yup';
import { Suggest } from '@blueprintjs/select';
import { Icon, Intent } from '@blueprintjs/core';
import { IconNames } from '@tir-ui/react-components';

import './ApiKeyAuthParamsPanel.scss';
import { FormikProps } from 'formik';

export interface IApiKeyAuthDetailsPanelProps {
    isEdit: boolean;
    apiKey?: string;
    passingMethod?: ApiKeyMethodTypeEnum | string;
    queryStringParameterName?: string;
    authenticationScheme?: string;
    encodedAuthenticationScheme?: boolean;
    customHeaderName?: string;
    additionalRequestHeaders?: Array<{ name: string, key: string }>;
    tokenName?: string;
    onChangeApiKey?: (event) => void;
    onChangePassingMethod?: (event) => void;
    onChangeQueryStringParameter?: (event) => void;
    onChangeAuthScheme?: (event) => void;
    onChangeEncodedAuthScheme?: (event) => void;
    onChangeCustomHeaderName?: (event) => void;
    onChangeAdditionalRequestHeaders?: (requestHeaders) => void;
    onChangeTokenName?: (event) => void;
}

export enum ApiKeyMethodTypeEnum {
    'QueryParameter' = 0,
    'AuthorizationHeader' = 1,
    'RequestHeader' = 2,
    'RequestBodyField' = 3,
}

export function getPassingMethod(passingMethod) {
    if (passingMethod && typeof passingMethod === 'string') {
        return passingMethod;
    } else if (typeof passingMethod === 'number') {
        return ApiKeyMethodTypeEnum[passingMethod];
    } else {
        return undefined;
    }
}

export enum PassingMethods {
    'RequestHeader' = STRINGS.thirdPartyIntegrations.addAuthProfile.panels
        .apiKeyDetails.fields.passingMethod.methods.requestHeader,
    'QueryParameter' = STRINGS.thirdPartyIntegrations.addAuthProfile.panels
        .apiKeyDetails.fields.passingMethod.methods.queryParameter,
    'RequestBodyField' = STRINGS.thirdPartyIntegrations.addAuthProfile.panels
        .apiKeyDetails.fields.passingMethod.methods.requestBodyField,
    'AuthorizationHeader' = STRINGS.thirdPartyIntegrations.addAuthProfile.panels
        .apiKeyDetails.fields.passingMethod.methods.authHeader,
}

const SUGGESTED_HTTP_AUTH_SCHEMES = [
    'Basic', 'Bearer', 'Digest', 'HOBA', 'Mutual', 'Negotiate', 'OAuth', 'SCRAM-SHA-1', 'SCRAM-SHA-256'
]

interface RequestHeaderRow {
    name: string;
    key: string;
}

const ApiKeyAuthDetailsPanel: React.FC<IApiKeyAuthDetailsPanelProps> = (props) => {
    const [passingMethod, setPassingMethod] = useState<PassingMethods | undefined>(PassingMethods[props?.passingMethod ? props.passingMethod : '']);
    const [typedQuery, setTypedQuery] = useState<string>(props.authenticationScheme || '');
    const translations: LangEN["thirdPartyIntegrations"]["addAuthProfile"] = STRINGS.thirdPartyIntegrations.addAuthProfile;
    const validationSchema = yup.object().shape({
        api_key: props.isEdit ?
            yup
                .string()
                .max(1024)
                .label(translations.panels
                    .apiKeyDetails.fields.apiKey.label) :
            yup
                .string()
                .required()
                .max(1024)
                .label(translations.panels
                    .apiKeyDetails.fields.apiKey.label)
                .notOneOf(['']),
        api_key_method: yup
            .string()
            .required()
            .notOneOf([
                translations.panels
                    .apiKeyDetails.fields.passingMethod.methods.selectMethod
            ])
            .label(translations.panels
                .apiKeyDetails.fields.passingMethod.label),
        query_string_param: yup
            .string()
            .required()
            .max(1024)
            .label(translations.panels
                .apiKeyDetails.fields.queryStringParamName.label),
        auth_scheme: yup
            .string()
            .required()
            .max(1024)
            .label(translations.panels
                .apiKeyDetails.fields.authScheme.label),
        custom_header_name: yup
            .string()
            .required()
            .max(1024)
            .label(translations.panels
                .apiKeyDetails.fields.customHeaderName.label),
        token_name: yup
            .string()
            .required()
            .max(1024)
            .label(translations.panels
                .apiKeyDetails.fields.tokenName.label),

    });

    function passingMethodChangeHandler(newValue) {
        if (props.onChangePassingMethod) {
            props.onChangePassingMethod(PassingMethods[newValue]);
        }
        setPassingMethod(newValue);
    }
    const AutocompleteSuggest = Suggest.ofType<string>();

    const handleItemSelection = (item: string) => {
        if (props.onChangeAuthScheme) {
            props.onChangeAuthScheme(item);
        }
        setTypedQuery(item);
    };

    const handleAddRequestHeaderRow = () => {
        if (props.onChangeAdditionalRequestHeaders) {
            const newRow: RequestHeaderRow = { name: "", key: "" };
            props.onChangeAdditionalRequestHeaders([...(props.additionalRequestHeaders || []), newRow]);
        }
    };

    const handleRemoveRequestHeaderRow = (index: number) => {
        if (props.onChangeAdditionalRequestHeaders && props.additionalRequestHeaders) {
            props.onChangeAdditionalRequestHeaders(
                props.additionalRequestHeaders.filter((_row: RequestHeaderRow, i: number) => i !== index)
            );
        }
    };

    const handleRequestHeaderRowInputChange = (index: number, e: ChangeEvent<HTMLInputElement>) => {
        if (props.onChangeAdditionalRequestHeaders && props.additionalRequestHeaders) {
            const { name, value } = e.target;
            props.onChangeAdditionalRequestHeaders(
                props.additionalRequestHeaders.map((row: RequestHeaderRow, i: number) => i === index ? { ...row, [name]: value } : row)
            );
        }
    };

    const createNewItemFromQuery = (query) => query;

    const createNewItemRenderer = (query, active, handleClick) => (
        <MenuItem
            text={`Click here to use custom scheme "${query}"`}
            active={active}
            onClick={handleClick}
            shouldDismissPopover={false}
        />
    );

    const onQueryChange = (query) => {
        setTypedQuery(query);
        if (query && !SUGGESTED_HTTP_AUTH_SCHEMES.includes(query)) {
            handleItemSelection(query);
        }
    };

    const passingMethodOptions = [translations.panels.apiKeyDetails.fields.passingMethod.methods.selectMethod].concat(Object.keys(PassingMethods).filter((item) => item.includes(' ')));

    return (
        <div className={classNames(Classes.DIALOG_BODY)}>
            <p>
                <b>
                    {
                        translations.panels.apiKeyDetails.title
                    }
                </b>
            </p>
            <Divider />
            <br />
            <Form
                className="api-key-auth-params-panel"
                initialValues={{
                    api_key: props.apiKey,
                    api_key_method: props.passingMethod,
                    query_string_param: props.queryStringParameterName,
                    auth_scheme: props.authenticationScheme,
                    custom_header_name: props.customHeaderName,
                    token_name: props.tokenName,
                }}
                validationSchema={validationSchema}
                loading={false}>
                {(formProps: FormikProps<object>) => <>
                    <SelectField
                        label={
                            translations.panels
                                .apiKeyDetails.fields.passingMethod.label
                        }
                        required={true}
                        name="api_key_method"
                        onChange={(event) => {
                            passingMethodChangeHandler(event.target.value);
                        }}
                        value={props.passingMethod ? PassingMethods[props.passingMethod] : undefined}
                        options={passingMethodOptions}
                    />
                    {passingMethod && ApiKeyMethodTypeEnum[passingMethod ? PassingMethods[passingMethod] : ''] !== 2 && <InputField
                        name="api_key"
                        type="password"
                        required={true}
                        label={
                            translations.panels
                                .apiKeyDetails.fields.apiKey.label
                        }
                        placeholder={
                            props.isEdit ?
                                translations.panels.apiKeyDetails.fields.apiKey.placeholderForEdit :
                                translations.panels.apiKeyDetails.fields.apiKey.placeholder
                        }
                        onChange={(e) => {
                            props.onChangeApiKey && props.onChangeApiKey(e);
                        }}
                        value={props.apiKey}
                        disabled={false}
                        showPasswordOption={true}
                    />}
                    {ApiKeyMethodTypeEnum[passingMethod ? PassingMethods[passingMethod] : ''] === 0 && <>
                        {/* <p>
                        <b>
                            {
                                translations.panels.apiKeyDetails.fields.queryStringParamName.sectionName
                            }
                        </b>
                    </p> */}
                        <InputField
                            name="query_string_param"
                            type="text"
                            required={true}
                            label={
                                translations.panels
                                    .apiKeyDetails.fields.queryStringParamName.label
                            }
                            placeholder={
                                translations.panels
                                    .apiKeyDetails.fields.queryStringParamName.placeholder
                            }
                            onBlur={(event) => {
                                const value = event.target.value?.trim();
                                formProps.handleBlur(event);
                                props.onChangeQueryStringParameter && props.onChangeQueryStringParameter({
                                    target: {
                                        value: value
                                    }
                                });
                            }}
                            onChange={props.onChangeQueryStringParameter}
                            value={props.queryStringParameterName}
                            disabled={false}
                        />
                    </>}
                    {ApiKeyMethodTypeEnum[passingMethod ? PassingMethods[passingMethod] : ''] === 1 && <div>
                        <FormGroup label={translations.panels
                            .apiKeyDetails.fields.authScheme.label}
                            labelInfo={'* '}>
                            <AutocompleteSuggest
                                inputProps={{
                                    placeholder: translations.panels
                                        .apiKeyDetails.fields.authScheme.placeholder,
                                    name: "auth_scheme",
                                    onBlur: () => handleItemSelection(typedQuery),
                                }}
                                query={typedQuery}
                                onQueryChange={onQueryChange}
                                defaultSelectedItem={props.authenticationScheme}
                                resetOnSelect={true}
                                closeOnSelect={true}
                                resetOnClose={true}
                                popoverProps={{ minimal: true, boundary: "viewport" }}
                                items={SUGGESTED_HTTP_AUTH_SCHEMES}
                                itemRenderer={renderAutocompleteItem}
                                itemPredicate={(query, item) => {
                                    const queryLowerCase = query?.toLowerCase() || "";
                                    return item.toLowerCase().includes(queryLowerCase) ? true : false;
                                }}
                                fill={true}
                                inputValueRenderer={item => item}
                                onItemSelect={handleItemSelection}
                                noResults={<MenuItem disabled={true} text={STRINGS.globalFilters.empty} />}
                                createNewItemFromQuery={createNewItemFromQuery}
                                createNewItemRenderer={createNewItemRenderer}
                            />
                        </FormGroup>
                        <div>
                            <label className="d-flex">
                                {translations.panels
                                    .apiKeyDetails.fields.authScheme.encoded}
                                <Switch
                                    className="ml-1 mb-1"
                                    checked={props.encodedAuthenticationScheme}
                                    onChange={props.onChangeEncodedAuthScheme}
                                />
                            </label>
                        </div>
                    </div>
                    }
                    {ApiKeyMethodTypeEnum[passingMethod ? PassingMethods[passingMethod] : ''] === 2 && <>
                        <p className="mt-3 mb-3">
                            <b>
                                {
                                    translations.panels.apiKeyDetails.fields.customHeaderName.sectionName
                                }
                            </b>
                        </p>
                        <div className="request-header-row">
                            <div className="request-header-input">
                                <InputField
                                    name="custom_header_name"
                                    type="text"
                                    required={true}
                                    label={translations.panels
                                        .apiKeyDetails.fields.customHeaderName.label}
                                    placeholder={
                                        translations.panels
                                            .apiKeyDetails.fields.customHeaderName.placeholder
                                    }
                                    onBlur={(event) => {
                                        const value = event.target.value?.trim();
                                        formProps.handleBlur(event);

                                        props.onChangeCustomHeaderName && props.onChangeCustomHeaderName({
                                            target: {
                                                value: value
                                            }
                                        });
                                    }}
                                    onChange={props.onChangeCustomHeaderName}
                                    value={props.customHeaderName}
                                    disabled={false}
                                />
                            </div>
                            <div className="request-header-input">
                                <InputField
                                    name="api_key"
                                    type="password"
                                    required={true}
                                    label={translations.panels
                                        .apiKeyDetails.fields.apiKey.label}
                                    placeholder={
                                        props.isEdit ?
                                            translations.panels.apiKeyDetails.fields.apiKey.placeholderForEdit :
                                            translations.panels.apiKeyDetails.fields.apiKey.placeholderRequestHeaderKey
                                    }
                                    onBlur={(event) => {
                                        const value = event.target.value?.trim();
                                        formProps.handleBlur(event);

                                        props.onChangeApiKey && props.onChangeApiKey({
                                            target: {
                                                value: value
                                            }
                                        });
                                    }}
                                    onChange={props.onChangeApiKey}
                                    value={props.apiKey}
                                    disabled={false}
                                    showPasswordOption={true}
                                />
                            </div>
                            <div className="remove-request-header"></div>
                        </div>
                        {!!props.additionalRequestHeaders?.length && props.additionalRequestHeaders.map((row: RequestHeaderRow, index: number) => (<div className="request-header-row" key={index}>
                            <div className="request-header-input">
                                <InputField
                                    name="name"
                                    type="text"
                                    required={true}
                                    label={""}
                                    placeholder={
                                        translations.panels
                                            .apiKeyDetails.fields.customHeaderName.placeholder
                                    }
                                    onChange={(e: ChangeEvent<HTMLInputElement>) => handleRequestHeaderRowInputChange(index, e)}
                                    value={row.name}
                                    disabled={false}
                                />
                            </div>
                            <div className="request-header-input">
                                <InputField
                                    name="key"
                                    type="password"
                                    required={true}
                                    label={""}
                                    placeholder={
                                        props.isEdit ?
                                            translations.panels.apiKeyDetails.fields.apiKey.placeholderForEdit :
                                            translations.panels.apiKeyDetails.fields.apiKey.placeholderRequestHeaderKey
                                    }
                                    onChange={(e: ChangeEvent<HTMLInputElement>) => handleRequestHeaderRowInputChange(index, e)}
                                    value={row.key}
                                    disabled={false}
                                    showPasswordOption={true}
                                />
                            </div>
                            <div className="remove-request-header">
                                <Icon onClick={() => handleRemoveRequestHeaderRow(index)}
                                    intent={Intent.NONE}
                                    icon={IconNames.CROSS} />
                            </div>
                        </div>))}
                        <div className="add-request-header-control">
                            <Button
                                id="add_request_header"
                                minimal
                                className="font-weight-bold"
                                icon={IconNames.ADD}
                                text={translations.panels.apiKeyDetails.fields.customHeaderName.addRequestHeader}
                                onClick={handleAddRequestHeaderRow}
                            />
                        </div>
                    </>}
                    {ApiKeyMethodTypeEnum[passingMethod ? PassingMethods[passingMethod] : ''] === 3 && <>
                        {/* <p>
                        <b>
                            {
                                translations.panels.apiKeyDetails.fields.tokenName.sectionName
                            }
                        </b>
                    </p> */}
                        <InputField
                            name="token_name"
                            type="text"
                            required={true}
                            label={
                                translations.panels
                                    .apiKeyDetails.fields.tokenName.label
                            }
                            placeholder={
                                translations.panels
                                    .apiKeyDetails.fields.tokenName.placeholder
                            }
                            onBlur={(event) => {
                                const value = event.target.value?.trim();
                                formProps.handleBlur(event);

                                props.onChangeTokenName && props.onChangeTokenName({
                                    target: {
                                        value: value
                                    }
                                });
                            }}
                            onChange={props.onChangeTokenName}
                            value={props.tokenName}
                            disabled={false}
                        />
                    </>}
                </>}
            </Form>
        </div>
    );
};

export { ApiKeyAuthDetailsPanel };

const renderAutocompleteItem = (item: string, { handleClick, modifiers, query }): JSX.Element => {
    return (
        <MenuItem
            active={modifiers.active}
            key={"ac-" + item}
            onClick={handleClick}
            text={<span>{item}</span>}
        />
    );
};