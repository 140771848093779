/** This module creates an AppAuthService which is a wrapper around the TirAuthService
 *  @module
 */
import { AuthService as TirAuthService } from '@tir-ui/azure-auth'
import { ITenant } from '@tir-ui/azure-auth/dist/tenants';

/** this interface defines the app user account object which has three functions. */
export interface AppUserAccount {
    /** returns the username.
     *  @returns a String with the username. */
    getUsername(): string;
    /** returns the user's name.
     *  @returns a String with the user's name. */
    getName(): string;
    /** returns the user id.
     *  @returns a String with the user id. */
    getUserId(): string;
}

/** this interface defines the methods available in the auth service. */
export interface AuthService {
    /** logs out the user. */
    logout(): void;
    /** returns the auth token.
     *  @returns a promise with the token. */
    getToken(): Promise<string>;
    /** returns the user account.
     *  @returns a reference to the user account object. */
    getUserAccount(): AppUserAccount;
    /** returns the tenant object with the tenant information.
     *  @returns the tenant object with the tenant information. */
    getTenant(): ITenant;
    /** returns the tenant id.
     *  @returns a String with the tenant id. */
    getTenantId(): string;
    /** returns a string with the region.
     *  @returns a String with the region. */
    getRegion(): string;
    /** returns a string with the subscription status.
     *  @returns a String with the subscription status. */
    getSubscription(): boolean;
    /** returns whether or not the user is authenticated.
     *  @returns a boolean value, true if we are authenticated, false otherwise. */
    isAuthenticated(): boolean;
    /** returns whether or not the user is an administrator of the whole portfoloio of products.
     *  @returns a boolean value, true if the user is admistrator of the whole portfolio of products. */
    isUserPortfolioAdmin(): boolean;
    /** returns whether or not the user is an administrator of the specified product.
     *  @param product a String that uniquely identifies the product.
     *  @returns a boolean value, true if the user is admistrator of the specified product. */
    isUserProductAdmin(product: string): boolean;
    /** returns whether or not the user has write access to the specified product.
     *  @param product a String that uniquely identifies the product.
     *  @returns a boolean value, true if the user is has write access to the specified product. */
    userHasWriteAccess(product: string): boolean;
    /** returns true if the user has all of the specified permissions.
     *  @param permissionss the array of permissions.
     *  @returns a boolean value, true if the user has all of the permissions. */
	userHasMultiplePermissions(permissionss: string[]): boolean;
    /** returns true if the user has one of the specified permissions.
     *  @param permissionss the array of permissions.
     *  @returns a boolean value, true if the user has one of the permissions. */
	userHasSinglePermission(permissions: string[]): boolean;
}

/** this class is an implementation of the auth service. */
export class AppAuthService implements AuthService {
    /** returns the auth token.
     *  @returns a promise with the token. */
    getToken(): Promise<string> {
        return TirAuthService.getToken()
    }

    /** returns whether or not the user is authenticated.
     *  @returns a boolean value, true if we are authenticated, false otherwise. */
    isAuthenticated(): boolean {
        return TirAuthService.isAuthenticated()
    }

    /** returns the user account.
     *  @returns a reference to the user account object. */
    getUserAccount(): AppUserAccount {
        const userAccount = TirAuthService.getUserAccount()
        return {
            getUsername: function() { return userAccount.getUsername() },
            getName: function() { return userAccount.getName() },
            getUserId: function () { return userAccount.getUserId()}
        }
    }

    /** logs out the user. */
    logout(): void {
        return TirAuthService.logout()
    }

    /** returns the tenant object with the tenant information.
     *  @returns the tenant object with the tenant information. */
    getTenant(): ITenant {
        return TirAuthService.getTenant()
    }

    /** returns the tenant id.
     *  @returns a String with the tenant id. */
    getTenantId(): string {
        return TirAuthService.getTenantId()
    }

    /** returns a string with the region.
     *  @returns a String with the region. */
    getRegion(): string {
        return TirAuthService.getTenant()?.region || "";
    }

    /** returns a string with the subscription status.
     *  @returns a String with the subscription status. */
    getSubscription(): boolean {
        return (TirAuthService.getTenant() as any)?.halt;
    }

    /** returns whether or not the user is an administrator of the whole portfoloio of products.
     *  @returns a boolean value, true if the user is admistrator of the whole portfolio of products. */
    isUserPortfolioAdmin(): boolean {
        return TirAuthService.isUserPortfolioAdmin();
    }

    /** returns whether or not the user is an administrator of the specified product.
     *  @param product a String that uniquely identifies the product.
     *  @returns a boolean value, true if the user is admistrator of the specified product. */
    isUserProductAdmin(product: string): boolean {
        return TirAuthService.isUserProductAdmin(product);
    }

    /** returns whether or not the user has write access to the specified product.
     *  @param product a String that uniquely identifies the product.
     *  @returns a boolean value, true if the user is has write access to the specified product. */
    userHasWriteAccess(product: string): boolean {
        return TirAuthService.userHasWriteAccess(product);
    }

    /**
     * Finds multiple permissionss assigned to a given user.
     */
	userHasMultiplePermissions(permissionss: string[]): boolean {
		return TirAuthService.userHasMultiplePermissions(permissionss);
	}

    /**
     * Determines if a user only has one single permission. If you need to
     * find multiple permissionss, or a user is assigned multiple permissionss, refer to
     * `userHasMultiplePermissionss()` static method.
     *
     * WARN: if `permission` array is greater than one, will return `false`.
     * Please refer to `userHasMultiplePermissions` instead if user is assigned
     * multiple permissionss.
     */
	userHasSinglePermission(permission: string[]): boolean {
		return TirAuthService.userHasSinglePermission(permission);
	}
}
