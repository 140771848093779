/** This file defines the transform editor React component.  The transform editor allows you 
 *  to set the keys and metrics that the transform node will output as well as the template that
 *  is used to transform the input and the output data format.
 *  @module */
import React, { useCallback, useContext, useEffect, useState } from "react";
import { SimpleNodeEditorProps } from "../simple/SimpleNodeEditor";
import { TransformNodeUtils, TRANSFORM_NODE_EDIT_PROPS } from "./TransformNodeUtils";
import { STRINGS } from "app-strings";
import { Button, HTMLSelect, Icon, Intent, Radio, RadioGroup } from "@blueprintjs/core";
import { OutputDataBlock } from "./OutputDataBlock";
import { UniversalNode } from "../../UniversalNode";
import { useStateSafePromise } from "utils/hooks";
import { DataLoadFacade } from "components/reporting/data-load-facade/DataLoadFacade";
import { Tab, TabbedSubPages } from "components/common/layout/tabbed-sub-pages/TabbedSubPages";
import { OutputDataElementDefinition, OutputDataValueType } from "./OutputDataElement";
import { generateRandomID } from "components/common/condition-tree-builder/condition/ConditionUtils";
import { RunbookContextSummary, getExampleOutputText } from "../RunbookContextSummary";
import { GenericKey, NodeUtils } from "utils/runbooks/NodeUtil";
import { DataOceanKey, DataOceanMetadata } from "../data-ocean/DataOceanMetadata.type";
import { SHOW_CONTEXT } from "components/enums/QueryParams";
import { DataOceanUtils } from "../data-ocean/DataOceanUtils";
import { Unit } from "reporting-infrastructure/types/Unit.class";
import { StructuredVariable, GLOBAL_SCOPE, INCIDENT_SCOPE, RUNTIME_SCOPE } from "utils/runbooks/VariablesUtils";
import { VariableContext } from "utils/runbooks/VariableContext";
import { NodeLibraryNode } from 'pages/create-runbook/views/create-runbook/NodeLibrary';
import { BasicDialog, DialogState, updateDialogState } from "components/common/basic-dialog/BasicDialog";
import { Classes, IconNames } from "@tir-ui/react-components";
import { 
    GraphDef, InputType, LIFECYCLE_TRIGGER_TYPES, NodeDef, VARIANTS_WITH_GLOBAL_VARS, 
    VARIANTS_WITH_INCIDENT_VARS, VARIANTS_WITH_RUNTIME_BUILTIN_VARS 
} from "../../types/GraphTypes";
import { CustomPropertyContext } from "pages/create-runbook/views/create-runbook/CustomPropertyTypes";
import { LiquidTemplateEditor } from "./LiquidTemplateEditor";
import { Context, RunbookContext } from "utils/runbooks/RunbookContext.class";
import { RunbookContextUtils } from "utils/runbooks/RunbookContextUtils.class";
import { getNodeFromGraphDef } from "utils/runbooks/RunbookUtils";
import { getTypes } from "utils/stores/GlobalDataSourceTypeStore";

/** this interface defines the properties that are used to store the nodes configurable parameters. */
interface TransformNodeProperties {
    /** a string with the transform template. */
    transformTemplate?: string;
    /** an enum with the output data format which is one of summarized or timeseries. */
    outputDataFormat?: OutputDataFormats;
    /** the array of OutputDataFields with the list of properties. */
    outputDataProperties?: OutputDataField[];
    /** the array of OutputDataFields with the list of metrics. */
    outputDataMetrics?: OutputDataField[];
    /** an enum with the output data format which is one of manual or load from variable. */
    outputDefinitionMethod?: OutputDefinitionMethods;
    /** a string with the name of variable where the node loads from. */
    useVariableDefinition?: string;
}

/** this interface defines some additional behaviors that are added on to a GenericKey */
export interface TriggerKey extends GenericKey {
    /** the trigger type that the trigger key comes from. */
    triggerType?: InputType;
}

/** an enum that specifies the possible output formats. */
export enum OutputDataFormats {
    /** the enumerated value for summary or average data values. */
    SUMMARIZED = "summary",
    /** the enumerated value for timeseries data values. */
    TIMESERIES = "timeseries",
}

/** an enum that specifies the possible output definition methods. */
export enum OutputDefinitionMethods {
    /** the enumerated value for manual values. */
    MANUAL = "manual",
    /** the enumerated value for use variable definition values. */
    USE_VARIABLE_DEFINITION = "use_variable_definition",
}

/** this enum defines the possible update event types that might be fired by the output data block and output data element. */
export enum UpdateEventType {
    /** the enumerated value for a name change. */
    NAME_CHANGED = "NAME_CHANGED",
    /** the enumerated value for a value type change. */
    VALUE_TYPE_CHANGED = "VALUE_TYPE_CHANGED",
    /** the enumerated value for a unit change. */
    UNIT_CHANGED = "UNIT_CHANGED",
    /** the enumerated value for the selection of a default (one of the existing DO ids) key or metric. */
    DEFAULT_SELECTED = "DEFAULT_SELECTED",
    /** the enumerated value for the deletion of a key or metric. */
    DELETED = "DELETED",
}

/** this enum defines the possible element types, which are keys (aka properties) and metrics. */
export enum ElementType {
    /** the enumerated value for a property element. */
    PROPERTY = "Property",
    /** the enumerated value for a metric element. */
    METRIC = "Metric"
}

/** this enum defines the possible section types, which are keys (aka properties) and metrics. */
export enum SectionType {
    /** the enumerated value for a property section. */
    PROPERTY = "PROPERTIES",
    /** the enumerated value for a metric section. */
    METRIC = "METRICS"
}

/** Describe UpdateEvent, triggered when user edits one of the fields */
export interface UpdateEvent {
    /** a string with the unique id for the element. */
    uniqueId: string | undefined;
    /** a boolean value true if the id is a default DO id and false or undefined otherwise. */
    isDoId?: boolean | undefined;
    /** the id for the elemement. */
    id: string;
    /** the value for the update. */
    value: string;
    /** the type of update. */
    type: UpdateEventType;
    /** the element type for the update. */
    element?: ElementType
}

/** Interface that describes Output Data */
export interface OutputDataField {
    /** the id of the key or metric. */
    id: string;
    /** the display label for the key or metric. */
    label: string;
    /** the type of value: string, float, integer, ipaddr, etc. */
    type: string;
    /** a string with the unit. */
    unit: string;
    /** a string with the id of one of the data ocean keys or metrics. */
    dataOceanId?: string;
}

/** Component for editing the properties of a Transform node
 *  @param selectedNode - Currently selected active transform node
 *  @param libraryNode- Selected transform node's meta data.
 *  @param graphDef - Graphdef object that defines the entire runbook. Provides a way to access all the nodes in the graph 
 *  @returns a JSX component with the transform node editor. */
export const TransformNodeEditor = React.forwardRef(({ selectedNode, libraryNode, graphDef, variant }: SimpleNodeEditorProps, ref: any): JSX.Element => {
    const LOAD_FROM_DEFAULT_OPTIONS = {
        MANUAL: "manual",
        UNSELECTED: ""
    }

    const {getVariables} = useContext(VariableContext);
    const initVariables = [
        ...getVariables(RUNTIME_SCOPE).structuredVariables, 
        ...(!VARIANTS_WITH_INCIDENT_VARS.includes(libraryNode?.uiAttrs?.variant!) 
            ? [] 
            : getVariables(INCIDENT_SCOPE).structuredVariables),
        ...(!VARIANTS_WITH_GLOBAL_VARS.includes(libraryNode?.uiAttrs?.variant!) 
            ? [] 
            : getVariables(GLOBAL_SCOPE).structuredVariables)
    ];
    const [structuredVariables] = useState<Array<StructuredVariable>>(initVariables);

    const toolbarVariables = getVariables(RUNTIME_SCOPE, VARIANTS_WITH_RUNTIME_BUILTIN_VARS.includes(variant)).primitiveVariables.concat(
        VARIANTS_WITH_INCIDENT_VARS.includes(variant) ? getVariables(INCIDENT_SCOPE, true).primitiveVariables : []
    ).concat(
        VARIANTS_WITH_GLOBAL_VARS.includes(variant) ? getVariables(GLOBAL_SCOPE, true).primitiveVariables : []
    );

    const [objMetricMetaData, setObjMetricMetaData] = useState<DataOceanMetadata>();
    const [executeSafely] = useStateSafePromise();

    // Component states
    const [curProperties, setCurProperties] = useState<TransformNodeProperties>({
        transformTemplate: selectedNode?.getProperty(TRANSFORM_NODE_EDIT_PROPS.TRANSFORM_TEMPLATE),
        outputDataFormat: selectedNode?.getProperty(TRANSFORM_NODE_EDIT_PROPS.OUTPUT_DATA_FORMAT) as OutputDataFormats,
        outputDataMetrics: (selectedNode?.getProperty(TRANSFORM_NODE_EDIT_PROPS.SYNTH_METRICS) as Array<OutputDataField>)?.map(metric => { return { ...metric, id: generateRandomID() }; }),
        outputDataProperties: (selectedNode?.getProperty(TRANSFORM_NODE_EDIT_PROPS.SYNTH_KEYS) as Array<OutputDataField>)?.map(key => { return { ...key, id: generateRandomID() }; }),
        outputDefinitionMethod: selectedNode?.getProperty(TRANSFORM_NODE_EDIT_PROPS.USE_VARIABLE_DEFINITION) ? OutputDefinitionMethods.USE_VARIABLE_DEFINITION : OutputDefinitionMethods.MANUAL,
        useVariableDefinition: selectedNode?.getProperty(TRANSFORM_NODE_EDIT_PROPS.USE_VARIABLE_DEFINITION) ? selectedNode?.getProperty(TRANSFORM_NODE_EDIT_PROPS.USE_VARIABLE_DEFINITION) : LOAD_FROM_DEFAULT_OPTIONS.UNSELECTED,
    });

    const [dialogState, setDialogState] = useState<any>({showDialog: false, loading: true, title: STRINGS.viewRunbooks.inputDialogTitle, dialogContent: null, dialogFooter: null});

    const fetchData = useCallback(
        () => {
            return executeSafely(DataOceanUtils.init()).then((response: any) => {
                setObjMetricMetaData(response);
            }, error => {
                console.error(error);
            });
        },
        [executeSafely]
    );

    const [keyDefinitions, setKeyDefinitions] = useState<Array<OutputDataElementDefinition>>(curProperties?.outputDataProperties?.map((keyDef) => {
        return { id: keyDef.id, label: keyDef.label, type: keyDef.type, unit: keyDef.unit, dataOceanId: keyDef.dataOceanId }
    }) || []);

    const [metricDefinitions, setMetricDefinitions] =useState<Array<OutputDataElementDefinition>>(curProperties?.outputDataMetrics?.map((metricDef) => {
        return { id: metricDef.id, label: metricDef.label, type: metricDef.type, unit: metricDef.unit, dataOceanId: metricDef.dataOceanId }
    }) || []);

    useEffect(() => {
        // Fetch Meta data on load.
        fetchData();
    }, [fetchData]);

    useEffect(() => {
        if (curProperties?.useVariableDefinition && 
            curProperties?.useVariableDefinition !== selectedNode?.getProperty(TRANSFORM_NODE_EDIT_PROPS.USE_VARIABLE_DEFINITION)) {
                const isTimeseries = { outputDataFormat: structuredVariables?.find(item => item.name === curProperties?.useVariableDefinition)?.isTimeseries ? OutputDataFormats.TIMESERIES : OutputDataFormats.SUMMARIZED};
                syncProperties(isTimeseries);
                const keyDefs = generateKeysWhenUsingVarDef(curProperties?.useVariableDefinition);
                setKeyDefinitions(keyDefs);
                const metricDefs = generateMetricsWhenUsingVarDef(curProperties?.useVariableDefinition);
                setMetricDefinitions(metricDefs);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [curProperties?.useVariableDefinition, objMetricMetaData]);

    const customProperties = useContext(CustomPropertyContext);

    /**
     * Partially update the state of the current properties
     * @param partialProps -  transform node properties that need to be updated
     */
    function syncProperties(partialProps: TransformNodeProperties): void {
        const updatedProperties = { ...curProperties, ...partialProps };
        setCurProperties(updatedProperties);
    }

    /**
     * Update the selected node properties. This is invoked when done button is clicked
     * on the node editor dialog.
     * @param properties -  Properties in selected node that need to updated
     * @param selectedNode - node being editied
     * @param libraryNode - object that specifies all the editable properties for a given node.
     */
    function updateNode(properties: TransformNodeProperties, selectedNode: UniversalNode | undefined, libraryNode: NodeLibraryNode | undefined) {
        if (!selectedNode || !libraryNode || !properties) {
            console.warn("updateNode has invlaid inputs. Node update failed");
            return;
        }
        selectedNode.setProperty(TRANSFORM_NODE_EDIT_PROPS.TRANSFORM_TEMPLATE, curProperties?.transformTemplate);
        selectedNode.setProperty(TRANSFORM_NODE_EDIT_PROPS.OUTPUT_DATA_FORMAT, curProperties?.outputDataFormat);
        selectedNode.setProperty(TRANSFORM_NODE_EDIT_PROPS.USE_VARIABLE_DEFINITION, curProperties?.outputDefinitionMethod === OutputDefinitionMethods.USE_VARIABLE_DEFINITION ? curProperties?.useVariableDefinition : "");

        const synthMetrics = curProperties?.outputDataMetrics;
        synthMetrics?.forEach((metric) => {
            metric.id = metric.dataOceanId ? metric.dataOceanId : metric.label.replace(/ /g, "_");
        });
        selectedNode.setProperty(TRANSFORM_NODE_EDIT_PROPS.SYNTH_METRICS, synthMetrics);

        const synthKeys = curProperties?.outputDataProperties;
        synthKeys?.forEach((key) => {
            key.id = key.dataOceanId ? key.dataOceanId : key.label.replace(/ /g, "_");
        });
        selectedNode.setProperty(TRANSFORM_NODE_EDIT_PROPS.SYNTH_KEYS, synthKeys);
    }

    function getNodeProperties(properties: TransformNodeProperties, selectedNode: UniversalNode | undefined, libraryNode: NodeLibraryNode | undefined) {
        if (!selectedNode || !libraryNode || !properties) {
            console.warn("updateNode has invlaid inputs. Node update failed");
            return;
        }
        const outputProperties = {};
        outputProperties[TRANSFORM_NODE_EDIT_PROPS.USE_VARIABLE_DEFINITION] = curProperties?.useVariableDefinition;

        let synthKeys: Array<OutputDataField> = curProperties?.outputDataProperties || [];
        synthKeys = synthKeys?.map((key) => {
            return { ...key, id: key.dataOceanId ? key.dataOceanId : key.label.replace(/ /g, "_") };
        });
        outputProperties[TRANSFORM_NODE_EDIT_PROPS.SYNTH_KEYS] = synthKeys;

        let synthMetrics: Array<OutputDataField> = curProperties?.outputDataMetrics || [];
        synthMetrics = synthMetrics?.map((metric) => {
            return { ...metric, id: metric.dataOceanId ? metric.dataOceanId : metric.label.replace(/ /g, "_") };
        });
        outputProperties[TRANSFORM_NODE_EDIT_PROPS.SYNTH_METRICS] = synthMetrics;

        outputProperties[TRANSFORM_NODE_EDIT_PROPS.TRANSFORM_TEMPLATE] = curProperties?.transformTemplate;
        outputProperties[TRANSFORM_NODE_EDIT_PROPS.OUTPUT_DATA_FORMAT] = curProperties?.outputDataFormat;
        return outputProperties;
    }

    ref.current = {
        updateNode: () => {
            updateNode(curProperties, selectedNode, libraryNode);
        },
        validate: () => {
            const errorMessages = new Array<string>();
            TransformNodeUtils.validateNodeProperties(
                curProperties,
                errorMessages,
            );
            return errorMessages;
        }
    };

    const [triggerKeys, setTriggerKeys] = useState<TriggerKey[]>([]);
    const [triggerMetrics, setTriggerMetrics] = useState<GenericKey[]>([]);
    const [parentKeys, setParentKeys] = useState<GenericKey[]>([]);
    const [parentMetrics, setParentMetrics] = useState<GenericKey[]>([]);
    useEffect(() => {
        if (selectedNode?.node) {
            let selNodeId = selectedNode?.getId();
            selNodeId = selNodeId ? selNodeId : "";
            let nodeDefObj = getNodeFromGraphDef(selNodeId, graphDef);
            if (nodeDefObj && objMetricMetaData) {
                let runbookContext = new RunbookContext(nodeDefObj, graphDef, objMetricMetaData, customProperties, {forLiquid: true});
                const nodeContexts: Context[] = runbookContext.getNodeContexts();
                let nodeContext: Context | undefined =  nodeContexts?.length ? nodeContexts[nodeContexts.length - 1] : undefined;
                if (nodeContext) {
                    setParentKeys(nodeContext.expandedKeys || []);
                    setParentMetrics(nodeContext.metrics || []);
                }
                if (runbookContext && runbookContext.getTriggerContext()) {
                    setTriggerKeys((runbookContext.getTriggerContext()?.expandedKeys || []).map((key) => {
                        return {...key, triggerType: runbookContext.getTriggerType(), liquid_key: runbookContext.getTriggerContext()?.liquid_key};
                    }));
                    if ([...LIFECYCLE_TRIGGER_TYPES, InputType.WEBHOOK].includes(runbookContext.getTriggerType() as InputType)) {
                        setTriggerMetrics([]);
                    } else {
                        setTriggerMetrics(runbookContext.getTriggerContext()?.metrics || []);
                    }
                }
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedNode, objMetricMetaData])

    function handleOutputDataKeyChange(event: UpdateEvent) {
        let synthKeys: Array<OutputDataField> = curProperties?.outputDataProperties || [];
        synthKeys = ([] as Array<OutputDataField>).concat(synthKeys);
        const uniqueId = event.uniqueId;
        //const id = event.id;
        const type = event.type;
        const value = event.value;

        let keyDef: any = synthKeys.find((key) => key.id === uniqueId);
        if (!keyDef) {
            // We are defining a new metric
            keyDef = {
                id: uniqueId,
                label: '',
                type: Object.keys(OutputDataValueType)?.map(k => OutputDataValueType[k as any])[0],
                unit: ''
            };
            synthKeys.push(keyDef);
        }

        switch (type) {
            case UpdateEventType.NAME_CHANGED:
                //metricDef.id = value.replace(/ /g, "_");
                keyDef.label = value;
                if (keyDef.dataOceanId) {
                    keyDef.type = Object.keys(OutputDataValueType)?.map(k => OutputDataValueType[k as any])[0];
                    keyDef.unit = Unit.BASE_UNITS[0];
                }
                delete keyDef.dataOceanId;
                delete keyDef.dataOceanKeys;
                break;
            case UpdateEventType.UNIT_CHANGED:
                keyDef.unit = value;
                break;
            case UpdateEventType.VALUE_TYPE_CHANGED:
                keyDef.type = value;
                break;
            case UpdateEventType.DEFAULT_SELECTED:
                keyDef.dataOceanId = event.isDoId ? event.id : null;
                keyDef.label = event.value;
                keyDef.type = "object";
                let expandedKeys: Array<GenericKey> = [];
                const keyId = keyDef.dataOceanId;
                const doKeyDef = objMetricMetaData?.keys[keyId];
                if (doKeyDef) {
                    NodeUtils.expandKeyProperties(doKeyDef, keyId, expandedKeys);
                }
                keyDef.dataOceanKeys = expandedKeys;
                break;
            case UpdateEventType.DELETED:
                for (let i = 0; i < synthKeys.length; i++) {
                    if (synthKeys[i].id === uniqueId) {
                        synthKeys.splice(i, 1);
                        break;
                    }
                }
                break;
        }

        syncProperties({ outputDataProperties: synthKeys });
        setKeyDefinitions(synthKeys);
    }

    function handleOutputDataMetricChange(event: UpdateEvent) {
        let synthMetrics: Array<OutputDataField> = curProperties?.outputDataMetrics || [];
        synthMetrics = ([] as Array<OutputDataField>).concat(synthMetrics);
        const uniqueId = event.uniqueId;
        //const id = event.id;
        const type = event.type;
        const value = event.value;

        let metricDef: any = synthMetrics.find((metric) => metric.id === uniqueId);
        if (!metricDef) {
            // We are defining a new metric
            metricDef = {
                id: uniqueId,
                label: '',
                type: Object.keys(OutputDataValueType)?.map(k => OutputDataValueType[k as any])[0],
                unit: ''
            };
            synthMetrics.push(metricDef);
        }

        switch (type) {
            case UpdateEventType.NAME_CHANGED:
                //metricDef.id = value.replace(/ /g, "_");
                metricDef.label = value;
                if (metricDef.dataOceanId) {
                    metricDef.type = Object.keys(OutputDataValueType)?.map(k => OutputDataValueType[k as any])[0];
                    metricDef.unit = Unit.BASE_UNITS[0];
                }
                delete metricDef.dataOceanId;
                break;
            case UpdateEventType.UNIT_CHANGED:
                metricDef.unit = value;
                break;
            case UpdateEventType.VALUE_TYPE_CHANGED:
                metricDef.type = value;
                break;
            case UpdateEventType.DEFAULT_SELECTED:
                metricDef.dataOceanId = event.isDoId ? event.id : null;
                metricDef.label = event.value;
                metricDef.type = objMetricMetaData?.metrics[event.id].type;
                metricDef.unit = objMetricMetaData?.metrics[event.id].unit;
                break;
            case UpdateEventType.DELETED:
                for (let i = 0; i < synthMetrics.length; i++) {
                    if (synthMetrics[i].id === uniqueId) {
                        synthMetrics.splice(i, 1);
                        break;
                    }
                }
                break;
        }
        syncProperties({ outputDataMetrics: synthMetrics });
        setMetricDefinitions(synthMetrics);
    }

    let outputDataText = "";
    if (objMetricMetaData && (curProperties?.outputDataMetrics?.length || curProperties?.outputDataProperties?.length)) {
        const currentProperties = getNodeProperties(curProperties, selectedNode, libraryNode);
        let node: NodeDef = graphDef.nodes.find(node => node.id === selectedNode?.getId())!;
        node = node ? JSON.parse(JSON.stringify(node)) : {};
        node.properties = [];
        for (const key in currentProperties) {
            node.properties.push({key: key, value: currentProperties[key]});
        }
        const updatedGraphDef: GraphDef = JSON.parse(JSON.stringify(graphDef));
        for (let index = 0; index < updatedGraphDef.nodes.length; index++) {
            if (updatedGraphDef.nodes[index].id === node.id) {
                updatedGraphDef.nodes[index] = node;
                break;
            }
        }
        let runbookContext = new RunbookContext(node, updatedGraphDef, DataOceanUtils.dataOceanMetaData, customProperties);
        outputDataText = getExampleOutputText([runbookContext.getNodeContext(node, updatedGraphDef)!]);
    }

    function getLoadFromOptions() {
        return structuredVariables?.map(function (item) {
            return <option value={item.name} key={item.name + generateRandomID()}>{item.name}</option>;
        });
    }

    function handleVariableSelection(event) {
        if (event.currentTarget.value) {
            const keyDefs : Array<any>= generateKeysWhenUsingVarDef(event.target.value);
            setKeyDefinitions(keyDefs);
            const metricDefs = generateMetricsWhenUsingVarDef(event.target.value);
            const updateLoadFromVar = {
                useVariableDefinition: event.currentTarget.value,
                outputDataProperties: keyDefs,
                outputDataMetrics: metricDefs
            }
            setMetricDefinitions(metricDefs);
            setCurProperties({ ...curProperties, ...updateLoadFromVar });
        } else {
            const updateLoadFromVar = {
                useVariableDefinition: event.currentTarget.value,
                outputDataProperties: [],
                outputDataMetrics: []
            }
            setKeyDefinitions([]);
            setMetricDefinitions([]);
            setCurProperties({ ...curProperties, ...updateLoadFromVar });
        }
    }

    function generateKeysWhenUsingVarDef(varName: string) {
        let keyDefs: Array<any>;
        const selectedStructuredVariable = structuredVariables?.find((variable) => variable["name"] === varName);
        let keyDef: any = {
            label: selectedStructuredVariable?.name,
            type: selectedStructuredVariable?.type,
        }
        if (selectedStructuredVariable?.type !== 'custom') {
            keyDef.id = selectedStructuredVariable?.type;
            keyDef.unit = '';
            keyDef.dataOceanId = selectedStructuredVariable?.type;
            let expandedKeys: Array<GenericKey> = [];
            const keyId = selectedStructuredVariable?.type || '';
            const doKeyDef = objMetricMetaData?.keys[keyId];
            if (doKeyDef) {
                NodeUtils.expandKeyProperties(doKeyDef, keyId, expandedKeys);
            }
            keyDef.dataOceanKeys = expandedKeys;
            keyDefs = [ keyDef ];
        } else {
            keyDefs = selectedStructuredVariable?.keys?.map((keyDef) => {
                return {
                    id: keyDef?.id || generateRandomID(),
                    label: keyDef?.label || '',
                    type: keyDef?.type.toString() || '',
                    unit: keyDef?.unit?.toString() || '',
                    dataOceanId: (selectedStructuredVariable?.type !== 'custom' ? selectedStructuredVariable.type : '') }
            }) || [];
        }
        return keyDefs;
    }

    function generateMetricsWhenUsingVarDef(varName) {
        const selectedStructuredVariable = structuredVariables?.find((variable) => variable["name"] === varName);
        const metricDefs = selectedStructuredVariable?.metrics?.map((metricDef) => {
            return {
                id: generateRandomID(),
                label: metricDef?.label || '',
                type: metricDef?.type.toString() || '',
                unit: metricDef?.unit?.toString() || '',
                dataOceanId: metricDef.id }
            }
        ) || [];
        return metricDefs;
    }

    function handleDefinitionMethodChange(event){
        let updatedPartialProperties: TransformNodeProperties = {
            outputDefinitionMethod: event.currentTarget.value as OutputDefinitionMethods,
        }
        if (event.currentTarget.value === OutputDefinitionMethods.MANUAL) {
            let keyDefs = !selectedNode?.getProperty(TRANSFORM_NODE_EDIT_PROPS.USE_VARIABLE_DEFINITION) ? selectedNode?.getProperty(TRANSFORM_NODE_EDIT_PROPS.SYNTH_KEYS)?.map((keyDef) => {
                return { id: generateRandomID(), label: keyDef.label, type: keyDef.type, unit: keyDef.unit, dataOceanId: keyDef.dataOceanId }
            }) : [];
            const metricDefs = !selectedNode?.getProperty(TRANSFORM_NODE_EDIT_PROPS.USE_VARIABLE_DEFINITION) ? selectedNode?.getProperty(TRANSFORM_NODE_EDIT_PROPS.SYNTH_METRICS)?.map((metricDef) => {
                return { id: generateRandomID(), label: metricDef.label, type: metricDef.type, unit: metricDef.unit, dataOceanId: metricDef.dataOceanId }
            }) : [];
            for( const key of keyDefs) {
                if (key?.dataOceanId) {
                    let expandedKeys: Array<GenericKey> = [];
                    const keyId = key.dataOceanId;
                    const doKeyDef = objMetricMetaData?.keys[keyId];
                    if (doKeyDef) {
                        NodeUtils.expandKeyProperties(doKeyDef, keyId, expandedKeys);
                    }
                    keyDefs[keyDefs.indexOf(key)].dataOceanKeys = expandedKeys;
                }
            }
            setKeyDefinitions(keyDefs);
            setMetricDefinitions(metricDefs);
            updatedPartialProperties = {
                ...updatedPartialProperties,
                outputDataFormat: selectedNode?.getProperty(TRANSFORM_NODE_EDIT_PROPS.OUTPUT_DATA_FORMAT) as OutputDataFormats,
                useVariableDefinition: '',
                outputDataMetrics: metricDefs,
                outputDataProperties: keyDefs
            };
        }
        syncProperties(updatedPartialProperties);
    }

    function updateTransformTemplate() {
        let updatedTransformTemplateValue = curProperties?.transformTemplate || '';
        setDialogState({
            showDialog: true,
            doNotAllowOutsideClick: true,
            title: STRINGS.runbookEditor.nodeEditor.transformTemplate,
            dialogContent: <>
                <TabbedSubPages renderActiveTabPanelOnly={false}>
                    <Tab id="template" title={STRINGS.runbookEditor.nodeEditor.templateTabName}>
                        <LiquidTemplateEditor
                            data-testid="template_modal"
                            placeholder={STRINGS.runbookEditor.nodeEditor.transformTemplatePlaceholder}
                            value={curProperties?.transformTemplate}
                            style={{ resize: "both", width: "100%", height: "180px", fontFamily: "monospace", fontSize: "small", borderColor: "#999" }}
                            className="bg-white text-black mt-3"
                            variables={toolbarVariables}
                            triggerExpandedKeys={triggerKeys} triggerMetrics={triggerMetrics}
                            parentExpandedKeys={parentKeys} parentMetrics={parentMetrics}
                            onChange={value => {
                                updatedTransformTemplateValue = value;
                            }}
                            variant={variant}
                        />
                    </Tab>
                    <Tab id="example" title={STRINGS.runbookEditor.nodeEditor.exampleTemplateTabName}>
                        <textarea
                            value={outputDataText}
                            disabled={true}
                            style={{ resize: "both", width: "100%", height: "180px", fontFamily: "monospace", fontSize: "small", borderColor: "#999" }}
                            className="bg-white text-black mt-3"
                        ></textarea>
                    </Tab>
                </TabbedSubPages>
            </>,
            closeable: true,
            dialogFooter: <div className="d-flex justify-content-between flex-grow-1">
                <Button
                    className="ml-0"
                    text={STRINGS.runbookEditor.discardAndCloseBtn}
                    intent={Intent.DANGER}
                    onClick={() => {
                        setDialogState({ ...dialogState, showDialog: false, closeable: true });
                    }}
                />
                <Button
                    icon={IconNames.SAVED}
                    text={STRINGS.runbookEditor.saveAndCloseBtn}
                    intent={Intent.SUCCESS}
                    onClick={() => {
                        const updatedPartialProperties = {
                            transformTemplate: updatedTransformTemplateValue,
                        }
                        syncProperties(updatedPartialProperties);
                        setDialogState({ ...dialogState, showDialog: false, closeable: true });
                    }}
                />
            </div>,
        } as DialogState);
    }

    const keySuggestionList = {};
    const metricSuggestionList = {};
    if (objMetricMetaData) {
        DataOceanUtils.getAvailableMetrics(Object.keys(objMetricMetaData.metrics), [], getTypes(), false, "summary").forEach(
            (metric) => {metricSuggestionList[metric.id] = metric;}
        );
        const keysToUse: Record<string, DataOceanKey> = RunbookContextUtils.getAvailableKeysForKeyMap(objMetricMetaData.keys, [], getTypes());
        for (const key in keysToUse) {
            keySuggestionList[key] = keysToUse[key];
        }
    }

    // Wait for api call to complete before rendering the editor
    if (!objMetricMetaData) {
        return <tr><td colSpan={2}><DataLoadFacade loading /></td></tr>;
    }
    return (
        <>
            <BasicDialog dialogState={dialogState} className="transform-template-dialog" onClose={() => {
                setDialogState(updateDialogState(dialogState, false, false, []));
            }} />
            <tr>
                <td className="display-7 font-weight-bold pt-2" colSpan={2}>{STRINGS.runbookEditor.nodeEditor.outputDataDefMethod}</td>
            </tr>
            <tr>
                <td colSpan={2}>
                    <div className="d-flex flex-row pt-3">
                        <RadioGroup
                            name="outputDefMethod"
                            onChange={handleDefinitionMethodChange}
                            selectedValue={ curProperties?.outputDefinitionMethod }
                            inline={true}
                            disabled={false}
                            className="align-self-center"
                        >
                            <Radio
                                label={STRINGS.runbookEditor.nodeLibrary.propertyLabels.manualDefMethod}
                                value={OutputDefinitionMethods.MANUAL}
                                className="mb-0"
                            />
                            <Radio
                                label={STRINGS.runbookEditor.nodeLibrary.propertyLabels.loadFromDefMethod}
                                value={OutputDefinitionMethods.USE_VARIABLE_DEFINITION}
                                className="mb-0 mr-1"
                            />
                            <HTMLSelect
                                name="variableSelect"
                                disabled={curProperties.outputDefinitionMethod === OutputDefinitionMethods.MANUAL}
                                value={curProperties?.useVariableDefinition}
                                className="mb-0 ml-1"
                                style={{maxWidth: "170px"}}
                                onChange={handleVariableSelection}>
                                    <option key={LOAD_FROM_DEFAULT_OPTIONS.UNSELECTED} label={STRINGS.runbookEditor.nodeEditor.defDropDownOption} value={undefined} />
                                    {getLoadFromOptions()}
                            </HTMLSelect>
                        </RadioGroup>
                    </div>
                </td>
            </tr>
            <tr>
                <td className="display-7 font-weight-bold pt-2" colSpan={2}>{STRINGS.runbookEditor.nodeEditor.outputDataDef}</td>
            </tr>
            <tr>
                <td colSpan={2}>
                    <div className="d-flex flex-row pt-3">
                        <label className="mb-0">{STRINGS.runbookEditor.nodeEditor.dataFormat}</label>
                        <RadioGroup
                            name="outputDataFormat"
                            disabled={curProperties?.outputDefinitionMethod === OutputDefinitionMethods.USE_VARIABLE_DEFINITION ? true : false}
                            onChange={e => {
                                const updatedPartialProperties = {
                                    outputDataFormat: e.currentTarget.value as OutputDataFormats,
                                }
                                syncProperties(updatedPartialProperties);
                            }}
                            selectedValue={curProperties?.outputDataFormat}
                            inline={true}
                            className="pl-4 align-self-center"
                        >
                            <Radio
                                label={STRINGS.runbookEditor.nodeLibrary.propertyLabels.summarized}
                                value={OutputDataFormats.SUMMARIZED}
                                className="mb-0"
                            />
                            <Radio
                                label={STRINGS.runbookEditor.nodeLibrary.propertyLabels.timeSeries}
                                value={OutputDataFormats.TIMESERIES}
                                className="mb-0"
                            />
                        </RadioGroup>
                    </div>
                </td>
            </tr>
            <tr>
                <td className="display-7 font-weight-bold pt-2" colSpan={2}>
                    <OutputDataBlock key={keyDefinitions.length + generateRandomID()}
                        sectionName={SectionType.PROPERTY}
                        elementName={ElementType.PROPERTY}
                        suggestionsList={keySuggestionList}
                        onChange={(e: UpdateEvent) => { handleOutputDataKeyChange(e) }}
                        definitions={keyDefinitions}
                        loadFromVar={curProperties?.outputDefinitionMethod === OutputDefinitionMethods.USE_VARIABLE_DEFINITION ? true : false}
                    />
                    <OutputDataBlock key={metricDefinitions.length + generateRandomID()}
                        sectionName={SectionType.METRIC}
                        elementName={ElementType.METRIC}
                        suggestionsList={metricSuggestionList}
                        onChange={(e: UpdateEvent) => { handleOutputDataMetricChange(e) }}
                        definitions={metricDefinitions}
                        loadFromVar={curProperties?.outputDefinitionMethod === OutputDefinitionMethods.USE_VARIABLE_DEFINITION ? true : false}
                    />
                </td>
            </tr>
            <tr>
                <td colSpan={2}>
                    <div className="d-flex justify-content-between">
                        <div className="display-7 font-weight-bold pt-2">{STRINGS.runbookEditor.nodeEditor.transformTemplate}</div>
                        <div className="display-7 pt-2 float-right">
                            <Button
                                aria-label="transform-template-button"
                                className={Classes.MINIMAL}
                                icon={<Icon icon="fullscreen"/>}
                                onClick={() => updateTransformTemplate()}
                            />
                        </div>
                    </div>
                </td>
            </tr>
            <tr>
                <td className="display-7 font-weight-bold pt-2" colSpan={2}>
                    <TabbedSubPages data-testid="transform-template" renderActiveTabPanelOnly={false}>
                        <Tab id="template" title={STRINGS.runbookEditor.nodeEditor.templateTabName}>
                            <LiquidTemplateEditor
                                data-testid="template"
                                placeholder={STRINGS.runbookEditor.nodeEditor.transformTemplatePlaceholder}
                                value={curProperties?.transformTemplate}
                                style={{ width: "100%", height: "180px", fontFamily: "monospace", fontSize: "small", borderColor: "#999" }}
                                className="bg-white text-black mt-3"
                                variables={toolbarVariables}
                                triggerExpandedKeys={triggerKeys} triggerMetrics={triggerMetrics}
                                parentExpandedKeys={parentKeys} parentMetrics={parentMetrics}
                                onChange={value => {
                                    const updatedPartialProperties = {
                                        transformTemplate: value,
                                    }
                                    syncProperties(updatedPartialProperties);
                                }}
                                variant={variant}
                            />
                        </Tab>
                        <Tab id="example" title={STRINGS.runbookEditor.nodeEditor.exampleTemplateTabName}>
                            <textarea
                                value={outputDataText}
                                disabled={true}
                                style={{ width: "100%", height: "180px", fontFamily: "monospace", fontSize: "small", borderColor: "#999" }}
                                className="bg-white text-black mt-3"
                            ></textarea>
                        </Tab>
                    </TabbedSubPages>
                </td>
            </tr>
            {SHOW_CONTEXT && <RunbookContextSummary
                currentProperties={getNodeProperties(curProperties, selectedNode, libraryNode)}
                node={graphDef.nodes.find(node => node.id === selectedNode?.getId())!} graphDef={graphDef}
                showOutputExample={true} showInputExample={true}
            />}
        </>
    )
});
