/** This module contains the DataSourceService that can be used to query data source data
 *  @module
 */
import { ApiService } from 'utils/services/ApiService';

export enum gatewayTypeOptions {
	Vhd = 'Azure',
	Ami = 'AWS',
	Ova = 'ESXi'
}

export enum dataSourceTypeOptions {
	NetProfiler = 'NetProfiler',
	AppResponse = 'AppResponse',
	NetIm = 'NetIM',
	Aternity = 'Aternity',
    AlluvioAgent = 'AlluvioAgent'
}

export enum vmImageTypes {
	Vhd = 'https://azuremarketplace.microsoft.com/en-us/marketplace/apps/canonical.0001-com-ubuntu-server-jammy?tab=Overview',
	Ami = 'https://aws.amazon.com/marketplace/pp/prodview-f2if34z3a4e3i?sr=0-5&ref_=beagle&applicationId=AWSMPContessa',
	Ova = 'https://cloud-images.ubuntu.com/releases/22.04/release/'
}

/** this type defines a GatewayConfig. */
export type GatewayConfig = {
	version: string;
	timestamp: string;
	doctype: string;
	utid: string;
	entity: {
		kind: string;
		attributes: {
			id?: string;
			name: string;
			image_type: string;
			proxy_protocol: string | null;
			proxy_hostname: string | null;
			proxy_port: string | null;
			websockets: boolean;
		};
	};
	/** a catch-all for any additional properies of the gateway. */
	[anyOtherKey: string]: any;
};

/** this type defines a DataSourceConfig. */
export type DataSourceConfig = {
	version: string;
	timestamp: string;
	doctype: string;
	utid: string;
	entity: {
		kind: string;
		attributes: {
			id?: string;
            name: string;
			type: string;
			host: string;
			proto: string;
			port: string;
            state: string;
			iot: {
				device?: string;
			};
		};
	};
    source: {
        type: string;
        id: string;
    };
	/** a catch-all for any additional properies of the datasource. */
	[anyOtherKey: string]: any;
};

export type CloudIMDataSource = {
	dataSourceId?: string,
	authProfileId: string,
	dataSourceName: string,
}

// The URL for the API server.
export const DATA_SOURCE_BASE_URL = '/api/affogato/';

/** this class defines the DataSourceApiService. */
class DataSourceApiService extends ApiService {
	/** the constructor for the class. */
	constructor() {
		super(DATA_SOURCE_BASE_URL);
	}

    /** returns the base uri, this can be overridden in subclasses to allow the uri to change
     *      after construction.
     *  @returns a String with the base uri. */
     protected getBaseUri(): string {
        if (ApiService.USE_REGION) {
            const region = ApiService.AUTH_SERVICE.getRegion();
            return `/api/iq/${region}/`;    
        } else {
            return this.baseApiUri;
        }
    }

	/** Read Edge Gateway cloud-init
	 *  @param id a String with the id of the edge gateway.
	 *  @returns a Promise which resolves to the returned data sources. */
	downloadInitScript(id: string): Promise<string> {
		const utid = ApiService.AUTH_SERVICE.getTenantId();
		return new Promise((resolve, reject) => {
			super
				.download(
					`edge/tenants/${utid}/edge_gateway/${id}/cloud_init`,
					`cloud-init-${id}.zip`
				)
				.then(
					(response) => {
						resolve('');
					},
					(err) => {
						reject(err);
						console.error(err);
					}
				);
		});
	}

	/** Download Edge Gateway OVA file
	 *  @param id a String with the id of the edge gateway.
	 *  @returns a Promise which resolves to the returned data sources. */
	downloadOvaFile(id: string): Promise<string> {
		const utid = ApiService.AUTH_SERVICE.getTenantId();
		return new Promise((resolve, reject) => {
			super
				.download(
					`edge/tenants/${utid}/edge_gateway/${id}/nocloud_iso`,
					`nocloud.iso`
				)
				.then(
					(response) => {
						resolve('');
					},
					(err) => {
						reject(err);
						console.error(err);
					}
				);
		});
	}

	/** returns all CloudIM data sources.
	 *  @returns a Promise which resolves to the returned data sources. */
	getCloudIMDataSources(): Promise<CloudIMDataSource[]> {
		const utid = ApiService.AUTH_SERVICE.getTenantId();
		return new Promise((resolve, reject) => {
			super.get<CloudIMDataSource[]>(`cloudim/1.0/tenants/${utid}/dataSources`).then(
				(results) => {
					resolve(results);
				},
				(err) => {
					reject(err);
				}
			);
		});
	}

	/** creates a new CloudIM data source.
	 * @returns ID of newly created data source */
	createCloudIMDataSource(ds: CloudIMDataSource) {
		const utid = ApiService.AUTH_SERVICE.getTenantId();
		return new Promise((resolve, reject) => {
			super.post<string>(`cloudim/1.0/tenants/${utid}/dataSources`, ds)
				.then(
					(results) => {
						resolve(results);
					},
					(err) => {
						reject(err);
					}
				);
		});
	}

	/** updates a CloudIM data source.
	 * @param id a String with the id of the data source. */
	updateCloudIMDataSource(ds: CloudIMDataSource) {
		const utid = ApiService.AUTH_SERVICE.getTenantId();
		return new Promise((resolve, reject) => {
			super.put<string>(`cloudim/1.0/tenants/${utid}/dataSources/${ds.dataSourceId}`, ds)
				.then(
					(results) => {
						resolve(results);
					},
					(err) => {
						reject(err);
					}
				);
		});
	}

	/** deletes a CloudIM data source.
	 * @param id a String with the id of the data source. */
	deleteCloudIMDataSource(ds: CloudIMDataSource) {
		const utid = ApiService.AUTH_SERVICE.getTenantId();
		return new Promise((resolve, reject) => {
			super.delete<string>(`cloudim/1.0/tenants/${utid}/dataSources/${ds.dataSourceId}`)
				.then(
					(results) => {
						resolve(results);
					},
					(err) => {
						reject(err);
					}
				);
		});
	}

	/** returns all data sources.
	 *  @returns a Promise which resolves to the returned data sources. */
	getDataSources(): Promise<DataSourceConfig> {
		const utid = ApiService.AUTH_SERVICE.getTenantId();
		return new Promise((resolve, reject) => {
			super.get<DataSourceConfig>(`edge/tenants/${utid}/data_source`).then(
				(results) => {
					resolve(results);
				},
				(err) => {
					reject(err);
					console.error(err);
				}
			);
		});
	}

	/** returns a particular data source.
	 *  @param id a String with the id of the data source.
	 *  @returns a Promise which resolves to the returned data source. */
	getDataSource(id: string | undefined): Promise<DataSourceConfig> {
		const utid = ApiService.AUTH_SERVICE.getTenantId();
		return new Promise((resolve, reject) => {
			super
				.get<DataSourceConfig>(`edge/tenants/${utid}/data_source/${id}`)
				.then(
					(results) => {
						resolve(results);
					},
					(err) => {
						reject(err);
						console.error(err);
					}
				);
		});
	}

	/** returns status of particular source.
	 *  @param id a String with the id of the datasource device.
	 *  @returns a Promise which resolves to the returned status. */
	 getDataSourceStatus(id: string): Promise<string> {
		const utid = ApiService.AUTH_SERVICE.getTenantId();
		return new Promise((resolve, reject) => {
			super
				.get<string>(`dataocean/1.0/tenants/${utid}/product_info/${id}`)
				.then(
					(results) => {
						resolve(results);
					},
					(err) => {
						reject(err);
						console.error(err);
					}
				);
		});
	}

	/** creates a new data source.
	 *  @param dataSource to be created.
	 *  @returns a Promise which resolves to the newly created data source. */
	createDataSource(source: DataSourceConfig): Promise<DataSourceConfig> {
		return new Promise((resolve, reject) => {
			const utid = ApiService.AUTH_SERVICE.getTenantId();
			super
				.post<DataSourceConfig>(
					`edge/tenants/${utid}/data_source`,
					source
				)
				.then(
					(response) => {
						resolve(response);
					},
					(err) => {
						reject(err);
						console.error(err);
					}
				);
		});
	}

	/** deletes an existing data source.
	 *  @param id a String with the id of the data source.
	 *  @returns a Promise. */
	deleteDataSource(id: string): Promise<string> {
		const utid = ApiService.AUTH_SERVICE.getTenantId();
		return new Promise((resolve, reject) => {
			super
				.delete<string>(`edge/tenants/${utid}/data_source/${id}`, false)
				.then(
					(response: string) => {
						resolve(response);
					},
					(err) => {
						reject(err);
						console.error(err);
					}
				);
		});
	}

	/** update exsiting data source.
	 *  @param id the data source id
	 *  @param source the data source to be updated.
	 *  @returns a Promise. */
	 updateDataSource(id: string | undefined, source: DataSourceConfig): Promise<DataSourceConfig> {
		return new Promise((resolve, reject) => {
			const utid = ApiService.AUTH_SERVICE.getTenantId();
			super
				.put<DataSourceConfig>(`edge/tenants/${utid}/data_source/${id}`, source)
				.then(
					(response: DataSourceConfig) => {
						resolve(response);
					},
					(err) => {
						reject(err);
						console.error(err);
					}
				);
		});
	}

	/** returns a particular edge gateway.
	 *  @param id a String with the id of the edge gateway.
	 *  @returns a Promise which resolves to the returned edge gateway. */
	 getEdgeGateway(id: string | undefined): Promise<GatewayConfig> {
		const utid = ApiService.AUTH_SERVICE.getTenantId();
		return new Promise((resolve, reject) => {
			super
				.get<GatewayConfig>(`edge/tenants/${utid}/edge_gateway/${id}`)
				.then(
					(results) => {
						resolve(results);
					},
					(err) => {
						reject(err);
						console.error(err);
					}
				);
		});
	}

	/** returns all edge gateways.
	 *  @returns a Promise which resolves to the returned edge gateways. */
	getEdgeGateways(): Promise<GatewayConfig> {
		const utid = ApiService.AUTH_SERVICE.getTenantId();
		return new Promise((resolve, reject) => {
			super.get<GatewayConfig>(`edge/tenants/${utid}/edge_gateway`).then(
				(results: any = []) => {
					resolve(results);
				},
				(err) => {
					reject(err);
				}
			);
		});
	}

	/** creates a new edge gateway.
	 *  @param gateway the new gateway to be created.
	 *  @returns a Promise which resolves to the newly created gateway. */
	createEdgeGateway(gateway: GatewayConfig): Promise<GatewayConfig> {
		return new Promise((resolve, reject) => {
			const utid = ApiService.AUTH_SERVICE.getTenantId();
			super
				.post<GatewayConfig>(`edge/tenants/${utid}/edge_gateway`, gateway)
				.then(
					(response: GatewayConfig) => {
						resolve(response);
					},
					(err) => {
						reject(err.response);
					}
				);
		});
	}

	/** update exsiting  edge gateway.
	 *  @param id the gateway id
	 *  @param gateway the gateway to be updated.
	 *  @returns a Promise. */
	 updateEdgeGateway(id: string | undefined, gateway: GatewayConfig): Promise<GatewayConfig> {
		return new Promise((resolve, reject) => {
			const utid = ApiService.AUTH_SERVICE.getTenantId();
			super
				.put<GatewayConfig>(`edge/tenants/${utid}/edge_gateway/${id}`, gateway)
				.then(
					(response: GatewayConfig) => {
						resolve(response);
					},
					(err) => {
						reject(err.response);
						console.error(err.response);
					}
				);
		});
	}

	/** deletes an existing edge gateway.
	 *  @param id a String with the id of the edge gateway.
	 *  @returns a Promise. */
	deleteEdgeGateway(id: string): Promise<string> {
		const utid = ApiService.AUTH_SERVICE.getTenantId();
		return new Promise((resolve, reject) => {
			super
				.delete<string>(`edge/tenants/${utid}/edge_gateway/${id}`, false)
				.then(
					(response: string) => {
						resolve(response);
					},
					(err) => {
						reject(err);
						console.error(err);
					}
				);
		});
	}

	/** returns status of particular edge device.
	 *  @param id a String with the id of the edge device.
	 *  @returns a Promise which resolves to the returned status. */
	getEdgeGatewayStatus(id: string): Promise<string> {
		const utid = ApiService.AUTH_SERVICE.getTenantId();
		return new Promise((resolve, reject) => {
			super
				.get<string>(`edge/tenants/${utid}/edge_gateway/${id}/status`)
				.then(
					(results) => {
						resolve(results);
					},
					(err) => {
						reject(err);
						console.error(err);
					}
				);
		});
	}
}

/** a constant with an instance of the DataSourceApiService. */
const DataSourceService = new DataSourceApiService();
export { DataSourceService };
