/** This module contains all the types for the graph component.
 *  @module
 */

import { IconNames } from "@tir-ui/react-components";
import { STRINGS } from "app-strings";
import { AdditionalInfoOptions } from "utils/runbooks/RunbookValidationUtils";
import { VariableCollection } from "utils/runbooks/VariablesUtils";

/** this interface defines the NodeDef object. The NodeDef object defines a node in 
 *  a graph.*/
export interface NodeDef {
    /** the id of the node. */
    id: string;
    /** the name of the node. */
    name: string;
    /** the description of the node. */
    info: string;
    /** the type of the node. */
    type: string;
    /** the color of the node. */
    color?: string;
    /** the icon to display in the node. */
    icon?: string;
    /** the x-coordinate of the node. */
    x?: number;
    /** the y-coordinate of the node. */
    y?: number;
    /** the specification for what wires are allowed in the node.*/
    wires?: NodeWiresSetting;
    /** an array of node properties. */
    properties?: Array<NodeProperty>;
    /** an array of the node properties that are not exposed in this UI. */
    passThruProperties?: Array<NodeProperty>;
    /** the array of subflow environment values. */
    env?: Array<NodeEnvSetting>;
    /** a string with the key into the strings object with the translated text for the name field.  This is for runbooks that 
     *  are shipped with the product, so they can be translated.  If this string exists it looks up the text.*/
    i18nNameKey?: string;
    /** a string with the key into the strings object with the translated text for the info field.  This is for runbooks that 
     *  are shipped with the product, so they can be translated.  If this string exists it looks up the text.*/
    i18nInfoKey?: string;
    /** this specifies whether a warning was detected in the node. */
    warnings?: Array<string>;
    /** this specifies whether an error was detected in the node. */
    errors?: Array<string>;
    /** this specifies whether a node has been edited by user or is in new state */
    editedByUser?: boolean;
}

/** this interface defines the NodeProperty object. The NodeProperty object defines a property for 
 *  a node.*/
export interface NodeProperty {
    /** the key for the property. */
    key: string;
    /** the value for the property. */
    value: any;
}

/** this interface defines the NodeProperty object. The NodeProperty object defines a property for 
 *  a node.*/
 export interface NodeEnvSetting {
    /** the key for the property. */
    name: string;
    /** the type of the property. */
    type: string;
    /** the value for the property. */
    value: any;
}

/** this interface defines the NodeWireSetting object. The NodeWireSetting object defines the settings for
 *  the wires that define what type of edges can be created.*/
 export interface NodeWiresSetting {
    /** one of the enumerated directions.  The following directions are supported in, out, both or none.
     *  an undefined or empty string also means none.*/
    direction: DIRECTION;
    /** specification for the inbound wires.  Right now this is only set for subflows.*/
    in?: Array<NodeWireInOutSetting>;
    /** an array of strings with the inbound labels.  Right now this is only set for subflows. */
    inputLabels?: Array<string>;
    /** specification for the outbound wires.  Right now this is only set for subflows. */
    out?: any;
    /** an array of strings with the outbound labels.  Right now this is only set for subflows. */
    outputLabels?: Array<string>;
    /** a boolean value, true if the node supports multiple outputs.  When a node supports multiple outputs then 
     *  its port number is included in the graph node.*/
    supportsMultipleOutputs?: boolean;
    /** Number of input ports that are supported by this node. */
    inputsCount?: number;
    /** Number of output ports that are supported by this node. */
    outputsCount?: number;
}

/** an enum with all the valid wire directions. */
export enum DIRECTION {
    /** supports inbound wires (connections). */
    IN      = "in",
    /** supports outbound wires (connections). */
    OUT     = "out",
    /** supports inbound and outbound wires (connections). */
    BOTH    = "both",
    /** supports neither inbound nor outbount wires (connections). */
    NONE    = "none"
}

/** this interface defines the NodeWireInOutSetting object. This corresponds to the node-red in and out 
 *  settings.*/
 export interface NodeWireInOutSetting {
    /** the x-coordinate of the wire port. */
    x: string;
    /** the y-coordinate of the wire port. */
    y: string;
    /** the array of wire port settings. */
    wires: Array<NodeWirePortSetting>
}

/** this interface defines the NodeWireInOutSetting object. This corresponds to the node-red in and out 
 *  settings.*/
 export interface NodeWirePortSetting {
    /** the id of the wire port. */
    id: string;
    /** the value for the wire port. */
    port: number;
}

/** this interface defines the EdgeDef object.  The EdgeDef object defines and edge in 
 *  a graph.*/
export interface EdgeDef {
    /** the id of the from node. */
    fromNode: string;
    /** the id of the to node. */
    toNode: string;
    /** tells which port the edge comes from. */
    fromPort?: string;
    /** tells which port the edge goes to. */
    toPort?: string;
    /** the thickness of the edge. */
    thickness?: number;
}
  
/** this interface defines the GraphDef object.  The GraphDef object defines the data in 
 *  a graph.  It consists of nodes and edges. */
export interface GraphDef {
    /** the array of nodes. */
    nodes: NodeDef[];
    /** the array of edges. */
    edges: EdgeDef[];
    /** a boolean value, which if true indicates the changes represented in this object should not
     *  result in a redraw of the graph, the changes are already represented in the graph. */
    silent?: boolean;
    /** this specifies whether a warning was detected in the graph. */
    warnings?: Array<string>;
    /** this specifies whether an error was detected in the graph. */
    errors?: Array<string>;
    /** this keeps additional information from validations */
    additionalInformation?: AdditionalInfoOptions;
}

/** this interface defines the data necessary for describing a runbook. */
export interface RunbookInfo {
    /** a String with the runbook id. */
    id: string;
    /** a String with the runbook label. Tabs have labels. */
    label: string;
    /** a String with the runbook name. Subflows have names. */
    name?: string;
    /** for subflows, a string with the category the subflow is in. */
    category?: string;
    /** a boolean value, true if the runbook is disabled, false otherwise.  This is only used in nodered, right now
     *  our UI does not set this flag or display it.  In the future we might want to start setting this flag. */
    disabled?: boolean;
    /** a string with the description. */
    info?: string;
    /**  the type of input the runbook will accept, for Alpha this can be interface, device, or application. */
    triggerType?: InputType;
    /** a string with the key into the strings object with the translated text for the name field.  This is for runbooks that 
     *  are shipped with the product, so they can be translated.  If this string exists it looks up the text.*/
    i18nNameKey?: string;
    /** a string with the key into the strings object with the translated text for the info field.  This is for runbooks that 
     *  are shipped with the product, so they can be translated.  If this string exists it looks up the text.*/
    i18nInfoKey?: string;
    /** an optional boolean value that specifies if this runbook is a factory runbook. */
    isFactory?: boolean;
    /** an optional boolean value that specifies if this runbook is ready */
    isReady?: boolean;
    /** a string with the runbook etag, if any. */
    eTag?: string;
    /** runtime variables definitions */
    runtimeVariables?: VariableCollection;
    /** subflow variables definitions */
    subflowVariables?: VariableCollection;
    /** a String with the version of the runbook as saved in the database. */
    origVersion?: string;
    /** the current version, potentially after user changes. */
    version?: string;
    /** the series id that identifies all the versions of a particular subflow. */
    seriesId?: string;
    /** a structure with all the other versions of this particular runbook or subflow. */
    otherVersions?: any;
    /** a boolean which is true if the runbook is part of a schedule job or false otherwise. */
    isScheduled?: boolean;
}

/** an enum with all the valid variants. */
export enum Variant {
    /** the enumerated value for the incident variant. */
    INCIDENT    = "incident",
    /** the enumerated value for the lifecycle variant. */
    LIFECYCLE   = "lifecycle",
    /** the enumerated value for the external variant. */
    EXTERNAL    = "external",
    /** the enumerated value for the subflow variant. */
    SUBFLOW     = "subflow",
    /** the enumerated value for the on-demand variant. */
    ON_DEMAND   = "on_demand"
}

/** a constant with the array of variants that have runtime built-in variables. */
export const VARIANTS_WITH_RUNTIME_BUILTIN_VARS: Variant[] = [Variant.INCIDENT, Variant.LIFECYCLE, Variant.ON_DEMAND];
/** a constant with the array of variants that have incident variables. */
export const VARIANTS_WITH_INCIDENT_VARS: Variant[] = [Variant.INCIDENT, Variant.LIFECYCLE];
/** a constant with the array of variants that have global variables. */
export const VARIANTS_WITH_GLOBAL_VARS: Variant[] = [Variant.INCIDENT, Variant.LIFECYCLE];
/** a constant with the array of variants that have auth and edge profile primitive variables. */
export const VARIANTS_WITH_AUTH_AND_EDGE_VARS: Variant[] = [Variant.SUBFLOW, Variant.ON_DEMAND];
/** a constant with the array of variants that have connector primitive variables. */
export const VARIANTS_WITH_CONNECTOR_VARS: Variant[] = [Variant.SUBFLOW];
/** a constant with the array of variants that support versioning. */
export const VARIANTS_WITH_VERSIONING: Variant[] = [Variant.SUBFLOW];
/** a constant with the array of variants in which the HTTP node should allow users to select auth and edge profiles. */
export const VARIANTS_WITH_AUTH_AND_EDGE_PROFILES_IN_HTTP_NODE: Variant[] = [Variant.INCIDENT, Variant.LIFECYCLE, Variant.ON_DEMAND];
/** a constant with the array of variants in which the runbook editor should allow users do a preview. */
export const VARIANTS_WITH_PREVIEW_IN_GRAPH: Variant[] = [Variant.INCIDENT, Variant.ON_DEMAND];
/** a constant with the array of variants in which the runbook editor should allow users do a test. */
export const VARIANTS_WITH_TEST_IN_GRAPH: Variant[] = [Variant.INCIDENT, Variant.LIFECYCLE, Variant.ON_DEMAND];

/** an enum with all the valid input types. */
export enum InputType {
    /** the input is an interface. */
    INTERFACE                   = "network_interface",
    /** the input is a device. */
    DEVICE                      = "network_device",
    /** the input is an application/location. */
    APPLICATION_LOCATION        = "application",
    /** the input is a location. */
    LOCATION                    = "location",
    /** the input is an application. */
    APPLICATION                 = "application_only",
    /** the input is a webhook. */
    WEBHOOK                     = "webhook",
    /** this enumerated value specifies that the input is an impact analysis ready lifecycle event. */
    IMPACT_ANALYSIS_READY       = "impact_analysis_ready_event",
    /** this enumerated value specifies that the input is an incident status changed lifecycle event. */
    INCIDENT_STATUS_CHANGED     = "incident_status_changed_event",
    /** this enumerated value specifies that the input is an incident note added lifecycle event. */
    INCIDENT_NOTE_ADDED         = "incident_note_added_event",
    /** this enumerated value specifies that the input is an incident note updated lifecycle event. */
    INCIDENT_NOTE_UPDATED       = "incident_note_updated_event",
    /** this enumerated value specifies that the input is an incident note deleted lifecycle event. */
    INCIDENT_NOTE_DELETED       = "incident_note_deleted_event",
    /** this enumerated value specifies that the input is an incident indicators updated lifecycle event. */
    INCIDENT_INDICATORS_UPDATED = "incident_indicators_updated_event",
    /** this enumerated value specifies that the input is an incident ongoing changed lifecycle event. */
    INCIDENT_ONGOING_CHANGED    = "incident_ongoing_changed_event",
    /** the input is a subflow. */
    SUBFLOW                     = "subflow_input",
    /** the input is an on-demand runbook input. */
    ON_DEMAND                   = "on_demand_input"
}

/** this constant defines the InputTypes for lifecycle runbooks. */
export const LIFECYCLE_TRIGGER_TYPES: InputType[] = [
    InputType.IMPACT_ANALYSIS_READY, InputType.INCIDENT_STATUS_CHANGED, InputType.INCIDENT_NOTE_ADDED, InputType.INCIDENT_NOTE_UPDATED,
    InputType.INCIDENT_INDICATORS_UPDATED, InputType.INCIDENT_ONGOING_CHANGED
];

/** a Map with the trigger names indexed by the trigger (input) type. */
export const NamesByTriggerType: Record<string, string> = {
    [InputType.INTERFACE]:                      STRINGS.runbookEditor.interfaceInputType,
    [InputType.DEVICE]:                         STRINGS.runbookEditor.deviceInputType,
    [InputType.APPLICATION_LOCATION]:           STRINGS.runbookEditor.applicationLocationInputType,
    [InputType.LOCATION]:                       STRINGS.runbookEditor.locationInputType,
    [InputType.APPLICATION]:                    STRINGS.runbookEditor.applicationInputType,
    [InputType.WEBHOOK]:                        STRINGS.runbookEditor.webhookInputType,
    [InputType.IMPACT_ANALYSIS_READY]:          STRINGS.runbookEditor.impactAnalysisInputType,
    [InputType.INCIDENT_STATUS_CHANGED]:        STRINGS.runbookEditor.incidentStatusChangedInputType,
    [InputType.INCIDENT_NOTE_ADDED]:            STRINGS.runbookEditor.incidentNoteAddedInputType,
    [InputType.INCIDENT_NOTE_UPDATED]:          STRINGS.runbookEditor.incidentNoteUpdatedInputType,
    [InputType.INCIDENT_NOTE_DELETED]:          STRINGS.runbookEditor.incidentNoteDeletedInputTyp,
    [InputType.INCIDENT_INDICATORS_UPDATED]:    STRINGS.runbookEditor.incidentIndicatorsUpdatedInputType,
    [InputType.INCIDENT_ONGOING_CHANGED]:       STRINGS.runbookEditor.incidentOngoingChangedInputType
}

/** a Map with the DAL enum values indexed by the trigger (input) type. */
export const DalEnumByTriggerType: Record<string, string> = {
    [InputType.INTERFACE]:                      "NETWORK_INTERFACE",
    [InputType.DEVICE]:                         "NETWORK_DEVICE",
    [InputType.APPLICATION_LOCATION]:           "APPLICATION",
    [InputType.LOCATION]:                       "LOCATION",
    [InputType.APPLICATION]:                    "APPLICATION_ONLY",
    [InputType.WEBHOOK]:                        "WEBHOOK",
    [InputType.IMPACT_ANALYSIS_READY]:          "IMPACT_ANALYSIS_READY_EVENT",
    [InputType.INCIDENT_STATUS_CHANGED]:        "INCIDENT_STATUS_CHANGED_EVENT",
    [InputType.INCIDENT_NOTE_ADDED]:            "INCIDENT_NOTE_ADDED_EVENT",
    [InputType.INCIDENT_NOTE_UPDATED]:          "INCIDENT_NOTE_UPDATED_EVENT",
    [InputType.INCIDENT_NOTE_DELETED]:          "INCIDENT_NOTE_DELETED_EVENT",
    [InputType.INCIDENT_INDICATORS_UPDATED]:    "INCIDENT_INDICATORS_UPDATED_EVENT",
    [InputType.INCIDENT_ONGOING_CHANGED]:       "INCIDENT_ONGOING_CHANGED_EVENT"
}

/** an enum with all the valid DAL input types. */
export enum DAL_INPUT_TYPE {
    /** the input is an interface. */
    NETWORK_INTERFACE           = "NETWORK_INTERFACE",
    /** the input is a device. */
    NETWORK_DEVICE              = "NETWORK_DEVICE",
    /** the input is an application/location. */
    APPLICATION_LOCATION        = "APPLICATION",
    /** the input is an location. */
    LOCATION                    = "LOCATION",
    /** the input is an application. */
    APPLICATION                 = "APPLICATION_ONLY",
    /** the input is an webhook. */
    WEBHOOK                     = "WEBHOOK",
    /** this enumerated value specifies that the input is an impact analysis ready lifecycle event. */
    IMPACT_ANALYSIS_READY       = "IMPACT_ANALYSIS_READY_EVENT",
    /** this enumerated value specifies that the input is an incident status changed lifecycle event. */
    INCIDENT_STATUS_CHANGED     = "INCIDENT_STATUS_CHANGED_EVENT",
    /** this enumerated value specifies that the input is an incident note added lifecycle event. */
    INCIDENT_NOTE_ADDED         = "INCIDENT_NOTE_ADDED_EVENT",
    /** this enumerated value specifies that the input is an incident note updated lifecycle event. */
    INCIDENT_NOTE_UPDATED       = "INCIDENT_NOTE_UPDATED_EVENT",
    /** this enumerated value specifies that the input is an incident note deleted lifecycle event. */
    INCIDENT_NOTE_DELETED       = "INCIDENT_NOTE_DELETED_EVENT",
    /** this enumerated value specifies that the input is an incident indicators updated lifecycle event. */
    INCIDENT_INDICATORS_UPDATED = "INCIDENT_INDICATORS_UPDATED_EVENT",
    /** this enumerated value specifies that the input is an incident ongoing changed lifecycle event. */
    INCIDENT_ONGOING_CHANGED    = "INCIDENT_ONGOING_CHANGED_EVENT"
}

/** a Map with the trigger names indexed by the trigger (input) type. */
export const NamesByDalTriggerType: Record<string, string> = {
    [DAL_INPUT_TYPE.NETWORK_INTERFACE]:             STRINGS.runbookEditor.interfaceInputType,
    [DAL_INPUT_TYPE.NETWORK_DEVICE]:                STRINGS.runbookEditor.deviceInputType,
    [DAL_INPUT_TYPE.APPLICATION_LOCATION]:          STRINGS.runbookEditor.applicationLocationInputType,
    [DAL_INPUT_TYPE.LOCATION]:                      STRINGS.runbookEditor.locationInputType,
    [DAL_INPUT_TYPE.APPLICATION]:                   STRINGS.runbookEditor.applicationInputType,
    [DAL_INPUT_TYPE.WEBHOOK]:                       STRINGS.runbookEditor.webhookInputType,
    [DAL_INPUT_TYPE.IMPACT_ANALYSIS_READY]:         STRINGS.runbookEditor.impactAnalysisInputType,
    [DAL_INPUT_TYPE.INCIDENT_STATUS_CHANGED]:       STRINGS.runbookEditor.incidentStatusChangedInputType,
    [DAL_INPUT_TYPE.INCIDENT_NOTE_ADDED]:           STRINGS.runbookEditor.incidentNoteAddedInputType,
    [DAL_INPUT_TYPE.INCIDENT_NOTE_UPDATED]:         STRINGS.runbookEditor.incidentNoteUpdatedInputType,
    [DAL_INPUT_TYPE.INCIDENT_NOTE_DELETED]:         STRINGS.runbookEditor.incidentNoteDeletedInputTyp,
    [DAL_INPUT_TYPE.INCIDENT_INDICATORS_UPDATED]:   STRINGS.runbookEditor.incidentIndicatorsUpdatedInputType,
    [DAL_INPUT_TYPE.INCIDENT_ONGOING_CHANGED]:      STRINGS.runbookEditor.incidentOngoingChangedInputType
}

/** A map of index values for trigger types to be used when sorting. */
export const DAL_INPUT_TYPE_INDEX = {
    [DAL_INPUT_TYPE.NETWORK_INTERFACE]:             1,
    [DAL_INPUT_TYPE.NETWORK_DEVICE]:                2,
    [DAL_INPUT_TYPE.APPLICATION_LOCATION]:          3,
    [DAL_INPUT_TYPE.LOCATION]:                      4,
    [DAL_INPUT_TYPE.APPLICATION]:                   5,
    [DAL_INPUT_TYPE.WEBHOOK]:                       6,
    [DAL_INPUT_TYPE.IMPACT_ANALYSIS_READY]:         7,
    [DAL_INPUT_TYPE.INCIDENT_STATUS_CHANGED]:       8,
    [DAL_INPUT_TYPE.INCIDENT_NOTE_ADDED]:           9,
    [DAL_INPUT_TYPE.INCIDENT_NOTE_UPDATED]:         10,
    [DAL_INPUT_TYPE.INCIDENT_NOTE_DELETED]:         11,
    [DAL_INPUT_TYPE.INCIDENT_INDICATORS_UPDATED]:   12,
    [DAL_INPUT_TYPE.INCIDENT_ONGOING_CHANGED]:      13
}

/** a constant which maps the input type enum values to it's matching icon. */
export const DAL_INPUT_TYPE_TO_ICON_MAP: Record<string, string> = {
    [DAL_INPUT_TYPE.NETWORK_INTERFACE]:             IconNames.MERGE_LINKS,
    [DAL_INPUT_TYPE.NETWORK_DEVICE]:                IconNames.DEVICES,
    [DAL_INPUT_TYPE.APPLICATION_LOCATION]:          IconNames.APPLICATION,
    [DAL_INPUT_TYPE.LOCATION]:                      IconNames.LOCATE,
    [DAL_INPUT_TYPE.APPLICATION]:                   IconNames.APPLICATION,
    [DAL_INPUT_TYPE.WEBHOOK]:                       "third-party",
    [DAL_INPUT_TYPE.IMPACT_ANALYSIS_READY]:         "third-party",
    [DAL_INPUT_TYPE.INCIDENT_STATUS_CHANGED]:       "third-party",
    [DAL_INPUT_TYPE.INCIDENT_NOTE_ADDED]:           "third-party",
    [DAL_INPUT_TYPE.INCIDENT_NOTE_UPDATED]:         "third-party",
    [DAL_INPUT_TYPE.INCIDENT_NOTE_DELETED]:         "third-party",
    [DAL_INPUT_TYPE.INCIDENT_INDICATORS_UPDATED]:   "third-party",
    [DAL_INPUT_TYPE.INCIDENT_ONGOING_CHANGED]:      "third-party"
}
