/** This module contains all the types for the runbooks.
 *  @module
 */

import { IconNames } from "@tir-ui/react-components";
import { STRINGS } from "app-strings"
import { Variant } from "components/common/graph/types/GraphTypes";
import { Widget } from "components/common/vis-framework/widget/Widget.type";
import { PRIORITY, SEVERITY } from "components/enums"
import { EVENT_CATEGORY } from "pages/incident-list/Incident.type";
import { Entity } from "utils/runbooks/EntityUtils";

/** defines the runbook type.*/
export type RunbookOutput = {
    /** the id of the issue.*/
    id: string;
    /** a String with the name for the runbook. */
    name?: string;
    /** a String with the id of the target object. */
    targetId?: string;
    /** the severity of the issue detected by the runbook. */
    severity: SeverityScore;
    /** an array with all the datasets that are to be displayed.*/
    datasets: Array<DataSet>;
    /** the status of the runbook. */
    status?: RUNBOOK_STATUS;
    /** the runbook priority (Critical, High, etc). */
    priority?: PRIORITY;
    /** the array of priority reasons. */
    priorityReasons?: Array<PriorityReason>;
    /** a string with the timestamp in seconds.nanoseconds. */
    timestamp?: string;
    /** the runbook template with the runbook configuration JSON object serialized as a string. */
    template?: string;
    /** the list of impacted users stored in the runbook output. */
    impactedUsers?: Array<ImpactedItem>;
    /** the list of impacted locations stored in the runbook output. */
    impactedLocations?: Array<ImpactedItem>;
    /** the list of impacted applications stored in the runbook output. */
    impactedApplications?: Array<ImpactedItem>;
    /** the entity that the runbook was run on. */
    entity?: Entity;
    /** this object contains the information about all the variables available to the runbook and gives their
     *  values at the beginning and end of the run. */
    variableResults?: any;
    /** the object that contains all the runbook inputs. */
    triggerInputs?: RunbookInput;
    /** the list of indicators that were passed along with the trigger. */
    indicators?: any[];
    /** the array of RunbookNotes with any notes that were generated by this runbook run. */
    notes?: RunbookNote[];
    /** an object with any errors encountered when running the runbook. */
    errors?: ErrorInfo[];
    /** runbook variant */
    variant?: Variant;
    /** an array containing the nodes traversed during runbook execution. */
    nodeRunStatus?: any;
    /** the mapping with the trigger type which tells what type of incident the runbook was run on. */
    mapping?: {
        triggerType: EVENT_CATEGORY
    }
}

/** this type defines the format of the runbook input.  Note that this is awfully close to the definition in RunbookApiService.
 *  These two types should be collapsed into one.  Why are there two, this set of types were being used to define the types
 *  in the runbook output returned by DAL, while the other location was for types from the runbook service and initially there
 *  was little overlap.  As some of the api inputs started showing up in the runbook output, the two became closer and 
 *  closer, however the input returned by DAL is slightly different than the input expected by the RBO API and sent to it
 *  by the runbook editor.*/
export type RunbookInput = {
    /** a String with the request path. */
    requestPath?: string;
    /** a String with the request body. */
    requestBody?: RunbookInputRequestEntry[];
    /** a String with the request path. */
    requestHeaders?: RunbookInputRequestEntry[];
    /** a String with the request path. */
    requestQueryParameters?: RunbookInputRequestEntry[];
}

/** this type defines the runbook input request entry attribute. */
export type RunbookInputRequestEntry = {
    /** a String with the name of the field. */
    field: string;
    /** a String array with the field values. */
    values: string[];
}

/** defines the variable results type which contains both the primitive and structured variables.*/
export type VariableResults = {
    /** the array of PrimitiveVariableResults. */
    primitiveVariables?: Array<PrimitiveVariableResults>,
    /** the array of StructuredVariableResults. */
    structuredVariables?: Array<StructuredVariableResults>
}

/** defines the primitive variables type.*/
export type PrimitiveVariableResults = {
    /** the name of the variable in the format scope[.builtin].name. */
    name: string,
    /** a boolean value, true if the variable is read only, false otherwise. */
    isReadOnly: boolean,
    /** the type of variable, examples of which are "string", "float", etc. */
    type: COLUMN_TYPE,
    /** a String with the unit. */
    unit?: string,
    /** a String with the default value. */
    defaultValue?: string,
    /** a String with the value at the start of the run. */
    startValue?: string,
    /** a String with the value at the end of the run. */
    value?: string
}

/** defines the structured variables type.*/
export type StructuredVariableResults = {
    /** the name of the variable in the format scope[.builtin].name. */
    name: string,
    /** a boolean value, true if the variable is read only, false otherwise. */
    isReadOnly: boolean,
    /** the type of variable, examples of which are "custom", "network_interface", "network_device", "application" */
    type: string,
    /** a boolean value, true if this is time series data, false otherwise. */
    isTimeseries: boolean,
    /** the array of column objects.  These are being used for the Aternity demo and I believe they will
     *  be used for Alpah.  The keys are the array of key columns which are the objects that are being
     *  queried, for example an interface.*/
    keys?: Array<Column>;
    /** the array of column objects.  These are being used for the Aternity demo and I believe they will
     *  be used for Alpah.  The metrics are the array of metric columns which represent the data being
     *  queried, for example jitter, utilization, throughput.*/
    metrics?: Array<Column>,
    /** the value of the structured variable which has the array of data points.  Right now this can only be in GraphQL format. */
    value?: string | StructuredVariableValue    
}

/** defines the structured variables value object.*/
export type StructuredVariableValue = {
    /** the info object with the keys, metrics, filters, etc. */
    info: DataSetInfo,
    /** the data which contains the key and metric values. */
    data: DataResult[]
}

/** defines the runbook note type. */
export type RunbookNote = {
    /** a String with the note id. */
    id: string,
    /** a String with the note text. */
    text: string,
    /** a String with the note timestamp in the format of seconds.milliseconds. */
    timestamp: string,
}

export type DataResult = {
    /** an object with a map of the keys and their values.  For example {app: "exchange", device: "5.5.5.5"} using 
     *  raw data format from the runbook and the databes.  For the graphql format, this is an array of items such 
     *  as [{id: "app", value: "Exchange"}, {id: "device", value: "1.1.1.1"}].*/
    keys: Array<GraphqlDataValue>;
    /** an object with the metric ids mapped to their values.  For example {throughput: 5.3, packets: 1000}.  For
     *  timeseries data this has the form {timstamp1: {throughput: 5.3, packets: 1000}}.  Now for data coming from
     *  graphql it has the format {timestamp: "tsvalue", metrics: [{id: "throughput", value: 5}, {id: packets, value: 1000}]}*/
    data: Array<GraphqlData>;
}

/** this type defines the impacted user. */
export type ImpactedItem = {
    /** a String with the name of the impacted item. */
    name?: string;
    /** a String with the ip address of the user. */
    ipAddress?: string;
    /** a String with the Aternity device name for the user. */
    deviceName?: string;
}

/** this type defines the priority reason. */
export type PriorityReason = {
    /** the id of the node that the priority came from. */
    id: string;
    /** a String with the marked up text that has the reason. */
    text: string;
    /** one of the enumerated priorities. */
    priority: PRIORITY;
}

/** defines the data set type.*/
export type DataSet = {
    /** a string with the required data set id property.  The dataset id identifies the data set to the backend so
     *      its data can be queried.  The back-end defines this id.*/
    id: string;
    /** the type of data.  This can be summary or timeseries.  This should be filed out by the back-end.*/
    type: DATA_TYPE;
    /** the time reference that is used to distinguish between the current data vs the offset data used for comparisons. */
    timeReference?: TimeReference;
    /** the array of column objects.  This was used for the Riverbed Advisor demo.*/
    columns?: Array<Column>;
    /** the array of column objects.  These are being used for the Aternity demo and I believe they will
     *  be used for Alpah.  The keys are the array of key columns which are the objects that are being
     *  queried, for example an interface.*/
    keys?: Array<Column>;
    /** the array of column objects.  These are being used for the Aternity demo and I believe they will
     *  be used for Alpah.  The metrics are the array of metric columns which represent the data being
     *  queried, for example jitter, utilization, throughput.*/
    metrics?: Array<Column>;
    /** a Trigger with the id of the column that is the trigger.*/
    trigger?: Trigger;
    /** right now this field is only used on a test page to allow Desso to test his runbook flow. */
    datapoints?: Array<DataPoint>;
    /** the array of widgets that should be used to display the dataset. */
    widgets?: Array<Widget>;
    /** the data set info object which includes information on query times, data sources, filters, errors, etc. */
    info?: DataSetInfo;
    /** a boolean value, if true this is a comparison dataset if false it is not. */
    isComparison?: boolean;
    /** the severity of the data in the data set.
     *  @deprecated this was from the old riverbed advisor demo, we shouldn't see this anymore. */
    severity?: SeverityScore;
    /** a string with the debug output for the dataset.  Right now this has */
    debug?: Array<DebugOutput>;
}

/** this type defines the type for defining debug output. */
export type DebugOutput = {
    /** a string with a name for the debug output. */
    name: string;
    /** a string with the type like JSON. */
    type: DEBUG_OUTPUT_TYPE;
    /** a string with the content, for JSON this should be stringified JSON. */
    value: string;
}

/** defines the debug output type enum.*/
export enum DEBUG_OUTPUT_TYPE {
    /** the enumerated type that specifies that the debug information is JSON.*/
    JSON = "JSON",
    /** the enumerated type that specifies that the debug information is HTML.*/
    //HTML = "HTML",
}

/** this defines the time reference type.  The time reference is used in comparisons to specify
 *      that a dataset is offset from the current data. */
export type TimeReference = {
    /** the name of the time reference, the current data is called "current", the offset data has names like
     *  "ref1". */
    name: string;
    /** a string with the start time of the comparison data in seconds.nanoseconds. */
    startTime: string;
    /** a string with the end time of the comparison data in seconds.nanoseconds. */
    endTime: string;
}

/** defines the dataset info type. */
export type DataSetInfo = {
    /** the array of column objects.  These are being used for the Aternity demo and I believe they will
     *  be used for Alpah.  The keys are the array of key columns which are the objects that are being
     *  queried, for example an interface.*/
    keys?: Array<Column>;
    /** the array of column objects.  These are being used for the Aternity demo and I believe they will
     *  be used for Alpah.  The metrics are the array of metric columns which represent the data being
     *  queried, for example jitter, utilization, throughput.*/
    metrics?: Array<Column>,
    /** the array of actual times that were reported back from the data ocean. */
    actualTimes?: Array<ActualTimeInfo>;
    /** the array of data source objects that have the type of ds and the URL for the UI of the ds. */
    dataSources?: Array<DataSourceInfo>;
    /** the filters as a String that needs to be parsed by the UI. */
    actualFilters?: string;
    /** an object with any errors encountered when running the runbook. */
    error?: ErrorInfo;
    /** an object with any warnings encountered when running the runbook. */
    warning?: ErrorInfo;
}

/** defines the actual time type. */
export type ActualTimeInfo = {
    /** a string with the start time of the query in seconds.nanoseconds. */
    startTime: string;
    /** a string with the end time of the query in seconds.nanoseconds. */
    endTime: string;
    /** an array of strings in seconds. */
    granularities: Array<string>;
}

/** defines the data source type. */
export type DataSourceInfo = {
    /** the type of data source (NetIM, NetProfiler, etc). */
    type: string;
    /** the URL to reach the UI for the data source. */
    url: string;
    /** the string with the name of the data source. */
    name?: string;
}

/** defines the error type for errors or warnings passed by the RO in the info object. */
export type ErrorInfo = {
    /** a String with the code for the error/warning.  This code should be used by the UI to internationalize the UI text. */
    code?: string;
    /** a String with the message for the error/warning.  This should not be displayed to the users. */
    message?: string;
    /** the inner error object with the trace and the key/value pairs with some of the information specific to the specified error code. */
    innerError?: {trace?: Array<string>, properties: Array<{key: string, value: string}>};
    /** the error details, which is an array of ErrorInfo objects. */
    details?: Array<ErrorInfo>;
}

/** defines the column type.*/
export type Column = {
    /** a string with the required id property.*/
    id: string;
    /** a string with the required label property.*/
    label: string;
    /** a COLUMN_TYPE with the optional type property.*/
    type?: COLUMN_TYPE;
    /** a string with the optional unit property.*/
    unit?: string;
    /** the enumeration information passed for enumerated values. */
    enum?: {[x: string]: string} | Array<GraphqlEnumValue>;
    /** the order by weight is optional and it is used to tell the UI how to sort the enum.  If this is not 
     *  specified then it is sorted by its integer value. This is only used for enums. */
    order_by_weight?: {[x: string]: number};
    /** when the type is array this defines the type of the array items. */
    items_type?: "string" | "ipaddr";
    /** a boolean value, if true the column should not be displayed to the user. */
    hidden?: boolean;
    /** a boolean value, if true, it means that a missing data point, should be treated as a zero. */
    absentMeansZero?: boolean;
}

/** defines the data point type.  This type is used to represent data being returned for the runbook.*/
export type DataPoint = {
    /** an object with a map of the keys and their values.  For example {app: "exchange", device: "5.5.5.5"} using 
     *  raw data format from the runbook and the databes.  For the graphql format, this is an array of items such 
     *  as [{id: "app", value: "Exchange"}, {id: "device", value: "1.1.1.1"}].*/
    keys: DataPointKeys | Array<GraphqlDataValue>;
    /** an object with the metric ids mapped to their values.  For example {throughput: 5.3, packets: 1000}.  For
     *  timeseries data this has the form {timstamp1: {throughput: 5.3, packets: 1000}}.  Now for data coming from
     *  graphql it has the format {timestamp: "tsvalue", metrics: [{id: "throughput", value: 5}, {id: packets, value: 1000}]}*/
    data: AverageData | TimeData | Array<GraphqlData>;
    /** the granularity of this data point in seconds or null if not available. */
    granularity?: number;
}

/** defines the data point keys type.  This type is used to represent the key data being returned for the runbook. */
export type DataPointKeys = {
    /** an object with a map of the keys and their values.  For example {app: "exchange", device: "5.5.5.5"} using 
     *  raw data format from the runbook and the databes.  For the graphql format, this is an array of items such 
     *  as [{id: "app", value: "Exchange"}, {id: "device", value: "1.1.1.1"}].*/
    [x: string]: string | number | boolean | string[] | Record<string, any> | null;
    /** the group object that is used to pass filters around. */
    group?: any;
}

/** defines the data point data type for summary data.  This type is used to represent data being returned for the runbook.*/
export type AverageData = {
    /** an object with the metric ids mapped to their values.  For example {throughput: 5.3, packets: 1000}.*/
    [x: string]: string | number | null;
}

/** defines the data point data type for time data.  This type is used to represent data being returned for the runbook.*/
export type TimeData = {
    /** an object with the metric ids mapped to their values.  For example {throughput: 5.3, packets: 1000}.*/
    [timestamp: string]: {[x: string]: string | number | null;}
}

/** defines the format of the data object for data coming from graphql. */
export type GraphqlData = {
    /** a string with the timestamp.  For summary data this will be undefined. */
    timestamp?: string;
    /** an array of GraphqlDataValues with the metric values. */
    metrics: Array<GraphqlDataValue>;
}

/** defines the format of data for keys and metrics when the data comes from a GraphQL query.*/
export type GraphqlDataValue = {
    /** a String with the id of the key or metric for example ifindex or inbound_throughput. */
    id: string;
    /** the value for the key or metric, right now only strings or numbers are supported. */
    value: string | number;
}

/** defines the format of data for enumerations when the enum comes from a GraphQL query.*/
export type GraphqlEnumValue = {
    /** a String with the key for the enumeration. */
    key: string;
    /** a String with the value of the enumeration. */
    value: string;
    /** a String with the value of the order by weight. */
    weight?: number;
}

/** defines the trigger type.*/
export type Trigger = {
    /** a string with the id of the trigger column.*/
    id: string;
}

/** defines the severity score type. */
export type SeverityScore = {
    /** the severity of the issue detected by the runbook. */
    value: SEVERITY;
    /** the actual numeric score. */
    score?: number;
}

/** defines the data type enum.*/
export enum DATA_TYPE {
    /** the enumerated type that specifies that the data is average or aggregated data.*/
    SUMMARY = "summary",
    /** the enumerated type that specifies that the data is timeseries data.*/
    TIMESERIES = "timeseries",
}

/** defines the column type enum.*/
export enum COLUMN_TYPE {
    /** the string enumerated type.*/
    STRING  = "string",
    /** the integer enumerated type.*/
    INTEGER = "integer",
    /** the float enumerated type.*/
    FLOAT   = "float",
    /** the ip address enumerated type.*/
    IPADDR  = "ipaddr",
    /** the boolean enumerated type.*/
    BOOLEAN = "boolean",
    /** the array enumerated type.*/
    ARRAY   = "array",
    /** the array enumerated type.*/
    IP_LIST = "ipaddrList",
    /** the json enumerated type.*/
    JSON    = "json",
}

/** defines the runbook status enum.*/
export enum RUNBOOK_STATUS {
    /** new runbook */
    NEW                     = "NEW",
    /** the in progress enumerated type.*/
    IN_PROGRESS             = "IN_PROGRESS",
    /** the succeeded enumerated type.*/
    SUCCEEDED               = "SUCCEEDED",
    /** the succeeded enumerated type.*/
    SUCCEEDED_WITH_ERRORS   = "SUCCEEDED_WITH_ERRORS",
    /** the canceled enumerated type.*/
    CANCELED                = "CANCELED",
    /** the done enumerated type.*/
    DONE                    = "DONE",
    /** the failed enumerated type.*/
    FAILED                  = "FAILED",
    /** the unknown enumerated type. */
    UNKNOWN                 = "Unknown"

}

/** defines the trigger type enum.*/
export enum TRIGGER_TYPE_MAP {
    /** the enumerated value for the application location performance trigger. */
    "APPLICATION_LOCATION_PERFORMANCE_ISSUE"    = "application",
    /** the enumerated value for the device down trigger. */
    "DEVICE_DOWN_ISSUE"                         = "network_device",
    /** the enumerated value for the interface down trigger. */
    "INTERFACE_DOWN_ISSUE"                      = "interface_down",
    /** the enumerated value for the incident note added trigger. */
    "INCIDENT_NOTE_ADDED"                       = "incident_note_added_event",
    /** the enumerated value for the incident note updated trigger. */
    "INCIDENT_NOTE_UPDATED"                     = "incident_note_updated_event",
    /** the enumerated value for the incident note deleted trigger. */
    "INCIDENT_NOTE_DELETED"                     = "incident_note_deleted_event",
    /** the enumerated value for the incident indicators updated trigger. */
    "INCIDENT_INDICATORS_UPDATED"               = "incident_indicators_updated_event",
    /** the enumerated value for the incident ongoing changed trigger. */
    "INCIDENT_ONGOING_CHANGED"                  = "incident_ongoing_changed_event",
    /** the enumerated value for the incident status changed trigger. */
    "INCIDENT_STATUS_CHANGED"                   = "incident_status_changed_event",
    /** the enumerated value for the impact analysis ready trigger. */
    "IMPACT_ANALYSIS_READY"                     = "impact_analysis_ready_event",
    /** the enumerated value for the site application performance issue trigger. */
    //"SITE_APPLICATION_PERFORMANCE_ISSUE"        = "",
    /** the enumerated value for the interface performance trigger. */
    "INTERFACE_PERFORMANCE_ISSUE"               = "network_interface",
    /** the enumerated value for the site outage trigger. */
    "SITE_OUTAGE_ISSUE"                         = "location",
    /** the enumerated value for the multi-device down trigger. */
    "MULTI_DEVICE_DOWN_ISSUE"                   = "network_device",
    /** the enumerated value for the multi-app trigger. */
    "SINGLE_LOC_MULTI_APP_PERFORMANCE_ISSUE"    = "application", //"location",
    /** the enumerated value for the multi-loc trigger. */
    "SINGLE_APP_MULTI_LOC_PERFORMANCE_ISSUE"    = "application", //"application_only",
    /** the enumerated value for the webhook trigger. */
    "WEBHOOK"                                   = "webhook",
}

export const TRIGGER_TYPE_TO_VARIANT_MAP = {
    "APPLICATION_LOCATION_PERFORMANCE_ISSUE": Variant.INCIDENT,
    "DEVICE_DOWN_ISSUE": Variant.INCIDENT,
    "INTERFACE_DOWN_ISSUE": Variant.INCIDENT,
    "INCIDENT_NOTE_ADDED": Variant.LIFECYCLE,
    "INCIDENT_NOTE_UPDATED": Variant.LIFECYCLE,
    "INCIDENT_NOTE_DELETED": Variant.LIFECYCLE,
    "INCIDENT_INDICATORS_UPDATED": Variant.LIFECYCLE,
    "INCIDENT_ONGOING_CHANGED": Variant.LIFECYCLE,
    "INCIDENT_STATUS_CHANGED": Variant.LIFECYCLE,
    "IMPACT_ANALYSIS_READY": Variant.LIFECYCLE,
    //"SITE_APPLICATION_PERFORMANCE_ISSUE": Variant.INCIDENT,
    "INTERFACE_PERFORMANCE_ISSUE": Variant.INCIDENT,
    "SITE_OUTAGE_ISSUE": Variant.INCIDENT,
    "MULTI_DEVICE_DOWN_ISSUE": Variant.INCIDENT,
    "SINGLE_LOC_MULTI_APP_PERFORMANCE_ISSUE": Variant.INCIDENT,
    "SINGLE_APP_MULTI_LOC_PERFORMANCE_ISSUE": Variant.INCIDENT,
    "WEBHOOK": Variant.INCIDENT,
};

/** a constant which maps the runbook status enum value to additional props. */
export const RUNBOOK_STATUS_PROPS: Record<string, {
    /** Localized label to be used for this status */
    label: string,
    /** Icon to be used if it applies for this status */
    icon?: string,
    /** CSS class that should be applied to the icon if this status has a supported icon */
    iconClass?: string,
}> = {
    [RUNBOOK_STATUS.NEW]: {
        label: STRINGS.runbookStatus.new,
        icon: IconNames.TIME,
        iconClass: "text-primary",
    },
    [RUNBOOK_STATUS.IN_PROGRESS]: {
        label: STRINGS.runbookStatus.inProgress,
        icon: IconNames.SOCIAL_MEDIA,
        iconClass: "text-primary rotate",
    },
    [RUNBOOK_STATUS.SUCCEEDED]: {
        label: STRINGS.runbookStatus.succeeded,
        icon: IconNames.TICK_CIRCLE,
        iconClass: "bp3-intent-success",
    },
    [RUNBOOK_STATUS.SUCCEEDED_WITH_ERRORS]: {
        label: STRINGS.runbookStatus.partiallySucceeded,
        icon: IconNames.WARNING_SIGN,
        iconClass: "text-warning",
    },
    [RUNBOOK_STATUS.DONE]: {
        label: STRINGS.runbookStatus.done,
        icon: IconNames.UPDATED,
        iconClass: "bp3-intent-success",
    },
    [RUNBOOK_STATUS.FAILED]: {
        label: STRINGS.runbookStatus.failed,
        icon: IconNames.ERROR,
        iconClass: "text-critical",
    },
    [RUNBOOK_STATUS.UNKNOWN]: {
        label: STRINGS.runbookStatus.unknown,
        icon: IconNames.HELP,
    },
}

/** this interface defines the base properties that should be supported by all runbook view
 *  React function components. */
export interface BaseRunbookViewProps {
    /** a string with the unique id of the incident.*/
    incidentId?: string;
    /** this identifies a specific runbook execution. Primarly used for sharing link to a specific runbook */
    runbookId?: string;
    /** the array of datasets that will be displayed in the widget.  This dataset objects have the keys and metrics definition as 
     *  well as the title for the dataset and the data.*/
    datasets: Array<DataSet>;
    /** an optional boolean value, that specifies whether or not the data for this widget is loading. */
    loading?: boolean;
    /** the Widget object with the information about the widget. */
    widget?: Widget;
    /** the height of the widget in pixels. */
    height?: string;
    /** the handler for group selection events. */
    onGroupsAndMetricsSelected?: (groups: any[], metrics: string[]) => void;
    /** Either show or hide datapoints */
    showDatapoints?: boolean;
}
