import { Node, Edge, Elements, isNode, isEdge } from "react-flow-renderer";
import { STRINGS } from "app-strings";
import { LayoutOptions } from "elkjs/lib/elk.bundled.js";
import { getLayoutedGraph, overlapRemovalOption } from "./ELKLayout";

/** this interface defines the CloudIM GraphDef object. The CloudIM GraphDef object defines the data in 
 *  a graph.  It consists of nodes and edges. */
export interface CloudIMGraphDef {
    /** the array of nodes. */
    nodes: Node[];
    /** the array of edges. */
    edges: Edge[];
}

/** outputs the react-flow elements based on the CloudIM GraphDef object.
 *  @param graphDef the CloudIM GraphDef object with the graph data.
 *  @returns the Elements with the graph nodes and edges.*/
export function getElements(graphDef: CloudIMGraphDef): Elements {
    const elements: Elements = [];

    for (const node of graphDef.nodes) {
        let nodeEl: Node<any> = node;
        elements.push(nodeEl);
    }

    for (const edge of graphDef.edges) {
        let edgeEl: Edge<any> = edge;
        elements.push(edgeEl);
    }

    return elements;
}

/** outputs the CloudIM GraphDef object based on the react-flow elements.
 *  @param the Elements with the graph nodes and edges.
 *  @returns graphDef the CloudIM GraphDef object with the graph data.*/
export function getGraphDef(elements: Elements): CloudIMGraphDef {
    let nodes: Node[] = [];
    let edges: Edge[] = [];
    if (elements) {
        for (const element of elements) {
            if (isNode(element)) {
                nodes.push(element as Node);
            } else if (isEdge(element)) {
                edges.push(element as Edge);
            }
        }
    }

    return {
        nodes: nodes,
        edges: edges
    };
}

/** this enum specifies the supported AWS types. */
export enum AWSSupportedTypes {
    ACCOUNT = "account",
    OWNER = "owner",
    VPC = "vpc",
    VPCPEERINGCONNECTION = "vpcPeeringConnection",
    SUBNET = "subnet",
    COMPUTEINSTANCE = "compute_instance",
    BLOBSTORAGE = "blob_storage",
    LAMBDA = "lambda"
}

/** a constant which maps the aws type enum value to a label. */
export const AWS_TYPES_TO_LABEL_MAP: Record<string, string> = {
    [AWSSupportedTypes.ACCOUNT]: STRINGS.cloudim.topology.aws.account,
    [AWSSupportedTypes.OWNER]: STRINGS.cloudim.topology.aws.owner,
    [AWSSupportedTypes.VPC]: STRINGS.cloudim.topology.aws.vpc,
    [AWSSupportedTypes.VPCPEERINGCONNECTION]: STRINGS.cloudim.topology.aws.vpcPeeringConnection,
    [AWSSupportedTypes.SUBNET]: STRINGS.cloudim.topology.aws.subnet,
    [AWSSupportedTypes.COMPUTEINSTANCE]: STRINGS.cloudim.topology.aws.computeInstance,
    [AWSSupportedTypes.BLOBSTORAGE]: STRINGS.cloudim.topology.aws.blobStorage,
    [AWSSupportedTypes.LAMBDA]: STRINGS.cloudim.topology.aws.lambda,
}

/** this enum specifies the operation performed on ResourceOperation function. */
enum OperationTypes {
    CHECK = "Check",
    ADD = "Add",
    CHECKANDADD = "CheckAndAdd",
}

/** outputs true if an AWS resource id already exist or if it is undefined (to prevent adding to the dataset)
 *  @param kind: what we are checking. attributes: the data. IdSet: the set we are checking.
 *  @returns boolean: based on if the resource id exist or if it is undefined.*/
function AWSIdAddable(kind: AWSSupportedTypes, attributes: any, IdSet?: Set<string> | undefined): boolean {
    let addable: boolean = false;

    if (IdSet === undefined) {
        return false;
    }

    // Ids that are named differently
    const ownerIds = [attributes.OwnerId, attributes.ownerId];
    const ownerId = ownerIds.find(id => id !== undefined);
    const vpcIds = [attributes.VpcId, attributes.vpcId];
    const vpcId = vpcIds.find(id => id !== undefined);

    switch (kind) {
        case AWSSupportedTypes.ACCOUNT:
            addable = ownerId !== undefined && ownerId !== null && !IdSet.has(ownerId);
            break;
        case AWSSupportedTypes.OWNER:
            addable = ownerId !== undefined && ownerId !== null && !IdSet.has(ownerId);
            break;
        case AWSSupportedTypes.VPC:
            addable = vpcId !== undefined && vpcId !== null && !IdSet.has(vpcId);
            break;
        case AWSSupportedTypes.VPCPEERINGCONNECTION:
            addable = attributes.VpcPeeringConnectionId !== undefined && attributes.VpcPeeringConnectionId !== null && !IdSet.has(attributes.VpcPeeringConnectionId);
            break;
        case AWSSupportedTypes.SUBNET:
            addable = attributes.SubnetId !== undefined && attributes.SubnetId !== null && !IdSet.has(attributes.SubnetId);
            break;
        case AWSSupportedTypes.COMPUTEINSTANCE:
            addable = attributes.InstanceId !== undefined && attributes.InstanceId !== null && !IdSet.has(attributes.InstanceId);
            break;
        case AWSSupportedTypes.BLOBSTORAGE:
            addable = attributes.S3BucketId !== undefined && attributes.S3BucketId !== null && !IdSet.has(attributes.S3BucketId);
            break;
        case AWSSupportedTypes.LAMBDA:
            addable = attributes.Arn !== undefined && attributes.Arn !== null && !IdSet.has(attributes.Arn);
            break;
        default:
            break;
    }
    return addable;
}

/** outputs true if we successfully added the AWS resource into the dataset. This works by reference.
 *  @param kind: what we are checking. attributes: the data. nodes: our dataset. edges: our dataset. IdSet: the set we are checking.
 *  @returns boolean: based on if we successfully added the data.*/
function AWSAddResource(kind: AWSSupportedTypes, attributes: any, nodes: Array<any>, edges: Array<any>, IdSet?: Set<string> | undefined): boolean {
    let success: boolean = false;

    // Ids that are named differently
    const ownerIds = [attributes.OwnerId, attributes.ownerId];
    const ownerId = ownerIds.find(id => id !== undefined);
    const vpcIds = [attributes.VpcId, attributes.vpcId];
    const vpcId = vpcIds.find(id => id !== undefined);

    switch (kind) {
        case AWSSupportedTypes.ACCOUNT:
            // Check if id is null
            if (ownerId === null || ownerId === undefined) return false;
            nodes.push({
                "id": ownerId,
                "type": "awsNode",
                "width": 40,
                "height": 40,
                "data": {
                    "label": ownerId,
                    "type": kind,
                }
            });

            if (IdSet) {
                IdSet.add(ownerId);
            }

            success = true;
            break;
        case AWSSupportedTypes.OWNER:
            // Check if id is null or undefined
            if (ownerId === null || ownerId === undefined) return false;
            nodes.push({
                "id": ownerId,
                "type": "awsNode",
                "width": 40,
                "height": 40,
                "data": {
                    "label": attributes.OwnerName ? attributes.OwnerName : ownerId,
                    "type": kind,
                }
            });

            if (IdSet) {
                IdSet.add(ownerId);
            }

            success = true;
            break;
        case AWSSupportedTypes.VPC:
            // Check if id is null or undefined
            if (vpcId === null || vpcId === undefined) return false;
            // For vpcPeeringConnections, connecting VPC may be in another region.
            const region = attributes.region ? " (" + attributes.region + ")" : "";

            nodes.push({
                "id": vpcId,
                "type": "awsNode",
                "width": 40,
                "height": 40,
                "data": {
                    "label": vpcId + region,
                    "type": kind
                }
            });

            // Check if id is null or undefined
            if (vpcId === null || vpcId === undefined || ownerId === null || ownerId === undefined) return false;
            edges.push({
                "id": ownerId + "-" + vpcId,
                "source": ownerId,
                "target": vpcId
            });

            if (IdSet) {
                IdSet.add(vpcId);
            }

            success = true;
            break;
        case AWSSupportedTypes.VPCPEERINGCONNECTION:
            // Check if id is null or undefined
            if (attributes.VpcPeeringConnectionId === null || attributes.VpcPeeringConnectionId === undefined) return false;
            nodes.push({
                "id": attributes.VpcPeeringConnectionId,
                "type": "awsNode",
                "width": 40,
                "height": 40,
                "data": {
                    "label": attributes.VpcPeeringConnectionId,
                    "type": kind
                }
            });

            // Check if id is null or undefined
            if (attributes.VpcPeeringConnectionId === null || attributes.VpcPeeringConnectionId === undefined || attributes.RequesterVpcInfo.vpcId === null || attributes.RequesterVpcInfo.vpcId === undefined) return false;
            edges.push({
                "id": attributes.RequesterVpcInfo.vpcId + "-" + attributes.VpcPeeringConnectionId,
                "source": attributes.RequesterVpcInfo.vpcId,
                "target": attributes.VpcPeeringConnectionId
            });

            // Check if id is null or undefined
            if (attributes.VpcPeeringConnectionId === null || attributes.VpcPeeringConnectionId === undefined || attributes.AccepterVpcInfo.vpcId === null || attributes.AccepterVpcInfo.vpcId === undefined) return false;
            edges.push({
                "id": attributes.VpcPeeringConnectionId + "-" + attributes.AccepterVpcInfo.vpcId,
                "source": attributes.VpcPeeringConnectionId,
                "target": attributes.AccepterVpcInfo.vpcId
            });

            if (IdSet) {
                IdSet.add(attributes.VpcPeeringConnectionId);
            }

            success = true;
            break;
        case AWSSupportedTypes.SUBNET:
            // Check if id is null or undefined
            if (attributes.SubnetId === null || attributes.SubnetId === undefined) return false;
            nodes.push({
                "id": attributes.SubnetId,
                "type": "awsNode",
                "width": 40,
                "height": 40,
                "data": {
                    "label": attributes.SubnetId,
                    // TODO: Not sure if it is a public or private subnet, so currently using custom subnet without background
                    "type": "subnet",
                }
            });

            // Check if id is null or undefined
            if (attributes.VpcId === null || attributes.VpcId === undefined || attributes.SubnetId === null || attributes.SubnetId === undefined) return false;
            edges.push({
                "id": attributes.VpcId + "-" + attributes.SubnetId,
                "source": attributes.VpcId,
                "target": attributes.SubnetId
            });

            if (IdSet) {
                IdSet.add(attributes.SubnetId);
            }

            success = true;
            break;
        case AWSSupportedTypes.COMPUTEINSTANCE:
            // Check if id is null or undefined
            if (attributes.InstanceId === null || attributes.InstanceId === undefined) return false;
            nodes.push({
                "id": attributes.InstanceId,
                "type": "awsNode",
                "width": 40,
                "height": 40,
                "data": {
                    "label": attributes.InstanceId,
                    "type": kind,
                }
            });

            // Check if id is null or undefined
            if (attributes.InstanceId === null || attributes.InstanceId === undefined || attributes.SubnetId === null || attributes.SubnetId === undefined) return false;
            edges.push({
                "id": attributes.SubnetId + "-" + attributes.InstanceId,
                "source": attributes.SubnetId,
                "target": attributes.InstanceId
            });

            if (IdSet) {
                IdSet.add(attributes.InstanceId);
            }

            success = true;
            break;
        case AWSSupportedTypes.BLOBSTORAGE:
            // Check if id is null or undefined
            if (attributes.S3BucketId === null || attributes.S3BucketId === undefined) return false;
            nodes.push({
                "id": attributes.S3BucketId,
                "type": "awsNode",
                "width": 40,
                "height": 40,
                "data": {
                    "label": attributes.Name,
                    "type": kind,
                }
            });

            // Check if id is null or undefined
            if (attributes.S3BucketId === null || attributes.S3BucketId === undefined || ownerId === null || ownerId === undefined) return false;
            edges.push({
                "id": ownerId + "-" + attributes.S3BucketId,
                "source": ownerId,
                "target": attributes.S3BucketId
            });

            if (IdSet) {
                IdSet.add(attributes.S3BucketId);
            }

            success = true;
            break;
        case AWSSupportedTypes.LAMBDA:
            // Check if id is null or undefined
            if (attributes.Arn === null || attributes.Arn === undefined) return false;
            nodes.push({
                "id": attributes.Arn,
                "type": "awsNode",
                "width": 40,
                "height": 40,
                "data": {
                    "label": attributes.Name,
                    "type": kind,
                }
            });

            // Check if id is null or undefined
            if (attributes.Arn === null || attributes.Arn === undefined || ownerId === null || ownerId === undefined) return false;
            edges.push({
                "id": ownerId + "-" + attributes.Arn,
                "source": ownerId,
                "target": attributes.Arn
            });

            if (IdSet) {
                IdSet.add(attributes.Arn);
            }

            success = true;
            break;
        default:
            break;
    }
    return success;
}

/** outputs true if the data exist in the dataset.
 *  @param operation: what we want to do. kind: what we are checking. attributes: the data. nodes: our dataset. edges: our dataset. IdSet: the set we are checking.
 *  @returns boolean: if the data currently exist in the dataset */
function AWSResourceOperation(operation: OperationTypes, kind: AWSSupportedTypes, attributes: any, nodes: Array<any>, edges: Array<any>, IdSet?: Set<string>): boolean {
    let result: boolean = false;
    switch (operation) {
        case OperationTypes.ADD:
            result = AWSAddResource(kind, attributes, nodes, edges, IdSet);
            break;
        case OperationTypes.CHECKANDADD:
            if (AWSIdAddable(kind, attributes, IdSet)) {
                result = AWSAddResource(kind, attributes, nodes, edges, IdSet);
            }
            break;
        default:
            break;
    }
    return result;
}

/** outputs the CloudIM GraphDef object based on the cloudim aws data, it also performs a layout to the data.
 *  @param AWS data, we will transform this data to fit our schema.
 *  @returns graphDef the CloudIM GraphDef object with the graph data.*/
export async function AWSLayoutGraph(data: Array<any>, options?: LayoutOptions, overlapRemoval?: boolean): Promise<CloudIMGraphDef> {
    let layoutNodes: Node[] = [];
    let layoutEdges: Edge[] = [];

    if (data) {
        let nodes: Array<any> = [];
        let edges: Array<any> = [];

        // Storing OwnerIds
        let accountIdSet = new Set<string>();

        // VPC and subnet may be missing from data, use this to prevent errors
        let vpcIdSet = new Set<string>();
        let subnetIdSet = new Set<string>();

        for (const resource of data) {
            const kind = resource?.entityKind;
            const attributes = resource?.entityAttributes.rootElement;

            // Handle each supported type
            switch (kind) {
                case AWSSupportedTypes.VPC:
                    // Save AWS account if we haven't already
                    AWSResourceOperation(OperationTypes.CHECKANDADD, AWSSupportedTypes.ACCOUNT, attributes, nodes, edges, accountIdSet);
                    AWSResourceOperation(OperationTypes.CHECKANDADD, AWSSupportedTypes.VPC, attributes, nodes, edges, vpcIdSet);
                    break;
                case AWSSupportedTypes.VPCPEERINGCONNECTION:
                    // Save AWS account if we haven't already
                    AWSResourceOperation(OperationTypes.CHECKANDADD, AWSSupportedTypes.ACCOUNT, attributes, nodes, edges, accountIdSet);
                    AWSResourceOperation(OperationTypes.CHECKANDADD, AWSSupportedTypes.ACCOUNT, attributes.RequesterVpcInfo, nodes, edges, accountIdSet);
                    AWSResourceOperation(OperationTypes.CHECKANDADD, AWSSupportedTypes.VPC, attributes.RequesterVpcInfo, nodes, edges, vpcIdSet);
                    AWSResourceOperation(OperationTypes.CHECKANDADD, AWSSupportedTypes.ACCOUNT, attributes.AccepterVpcInfo, nodes, edges, accountIdSet);
                    AWSResourceOperation(OperationTypes.CHECKANDADD, AWSSupportedTypes.VPC, attributes.AccepterVpcInfo, nodes, edges, vpcIdSet);
                    AWSResourceOperation(OperationTypes.ADD, AWSSupportedTypes.VPCPEERINGCONNECTION, attributes, nodes, edges);
                    break;
                case AWSSupportedTypes.SUBNET:
                    // Save AWS account if we haven't already
                    AWSResourceOperation(OperationTypes.CHECKANDADD, AWSSupportedTypes.ACCOUNT, attributes, nodes, edges, accountIdSet);
                    AWSResourceOperation(OperationTypes.CHECKANDADD, AWSSupportedTypes.VPC, attributes, nodes, edges, vpcIdSet);
                    AWSResourceOperation(OperationTypes.CHECKANDADD, AWSSupportedTypes.SUBNET, attributes, nodes, edges, subnetIdSet);
                    break;
                case AWSSupportedTypes.COMPUTEINSTANCE:
                    // Save AWS account if we haven't already
                    AWSResourceOperation(OperationTypes.CHECKANDADD, AWSSupportedTypes.ACCOUNT, attributes, nodes, edges, accountIdSet);
                    AWSResourceOperation(OperationTypes.CHECKANDADD, AWSSupportedTypes.VPC, attributes, nodes, edges, vpcIdSet);
                    AWSResourceOperation(OperationTypes.CHECKANDADD, AWSSupportedTypes.SUBNET, attributes, nodes, edges, subnetIdSet);
                    AWSResourceOperation(OperationTypes.ADD, AWSSupportedTypes.COMPUTEINSTANCE, attributes, nodes, edges);
                    break;
                case AWSSupportedTypes.BLOBSTORAGE:
                    /** In AWS, the concept of the "owner" of an S3 bucket is different from the "account ID" used in other services like EC2. 
                     * When you create a S3 bucket, the bucket is associated with an owner. 
                     * The owner ID is a canonical user ID, which is a unique identifier for the AWS account that owns the bucket. 
                     * This canonical user ID is specific to the S3 service.
                     */
                    AWSResourceOperation(OperationTypes.CHECKANDADD, AWSSupportedTypes.OWNER, attributes, nodes, edges, accountIdSet);
                    AWSResourceOperation(OperationTypes.ADD, AWSSupportedTypes.BLOBSTORAGE, attributes, nodes, edges);
                    break;
                case AWSSupportedTypes.LAMBDA:
                    // Save AWS account if we haven't already
                    AWSResourceOperation(OperationTypes.CHECKANDADD, AWSSupportedTypes.ACCOUNT, attributes, nodes, edges, accountIdSet);
                    AWSResourceOperation(OperationTypes.ADD, AWSSupportedTypes.LAMBDA, attributes, nodes, edges);
                    break;
                default:
                    break;
            }
        }

        let layoutedGraph = await getLayoutedGraph(nodes, edges, options);
        if (overlapRemoval) {
            layoutedGraph = await getLayoutedGraph(layoutedGraph.nodes, layoutedGraph.edges, overlapRemovalOption);
        }

        layoutNodes = layoutedGraph.nodes;
        layoutEdges = layoutedGraph.edges;
    }

    return {
        nodes: layoutNodes,
        edges: layoutEdges
    }
}

/** this enum specifies the supported NetIM entity. */
enum NetIMSupportedEntityKind {
    NETWORKDEVICE = "network_device",
    NETWORKLINK = "network_link",
    NETWORKEDGE = "network_edge",
}

/** this enum specifies the supported NetIM Device types. */
export enum NetIMSupportedDeviceTypes {
    SWITCH = "Switch",
    ROUTER = "Router",
    FIREWALL = "Firewall",
    LOADBALANCER = "Load Balancer",
    HOST = "Host",
    WANACCELERATOR = "WAN Accelerator",
    MULTILAYERSWITCH = "Multi-Layer Switch",
    PRINTER = "Printer",
    UNIFIEDCOMMUNICATION = "Unified Communication",
    WIRELESS = "Wireless",
    SDWAN = "SD-WAN",
    OTHER = "Device - Other",
}

/** this enum specifies the supported NetIM Link types. */
export enum NetIMSupportedLinkTypes {
    AGGREGATE = "Aggregate",
    AGGREGATE_SERIAL_CLOUD = "Aggregate Serial Cloud",
    ETHERNET = "Ethernet",
    FAST_ETHERNET = "Fast Ethernet",
    GIGABIT_ETHERNET = "Gigabit Ethernet",
    TEN_GIGABIT_ETHERNET = "Ten Gigabit Ethernet",
    HUNDRED_GIGABIT_ETHERNET = "Hundred Gigabit Ethernet",
    LAN = "LAN",
    LAN_CLOUD = "LAN Cloud",
    ATM_CLOUD = "ATM Cloud",
    ATM = "ATM",
    ATM_PVC = "ATM PVC",
    ATM_PVP = "ATM PVP",
    ATM_SPVP = "ATM SPVP",
    ATM_SVC = "ATM SVC",
    ATM_FRAME_RELAY_PVC = "ATM-Frame Relay PVC",
    DATA_CONNECTION = "Data Connection",
    E1 = "E1",
    E3 = "E3",
    FDDI = "FDDI",
    FRAME_RELAY_CLOUD = "Frame Relay Cloud",
    FRAME_RELAY_PVC = "Frame Relay PVC",
    HSSI = "HSSI",
    IP_CLOUD = "IP Cloud",
    IP_RELAY_CLOUD = "IP Relay Cloud",
    IPSEC_TUNNEL = "IPSec Tunnel",
    ISDN = "ISDN",
    LOGICAL_IP_CLOUD = "Logical IP Cloud",
    LOOPBACK = "Loopback",
    MPLS_LSP = "MPLS LSP",
    OC3 = "OC3",
    OC12 = "OC12",
    OC192 = "OC192",
    PASSPORT_TRUNK = "Passport Trunk",
    PNNI = "Pnni",
    POINT_TO_POINT = "Point To Point",
    RADIO = "Radio",
    SDWAN_VPN = "SDWAN VPN",
    SERIAL_CLOUD = "Serial Cloud",
    SONET = "Sonet",
    STAR_LAN = "Star LAN",
    T1 = "T1",
    T3 = "T3",
    TOKEN_RING = "Token Ring",
    TUNNEL = "Tunnel",
    VIRTUAL_LAN = "Virtual LAN",
    VOICE_CONNECTION = "Voice Connection",
    OTHER = "Link - Other",
}

/** a constant which maps the netim type enum value to a label. */
export const NETIM_TYPES_TO_LABEL_MAP: Record<string, string> = {
    // NetIMSupportedDeviceTypes
    [NetIMSupportedDeviceTypes.SWITCH]: STRINGS.cloudim.topology.netim.switch,
    [NetIMSupportedDeviceTypes.ROUTER]: STRINGS.cloudim.topology.netim.router,
    [NetIMSupportedDeviceTypes.FIREWALL]: STRINGS.cloudim.topology.netim.firewall,
    [NetIMSupportedDeviceTypes.LOADBALANCER]: STRINGS.cloudim.topology.netim.loadBalancer,
    [NetIMSupportedDeviceTypes.HOST]: STRINGS.cloudim.topology.netim.host,
    [NetIMSupportedDeviceTypes.WANACCELERATOR]: STRINGS.cloudim.topology.netim.wanAccelerator,
    [NetIMSupportedDeviceTypes.MULTILAYERSWITCH]: STRINGS.cloudim.topology.netim.multilayeredSwitch,
    [NetIMSupportedDeviceTypes.PRINTER]: STRINGS.cloudim.topology.netim.printer,
    [NetIMSupportedDeviceTypes.UNIFIEDCOMMUNICATION]: STRINGS.cloudim.topology.netim.unifiedCommunication,
    [NetIMSupportedDeviceTypes.WIRELESS]: STRINGS.cloudim.topology.netim.wireless,
    [NetIMSupportedDeviceTypes.SDWAN]: STRINGS.cloudim.topology.netim.sdwan,
    [NetIMSupportedDeviceTypes.OTHER]: STRINGS.cloudim.topology.netim.other,
    // NetIMSupportedLinkTypes
    [NetIMSupportedLinkTypes.AGGREGATE]: STRINGS.cloudim.topology.netim.aggregate,
    [NetIMSupportedLinkTypes.AGGREGATE_SERIAL_CLOUD]: STRINGS.cloudim.topology.netim.aggregateSerialCloud,
    [NetIMSupportedLinkTypes.ETHERNET]: STRINGS.cloudim.topology.netim.ethernet,
    [NetIMSupportedLinkTypes.FAST_ETHERNET]: STRINGS.cloudim.topology.netim.fastEthernet,
    [NetIMSupportedLinkTypes.GIGABIT_ETHERNET]: STRINGS.cloudim.topology.netim.gigabitEthernet,
    [NetIMSupportedLinkTypes.TEN_GIGABIT_ETHERNET]: STRINGS.cloudim.topology.netim.tenGigabitEthernet,
    [NetIMSupportedLinkTypes.HUNDRED_GIGABIT_ETHERNET]: STRINGS.cloudim.topology.netim.hundredGigabitEthernet,
    [NetIMSupportedLinkTypes.LAN]: STRINGS.cloudim.topology.netim.lan,
    [NetIMSupportedLinkTypes.LAN_CLOUD]: STRINGS.cloudim.topology.netim.lanCloud,
    [NetIMSupportedLinkTypes.ATM]: STRINGS.cloudim.topology.netim.atm,
    [NetIMSupportedLinkTypes.ATM_CLOUD]: STRINGS.cloudim.topology.netim.atmCloud,
    [NetIMSupportedLinkTypes.ATM_PVC]: STRINGS.cloudim.topology.netim.atmPVC,
    [NetIMSupportedLinkTypes.ATM_PVP]: STRINGS.cloudim.topology.netim.atmPVP,
    [NetIMSupportedLinkTypes.ATM_SPVP]: STRINGS.cloudim.topology.netim.atmSPVP,
    [NetIMSupportedLinkTypes.ATM_SVC]: STRINGS.cloudim.topology.netim.atmSVC,
    [NetIMSupportedLinkTypes.ATM_FRAME_RELAY_PVC]: STRINGS.cloudim.topology.netim.atmFrameRelayPVC,
    [NetIMSupportedLinkTypes.DATA_CONNECTION]: STRINGS.cloudim.topology.netim.dataConnection,
    [NetIMSupportedLinkTypes.E1]: STRINGS.cloudim.topology.netim.e1,
    [NetIMSupportedLinkTypes.E3]: STRINGS.cloudim.topology.netim.e3,
    [NetIMSupportedLinkTypes.FDDI]: STRINGS.cloudim.topology.netim.fddi,
    [NetIMSupportedLinkTypes.FRAME_RELAY_CLOUD]: STRINGS.cloudim.topology.netim.frameRelayCloud,
    [NetIMSupportedLinkTypes.FRAME_RELAY_PVC]: STRINGS.cloudim.topology.netim.frameRelayPVC,
    [NetIMSupportedLinkTypes.HSSI]: STRINGS.cloudim.topology.netim.hssi,
    [NetIMSupportedLinkTypes.IP_CLOUD]: STRINGS.cloudim.topology.netim.ipCloud,
    [NetIMSupportedLinkTypes.IP_RELAY_CLOUD]: STRINGS.cloudim.topology.netim.ipRelayCloud,
    [NetIMSupportedLinkTypes.IPSEC_TUNNEL]: STRINGS.cloudim.topology.netim.ipSecTunnel,
    [NetIMSupportedLinkTypes.ISDN]: STRINGS.cloudim.topology.netim.isdn,
    [NetIMSupportedLinkTypes.LOGICAL_IP_CLOUD]: STRINGS.cloudim.topology.netim.logicalIPCloud,
    [NetIMSupportedLinkTypes.LOOPBACK]: STRINGS.cloudim.topology.netim.loopback,
    [NetIMSupportedLinkTypes.MPLS_LSP]: STRINGS.cloudim.topology.netim.mplsLSP,
    [NetIMSupportedLinkTypes.OC3]: STRINGS.cloudim.topology.netim.oc3,
    [NetIMSupportedLinkTypes.OC12]: STRINGS.cloudim.topology.netim.oc12,
    [NetIMSupportedLinkTypes.OC192]: STRINGS.cloudim.topology.netim.oc192,
    [NetIMSupportedLinkTypes.PASSPORT_TRUNK]: STRINGS.cloudim.topology.netim.passportTrunk,
    [NetIMSupportedLinkTypes.PNNI]: STRINGS.cloudim.topology.netim.pnni,
    [NetIMSupportedLinkTypes.POINT_TO_POINT]: STRINGS.cloudim.topology.netim.pointToPoint,
    [NetIMSupportedLinkTypes.RADIO]: STRINGS.cloudim.topology.netim.radio,
    [NetIMSupportedLinkTypes.SDWAN_VPN]: STRINGS.cloudim.topology.netim.sdwanVPN,
    [NetIMSupportedLinkTypes.SERIAL_CLOUD]: STRINGS.cloudim.topology.netim.serialCloud,
    [NetIMSupportedLinkTypes.SONET]: STRINGS.cloudim.topology.netim.sonet,
    [NetIMSupportedLinkTypes.STAR_LAN]: STRINGS.cloudim.topology.netim.starLAN,
    [NetIMSupportedLinkTypes.T1]: STRINGS.cloudim.topology.netim.t1,
    [NetIMSupportedLinkTypes.T3]: STRINGS.cloudim.topology.netim.t3,
    [NetIMSupportedLinkTypes.TOKEN_RING]: STRINGS.cloudim.topology.netim.tokenRing,
    [NetIMSupportedLinkTypes.TUNNEL]: STRINGS.cloudim.topology.netim.tunnel,
    [NetIMSupportedLinkTypes.VIRTUAL_LAN]: STRINGS.cloudim.topology.netim.virtualLAN,
    [NetIMSupportedLinkTypes.VOICE_CONNECTION]: STRINGS.cloudim.topology.netim.voiceConnection,
    [NetIMSupportedLinkTypes.OTHER]: STRINGS.cloudim.topology.netim.other,
}

/** outputs true if an NetIM resource id already exist or if it is undefined (to prevent adding to the dataset)
 *  @param kind: what we are checking. resource: the data. IdSet: the set we are checking.
 *  @returns boolean: based on if the resource id exist or if it is undefined.*/
function NetIMIdAddable(kind: NetIMSupportedEntityKind, resource: any, IdSet?: Set<string> | undefined): boolean {
    let addable: boolean = false;

    if (IdSet === undefined) {
        return false;
    }

    switch (kind) {
        case NetIMSupportedEntityKind.NETWORKDEVICE:
            const deviceId = resource?.netImEntityId;
            addable = deviceId !== undefined && deviceId !== null && !IdSet.has(deviceId);
            break;
        case NetIMSupportedEntityKind.NETWORKLINK:
            const linkId = resource?.netImEntityId;
            addable = linkId !== undefined && linkId !== null && !IdSet.has(linkId);
            break;
        case NetIMSupportedEntityKind.NETWORKEDGE:
            const edgeId = resource?.netImEntityId;
            addable = edgeId !== undefined && edgeId !== null && !IdSet.has(edgeId);
            break;
        default:
            break;
    }
    return addable;
}

/** outputs true if we successfully added the NetIM Resource into the dataset. This works by reference.
 *  @param kind: what we are checking. resource: the data. nodes: our dataset. edges: our dataset. IdSet: the set we are checking.
 *  @returns boolean: based on if we successfully added the data.*/
function NetIMAddResource(kind: NetIMSupportedEntityKind, resource: any, nodes: Array<any>, edges: Array<any>, IdSet?: Set<string> | undefined): boolean {
    let success: boolean = false;

    // Check NetIM Supported Entity Kinds
    switch (kind) {
        case NetIMSupportedEntityKind.NETWORKDEVICE:
            const deviceId = resource?.netImEntityId;
            const deviceName = resource?.entityAttributes?.rootElement?.name;
            const deviceType = resource?.entityAttributes?.rootElement?.type;

            // check if deviceId is null or undefined
            if (deviceId === null || deviceId === undefined) return false;
            // Check NetIM Support Types
            switch (deviceType) {
                case NetIMSupportedDeviceTypes.SWITCH:
                case NetIMSupportedDeviceTypes.ROUTER:
                case NetIMSupportedDeviceTypes.FIREWALL:
                case NetIMSupportedDeviceTypes.LOADBALANCER:
                case NetIMSupportedDeviceTypes.HOST:
                case NetIMSupportedDeviceTypes.WANACCELERATOR:
                case NetIMSupportedDeviceTypes.MULTILAYERSWITCH:
                case NetIMSupportedDeviceTypes.PRINTER:
                case NetIMSupportedDeviceTypes.UNIFIEDCOMMUNICATION:
                case NetIMSupportedDeviceTypes.WIRELESS:
                case NetIMSupportedDeviceTypes.SDWAN:
                    nodes.push({
                        "id": deviceId,
                        "type": "netimNode",
                        "width": 40,
                        "height": 40,
                        "data": {
                            "label": deviceName,
                            "type": deviceType
                        }
                    });

                    if (IdSet) {
                        IdSet.add(deviceId);
                    }

                    success = true;
                    break;
                default:
                    nodes.push({
                        "id": deviceId,
                        "type": "netimNode",
                        "width": 40,
                        "height": 40,
                        "data": {
                            "label": deviceName,
                            "type": NetIMSupportedDeviceTypes.OTHER
                        }
                    });

                    if (IdSet) {
                        IdSet.add(deviceId);
                    }

                    success = true;
                    break;
            }
            break;
        case NetIMSupportedEntityKind.NETWORKLINK:
            const linkId = resource?.netImEntityId;
            const linkName = resource?.entityAttributes?.rootElement?.name;
            const linkType = resource?.entityAttributes?.rootElement?.linkType;

            // Check if linkId is null or undefined
            if (linkId === null || linkId === undefined) return false;
            // Check NetIM Support Types
            switch (linkType) {
                case NetIMSupportedLinkTypes.AGGREGATE:
                case NetIMSupportedLinkTypes.AGGREGATE_SERIAL_CLOUD:
                case NetIMSupportedLinkTypes.ETHERNET:
                case NetIMSupportedLinkTypes.FAST_ETHERNET:
                case NetIMSupportedLinkTypes.GIGABIT_ETHERNET:
                case NetIMSupportedLinkTypes.TEN_GIGABIT_ETHERNET:
                case NetIMSupportedLinkTypes.HUNDRED_GIGABIT_ETHERNET:
                case NetIMSupportedLinkTypes.LAN:
                case NetIMSupportedLinkTypes.LAN_CLOUD:
                case NetIMSupportedLinkTypes.ATM_CLOUD:
                case NetIMSupportedLinkTypes.ATM:
                case NetIMSupportedLinkTypes.ATM_PVC:
                case NetIMSupportedLinkTypes.ATM_PVP:
                case NetIMSupportedLinkTypes.ATM_SPVP:
                case NetIMSupportedLinkTypes.ATM_SVC:
                case NetIMSupportedLinkTypes.ATM_FRAME_RELAY_PVC:
                case NetIMSupportedLinkTypes.DATA_CONNECTION:
                case NetIMSupportedLinkTypes.E1:
                case NetIMSupportedLinkTypes.E3:
                case NetIMSupportedLinkTypes.FDDI:
                case NetIMSupportedLinkTypes.FRAME_RELAY_CLOUD:
                case NetIMSupportedLinkTypes.FRAME_RELAY_PVC:
                case NetIMSupportedLinkTypes.HSSI:
                case NetIMSupportedLinkTypes.IP_CLOUD:
                case NetIMSupportedLinkTypes.IP_RELAY_CLOUD:
                case NetIMSupportedLinkTypes.IPSEC_TUNNEL:
                case NetIMSupportedLinkTypes.ISDN:
                case NetIMSupportedLinkTypes.LOGICAL_IP_CLOUD:
                case NetIMSupportedLinkTypes.LOOPBACK:
                case NetIMSupportedLinkTypes.MPLS_LSP:
                case NetIMSupportedLinkTypes.OC3:
                case NetIMSupportedLinkTypes.OC12:
                case NetIMSupportedLinkTypes.OC192:
                case NetIMSupportedLinkTypes.PASSPORT_TRUNK:
                case NetIMSupportedLinkTypes.PNNI:
                case NetIMSupportedLinkTypes.POINT_TO_POINT:
                case NetIMSupportedLinkTypes.RADIO:
                case NetIMSupportedLinkTypes.SDWAN_VPN:
                case NetIMSupportedLinkTypes.SERIAL_CLOUD:
                case NetIMSupportedLinkTypes.SONET:
                case NetIMSupportedLinkTypes.STAR_LAN:
                case NetIMSupportedLinkTypes.T1:
                case NetIMSupportedLinkTypes.T3:
                case NetIMSupportedLinkTypes.TOKEN_RING:
                case NetIMSupportedLinkTypes.TUNNEL:
                case NetIMSupportedLinkTypes.VIRTUAL_LAN:
                case NetIMSupportedLinkTypes.VOICE_CONNECTION:
                    nodes.push({
                        "id": linkId,
                        "type": "netimNode",
                        "width": 40,
                        "height": 40,
                        "data": {
                            "label": linkName,
                            "type": linkType
                        }
                    });

                    if (IdSet) {
                        IdSet.add(linkId);
                    }

                    success = true;
                    break;
                default:
                    nodes.push({
                        "id": linkId,
                        "type": "netimNode",
                        "width": 40,
                        "height": 40,
                        "data": {
                            "label": linkName,
                            "type": NetIMSupportedLinkTypes.OTHER
                        }
                    });

                    if (IdSet) {
                        IdSet.add(linkId);
                    }

                    success = true;
                    break;
            }
            break;
        case NetIMSupportedEntityKind.NETWORKEDGE:
            const edgeId = resource?.netImEntityId;
            const source = resource?.entityAttributes?.rootElement?.source;
            const target = resource?.entityAttributes?.rootElement?.target;
            const data = resource?.networkInterface?.entityAttributes?.rootElement;
            // Check if edgeId, source, or target is null or undefined
            if (edgeId === null || edgeId === undefined || source === null || source === undefined || target === null || target === undefined) return false;

            edges.push({
                "id": edgeId + "-" + source + "-" + target,
                "type": "netimEdge",
                "source": source,
                "target": target,
                "data": data
            });

            if (IdSet) {
                IdSet.add(edgeId + "-" + source + "-" + target);
            }

            success = true;
            break;
        default:
            break;
    }
    return success;
}

/** outputs true if the data exist in the dataset.
 *  @param operation: what we want to do. kind: what we are checking. resource: the data. nodes: our dataset. edges: our dataset. IdSet: the set we are checking.
 *  @returns boolean: if the data currently exist in the dataset */
function NetIMResourceOperation(operation: OperationTypes, kind: NetIMSupportedEntityKind, resource: any, nodes: Array<any>, edges: Array<any>, IdSet?: Set<string>): boolean {
    let result: boolean = false;
    switch (operation) {
        case OperationTypes.ADD:
            result = NetIMAddResource(kind, resource, nodes, edges, IdSet);
            break;
        case OperationTypes.CHECKANDADD:
            if (NetIMIdAddable(kind, resource, IdSet)) {
                result = NetIMAddResource(kind, resource, nodes, edges, IdSet);
            }
            break;
        default:
            break;
    }
    return result;
}

/** outputs the CloudIM GraphDef object based on the cloudim NetIM data, it also performs a layout to the data.
 *  @param NetIM data, we will transform this data to fit our schema.
 *  @returns graphDef the CloudIM GraphDef object with the graph data.*/
export async function NetIMLayoutGraph(data: Array<any>, options?: LayoutOptions, overlapRemoval?: boolean): Promise<CloudIMGraphDef> {
    let layoutNodes: Node[] = [];
    let layoutEdges: Edge[] = [];

    if (data) {
        let nodes: Array<any> = [];
        let edges: Array<any> = [];

        for (const resource of data) {
            const kind = resource?.entityKind;

            // Handle each supported type
            switch (kind) {
                case NetIMSupportedEntityKind.NETWORKDEVICE:
                    NetIMResourceOperation(OperationTypes.ADD, NetIMSupportedEntityKind.NETWORKDEVICE, resource, nodes, edges);
                    break;
                case NetIMSupportedEntityKind.NETWORKLINK:
                    NetIMResourceOperation(OperationTypes.ADD, NetIMSupportedEntityKind.NETWORKLINK, resource, nodes, edges);
                    break;
                case NetIMSupportedEntityKind.NETWORKEDGE:
                    NetIMResourceOperation(OperationTypes.ADD, NetIMSupportedEntityKind.NETWORKEDGE, resource, nodes, edges);
                    break;
                default:
                    break;
            }
        }

        let layoutedGraph = await getLayoutedGraph(nodes, edges, options);
        if (overlapRemoval) {
            layoutedGraph = await getLayoutedGraph(layoutedGraph.nodes, layoutedGraph.edges, overlapRemovalOption);
        }

        layoutNodes = layoutedGraph.nodes;
        layoutEdges = layoutedGraph.edges;
    }

    return {
        nodes: layoutNodes,
        edges: layoutEdges
    }
}
