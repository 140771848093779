import React, { useState, useCallback, useReducer, useRef, useMemo, useEffect } from "react";
import { Tab, TabbedSubPages } from "components/common/layout/tabbed-sub-pages/TabbedSubPages";
import { treeReducer, createTree } from "../primary-indicator/JsonViewer";
import _ from "lodash";
import { BladeContainer } from "components/common/layout/containers/blade-container/BladeContainer";
import { STRINGS } from "app-strings";
import NodeEditorPanel from "components/common/graph/NodeEditorPanel";
import { VariableContext } from "utils/runbooks/VariableContext";
import { Button, TextArea, Tree } from "@blueprintjs/core";
import { useStateSafePromise } from "@tir-ui/react-components";
import { useVariables } from "utils/hooks/useVariables";
import { NodeDef, Variant} from '../../../../components/common/graph/types/GraphTypes';
import { NodeLibrary, NodeLibrarySpec } from "pages/create-runbook/views/create-runbook/NodeLibrary";
import { getLibraryNode } from "pages/create-runbook/views/create-runbook/CreateRunbookView";
import { RunbookIntegrationDetails } from 'pages/integrations/types/IntegrationTypes';
import { IntegrationLibraryService } from 'utils/services/IntegrationLibraryApiService';
import { RunbookNode } from 'utils/services/RunbookApiService';
import { runbookService } from 'utils/runbooks/RunbookUtils';
import { getGraphDefFromRunbookConfig, createSubflowNodes } from 'pages/create-runbook/views/create-runbook/CreateRunbookView';
import IncidentRunbookNodeLibrary from 'pages/create-runbook/views/create-runbook/node_library.json';
import SubflowRunbookNodeLibrary from 'pages/create-runbook/views/create-runbook/subflow_node_library.json';
import { getIntegrationIcon } from "pages/integrations/IntegrationsLibraryPage";
import { getIconForNode } from "components/common/graph/react-flow/nodes/BaseNodeContent";
import { SDWAN_ICONS } from "components/sdwan/enums";
import LifecycleRunbookNodeLibrary from 'pages/create-runbook/views/create-runbook/lifecycle_node_library.json';
import OnDemandRunbookNodeLibrary from 'pages/create-runbook/views/create-runbook/on_demand_node_library.json';
import { CustomProperty, CustomPropertyContext } from "../../../../pages/create-runbook/views/create-runbook/CustomPropertyTypes";
import { useCustomProperties } from "utils/hooks/useCustomProperties";
import '../primary-indicator/JsonViewer';

/** defines the NodePath type. */
type NodePath = number[];

export interface RunbookPathTraversalNodeInfoProps {
    traversedNode: any;
    runbookOutput: any;
    runbookConfig: any;
}

export function RunbookPathTraversalNodeInfo(props: RunbookPathTraversalNodeInfoProps): JSX.Element {
    const [integrations, setIntegrations] = useState<RunbookIntegrationDetails[] | undefined>(undefined);
    const [showTreeView, setShowTreeView] = useState<boolean>(true);
	const integrationsCache = useRef<RunbookIntegrationDetails[] | undefined>();
    const originalSubflowName = useRef<string | undefined>("");
    const originalSubflowVersion = useRef<string | undefined>("");
    const [executeSafely] = useStateSafePromise();

    const [properties, setProperties] = useState<CustomProperty[] | undefined>(undefined);
    const customPropertiesQuery = useCustomProperties({});
    useEffect(
        () => {
            setProperties(customPropertiesQuery.data);
        },
        [customPropertiesQuery.data]
    );

    const globalNodes: NodeDef[] = [];

	useEffect(() => {
		const integrationsPromise = new Promise<RunbookIntegrationDetails[]>(async (resolve, reject) => {
			try {
				const newIntegrations = await IntegrationLibraryService.getRunbookIntegrations();
				resolve(newIntegrations as RunbookIntegrationDetails[]);    
			} catch (error) {
				reject(error);
			}
		});
		executeSafely(integrationsPromise).then(
			(integrations) => {
				integrationsCache.current = integrations;
				setIntegrations(integrations);
			}, 
			() => {
				integrationsCache.current = integrations;
				setIntegrations([]);
			}
		);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

    const [subflows, setNodeRedSubflows] = useState<Array<RunbookNode>>([]);

    const subflowNodeLibrary = new NodeLibrary(
        SubflowRunbookNodeLibrary as NodeLibrarySpec
    );

    useEffect(
		() => {
			async function fetchMyAPI() {
				let newSubflows: Array<RunbookNode> = [];
				try {
					const subflowVariant = Variant.SUBFLOW;
					const retFlows = await runbookService.getRunbooks(subflowVariant, true);
					if (retFlows) {
						for (const flow of retFlows) {
							newSubflows.push(flow);
						}
					}
				} catch (error) {
					console.log(error);
				}
				newSubflows = createSubflowNodes(
					newSubflows,
					subflowNodeLibrary,
                    integrationsCache.current
				);
				setNodeRedSubflows(newSubflows);
			}
            fetchMyAPI();
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[]
	);

    const InitNodeLibrary =
		props.runbookConfig.variant === Variant.LIFECYCLE
			? LifecycleRunbookNodeLibrary
			: props.runbookConfig.varian === Variant.ON_DEMAND
			? OnDemandRunbookNodeLibrary
			: props.runbookConfig.varian === Variant.SUBFLOW
			? SubflowRunbookNodeLibrary
			: IncidentRunbookNodeLibrary;
    
	const nodeLibrary = useRef<NodeLibrary>(
		new NodeLibrary(InitNodeLibrary as NodeLibrarySpec)
	);

    const graphDef = getGraphDefFromRunbookConfig(
        nodeLibrary.current, props.runbookConfig, subflows, integrationsCache.current
    );

    const getNodeById = (elements, nodeId: string) => {
        return (
          elements.find(element => element.id === nodeId) || null
        );
    };

    const properNodeObject = getNodeById(graphDef.nodes,props.traversedNode?.id);

    properNodeObject.getType = function () {
        return this.type;
    };

    properNodeObject.getName = function () {
        return this.name;
    };

    properNodeObject.getProperties = function () {
        return this.properties;
    };

    properNodeObject.getId = function () {
        return this.id;
    };

    properNodeObject.getProperty = function (key: string) {
        return this.properties?.find(item => item.key === key)?.value;
    };

    properNodeObject.getInfo = function () {
        return this.info;
    };

    const libraryNode = getLibraryNode(nodeLibrary.current, properNodeObject.type, subflows,  undefined, integrations);

    const nodeConfig = props.runbookConfig && props.traversedNode?.id ? props.runbookConfig?.nodes?.find(node => node.id === props.traversedNode.id) : null;
    
    const dataSets = props.runbookOutput?.datasets;

    const nodeDataSet = dataSets?.find(dataset => dataset.id?.includes(props.traversedNode?.id));

    const nodeError = nodeDataSet?.info?.error ? JSON.parse(JSON.stringify(nodeDataSet.info.error)) : null;

    if (nodeError) {
        delete nodeError.__typename;
        delete nodeError?.innerError?.__typename;
        nodeError.innerError?.properties?.forEach((_item, index) => {
            delete nodeError.innerError?.properties?.[index]?.__typename;
        });
    }

    const nodeDebugInfo = nodeDataSet?.debug;

    let beautifiedNodeDebugInfo;

    const dataset: any = {};

    if (nodeDebugInfo) {
        beautifiedNodeDebugInfo = {};
        nodeDebugInfo.forEach(item => {
            const innerObject = {
                ...item,
            };
            delete innerObject.name;
            delete innerObject.__typename;
            beautifiedNodeDebugInfo[item.name] = innerObject;
        });
    }

    dataset["Dataset: 1"] = {...beautifiedNodeDebugInfo};

    // Setup the tree.
    const INITIAL_STATE: Array<any> = createTree(dataset);
    const [nodes, dispatch] = useReducer(treeReducer, INITIAL_STATE);

    const handleNodeCollapse = useCallback((_node: any, nodePath: NodePath) => {
        dispatch({
            payload: { path: nodePath, isExpanded: false },
            type: "SET_IS_EXPANDED",
        });
    }, []);

    const handleNodeExpand = useCallback((_node: any, nodePath: NodePath) => {
        dispatch({
            payload: { path: nodePath, isExpanded: true },
            type: "SET_IS_EXPANDED",
        });
    }, []);

    const lastJson = useRef<any>(undefined);
    if (lastJson.current !== undefined && lastJson.current !== nodeDataSet?.debug) {
        dispatch({
            payload: INITIAL_STATE,
            type: "RESET",
        });
    }
    lastJson.current = nodeDataSet?.debug;

    const nodeEditorPanelComponent = useRef<any>(null);

    const [getVariables, setVariables, syncRuntimeOrSubflowVariables] = useVariables({runbookInfo: props.runbookConfig, variant: props.runbookConfig.variant});

    const variablesContext = useMemo(() => {
        return {getVariables, setVariables, syncRuntimeOrSubflowVariables}
    }, [getVariables, setVariables, syncRuntimeOrSubflowVariables])

    const nodeEditorPanel = () => {
        return <VariableContext.Provider value={variablesContext}><NodeEditorPanel
            showNodeErrors={false}
            ref={nodeEditor => nodeEditorPanelComponent.current = nodeEditor}
            graphDef={graphDef}
            activeRunbook={props.runbookConfig}
            selectedNode={properNodeObject}
            libraryNode={libraryNode as any} globalNodes={globalNodes}
            nodeLibrary={nodeLibrary as any}
            variant={props.runbookConfig.variant}
            subflows={subflows}
            onNodeEdited={(_node) => ({})}
            onCancel={() => ({})}
        /></VariableContext.Provider>
    }

    const bladeNodeIntegration = !!libraryNode?.integrationId && integrations?.find(el => el.id === libraryNode?.integrationId);
    const bladeIcon = bladeNodeIntegration ? 
    getIntegrationIcon(
        bladeNodeIntegration?.branding.icons, 
        bladeNodeIntegration?.name, 
        "badge", 
        bladeNodeIntegration?.branding?.secondaryColor
    ) : 
    getIconForNode(libraryNode?.icon) || SDWAN_ICONS.RUNBOOK;

    const nodeName = (libraryNode && (
        STRINGS.runbookEditor?.nodeLibrary?.nodes[libraryNode.type]?.name ||
        libraryNode.subflowName ||
        libraryNode.type)) || "";

        if (properNodeObject.type && properNodeObject.type === "subflow") {
            let subflowConfigurationId: string | undefined;
            const {data: traversedNodeData}: any = props.traversedNode;
            if (traversedNodeData && traversedNodeData?.properties?.length) {
                const subflowConfigurationIdProperty = traversedNodeData.properties.find(property => property.key === "configurationId");
                subflowConfigurationId = subflowConfigurationIdProperty.value;
            }
            if (subflows?.length && subflowConfigurationId) {
                for (const subflow of subflows) {
                    if (subflow.id === subflowConfigurationId) {
                        originalSubflowName.current = subflow.name;
                        originalSubflowVersion.current = subflow.version;
                        break;
                    }
                }
            }
        } else {
            originalSubflowName.current = "";
            originalSubflowVersion.current = "";
        }

    const getNodeExecutionTime = (started: string, ended: string): number => {
        const start: number = parseFloat(started);
        const end: number = parseFloat(ended);
    
        const durationInSeconds: number = end - start;
    
        const hours: number = Math.floor(durationInSeconds / 3600);
        const minutes: number = Math.floor((durationInSeconds % 3600) / 60);
        const seconds: string = (durationInSeconds % 60).toFixed(3);
    
        let durationString: string = '';
        if (hours > 0) {
            durationString += `${hours}h `;
        }
        if (minutes > 0) {
            durationString += `${minutes}m `;
        }
        durationString += `${seconds}s`;
    
        return +durationString.trim();
    }

    const nodeRunStatus = props.runbookOutput?.nodeRunStatus?.find(item => item.id === props.traversedNode?.id);

    // we need to disable inputs and dropdowns that appear 
    // in the Config tab as these are taken from the blade.
    useEffect(() => {
        const container = document.querySelector(".path-traversal-tabs");
        if (container) {
            const inputs: any = container.querySelectorAll("input:not([type=radio]");
            const dropdowns: any = container.querySelectorAll("select");
            const inputsArray = inputs ? [...inputs] : null;
            const dropdownsArray = dropdowns ? [...dropdowns] : null;

            inputsArray?.forEach(item => {
                item.disabled = true;
            });

            dropdownsArray?.forEach(item => {
                item.disabled = true;
            });
        }   
    });

    const nodeExecutionTime: number = getNodeExecutionTime(nodeRunStatus?.started, nodeRunStatus?.ended);

    return <>
        <TabbedSubPages urlStateKey="nodeInfoDebugTab" className="path-traversal-tabs">
            <Tab id="nodeEditorContent" title="CONFIG">
                <div className="switchToJSONview d-flex justify-content-between">
                    {!isNaN(nodeExecutionTime) && <div className="pt-3">{STRINGS.runbookEditor.nodeEditor.executionTime}{nodeExecutionTime}</div>}
                    {isNaN(nodeExecutionTime) && <div className="pt-3">{STRINGS.runbookEditor.nodeEditor.executionTimeNone}</div>}
                    <Button icon="document" text={showTreeView ? "Switch to JSON" : "Switch to tree view"} onClick={() => {
                        setShowTreeView(!showTreeView);
                    }} className="pt-3" />
                </div>
                {showTreeView && <div style={{ minHeight: "400px", maxHeight: "400px", overflowY: "auto", fontSize: "16px" }} className="json-viewer-content display-8">
                    <CustomPropertyContext.Provider value={properties || []}>
                        <BladeContainer
                            icon={bladeIcon}
                            title={<div className={"text-nowrap text-truncate"}>{props.traversedNode?.name || STRINGS.runbookEditor.nodeEditor.title}</div>}
                            subText={<div>
                                <div className="title-holder font-weight-600">{
                                    (nodeName ? "(" + STRINGS.runbookEditor.nodeEditor.nodeType + ": " + nodeName + 
                                    (originalSubflowName.current ? ") (" + STRINGS.runbookEditor.nodeEditor.subflowOriginalNameLabel + ": " + originalSubflowName.current : "") + ")" : undefined)
                                }</div>
                            </div>}
                            onCloseClicked={() => ({})}
                            noContentPadding
                            showIconWithoutBg={!!bladeNodeIntegration}
                        >
                            <div className="p-3"> {nodeEditorPanel()} </div>
                        </BladeContainer>
                    </CustomPropertyContext.Provider>
                </div>}
                {!showTreeView && <div style={{ minHeight: "400px", maxHeight: "400px", overflowY: "auto", fontSize: "16px" }} className="json-viewer-content display-8">
                    <TextArea 
                        value={JSON.stringify(nodeConfig || {}, null, 4)} 
                        className="json-viewer-config-textarea display-8 w-100"
                        style={{ minHeight: "400px"}}
                        disabled={true}
                    />
                </div>}
            </Tab>
            {nodes?.[0]?.childNodes?.length > 0 && <Tab id="jsonTree" title="DEBUG">
                <div style={{ minHeight: "400px", maxHeight: "400px", overflowY: "auto" }}className="json-viewer-content display-8">
                    <Tree contents={nodes} onNodeCollapse={handleNodeCollapse} onNodeExpand={handleNodeExpand} />
                </div>
            </Tab>}
            {!_.isEmpty(nodeError) && <Tab id="nodeInError" title="ERROR">
                <div style={{ minHeight: "400px", maxHeight: "400px", overflowY: "auto", fontSize: "16px" }} className="json-viewer-content display-8">
                    <TextArea 
                        value={JSON.stringify(nodeError || {}, null, 4)} 
                        className="json-viewer-error-textarea display-8 w-100"
                        style={{ minHeight: "400px"}}
                        disabled={true}
                    />
                </div>
            </Tab>}
        </TabbedSubPages>
    </>;
}