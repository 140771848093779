import React, { useState } from 'react';
import classNames from 'classnames';
import { LangEN, STRINGS } from 'app-strings';
import { Button, Divider, Switch } from '@blueprintjs/core';
import * as yup from 'yup';
import { Classes, IconNames } from '@tir-ui/react-components';
import { Form, InputField, SelectField } from 'components/common/form';
import { OAuth2GrantType } from 'utils/services/ThirdPartyIntegrationApiService';
import { WrapInTooltip } from 'components/common/wrap-in-tooltip/WrapInTooltip';
import { generateRandomID } from 'components/common/condition-tree-builder/condition/ConditionUtils';
import { isEmpty } from 'lodash';
import { FormikProps } from 'formik';

export interface OAuth2ParamsPanelProps {
    isEdit: boolean;
    oauth2GrantType: string | undefined;
    oauth2ClientId?: string;
    oauth2ClientSecret?: string;
    oauth2AuthUri?: string;
    oauth2Scope?: string;
    oauth2Username?: string;
    oauth2Password?: string;
    oA2Data?: any;
    onChangeOA2?: (cP: any) => void;
    onChangeOauth2GrantType: (event: any) => void;
    onChangeOauth2ClientId?: (event: any) => void;
    onChangeOauth2ClientSecret?: (event: any) => void;
    onChangeOauth2AuthUri?: (event: any) => void;
    onChangeOauth2Scope?: (event: any) => void;
    onChangeUsername?: (event: any) => void;
    onChangePassword?: (event: any) => void;
}

export enum OAuth2GrantTypeEnum {
    'ClientCredentials' = 0,
    'Password' = 1,
}

export function getGrantType(grantType) {
    if (grantType && typeof grantType === 'string') {
        return grantType;
    } else if (typeof grantType === 'number') {
        return OAuth2GrantTypeEnum[grantType] as OAuth2GrantType;
    } else {
        return undefined;
    }
}

const OAuth2ParamsPanel = (props: OAuth2ParamsPanelProps) => {
    const {
        oA2Data,
        onChangeOA2,
        isEdit,
        oauth2GrantType,
        oauth2ClientId,
        oauth2ClientSecret,
        oauth2AuthUri,
        oauth2Scope,
        oauth2Username,
        oauth2Password,
        onChangeOauth2GrantType,
        onChangeOauth2ClientId,
        onChangeOauth2ClientSecret,
        onChangeOauth2AuthUri,
        onChangeOauth2Scope,
        onChangeUsername,
        onChangePassword
    } = props;
    const translations:LangEN["thirdPartyIntegrations"]["addAuthProfile"] = STRINGS.thirdPartyIntegrations.addAuthProfile;
    const initialOptionalParam = () => ({
        key: "",
        value: "",
        sendVia: "RequestHeader",
        id: generateRandomID(),
    })
    const initialRequests = [
        { label: "Request Header", value: "RequestHeader" },
        { label: "Request URL", value: "RequestUrl" },
        { label: "Request Body", value: "RequestBody" }
    ];
    const [isAdvanced, setIsAdvanced] = useState(!isEmpty(oA2Data));
    const [customParams, setCustomParams] = useState<Array<any>>(oA2Data.map(x => ({ ...x, id: generateRandomID() })) || []);
    const grantTypes: Array<{ label: string, value: OAuth2GrantType }> = [{
        label: 'Client Credentials',
        value: 'ClientCredentials'
    }, {
        label: 'Password',
        value: 'Password'
    }];

    const validationSchema = yup.object().shape({
        grant_type: yup.string(),
        client_id: yup
            .string()
            .when([], {
                is: () => oauth2GrantType === 'ClientCredentials',
                then: yup.string().required()
            })
            .max(1024)
            .label(translations.panels
                .oAuth2Details.fields.clientId.label),
        client_secret: yup
            .string()
            .when([], {
                is: () => oauth2GrantType === 'ClientCredentials' && !props.isEdit,
                then: yup.string().required()
            })
            .max(1024)
            .label(translations.panels
                .oAuth2Details.fields.clientSecret.label),
        auth_uri: yup
            .string()
            .required()
            .max(1024)
            .label(translations.panels
                .oAuth2Details.fields.authUri.label),
        scope: yup
            .string()
            .max(1024)
            .label(translations.panels
                .oAuth2Details.fields.scope.label),
        profile_username: yup
            .string()
            .required()
            .max(2048)
            .label(translations.panels
                .basicAuthParams.fields.profileUsername.label),
        profile_password: props.isEdit ? 
            yup
                .string()
                .max(2048)
                .label(translations.panels
                    .basicAuthParams.fields.profilePassword.label):
            yup
                .string()
                .max(2048)
                .required()
                .label(translations.panels
                    .basicAuthParams.fields.profilePassword.label)
    });

    const handleCParamsChange = (index: number, field: string, value: any) => {
        const newInputGroups = [...customParams];
        newInputGroups[index][field] = value;
        setCustomParams(() => newInputGroups);
    };

    const OA2strings = translations.panels.oAuth2Details.fields.optParams;

    return (
        <div className={classNames(Classes.DIALOG_BODY)}>
            <p><b>{translations.panels.oAuth2Details.title}</b></p>
            <Divider />
            <Form
                className='mt-2'
                initialValues={{
                    client_id: oauth2ClientId,
                    client_secret: oauth2ClientSecret,
                    auth_uri: oauth2AuthUri,
                    profile_username: oauth2Username,
                    profile_password: oauth2Password,
                    custom: customParams
                }}
                validationSchema={validationSchema}
                loading={false}
            >
                {(formProps: FormikProps<object>) => <>
                <SelectField
                    name="grantType"
                    label={
                        translations.panels
                            .oAuth2Details.fields.grantType.label
                    }
                    onChange={onChangeOauth2GrantType}
                    defaultValue={oauth2GrantType}
                >
                    <option value="">
                        {translations.panels.oAuth2Details.fields.grantType.placeholder}
                    </option>
                    {grantTypes?.map((type, i) => <option key={i} value={type.value}>{type.label}</option>)}
                </SelectField>
                {oauth2GrantType === 'Password' && renderFieldsForPassword(formProps)}
                {oauth2GrantType === 'ClientCredentials' && renderFieldsForClientCredentials()}
                {oauth2GrantType &&
                    <Switch defaultChecked={!isEmpty(oA2Data)}
                        onChange={(e) => {
                            setIsAdvanced(!isAdvanced);
                            !isAdvanced && setCustomParams([]);
                            onChangeOA2?.([]);
                        }}>
                        {OA2strings.switch}
                    </Switch>}
                {(isAdvanced) && renderAdvancedConfigFields()}
                </>}
            </Form>
        </div>
    );

    /**
     * Render the fields for ClientCredentials grant type
     * 
     * @returns {JSX}
     */
    function renderFieldsForClientCredentials() {
        return (<>
            <InputField
                name="client_id"
                type="text"
                required={true}
                label={
                    translations.panels
                        .oAuth2Details.fields.clientId.label
                }
                placeholder={
                    translations.panels
                        .oAuth2Details.fields.clientId.placeholder
                }
                onChange={onChangeOauth2ClientId}
                value={oauth2ClientId}
                disabled={false}
            />
            <InputField
                name="client_secret"
                type="password"
                required={!isEdit}
                label={
                    translations.panels
                        .oAuth2Details.fields.clientSecret.label
                }
                placeholder={
                    isEdit ?
                        translations.panels.oAuth2Details.fields.clientSecret.placeholderForEdit:
                        translations.panels.oAuth2Details.fields.clientSecret.placeholder
                }
                onChange={(e: any) => {
                    onChangeOauth2ClientSecret && onChangeOauth2ClientSecret(e);
                }}
                value={oauth2ClientSecret}
                disabled={false}
                showPasswordOption={true}
            />
            <InputField
                name="auth_uri"
                type="text"
                required={true}
                label={
                    translations.panels
                        .oAuth2Details.fields.authUri.label
                }
                placeholder={
                    translations.panels
                        .oAuth2Details.fields.authUri.placeholder
                }
                onChange={onChangeOauth2AuthUri}
                value={oauth2AuthUri}
                disabled={false}
            />
            <InputField
                name="scope"
                type="text"
                required={false}
                label={
                    translations.panels
                        .oAuth2Details.fields.scope.label
                }
                placeholder={
                    translations.panels
                        .oAuth2Details.fields.scope.placeholder
                }
                onChange={onChangeOauth2Scope}
                value={oauth2Scope}
                disabled={false}
            />
        </>)
    }

    /**
     * Render the fields for Password grant type
     * 
     * @returns {JSX}
     */
    function renderFieldsForPassword(formProps: FormikProps<object>) {
        return <>
            <InputField
                name="profile_username"
                type="text"
                required={true}
                label={
                    translations.panels
                        .basicAuthParams.fields.profileUsername.label
                }
                placeholder={
                    translations.panels
                        .basicAuthParams.fields.profileUsername.placeholder
                }
                onBlur={(event) => {
                    const value = event.target.value?.trim();

                    formProps.handleBlur(event);
                    props.onChangeUsername && props.onChangeUsername({target: {
                        value: value
                    }});
                }}
                onChange={onChangeUsername}
                value={oauth2Username}
                disabled={false}
            />
            <InputField
                name="profile_password"
                type="password"
                required={!isEdit}
                label={
                    translations.panels
                        .basicAuthParams.fields.profilePassword.label
                }
                placeholder={
                    isEdit ?
                        translations.panels.basicAuthParams.fields.profilePassword.placeholderForEdit :
                        translations.panels.basicAuthParams.fields.profilePassword.placeholder
                }
                onBlur={(event) => {
                    const value = event.target.value?.trim();

                    formProps.handleBlur(event);

                    onChangePassword && onChangePassword({target: {
                        value: value
                    }});
                }}
                onChange={(e: any) => {
                    onChangePassword && onChangePassword(e)
                }}
                value={oauth2Password}
                disabled={false}
                showPasswordOption={true}
            />
            <InputField
                name="auth_uri"
                type="text"
                required={true}
                label={
                    translations.panels
                        .oAuth2Details.fields.authUri.label
                }
                placeholder={
                    translations.panels
                        .oAuth2Details.fields.authUri.placeholder
                }
                onBlur={(event) => {
                    const value = event.target.value?.trim();
                    
                    formProps.handleBlur(event);
                    onChangeOauth2AuthUri && onChangeOauth2AuthUri({target: {
                        value: value
                    }});
                }}
                onChange={onChangeOauth2AuthUri}
                value={oauth2AuthUri}
                disabled={false}
            />
            <InputField
                name="scope"
                type="text"
                required={false}
                label={
                    translations.panels
                        .oAuth2Details.fields.scope.label
                }
                placeholder={
                    translations.panels
                        .oAuth2Details.fields.scope.placeholder
                }
                onBlur={(event) => {
                    const value = event.target.value?.trim();

                    formProps.handleBlur(event);
                    onChangeOauth2Scope && onChangeOauth2Scope({target: {
                        value: value
                    }});
                }}
                onChange={onChangeOauth2Scope}
                value={oauth2Scope}
                disabled={false}
            />
            <InputField
                name="client_id"
                type="text"
                required={oauth2GrantType === 'ClientCredentials'}
                label={
                    translations.panels
                        .oAuth2Details.fields.clientId.label
                }
                placeholder={
                    translations.panels
                        .oAuth2Details.fields.clientId.placeholder
                }
                onBlur={(event) => {
                    const value = event.target.value?.trim();

                    formProps.handleBlur(event);
                    onChangeOauth2ClientId && onChangeOauth2ClientId({target: {
                        value: value
                    }});
                }}
                onChange={onChangeOauth2ClientId}
                value={oauth2ClientId}
                disabled={false}
            />
            <InputField
                name="client_secret"
                type="password"
                required={!isEdit && oauth2GrantType === 'ClientCredentials'}
                label={
                    translations.panels
                        .oAuth2Details.fields.clientSecret.label
                }
                placeholder={
                    isEdit ?
                        translations.panels.oAuth2Details.fields.clientSecret.placeholderForEdit:
                        translations.panels.oAuth2Details.fields.clientSecret.placeholder
                }
                onBlur={(event) => {
                    const value = event.target.value?.trim();

                    formProps.handleBlur(event);
                    onChangeOauth2ClientSecret && onChangeOauth2ClientSecret({target: {
                        value: value
                    }});
                }}
                onChange={(e: any) => {
                    onChangeOauth2ClientSecret && onChangeOauth2ClientSecret(e);
                }}
                value={oauth2ClientSecret}
                disabled={false}
                showPasswordOption={true}
            />
        </>
    }

    function renderAdvancedConfigFields() {
        return <>
            <div className='font-weight-bold py-2'>{OA2strings.subtitle}</div>
            <div className='advanced-grid font-weight-500 mb-2'>
                <span>{OA2strings.key}</span>
                <span>{OA2strings.value}</span>
                <span>{OA2strings.send}</span>
            </div>
            {isEmpty(customParams) &&
                <div className='text-secondary text-center pt-3 pb-2'>
                    {OA2strings.empty}
                </div>
            }
            {customParams.map((cParam, i) => (
                <div key={cParam.id}>
                    <div className='advanced-grid position-relative'>
                        <InputField name={"key" + i}
                            defaultValue={cParam.key}
                            onChange={(e) => {
                                handleCParamsChange(i, "key", e.currentTarget.value);
                                onChangeOA2?.([...customParams]);
                            }} />
                        <InputField name={"value" + i}
                            defaultValue={cParam.value}
                            onChange={(e) => {
                                handleCParamsChange(i, "value", e.currentTarget.value);
                                onChangeOA2?.([...customParams]);
                            }} />
                        <SelectField name={"via" + i}
                            defaultValue={cParam.sendVia}
                            onChange={e => {
                                handleCParamsChange(i, "sendVia", e.currentTarget.value)
                                onChangeOA2?.([...customParams]);
                            }}>
                            {initialRequests.map((via, i) => <option key={i} value={via.value}>{via.label}</option>)}
                        </SelectField>
                        <WrapInTooltip tooltip={OA2strings.tooltip}>
                            <Button minimal
                                icon={IconNames.SMALL_CROSS}
                                className='mt-3'
                                onClick={() => {
                                    const newParams = customParams.filter(item => item.id !== cParam.id);
                                    setCustomParams([...newParams]);
                                    onChangeOA2?.([...newParams]);
                                }}
                            />
                        </WrapInTooltip>
                    </div>
                    {Object.values(cParam).some(field => field === "") && <span className="field-error">{OA2strings.error}</span>}
                </div>
            ))}
            <Button fill large
                icon={IconNames.PLUS}
                className="btn-dotted mt-3"
                onClick={() => {
                    const newParams = [...customParams, initialOptionalParam()]
                    setCustomParams([...newParams])
                    onChangeOA2?.([...newParams])
                }}>
                {OA2strings.addParam}
            </Button>
        </>
    }
};

export { OAuth2ParamsPanel };
