/** This module contains constants and utilities that are used in the runbooks
 *  @module
 */
import { RunbookServiceIfc, RunbookService, RunbookNode, RunbookConfig } from 'utils/services/RunbookApiService';
import { AuthServiceProvider } from 'utils/providers/AuthServiceProvider';
import { STRINGS } from 'app-strings';
import { GraphDef, InputType, NamesByTriggerType, NodeDef } from 'components/common/graph/types/GraphTypes';
import { parseTimeFromDAL } from 'utils/hooks';
import { SEVERITY } from 'components/enums';
import { DataSet, DATA_TYPE } from 'pages/riverbed-advisor/views/runbook-view/Runbook.type';
import { WIDGET_TYPE, Widget } from "components/common/vis-framework/widget/Widget.type";
import { PrimitiveVariable } from 'utils/runbooks/VariablesUtils';
import { dataSourceTypeOptions } from 'utils/services/DataSourceApiService';
import { DataOceanMetadata, DataOceanMetric } from 'components/common/graph/editors/data-ocean/DataOceanMetadata.type';
import { Version } from 'utils/Version.class';

/** the environment, dev, staging or production. */
let { ENV } = window['runConfig'] ? window['runConfig'] : { ENV: '' };
export function setEnv(envValue: string): void {
    ENV = envValue;
}

// A reference to the auth service
const AuthService = AuthServiceProvider.getService();

// This constant determines which back-end we are using to serve the runbooks
export const runbookService: RunbookServiceIfc = RunbookService;

/** a boolean value that specifies whether to show the title icons in the runbook out. */
export const showTitleIcons = false;

/** this array has the string type for each of the chart nodes. */
export const chartNodes = [
    "ui_chart", "rvbd_ui_time_chart", "rvbd_ui_pie_chart", "rvbd_ui_bar_chart", "rvbd_ui_table", 
    "rvbd_ui_text", "rvbd_ui_cards", "rvbd_ui_gauges", "rvbd_ui_connection_graph", "rvbd_ui_debug",
    "rvbd_ui_bubble_chart", "rvbd_ui_correlation_chart"
];

/** this array has the string type for each of the time chart nodes. */
export const timeChartNodes = ["rvbd_ui_time_chart", "rvbd_ui_correlation_chart"];

/** this array has the string type for each of the card chart nodes. */
export const cardChartNodes = ["rvbd_ui_cards"];

/** this array has the string type for each of the chart nodes that has a columns editor. */
export const columnEditorChartNodes = ["rvbd_ui_table"];

/** this array has the string type for each of the card chart nodes that has a metrics editor. */
export const metricsEditorChartNodes = ["rvbd_ui_time_chart", "rvbd_ui_bar_chart", "rvbd_ui_cards", "rvbd_ui_gauges"];

/** this array has the string type for each of the card chart nodes that has a single metric editor. */
export const metricEditorChartNodes = ["rvbd_ui_pie_chart", "rvbd_ui_connection_graph"];

/** this array has the string type for each of the chart nodes that has a size metric editor. */
export const sizeMetricEditorChartNodes = ["rvbd_ui_bubble_chart"];

/** this array has the string type for each of the chart nodes that has a color metric editor. */
export const colorMetricEditorChartNodes = ["rvbd_ui_bubble_chart"];

/** this array has the string type for each of the chart nodes that has a x-axis metric editor. */
export const xMetricEditorChartNodes = ["rvbd_ui_correlation_chart"];

/** this array has the string type for each of the chart nodes that has a y-axis metric editor. */
export const yMetricEditorChartNodes = ["rvbd_ui_correlation_chart"];

/** this array has the string type for each of the connection graph nodes. */
export const connectionGraphNodes = ["rvbd_ui_connection_graph"];

/** this array has the string type for each of the debug nodes. */
export const debugNodes = ["rvbd_ui_debug"];

/** this array has the string type for each of the table nodes. */
export const tableNodes = ["rvbd_ui_table"];

/** this array has the string type for each of the text nodes. */
export const textNodes = ["rvbd_ui_text"];

/** this array has the string type for each of the trigger nodes. */
export const triggerNodes = ["trigger", "lifecycleTriggers", "input", "subflow_input", "on_demand_input"];

/** this array has the string type for each of the trigger nodes. */
export const outputNodes = ["subflow_output"];

/** this array has the string type for each of the data ocean nodes. */
export const dataOceanNodes: Array<string> = ["data_ocean"];

/** this array has the string type for each of the logical nodes. */
export const logicalNodes = ["logic"];

/** this array has the string type for each of the decision nodes. */
export const decisionNodes = ["decision"];

/** this array has the string type for each of the function nodes. */
export const aggregatorNodes: Array<string> = ["aggregator"];

/** this array has the string type for each of the comment nodes. */
export const commentNodes = ["comment"];

/** this array has the string type for each of the note nodes. */
export const noteNodes = ["notes"];

/** this array has the string type for each of the skeleton nodes. */
export const skeletonNodes = ["skeleton"];

/** this array has the string type for each of the priority nodes. */
export const priorityNodes = ["set_priority"];

/** this array has the string type for each of the priority nodes. */
export const tagNodes = ["tag"];

/** this array has the string type for each of the http nodes. */
export const httpNodes = ["http"];

/** this array has the string type for each of the transform nodes. */
export const transformNodes: Array<string> = ["transform"];

/** this array has the string type for each of the variable nodes. */
export const variableNodes = ["set_primitive_variables", "set_structured_variable"];

/** this array has the string type for each of the subflow nodes. */
export const subflowNodes = ["subflow"];

/** this array has the string type for each of the subflow input nodes. */
export const subflowInputNodes = ["subflow_input"];

/** this array has the string type for each of the subflow input nodes. */
export const subflowOutputNodes = ["subflow_output"];

/** this array has the string type for each of the on demand input nodes. */
export const onDemandInputNodes = ["on_demand_input"];

/** a new type that extends the RunbookNode type and adds the children and parents properties so we can build a graph */
export type RunbookGraphNode = RunbookNode & {
    /** an Array of RunbookGraphNodes that have the parents of the node. */
    parents: Array<RunbookGraphNode>;
    /** an Array of RunbookGraphNodes that have the children of the node. */
    children: Array<RunbookGraphNode>;
}

/** a new type that extends the NodeDef type and adds the children and parents properties so we can build a graph */
export type RunbookGraphNodeDef = NodeDef & {
    /** an Array of RunbookGraphNodeDefs that have the parents of the node. */
    parents: Array<RunbookGraphNodeDef>;
    /** an Array of RunbookGraphNodeDefs that have the children of the node. */
    children: Array<RunbookGraphNodeDef>;
}

/** returns the node with the specified id.
 *  @param id the id of the node to find.
 *  @param nodes the array of RunbookNodes that were stored in the template.
 *  @returns the node or null if it is not found.*/
export function getNode(id: string, nodes: Array<RunbookNode>): RunbookNode | null {
    for (const node of nodes) {
        // At one point in time there was an issue with caching and Marcello was changing the id of the dataset
        // if (node.id === id || (id.length > 5 && node.id.length > 0 && id.endsWith("-" + node.id))) {
        if (node.id === id) {
                return node;
        }
    }
    return null;
}

/** returns the chart node attached to the query node.
 *  @param queryNode the data ocean RunbookNode.
 *  @param nodes the array of RunbookNodes that were stored in the template.
 *  @param wireIndex the index of the wire that you want to check the query node for the chart node.
 *  @returns the chart node or null if it is not found.*/
export function getChartNode(queryNode: RunbookNode, nodes: Array<RunbookNode>, wireIndex: number = -1): RunbookNode | null {
    if (queryNode) {
        const wires = queryNode.wires;
        for (let index = 0; index < wires.length; index++) {
            if (wireIndex !== -1 && wireIndex !== index) {
                continue;
            }
            const wire = wires[index];
            for (const id of wire) {
                const node = getNode(id, nodes);
                if (node && chartNodes.includes(node.type)) {
                    return node;
                }
            }
        }
    }
    return null;
}

/** returns the chart node attached to the query node.  If no wireIndex is specified all wires will be
 *      searched for charts.
 *  @param queryNode the data ocean RunbookNode.
 *  @param nodes the array of RunbookNodes that were stored in the template.
 *  @param wireIndex the index of the wire that you want to check the query node for the chart node.
 *  @returns the chart node or null if it is not found.*/
export function getChartNodes(queryNode: RunbookNode, nodes: Array<RunbookNode>, wireIndex: number = -2): Array<RunbookNode> {
    const chartNodesToReturn: Array<RunbookNode> = [];
    if (queryNode) {
        const wires = queryNode.wires;
        for (let index = 0; index < wires.length; index++) {
            if (wireIndex !== -2 && wireIndex !== index) {
                continue;
            }
            const wire = wires[index];
            for (const id of wire) {
                const node = getNode(id, nodes);
                if (node && chartNodes.includes(node.type)) {
                    chartNodesToReturn.push(node);
                }
            }
        }
    }
    return chartNodesToReturn;
}

/** returns the nodes that are trigger nodes.
 *  @param runbookNodes all the runbook nodes.
 *  @returns the trigger nodes. */
export function getTriggerNodes(runbookNodes?: Array<RunbookNode>): Array<RunbookNode> {
    const nodes:Array<RunbookNode> = new Array<RunbookNode>();
    if (runbookNodes) {
        for (const node of runbookNodes) {
            if(triggerNodes.indexOf(node.type) > -1) {
                nodes.push(node);
            }
        }
    }
    return nodes;
}

/** checks to see if the specified node is of the specified type.
 *  @param node the node to test.
 *  @param type the type to check for.
 *  @returns a boolean value, true if the node is of the specified type, false otherwise.*/
export function isNodeOfType(node: RunbookNode, type: string): boolean {
    return node && node.type === type;
}

/** checks to see if the specified node is a trigger node.
 *  @param node the node to test.
 *  @returns a boolean value, true if the node is a trigger node, false otherwise.*/
 export function isTriggerNode(node: RunbookNode): boolean {
    return node && triggerNodes.includes(node.type);
}

/** checks to see if the specified node is a subflow node.
 *  @param node the node to test.
 *  @returns a boolean value, true if the node is a subflow node, false otherwise.*/
export function isSubflowNode(node: RunbookNode): boolean {
    //return node && node.type.startsWith("subflow:");
    return node && subflowNodes.includes(node.type);
}

/** checks to see if the specified node is a subflow input node.
 *  @param node the node to test.
 *  @returns a boolean value, true if the node is a subflow input node, false otherwise.*/
export function isSubflowInputNode(node: RunbookNode): boolean {
    return node && subflowInputNodes.includes(node.type);
}

/** checks to see if the specified node is a subflow output node.
 *  @param node the node to test.
 *  @returns a boolean value, true if the node is a subflow output node, false otherwise.*/
export function isSubflowOutputNode(node: RunbookNode): boolean {
    return node && subflowOutputNodes.includes(node.type);
}

/** checks to see if the specified node is a on-demand input node.
 *  @param node the node to test.
 *  @returns a boolean value, true if the node is a on-demand input node, false otherwise.*/
export function isOnDemandInputNode(node: RunbookNode): boolean {
    return node && onDemandInputNodes.includes(node.type);
}

/** checks to see if the specified node is a chart node.
 *  @param node the node to test.
 *  @returns a boolean value, true if the node is a chart node, false otherwise.*/
export function isChartNode(node: RunbookNode): boolean {
    return node && chartNodes.includes(node.type);
}

/** checks to see if the specified node is a time chart node.
 *  @param node the node to test.
 *  @returns a boolean value, true if the node is a time chart node, false otherwise.*/
export function isTimeChartNode(node: RunbookNode): boolean {
    return node && timeChartNodes.includes(node.type);
}

/** checks to see if the specified node is a card chart node.
 *  @param node the node to test.
 *  @returns a boolean value, true if the node is a card chart node, false otherwise.*/
export function isCardChartNode(node: RunbookNode): boolean {
    return node && cardChartNodes.includes(node.type);
}

/** checks to see if the specified node is a connection graph node.
 *  @param node the node to test.
 *  @returns a boolean value, true if the node is a connection graph node, false otherwise.*/
export function isConnectionGraphNode(node: RunbookNode): boolean {
    return node && connectionGraphNodes.includes(node.type);
}

/** checks to see if the specified node is a table node.
 *  @param node the node to test.
 *  @returns a boolean value, true if the node is a table node, false otherwise.*/
export function isTableNode(node: RunbookNode): boolean {
    return node && tableNodes.includes(node.type);
}

/** checks to see if the specified node is a text node.
 *  @param node the node to test.
 *  @returns a boolean value, true if the node is a text node, false otherwise.*/
export function isTextNode(node: RunbookNode): boolean {
    return node && textNodes.includes(node.type);
}

/** checks to see if the specified node is a debug node.
 *  @param node the node to test.
 *  @returns a boolean value, true if the node is a debug node, false otherwise.*/
export function isDebugNode(node: RunbookNode): boolean {
    return node && debugNodes.includes(node.type);
}

/** checks to see if the specified node is a chart node that supports the column editor.
 *  @param node the node to test.
 *  @returns a boolean value, true if the node is a chart node that supports the column editor, false otherwise.*/
export function isColumnEditorChartNode(node: RunbookNode): boolean {
    return node && columnEditorChartNodes.includes(node.type);
}

/** checks to see if the specified node is a chart node that supports the metrics editor.
 *  @param node the node to test.
 *  @returns a boolean value, true if the node is a chart node that supports the metrics editor, false otherwise.*/
export function isMetricsEditorChartNode(node: RunbookNode): boolean {
    return node && metricsEditorChartNodes.includes(node.type);
}

/** checks to see if the specified node is a chart node that supports the metric editor.
 *  @param node the node to test.
 *  @returns a boolean value, true if the node is a chart node that supports the metric editor, false otherwise.*/
export function isMetricEditorChartNode(node: RunbookNode): boolean {
    return node && metricEditorChartNodes.includes(node.type);
}

/** checks to see if the specified node is a chart node that supports the size metric editor.
 *  @param node the node to test.
 *  @returns a boolean value, true if the node is a chart node that supports the size metric editor, false otherwise.*/
export function isSizeMetricEditorChartNode(node: RunbookNode): boolean {
    return node && sizeMetricEditorChartNodes.includes(node.type);
}

/** checks to see if the specified node is a chart node that supports the color metric editor.
 *  @param node the node to test.
 *  @returns a boolean value, true if the node is a chart node that supports the color metric editor, false otherwise.*/
export function isColorMetricEditorChartNode(node: RunbookNode): boolean {
    return node && colorMetricEditorChartNodes.includes(node.type);
}

/** checks to see if the specified node is a chart node that supports the x-axis metric editor.
 *  @param node the node to test.
 *  @returns a boolean value, true if the node is a chart node that supports the x-axis metric editor, false otherwise.*/
export function isXMetricEditorChartNode(node: RunbookNode): boolean {
    return node && xMetricEditorChartNodes.includes(node.type);
}

/** checks to see if the specified node is a chart node that supports the y-axis metric editor.
 *  @param node the node to test.
 *  @returns a boolean value, true if the node is a chart node that supports the y-axis metric editor, false otherwise.*/
export function isYMetricEditorChartNode(node: RunbookNode): boolean {
    return node && yMetricEditorChartNodes.includes(node.type);
}

/** checks to see if the specified node is a comment node.
 *  @param node the node to test.
 *  @returns a boolean value, true if the node is a comment node, false otherwise.*/
export function isCommentNode(node: RunbookNode): boolean {
    return node && commentNodes.includes(node.type);
}

/** checks to see if the specified node is a note node.
 *  @param node the node to test.
 *  @returns a boolean value, true if the node is a note node, false otherwise.*/
export function isNoteNode(node: RunbookNode): boolean {
    return node && noteNodes.includes(node.type);
}

/** checks to see if the specified node is a priority node.
 *  @param node the node to test.
 *  @returns a boolean value, true if the node is a priority node, false otherwise.*/
export function isPriorityNode(node: RunbookNode): boolean {
    return node && priorityNodes.includes(node.type);
}

/** checks to see if the specified node is a data ocean node.
 *  @param node the node to test.
 *  @returns a boolean value, true if the node is a data ocean node, false otherwise.*/
export function isDataOceanNode(node: RunbookNode): boolean {
        return node && dataOceanNodes.includes(node.type);
}

/** checks to see if the specified node is a logical node.
 *  @param node the node to test.
 *  @returns a boolean value, true if the node is a logical node, false otherwise.*/
export function isLogicalNode(node: RunbookNode): boolean {
    return node && logicalNodes.includes(node.type);
}

/** checks to see if the specified node is a decision node.
 *  @param node the node to test.
 *  @returns a boolean value, true if the node is a decision node, false otherwise.*/
 export function isDecisionNode(node: RunbookNode): boolean {
    return node && decisionNodes.includes(node.type);
}

/** checks to see if the specified node is an aggregator node.
 *  @param node the node to test.
 *  @returns a boolean value, true if the node is a function node, false otherwise.*/
export function isAggregatorNode(node: RunbookNode): boolean {
    return node && aggregatorNodes.includes(node.type);
}

/** checks to see if the specified node is a data node.  Data nodes are nodes that produce
 *      data, such as a data ocean node or logical node.
 *  @param node the node to test.
 *  @returns a boolean value, true if the node is a data node, false otherwise.*/
export function isDataNode(node: RunbookNode): boolean {
    return isDataOceanNode(node) || isLogicalNode(node) || isAggregatorNode(node) || isDecisionNode(node) || isTransformNode(node);
}

/** checks to see if the specified node is a tag node.
 *  @param node the node to test.
 *  @returns a boolean value, true if the node is a tag node, false otherwise.*/
export function isTagNode(node: RunbookNode): boolean {
    return node && tagNodes.includes(node.type);
}

/** checks to see if the specified node is an http node.
 *  @param node the node to test.
 *  @returns a boolean value, true if the node is a http node, false otherwise.*/
export function isHttpNode(node: RunbookNode): boolean {
    return node && httpNodes.includes(node.type);
}

/** checks to see if the specified node is a transform node.
 *  @param node the node to test.
 *  @returns a boolean value, true if the node is a transform node, false otherwise.*/
export function isTransformNode(node: RunbookNode): boolean {
    return node && transformNodes.includes(node.type);
}

/** checks to see if the specified node is a variables node.
 *  @param node the node to test.
 *  @returns a boolean value, true if the node is a variables node, false otherwise.*/
export function isVariablesNode(node: RunbookNode): boolean {
    return node && variableNodes.includes(node.type);
}

/** checks to see if the specified node is a set simple variables node.
 *  @param node the node to test.
 *  @returns a boolean value, true if the node is a set simple variables node, false otherwise.*/
export function isSetSimpleVariablesNode(node: RunbookNode): boolean {
    return node && variableNodes[0] === node.type;
}

/** checks to see if the specified node is a set complex variables node.
 *  @param node the node to test.
 *  @returns a boolean value, true if the node is a set simple complex node, false otherwise.*/
export function isSetComplexVariableNode(node: RunbookNode): boolean {
    return node && variableNodes[1] === node.type;
}

/** Checks to see if this node has a valid type.
 *  @param node the node to test.
 *  @returns a boolean value, true if the node is of a vlid type, false otherwise.*/
export function isValidType(node: RunbookNode): boolean {
    return isTagNode(node) || isDataOceanNode(node) || isLogicalNode(node) || isPriorityNode(node) || 
        isCommentNode(node) || isChartNode(node) || isTriggerNode(node) || isAggregatorNode(node) || 
        isDecisionNode(node) || isHttpNode(node) || isTransformNode(node) || isVariablesNode(node) ||
        isNoteNode(node) || isSubflowNode(node) || isSubflowOutputNode(node);
}

/** returns the children for the specified node.
 *  @param node the node whose children are requested.
 *  @param nodes the list of nodes in the runbook.
 *  @returns an Array of RunbookNodes that are the children of the specified node. */
export function getChildren(node: RunbookNode, nodes: Array<RunbookNode>): Array<RunbookNode> {
    const children: Array<RunbookNode> = [];
    if (node) {
        const wires = node.wires;
        for (const wire of wires) {
            for (const id of wire) {
                const node = getNode(id, nodes);
                if (node && !skeletonNodes.includes(node?.type || '')) {
                    children.push(node);
                }
            }
        }
    }
    return children;
}

/** returns the parents for the specified node.
 *  @param node the node whose parents are requested.
 *  @param nodes the list of nodes in the runbook.
 *  @returns an Array of RunbookNodes that are the parents of the specified node. */
export function getParents(node: RunbookNode, nodes: Array<RunbookNode>): Array<RunbookNode> {
    const parents: Array<RunbookNode> = [];
    if (node && nodes && nodes.length > 0) {
        for (const testNode of nodes) {
            const wires = testNode.wires;
            for (const wire of wires) {
                for (const id of wire) {
                    if (id === node.id && !skeletonNodes.includes(node?.type || '')) {
                        parents.push(testNode);
                    }
                }
            }    
        }
    }
    return parents;
}

/** returns the first parent with the specified type.
 *  @param node the node whose parents are requested.
 *  @param nodes the list of nodes in the runbook.
 *  @param types the Array of string types to check. 
 *  @returns the first node of the specified type or null if none is found. */
export function getFirstParentOfType(
    node: RunbookNode, runbookNodes: Array<RunbookNode>, types: Array<string>
): RunbookNode | null{
    const parents = getParents(node, runbookNodes);
    if (parents?.length > 0) {
        for (const parent of parents) {
            if (types.includes(parent.type)) {
                return parent;
            }
        }
        for (const parent of parents) {
            const parentOfType = getFirstParentOfType(parent, runbookNodes, types);
            if (parentOfType) {
                return parentOfType;
            }
        }
    }
    return null;
}

/** returns the first parent with the specified type.
 *  @param node the node whose parents are requested.
 *  @param nodes the list of nodes in the runbook.
 *  @param types the Array of string types to check. 
 *  @returns the array of first nodes in each branch of the specified type or empty array if none is found. */
export function getFirstParentOfTypeInEachBranch(
    node: RunbookNode, runbookNodes: Array<RunbookNode>, types: Array<string>
): Array<RunbookNode> {
    let parentsOfType: Array<RunbookNode> = [];

    const parents: Array<RunbookNode> = getParents(node, runbookNodes);
    if (parents && parents.length > 0) {
        for (const parent of parents) {
            if (types.includes(parent.type)) {
                parentsOfType.push(parent);
            } else {
                const newParents = getFirstParentOfTypeInEachBranch(parent, runbookNodes, types);
                if (newParents.length > 0) {
                    parentsOfType = parentsOfType.concat(newParents);
                }
            }
        }
    }

    return parentsOfType;
}

/** generates a graph from the list of runbook nodes.
 *  @param nodes the list of nodes in the runbook.
 *  @returns the Array of RunbookGraphNodes that are the roots for the graph. */
export function generateGraph(nodes: Array<RunbookNode>): Array<RunbookGraphNode> {
    const roots: Array<RunbookGraphNode> = [];

    if (nodes && nodes.length > 0) {
        const graphNodes: Array<RunbookGraphNode> = [];
        for (const node of nodes) {
            const nodeCopy: RunbookGraphNode = JSON.parse(JSON.stringify(node));
            nodeCopy.parents = [];
            nodeCopy.children = [];
            graphNodes.push(nodeCopy);
        }

        for (const node of graphNodes) {
            const parents = getParents(node, graphNodes);
            if (parents.length === 0) {
                // If there are no parents it is a root of the graph
                roots.push(node);
            }
            node.parents = (parents as Array<RunbookGraphNode>);

            const children = getChildren(node, graphNodes);
            node.children = (children as Array<RunbookGraphNode>);
        }
    }

    return roots;
}

/** checks the graph to see if it is cyclic.
 *  @param roots the roots of the graph.
 *  @returns a boolean true if the graph is cyclic, false otherwise. */
export function isGraphCyclic(roots: Array<RunbookGraphNode>): boolean {
    for (const rootNode of roots) {
        const traversedNodeIds: Array<string> = [];
        traversedNodeIds.push(rootNode.id);
        const children = rootNode.children;
        if (children.length > 0) {
            const isCyclicGraph = isCyclic(children, traversedNodeIds);
            if (isCyclicGraph) {
                return true;
            }
        }
    }
    return false;
}

/** checks a subtree of the graph to see if it is cyclic.
 *  @param nodes the nodes to check.
 *  @returns a boolean true if the subtree is cyclic, false otherwise. */
export function isCyclic(nodes: Array<RunbookGraphNode>, traversedNodeIds: Array<string>): boolean {
    for (const node of nodes) {
        if (traversedNodeIds.includes(node.id)) {
            return true;
        }
        traversedNodeIds = [node.id].concat(traversedNodeIds);
        const children = node.children;
        if (children.length > 0) {
            const isCyclicGraph = isCyclic(children, traversedNodeIds);
            if (isCyclicGraph) {
                return true;
            }
        }
    }
    return false;
}

/** returns the meta data for the specified runbook.
 *  @param runbook the Runbook object.
 *  @returns the meta data for the runbook. */
export function getRunbookMetaData(runbook): {name: string, description: string, trigger: string, createdTime?: Date, lastUpdatedTime?: Date, createdByUser: string, lastUpdatedUser: string} {
    const name: string = (runbook.i18nNameKey ? STRINGS.defaultRunbooks[runbook.i18nNameKey] : runbook.name || '');
    let description: string = (runbook.i18nInfoKey ? STRINGS.defaultRunbooks[runbook.i18nInfoKey] : runbook.description || '');
    let trigger: string = (runbook.triggerType ? NamesByTriggerType[runbook.triggerType] : STRINGS.runbookEditor.unknownInputType);
    const createdTime = parseTimeFromDAL(runbook.createdTime);
    const lastUpdatedTime = parseTimeFromDAL(runbook.lastUpdatedTime);
    const createdByUser = runbook.createdByUser || "";
    const lastUpdatedUser = runbook.lastUpdatedUser || "";
    return ({name, description, trigger, createdTime, lastUpdatedTime, createdByUser, lastUpdatedUser});
}

/** returns the dataset proccessed from the raw data coming in from Desso's runbook.  The processed data set
 *      adds the widget information to the existing data in the dataset
 *  @param dataset the raw data for the dataset.
 *  @param runbook the array of RunbookNodes that were stored in the template.
 *  @returns the dataset that was created from the data.*/
export function processDataset(dataset: any, runbook: Array<RunbookNode>): DataSet | undefined {
    let datasetId: string = dataset.id;
    let dataNodeId: string = datasetId;
    let wireIndex: number = 0;
    let isComparison: boolean = false;
    if (dataNodeId.includes(":")) {
        const refText = dataset.timeReference?.name ? ":" + dataset.timeReference?.name : undefined;
        if (refText && dataNodeId.endsWith(refText)) {
            isComparison = true;
            dataNodeId = dataNodeId.substring(0, dataNodeId.length - refText.length);
            datasetId = dataNodeId; 
        }
        dataNodeId = datasetId.substring(0, datasetId.indexOf(":"));
        wireIndex = parseInt(datasetId.substring(datasetId.indexOf(":") + 1, datasetId.length));
    }

    const queryNode = getNode(dataNodeId, runbook);
    let chartNodes: Array<RunbookNode> = [];

    // Get the node for the runbook and get the node for the chart.
    if (queryNode) {
        chartNodes = getChartNodes(queryNode, runbook, wireIndex);
    }

    let processedDataset: DataSet | undefined = undefined;
    if (queryNode && chartNodes.length > 0) {
        const widgets: Array<Widget> = [];
        for (const chartNode of chartNodes) {
            // We should check the chart to see what type of widget it is, but right now we 
            // only have tables.
            let widgetType: WIDGET_TYPE | null = null;
            const chartRow = chartNode.properties.row;
            let widgetRow: number = (chartRow ? parseInt(chartRow) : 0);
            let options: any = {};
            switch (dataset.type) {
                case DATA_TYPE.SUMMARY: 
                    switch (chartNode.type) {
                        case "rvbd_ui_table":
                            widgetType = WIDGET_TYPE.TABLE;
                            options.columns = chartNode.properties.columns;
                            options.sortColumn = chartNode.properties.sortColumn;
                            options.sortOrder = chartNode.properties.sortOrder;
                            options.flipTable = chartNode.properties.flipTable;
                            break;
                        case "rvbd_ui_text":
                            widgetType = WIDGET_TYPE.TEXT;
                            options.notes = chartNode.properties.notes;
                            break;
                        case "rvbd_ui_cards":
                            widgetType = WIDGET_TYPE.CARDS;
                            options.isComparison = isComparison;
                            options.metrics = chartNode.properties.metrics;
                            break;
                        case "rvbd_ui_gauges":
                            widgetType = WIDGET_TYPE.GAUGES;
                            options.metrics = chartNode.properties.metrics;
                            break;
                        case "rvbd_ui_bar_chart":
                            widgetType = WIDGET_TYPE.BAR;
                            options.showBarLabels = chartNode.properties.showBarLabels;
                            options.orientation = chartNode.properties.orientation;
                            options.stackBars = chartNode.properties.stackBars;
                            options.showLegend = chartNode.properties.showLegend;
                            options.legendPosition = chartNode.properties.legendPosition;
                            options.metrics = chartNode.properties.metrics;
                            break;
                        case "rvbd_ui_bubble_chart": {
                            widgetType = WIDGET_TYPE.BUBBLE;
                            const style = chartNode.properties.style;
                            if (style === "donut") {
                                options.style = "donut";
                            }
                            options.showPercentage = chartNode.properties.showPercentage;
                            options.showValue = chartNode.properties.showValue;
                            options.showLegend = chartNode.properties.showLegend;
                            options.legendPosition = chartNode.properties.legendPosition;
                            options.sizeMetric = chartNode.properties.sizeMetric;
                            options.colorMetric = chartNode.properties.colorMetric;
                            break;
                        }
                        case "rvbd_ui_connection_graph":
                            widgetType = WIDGET_TYPE.CONNECTION_GRAPH;
                            options.metric = chartNode.properties.metric;
                            break;
                        case "rvbd_ui_pie_chart": {
                            widgetType = WIDGET_TYPE.PIE;
                            const style = chartNode.properties.style;
                            if (style === "donut") {
                                options.style = "donut";
                            }
                            options.showPercentage = chartNode.properties.showPercentage;
                            options.showValue = chartNode.properties.showValue;
                            options.showLegend = chartNode.properties.showLegend;
                            options.legendPosition = chartNode.properties.legendPosition;
                            options.metric = chartNode.properties.metric;
                            break;
                        }
                        case "rvbd_ui_debug":
                            widgetType = WIDGET_TYPE.DEBUG;
                            options.showInOutput = chartNode.properties.showInOutput;
                            break;
                        default:
                            widgetType = WIDGET_TYPE.TABLE; 
                    }
                    break;
                default:
                    switch (chartNode.type) {
                        case "rvbd_ui_cards":
                            widgetType = WIDGET_TYPE.CARDS;
                            options.metrics = chartNode.properties.metrics;
                            break;
                        case "rvbd_ui_debug":
                            widgetType = WIDGET_TYPE.DEBUG;
                            options.showInOutput = chartNode.properties.showInOutput;
                            break;
                        case "rvbd_ui_correlation_chart":
                            widgetType = WIDGET_TYPE.CORRELATION;
                            options.showLegend = chartNode.properties.showLegend;
                            options.legendPosition = chartNode.properties.legendPosition;
                            options.xMetric = chartNode.properties.xMetric;
                            options.yMetric = chartNode.properties.yMetric;
                            break;
                        default:
                            widgetType = WIDGET_TYPE.TIMESERIES;
                            options.style = chartNode.properties.style;
                            options.showLegend = chartNode.properties.showLegend;
                            options.legendPosition = chartNode.properties.legendPosition;
                            options.metrics = chartNode.properties.metrics;
                    }

            }
            const chartNodeProperties = chartNode.properties;
            if (chartNodeProperties.notes && chartNodeProperties.notes.length > 0) {
                options.notes = chartNodeProperties.notes;
                chartNodeProperties.notesPosition && (options.notesPosition = chartNodeProperties.notesPosition);
            }

            const doNode = getFirstParentOfType(chartNode, runbook, dataOceanNodes);
            if (doNode) {
                const doNodeProperties = doNode.properties;
                if (doNodeProperties.objType === "cloud.resource") {
                    options.isAzure = true;
                }
                if (isComparison && doNodeProperties.comparedTo) {
                    options.comparedTo = doNodeProperties.comparedTo;
                }
            }
            options.isComparison = isComparison;

            const name = chartNodeProperties.title ? chartNodeProperties.title : "";
            const widget: Widget = {id: chartNode.id, name: name, type: widgetType, row: widgetRow};
            if (Object.keys(options).length > 0) {
                widget.options = options;
            }
            widgets.push(widget);
        }
        processedDataset = {
            id: datasetId, type: dataset.type, keys: dataset.keys, metrics: dataset.metrics, 
            severity: {value: SEVERITY.CRITICAL}, datapoints: dataset.datapoints, widgets: widgets,
            info: dataset.info, debug: dataset.debug, isComparison
        };
    }

    return processedDataset;
}

/** returns whether or not the specified runbook can be edited. 
 *  @param runbook the runbook to check to see if it can be edited.
 *  @returns a boolean value, true if the runbook can be edited, false otherwise. */
export function canEditRunbook(runbook: RunbookConfig): boolean {
    return !runbook?.isFactory && canEditRunbooks();
}

/** returns whether or not the user has the privileges to edit runbooks. 
 *  @returns a boolean value, true if the user has privileges to edit runbooks, false otherwise. */
export function canEditRunbooks(): boolean {
    return AuthService.userHasWriteAccess("gelato");
}

/** returns the node with the specified id.
 *  @param id the id of the node to find.
 *  @param graphDef The Graph Definition object.
 *  @returns the nodeDef or null if it is not found.*/
export function getNodeFromGraphDef(id: string, graphDef: GraphDef): NodeDef | null {
    for (const node of graphDef.nodes) {
        // At one point in time there was an issue with caching and Marcello was changing the id of the dataset
        // if (node.id === id || (id.length > 5 && node.id.length > 0 && id.endsWith("-" + node.id))) {
        if (node.id === id) {
            return node;
        }
    }
    return null;
}

/** returns the chart node attached to the query node.
 *  @param queryNode the data ocean RunbookNode.
 *  @param graphDef The Graph Definition object.
 *  @returns the chart node or null if it is not found.*/
export function getChartNodeFromGraphDef(queryNode: NodeDef, graphDef: GraphDef): NodeDef | null {
    if (queryNode) {
        for (const edge of graphDef.edges) {
            if (edge.fromNode === queryNode.id) {
                for (const toNode of graphDef.nodes) {
                    if (toNode.id === edge.toNode && chartNodes.includes(toNode.type)) {
                        return toNode;
                    }
                }
            }
        }
    }
    return null;
}

/** returns the chart nodes attached to the query node.
 *  @param queryNode the data ocean NodeDef.
 *  @param graphDef the GraphDef object with the nodes and edges.
 *  @returns the chart nodes or an empty array if none found.*/
export function getChartNodesFromGraphDef(queryNode: NodeDef, graphDef: GraphDef): Array<NodeDef> {
    const chartNodesToReturn: Array<NodeDef> = [];
    if (queryNode) {
        for (const edge of graphDef.edges) {
            if (edge.fromNode === queryNode.id) {
                for (const toNode of graphDef.nodes) {
                    if (toNode.id === edge.toNode && chartNodes.includes(toNode.type)) {
                        chartNodesToReturn.push(toNode);
                    }
                }
            }
        }
    }
    return chartNodesToReturn;
}

/** returns the nodes that are trigger nodes.
 *  @param graphDef the GraphDef object with the nodes and edges.
 *  @returns the trigger nodes. */
export function getTriggerNodesFromGraphDef(graphDef: GraphDef): Array<NodeDef> {
    const nodes:Array<NodeDef> = new Array<NodeDef>();
    if (graphDef && graphDef.nodes) {
        for (const node of graphDef.nodes) {
            if(triggerNodes.indexOf(node.type) > -1) {
                nodes.push(node);
            }
        }
    }
    return nodes;
}

/** checks to see if the specified node is of the specified type.
 *  @param node the node to test.
 *  @param type the type to check for.
 *  @returns a boolean value, true if the node is of the specified type, false otherwise.*/
export function isNodeOfTypeFromGraphDef(node: NodeDef, type: string): boolean {
    return node && node.type === type;
}

/** checks to see if the specified node is a trigger node.
 *  @param node the node to test.
 *  @returns a boolean value, true if the node is a trigger node, false otherwise.*/
export function isTriggerNodeFromGraphDef(node: NodeDef): boolean {
    return node && triggerNodes.includes(node.type);
}

/** checks to see if the specified node is a subflow node.
 *  @param node the node to test.
 *  @returns a boolean value, true if the node is a subflow node, false otherwise.*/
export function isSubflowNodeFromGraphDef(node: NodeDef): boolean {
    //return node && node.type.startsWith("subflow:");
    return node && subflowNodes.includes(node.type);
}

/** checks to see if the specified node is a subflow input node.
 *  @param node the node to test.
 *  @returns a boolean value, true if the node is a subflow input node, false otherwise.*/
export function isSubflowInputNodeFromGraphDef(node: NodeDef): boolean {
    return node && subflowInputNodes.includes(node.type);
}

/** checks to see if the specified node is a on demand input node.
 *  @param node the node to test.
 *  @returns a boolean value, true if the node is a on demand input node, false otherwise.*/
export function isOnDemandInputNodeFromGraphDef(node: NodeDef): boolean {
    return node && onDemandInputNodes.includes(node.type);
}

/** checks to see if the specified node is a subflow output node.
 *  @param node the node to test.
 *  @returns a boolean value, true if the node is a subflow output node, false otherwise.*/
export function isSubflowOutputNodeFromGraphDef(node: NodeDef): boolean {
    return node && subflowOutputNodes.includes(node.type);
}
/** checks to see if the specified node is a chart node.
 *  @param node the node to test.
 *  @returns a boolean value, true if the node is a chart node, false otherwise.*/
export function isChartNodeFromGraphDef(node: NodeDef): boolean {
    return node && chartNodes.includes(node.type);
}

/** checks to see if the specified node is a time chart node.
 *  @param node the node to test.
 *  @returns a boolean value, true if the node is a time chart node, false otherwise.*/
export function isTimeChartNodeFromGraphDef(node: NodeDef): boolean {
    return node && timeChartNodes.includes(node.type);
}

/** checks to see if the specified node is a card chart node.
 *  @param node the node to test.
 *  @returns a boolean value, true if the node is a card chart node, false otherwise.*/
export function isCardChartNodeFromGraphDef(node: NodeDef): boolean {
    return node && cardChartNodes.includes(node.type);
}

/** checks to see if the specified node is a connection graph node.
 *  @param node the node to test.
 *  @returns a boolean value, true if the node is a connection graph node, false otherwise.*/
export function isConnectionGraphNodeFromGraphDef(node: NodeDef): boolean {
    return node && connectionGraphNodes.includes(node.type);
}

/** checks to see if the specified node is a table node.
 *  @param node the node to test.
 *  @returns a boolean value, true if the node is a table node, false otherwise.*/
export function isTableNodeFromGraphDef(node: NodeDef): boolean {
    return node && tableNodes.includes(node.type);
}

/** checks to see if the specified node is a debug node.
 *  @param node the node to test.
 *  @returns a boolean value, true if the node is a debug node, false otherwise.*/
 export function isDebugNodeFromGraphDef(node: NodeDef): boolean {
    return node && debugNodes.includes(node.type);
}

/** checks to see if the specified node is a chart node that supports the column editor.
 *  @param node the node to test.
 *  @returns a boolean value, true if the node is a chart node that supports the column editor, false otherwise.*/
export function isColumnEditorChartNodeFromGraphDef(node: NodeDef): boolean {
    return node && columnEditorChartNodes.includes(node.type);
}

/** checks to see if the specified node is a chart node that supports the metrics editor.
 *  @param node the node to test.
 *  @returns a boolean value, true if the node is a chart node that supports the metrics editor, false otherwise.*/
export function isMetricsEditorChartNodeFromGraphDef(node: NodeDef): boolean {
    return node && metricsEditorChartNodes.includes(node.type);
}

/** checks to see if the specified node is a chart node that supports the metric editor.
 *  @param node the node to test.
 *  @returns a boolean value, true if the node is a chart node that supports the metric editor, false otherwise.*/
export function isMetricEditorChartNodeFromGraphDef(node: NodeDef): boolean {
    return node && metricEditorChartNodes.includes(node.type);
}

/** checks to see if the specified node is a chart node that supports the size metric editor.
 *  @param node the node to test.
 *  @returns a boolean value, true if the node is a chart node that supports the size metric editor, false otherwise.*/
export function isSizeMetricEditorChartNodeFromGraphDef(node: NodeDef): boolean {
    return node && sizeMetricEditorChartNodes.includes(node.type);
}

/** checks to see if the specified node is a chart node that supports the color metric editor.
 *  @param node the node to test.
 *  @returns a boolean value, true if the node is a chart node that supports the color metric editor, false otherwise.*/
export function isColorMetricEditorChartNodeFromGraphDef(node: NodeDef): boolean {
    return node && colorMetricEditorChartNodes.includes(node.type);
}

/** checks to see if the specified node is a chart node that supports the x-axis metric editor.
 *  @param node the node to test.
 *  @returns a boolean value, true if the node is a chart node that supports the x-axis metric editor, false otherwise.*/
export function isXMetricEditorChartNodeFromGraphDef(node: NodeDef): boolean {
    return node && xMetricEditorChartNodes.includes(node.type);
}

/** checks to see if the specified node is a chart node that supports the y-axis metric editor.
 *  @param node the node to test.
 *  @returns a boolean value, true if the node is a chart node that supports the y-axis metric editor, false otherwise.*/
export function isYMetricEditorChartNodeFromGraphDef(node: NodeDef): boolean {
    return node && yMetricEditorChartNodes.includes(node.type);
}

/** checks to see if the specified node is a comment node.
 *  @param node the node to test.
 *  @returns a boolean value, true if the node is a comment node, false otherwise.*/
export function isCommentNodeFromGraphDef(node: NodeDef): boolean {
    return node && commentNodes.includes(node.type);
}

/** checks to see if the specified node is a note node.
 *  @param node the node to test.
 *  @returns a boolean value, true if the node is a note node, false otherwise.*/
export function isNoteNodeFromGraphDef(node: NodeDef): boolean {
    return node && noteNodes.includes(node.type);
}

/** checks to see if the specified node is a skeleton node.
 *  @param node the node to test.
 *  @returns a boolean value, true if the node is a note node, false otherwise.*/
export function isSkeletonNodeFromGraphDef(node: NodeDef): boolean {
    return node && skeletonNodes.includes(node.type);
}

/** checks to see if the specified node is a priority node.
 *  @param node the node to test.
 *  @returns a boolean value, true if the node is a priority node, false otherwise.*/
export function isPriorityNodeFromGraphDef(node: NodeDef): boolean {
    return node && priorityNodes.includes(node.type);
}

/** checks to see if the specified node is a data ocean node.
 *  @param node the node to test.
 *  @returns a boolean value, true if the node is a data ocean node, false otherwise.*/
export function isDataOceanNodeFromGraphDef(node: NodeDef): boolean {
    return node && dataOceanNodes.includes(node.type);
}

/** checks to see if the specified node is a logical node.
 *  @param node the node to test.
 *  @returns a boolean value, true if the node is a logical node, false otherwise.*/
export function isLogicalNodeFromGraphDef(node: NodeDef): boolean {
    return node && logicalNodes.includes(node.type);
}

/** checks to see if the specified node is a decision node.
 *  @param node the node to test.
 *  @returns a boolean value, true if the node is a decision node, false otherwise.*/
 export function isDecisionNodeFromGraphDef(node: NodeDef): boolean {
    return node && decisionNodes.includes(node.type);
}
/** checks to see if the specified node is an aggregate node.
 *  @param node the node to test.
 *  @returns a boolean value, true if the node is an aggregate node, false otherwise.*/
 export function isAggregatorNodeFromGraphDef(node: NodeDef): boolean {
    return node && aggregatorNodes.includes(node.type);
}

/** checks to see if the specified node is a data node.  Data nodes are nodes that produce
 *      data, such as a data ocean node or logical node.
 *  @param node the node to test.
 *  @returns a boolean value, true if the node is a data node, false otherwise.*/
export function isDataNodeFromGraphDef(node: NodeDef): boolean {
    return isDataOceanNodeFromGraphDef(node) || isLogicalNodeFromGraphDef(node) || 
        isAggregatorNodeFromGraphDef(node) || isDecisionNodeFromGraphDef(node) || isTransformNodeFromGraphDef(node);
}

/** checks to see if the specified node is a tag node.
 *  @param node the node to test.
 *  @returns a boolean value, true if the node is a tag node, false otherwise.*/
export function isTagNodeFromGraphDef(node: NodeDef): boolean {
    return node && tagNodes.includes(node.type);
}

/** checks to see if the specified node is an http node.
 *  @param node the node to test.
 *  @returns a boolean value, true if the node is an http node, false otherwise.*/
 export function isHttpNodeFromGraphDef(node: NodeDef): boolean {
    return node && httpNodes.includes(node.type);
}

/** checks to see if the specified node is a transform node.
 *  @param node the node to test.
 *  @returns a boolean value, true if the node is a transform node, false otherwise.*/
 export function isTransformNodeFromGraphDef(node: NodeDef): boolean {
    return node && transformNodes.includes(node.type);
}

/** checks to see if the specified node is a variables node.
 *  @param node the node to test.
 *  @returns a boolean value, true if the node is a variables node, false otherwise.*/
 export function isVariablesNodeFromGraphDef(node: NodeDef): boolean {
    return node && variableNodes.includes(node.type);
}

/** checks to see if the specified node is a set simple variables node.
 *  @param node the node to test.
 *  @returns a boolean value, true if the node is a set simple variables node, false otherwise.*/
export function isSetSimpleVariablesNodeFromGraphDef(node: NodeDef): boolean {
    return node && variableNodes[0] === node.type;
}

/** checks to see if the specified node is a set complex variable node.
 *  @param node the node to test.
 *  @returns a boolean value, true if the node is a set complex variable node, false otherwise.*/
export function isSetComplexVariableNodeFromGraphDef(node: NodeDef): boolean {
    return node && variableNodes[1] === node.type;
}


/** Checks to see if this node has a valid type.
 *  @param node the node to test.
 *  @returns a boolean value, true if the node is of a vlid type, false otherwise.*/
export function isValidTypeFromGraphDef(node: NodeDef): boolean {
    return isTagNodeFromGraphDef(node) || isDataOceanNodeFromGraphDef(node) || isLogicalNodeFromGraphDef(node) || 
        isPriorityNodeFromGraphDef(node) || isCommentNodeFromGraphDef(node) || isChartNodeFromGraphDef(node) || 
        isTriggerNodeFromGraphDef(node) || isAggregatorNodeFromGraphDef(node) || isDecisionNodeFromGraphDef(node) ||
        isHttpNodeFromGraphDef(node) || isTransformNodeFromGraphDef(node) || isVariablesNodeFromGraphDef(node) ||
        isNoteNodeFromGraphDef(node) || isSkeletonNodeFromGraphDef(node) || isSubflowNodeFromGraphDef(node) ||
        isSubflowOutputNodeFromGraphDef(node);
}

/** returns the children for the specified node.
 *  @param node the node whose children are requested.
 *  @param graphDef the GraphDef object with the nodes and edges.
 *  @returns an Array of NodeDefs that are the children of the specified node or an empty array. */
export function getChildrenFromGraphDef(node: NodeDef, graphDef: GraphDef): Array<NodeDef> {
    const children: Array<NodeDef> = [];
    if (node) {
        for (const edge of graphDef.edges) {
            if (edge.fromNode === node.id) {
                const retNode = getNodeFromGraphDef(edge.toNode, graphDef);

                if (retNode && !isSkeletonNodeFromGraphDef(retNode)) {
                    children.push(retNode);
                }
            }
        }
    }
    return children;
}

/** Returns the parents of a give node from the graph definition
 *  @param node given node object
 *  @param graphDef the graph definition object
 *  @returns the array of parent nodes or empty array. */
export function getParentsFromGraphDef(node: NodeDef, graphDef: GraphDef): Array<NodeDef> {
    const parents: Array<NodeDef> = [];
    if (node && graphDef.nodes && graphDef.nodes.length > 0) {
        for (const edge of graphDef.edges) {
            if (edge.toNode === node.id) {
                const retNode = getNodeFromGraphDef(edge.fromNode, graphDef);
                if (retNode && !isSkeletonNodeFromGraphDef(retNode)) {
                    parents.push(retNode);
                }
            }
        }
    }
    return parents;
}

/** returns the first parent with the specified type.
 *  @param node the node whose parents are requested.
 *  @param graphDef the GraphDef object with the nodes and edges.
 *  @param types the Array of string types to check. 
 *  @returns the first node of the specified type or null if none is found. */
export function getFirstParentOfTypeFromGraphDef(
    node: NodeDef, graphDef: GraphDef, types: Array<string>
): NodeDef | null {
    const parents: Array<NodeDef> | undefined = getParentsFromGraphDef(node, graphDef);
    if (parents && parents.length > 0) {
        for (const parent of parents) {
            if (types.includes(parent.type)) {
                return parent;
            }
        }
        for (const parent of parents) {
            const parentOfType = getFirstParentOfTypeFromGraphDef(parent, graphDef, types);
            if (parentOfType) {
                return parentOfType;
            }
        }
    }
    return null;
}

/** returns the first parent with the specified type.
 *  @param node the node whose parents are requested.
 *  @param graphDef the GraphDef object with the nodes and edges.
 *  @param types the Array of string types to check. 
 *  @returns the array of first nodes in each branch of the specified type or empty array if none is found. */
 export function getFirstParentOfTypeInEachBranchFromGraphDef(
    node: NodeDef, graphDef: GraphDef, types: Array<string>
): Array<NodeDef> {
    let parentsOfType: Array<NodeDef> = [];

    const parents: Array<NodeDef> | undefined = getParentsFromGraphDef(node, graphDef);
    if (parents && parents.length > 0) {
        for (const parent of parents) {
            if (types.includes(parent.type)) {
                parentsOfType.push(parent);
            } else {
                const newParents = getFirstParentOfTypeInEachBranchFromGraphDef(parent, graphDef, types);
                if (newParents.length > 0) {
                    parentsOfType = parentsOfType.concat(newParents);
                }
            }
        }
    }

    return parentsOfType;
}

/** generates a graph from the list of runbook nodes.
 *  @param graphDef the GraphDef object with the nodes and edges.
 *  @returns the Array of RunbookGraphNodeDefs that are the roots for the graph. */
export function generateGraphFromGraphDef(graphDef: GraphDef): Array<RunbookGraphNodeDef> {
    const roots: Array<RunbookGraphNodeDef> = [];

    if (graphDef.nodes && graphDef.nodes.length > 0) {
        const graphNodes: Array<RunbookGraphNodeDef> = [];
        for (const node of graphDef.nodes) {
            const nodeCopy: RunbookGraphNodeDef = JSON.parse(JSON.stringify(node));
            nodeCopy.parents = [];
            nodeCopy.children = [];
            graphNodes.push(nodeCopy);
        }

        const newGraphDef: GraphDef = JSON.parse(JSON.stringify(graphDef));
        newGraphDef.nodes = graphNodes;
        for (const node of graphNodes) {
            const parents = getParentsFromGraphDef(node, newGraphDef);
            if (parents.length === 0) {
                // If there are no parents it is a root of the graph
                roots.push(node);
            }
            node.parents = (parents as Array<RunbookGraphNodeDef>);

            const children = getChildrenFromGraphDef(node, newGraphDef);
            node.children = (children as Array<RunbookGraphNodeDef>);
        }
    }

    return roots;
}

/** checks the graph to see if it is cyclic.
 *  @param roots the roots of the graph.
 *  @returns a boolean true if the graph is cyclic, false otherwise. */
export function isGraphCyclicFromGraphDef(roots: Array<RunbookGraphNodeDef>): boolean {
    for (const rootNode of roots) {
        const traversedNodeIds: Array<string> = [];
        traversedNodeIds.push(rootNode.id);
        const children = rootNode.children;
        if (children.length > 0) {
            const isCyclicGraph = isCyclicFromGraphDef(children, traversedNodeIds);
            if (isCyclicGraph) {
                return true;
            }
        }
    }
    return false;
}

/** checks a subtree of the graph to see if it is cyclic.
 *  @param nodes the nodes to check.
 *  @param traversedNodeIds the list of string ids that have already been traversed.  This is used
 *      to detect any cyclick dependencies.
 *  @returns a boolean true if the subtree is cyclic, false otherwise. */
export function isCyclicFromGraphDef(nodes: Array<RunbookGraphNodeDef>, traversedNodeIds: Array<string>): boolean {
    for (const node of nodes) {
        if (traversedNodeIds.includes(node.id)) {
            return true;
        }
        traversedNodeIds = [node.id].concat(traversedNodeIds);
        const children = node.children;
        if (children.length > 0) {
            const isCyclicGraph = isCyclicFromGraphDef(children, traversedNodeIds);
            if (isCyclicGraph) {
                return true;
            }
        }
    }
    return false;
}

/** returns the value of the property with the specified key from the node properties object.  Note that 
 *  the reason this function is needed is because the properies in the NodeDef are not a dictionary, instead
 *  they have the format {key: keyData, value: valueData}
 *  @param node the NodeDef with the properties.
 *  @param key a String with the key of the property value that should be returned.
 *  @returns the property value. */
export function getProperty(node: NodeDef, key: string): any {
    for (const prop of node.properties || []) {
        if (prop.key === key) {
            return prop.value;
        }
    }
    return undefined;
}

/** returns a dictionary with the node properties.   Note that 
 *  the reason this function is needed is because the properies in the NodeDef are not a dictionary, instead
 *  they have the format {key: keyData, value: valueData}
 *  @param node the NodeDef with the properties.
 *  @returns the dictionary with the node properties. */
export function getProperties(node: NodeDef): Record<string, any> {
    const properties = {};
    for (const prop of node.properties || []) {
        properties[prop.key] = prop.value;
    }
    return properties;
}

/** returns the ids of the trigger metrics.
 *  @param triggerType the type of trigger as defined by the InputType enumeration.
 *  @param displayedMetrics any metrics currently displayed, these will be listed as available even if they are not supported.
 *  @param dataOceanMetaData the DataOceanMetaData object with the full metadata for the data ocean.
 *  @param availableDataSources the array of dataSourceTypeOptions with the data sources to check.
 *  @param included a boolean value, which if true specifies that the included metrics should be returned and if false
 *      specifies the excluded metrics should be returned.
 *  @returns an Array of Strings with the trigger metric ids. */
export function getTriggerMetricIds(
    triggerType: InputType, displayedMetrics: string[], dataOceanMetadata: DataOceanMetadata, availableDataSources: dataSourceTypeOptions[],
    included: boolean = true
): Array<string> {
    let triggerMetricIds: Array<string> = [];
    switch (triggerType) {
        case InputType.DEVICE:
            triggerMetricIds = ["device_status", "device_up_time"];
            break;
        case InputType.INTERFACE:
            triggerMetricIds = [
                "iface_status", "in_utilization", "out_utilization", "out_packet_error_rate", 
                "out_packet_drops_rate", "in_packet_error_rate", "in_packet_drops_rate"
            ];
            break;
        case InputType.APPLICATION_LOCATION:
            triggerMetricIds = [
                "throughput", "mos", "response_time", 
                "connections_failed_percent", "user_response_time", "activity_network_time", "page_load_network_time",
                "retrans_percent"
            ];
            break;
        case InputType.LOCATION:
            triggerMetricIds = [
                "throughput", "mos", "response_time", 
                "connections_failed_percent", "user_response_time", "activity_network_time", "page_load_network_time",
                "retrans_percent"
            ];
            break;
        case InputType.APPLICATION:
            triggerMetricIds = [
                "throughput", "mos", "response_time", 
                "connections_failed_percent", "user_response_time", "activity_network_time", "page_load_network_time",
                "retrans_percent"
            ];
            break;
    }

    // Filter out the trigger metrics that do not apply to this environment
    triggerMetricIds = triggerMetricIds.filter((metricId) => {
        const metricDef: DataOceanMetric = dataOceanMetadata.metrics[metricId];
        const supportedEnvs = metricDef.supported_envs || ["dev", "staging", "prod"];
        if (metricDef && (
            (
                metricSupportsDataSources(metricDef, availableDataSources) && supportedEnvs.includes(ENV) && 
                !metricDef.deprecated
            ) || 
            displayedMetrics.includes(metricDef.id)
        )) {
            return included;
        } else {
            return !included;
        }

    });

    return triggerMetricIds;
}

/** returns whether or not the specified metric supports the specified data sources.
 *  @param metric the DataOceanMetric to check.
 *  @param availableDataSources the array of dataSourceTypeOptions with the data sources to check.
 *  @returns a boolean value, true if the specfied metric supports the specified data sources. */
function metricSupportsDataSources(metric: DataOceanMetric, availableDataSources: dataSourceTypeOptions[]): boolean {
    if (metric.supported_data_sources?.length && availableDataSources?.length) {
        for (const dataSource of availableDataSources) {
            if (metric.supported_data_sources.includes(dataSource)) {
                return true;
            }
        }

    }
    return false;
}

/** returns the string where variables replced by values.
 *  @param text string
 *  @param variables PrimitiveVariable
 *  @returns string */
export function formatVariables(text: string, variables: PrimitiveVariable[] | undefined | null): string {
    if (variables) {
        if (variables.length > 0) {
            variables.map(element => {
                const reg = new RegExp(`{{\\s*variables\\["${element.name}"\\]\\s*}}`, 'g');
                return (text = text?.replace("&quot;", '"').replace(reg, (element.value ?
                    element.value :
                    (element.defaultValue ? element.defaultValue : '?'))));
            });
        }
    }
    return text;
}

/** returns an object with two arrays (built in subflows and non built in subflows), each array containing the alphabetically ordered subflows
 *  @param subflows an array containing the subflow nodes
 *  @returns object */
export function subflowOrderingInNodePalette(subflows: Array<RunbookNode>) {
    const reorderedSubflows: { builtInSubflows: Array<RunbookNode>, nonBuiltInSubflows: Array<RunbookNode> } = {
        builtInSubflows: [],
        nonBuiltInSubflows: []
    };
    for (const subflow of subflows) {
        if (subflow.builtIn) {
            reorderedSubflows.builtInSubflows.push(subflow);
        } else {
            reorderedSubflows.nonBuiltInSubflows.push(subflow);
        }
    }
    reorderedSubflows.builtInSubflows.sort((a: any, b: any) => {
        if (a.name && b.name) {
            return a.name.localeCompare(b.name);
        }
        return a;
    });
    reorderedSubflows.nonBuiltInSubflows.sort((a: any, b: any) => {
        if (a.name && b.name) {
            return a.name.localeCompare(b.name);
        }
        return a;
    });
    return reorderedSubflows;
}


/** This function deduplications the subflows such that the same subflow of multiple versions does not show up
 *      multiple times.
 *  @param subflows the full list of subflows.
 *  @returns a deduplicated list of subflows.*/
export function deduplicateSubflows(subflows: RunbookNode[]): RunbookNode[] {
    let newSubflows: RunbookNode[] = [];

    if (subflows?.length) {
        const seriesSubflow: {[x: string]: RunbookNode} = {};
        subflows.forEach(subflow => {
            if (!subflow.seriesId) {
                newSubflows.push(subflow);
            } else {
                if (!seriesSubflow[subflow.seriesId] || Version.parse(subflow.version || "").isGreaterThan(Version.parse(seriesSubflow[subflow.seriesId].version || ""))) {
                    seriesSubflow[subflow.seriesId] = subflow;
                }
            }
        });
        newSubflows = newSubflows.concat(Object.values(seriesSubflow));
    }
    
    return newSubflows
}
