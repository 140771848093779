//ALL THE MODAL COMPONENTS
import { InviteUserModal, EditDomainsModal, EditUserModal } from 'components/modals';
import { AddNotesModal } from 'components/modals/AddNotesModal';
import EdgeConfigModal from 'components/modals/administration/EdgeConfig';
import CloudIMDataSourceConfigModal from 'components/modals/administration/CloudIMDataSource/CloudIMDataSourceConfigModal';
import AddClientSecretModal from 'components/modals/api-access/AddClientSecretModal';
import GenerateClientSecretsStepOneModal from 'components/modals/api-access/GenerateClientSecretsStepOneModal';
import GenerateClientSecretsStepTwoModal from 'components/modals/api-access/GenerateClientSecretsStepTwoModal';
import ManageClientSecretsModal from 'components/modals/api-access/ManageClientSecretsModal';
import DeleteCustomPropertiesModal from 'components/modals/custom-properties/DeleteCustomPropertiesModal';
import AddCustomPropertyModal from 'components/modals/explorer/custom-properties/AddCustomPropertyModal';
import ImportCustomPropertiesModal from 'components/modals/explorer/custom-properties/ImportCustomPropertiesModal';
import SetCustomPropertiesModal from 'components/modals/explorer/custom-properties/SetCustomPropertiesModal';
import { DeleteRunbookOutputsModal } from 'components/modals/DeleteRunbookOutputsModal/DeleteRunbookOutputsModal';
import TimelineModal from 'components/modals/timeline';

const MODALS: { [index: string]: any } = {
    inviteUserModal: InviteUserModal,
    editDomainsModal: EditDomainsModal,
    editUserModal: EditUserModal,
    addNotesModal: AddNotesModal,
    // Client Secrets
    generateClientSecretsStepOneModal: GenerateClientSecretsStepOneModal,
    generateClientSecretsStepTwoModal: GenerateClientSecretsStepTwoModal,
    addClientSecretModal: AddClientSecretModal,
    manageClientSecretsModal: ManageClientSecretsModal,
    // Custom Properties
    deleteCustomPropertiesModal: DeleteCustomPropertiesModal,
    addCustomPropertyModal: AddCustomPropertyModal,
    importCustomPropertiesModal: ImportCustomPropertiesModal,
    setCustomPropertiesModal: SetCustomPropertiesModal,
    // Administration
    edgeConfigModal: EdgeConfigModal,
    cloudIMDataSourceModal: CloudIMDataSourceConfigModal,
    // Runbook Analyses
    deleteRunbookOutput: DeleteRunbookOutputsModal,
    // Timeline Modal
    timelineModal: TimelineModal,
};

export { MODALS };
