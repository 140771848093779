/** This module creates a new edge for the react-flow graph that represents a NetIM edge.
 *  @module
 */
import React from "react";
import { useHistory } from "react-router";
import { STRINGS } from 'app-strings';
import { IconName, Menu, MenuDivider, MenuItem } from "@blueprintjs/core";
import { ContextMenu2, ContextMenu2ChildrenProps } from "@blueprintjs/popover2";
import { Icon, IconNames } from "@tir-ui/react-components";
import { IconNames as BPIcons } from "@blueprintjs/icons"
import { getURL } from 'utils/hooks/useQueryParams';
import { getURLPath } from "config";
import { SEARCH_TYPE } from "pages/incident-search/IncidentSearchPage";
import { FACET_FIELDS } from "utils/services/SearchApiService";
import { EdgeProps, EdgeText, getBezierPath, getEdgeCenter } from "react-flow-renderer";

import './NetIMEdge.scss';

export default function NetIMEdge({ id, sourceX, sourceY, targetX, targetY, sourcePosition, targetPosition, style, data }: EdgeProps) {
    const history = useHistory();

    const edgePath = getBezierPath({
        sourceX,
        sourceY,
        sourcePosition,
        targetX,
        targetY,
        targetPosition
    });

    const [centerX, centerY] = getEdgeCenter({
        sourceX,
        sourceY,
        targetX,
        targetY
    })

    return (
        <>
            <path
                id={id}
                className="react-flow__edge-path"
                d={edgePath}
                style={style}
            />
            {data?.name && <ContextMenu2
                content={
                    <Menu>
                        <MenuDivider title={<span>
                            <Icon className="mr-1" icon={IconNames.GROUP_OBJECTS} />
                            <div className="d-inline-block">{data.name}</div>
                        </span>} />
                        <MenuItem icon={BPIcons.AREA_OF_INTEREST as IconName} text={STRINGS.cloudim.topology.contextMenu.explorerView} onClick={() => {
                            history.push(
                                getURL(getURLPath('explorer'), {
                                    searchType: SEARCH_TYPE.interface,
                                    facets: {
                                        [FACET_FIELDS.NAME]: [data.name]
                                    },
                                }, { replaceQueryParams: true })
                            );
                        }} />
                        <MenuDivider />
                        <MenuItem icon={IconNames.DUPLICATE} text={STRINGS.cloudim.topology.contextMenu.copy} onClick={() => {
                            navigator.clipboard.writeText(data.name);
                        }} />
                    </Menu>
                }
            >
                {(ctxMenuProps: ContextMenu2ChildrenProps) => (
                    <>
                        <EdgeText
                            x={centerX}
                            y={centerY}
                            className="netimEdgeText"
                            label={data.name}
                            labelShowBg={true}
                            labelBgPadding={[4, 4]}
                            labelBgBorderRadius={2}
                            onContextMenu={(e) => { ctxMenuProps.onContextMenu(e as React.MouseEvent<Element, MouseEvent> as React.MouseEvent<HTMLElement, MouseEvent>) }}
                        />
                        {ctxMenuProps.popover}
                    </>
                )}
            </ContextMenu2>}
        </>
    );
}
