import React, { useRef, useState } from 'react';
import moment from 'moment';
import { useMutation } from '@apollo/client';
import {
    Button,
    ButtonGroup,
    Icon,
    Intent,
    Menu,
    MenuItem,
    Position,
    Switch,
} from '@blueprintjs/core';
import { Popover2, Popover2InteractionKind } from '@blueprintjs/popover2';
import {
    Table,
    IconNames,
    TableColumnDef,
    ErrorToaster,
    SuccessToaster,
    CheckboxColumnFilterControl,
} from '@tir-ui/react-components';
import { loader } from 'graphql.macro';
import { Query } from 'reporting-infrastructure/types/Query';
import { useQuery } from 'utils/hooks';
import { getURLPath } from 'config';
import { EditMappingModal } from 'pages/mapping-configuration/modals/EditMappingModal';
import { AddMappingModal } from 'pages/mapping-configuration/modals/AddMappingModal';
import { DataLoadFacade } from 'components/reporting/data-load-facade/DataLoadFacade';
import { BasicDialog, DialogState, updateDialogState } from 'components/common/basic-dialog/BasicDialog';
import { Variant } from 'components/common/graph/types/GraphTypes';
import { MappingConfig } from 'utils/services/MappingConfigApiService';
import { MetricMiniCard } from 'components/common/layout/metric-mini-card/MetricMiniCard';
import { CardsHolder } from 'components/common/layout/cards-holder/CardsHolder';
import { CATEGORIES, CATEGORY_TO_TRIGGER_MAP, EVENT_CATEGORY_INDEX } from 'pages/incident-list/Incident.type';
import { uniqBy, countBy } from 'lodash';
import { useViewCollection } from 'components/common/layout/view-collection/useViewCollection';
import { ViewCollection } from 'components/common/layout/view-collection/ViewCollection';
import { OneColumnContainer } from 'components/common/layout/containers/one-column-container/OneColumnContainer';
import { WrapInTooltip } from 'components/common/wrap-in-tooltip/WrapInTooltip';
import { useAppInsightsContext } from '@microsoft/applicationinsights-react-js';
import { EventNames, trackEvent } from 'utils/appinsights';
import { AuthServiceProvider } from 'utils/providers/AuthServiceProvider';
import {
    MappingDecisionBranchView,
    convertConditionTreeToDALFormat,
    convertExpressionToTreeFormat,
} from './decision-branch/MappingDecisionBranchView';
import { TRIGGER_TYPE_MAP } from 'pages/riverbed-advisor/views/runbook-view/Runbook.type';
import { getPropFreeConditionTree } from 'components/common/condition-tree-builder/condition/ConditionUtils';

import { STRINGS } from 'app-strings';

import './MappingConfigurationView.scss';

// Table columns
const getColumns = (envIsSupported): Array<TableColumnDef> => {
    return [
        ...(envIsSupported ? [{
            id: 'order',
            Header: STRINGS.MAPPING_CONFIGURATION_PAGE.columns.order,
            accessor: 'order',
            headerClassName: 'text-nowrap',
        },
        {
            id: 'name',
            Header: STRINGS.MAPPING_CONFIGURATION_PAGE.columns.automationName,
            accessor: 'name',
            showFilter: true,
            headerClassName: 'text-nowrap',
            formatter: row => row.name_ui
        }] : []),
        {
            id: 'description',
            Header: STRINGS.MAPPING_CONFIGURATION_PAGE.columns.description,
            accessor: 'description',
            showFilter: true,
            headerClassName: 'text-nowrap',
        },
        {
            id: 'runbook',
            Header: STRINGS.MAPPING_CONFIGURATION_PAGE.columns.runbookName,
            accessor: 'runbook_name',
            showFilter: true,
            headerClassName: 'text-nowrap',
            formatter: row => row.runbook_name_ui,
        },
        {
            id: 'last_updated_by',
            Header: STRINGS.MAPPING_CONFIGURATION_PAGE.columns.lastUpdatedBy,
            accessor: 'last_updated_by',
            showFilter: true,
            headerClassName: 'text-nowrap',
        },
        {
            id: 'last_updated_on',
            Header: STRINGS.MAPPING_CONFIGURATION_PAGE.columns.lastUpdatedOn,
            accessor: 'last_updated_on',
            headerClassName: 'text-nowrap',
            style: { minWidth: '150px' },
        },
        {
            id: 'last_matched',
            Header: STRINGS.MAPPING_CONFIGURATION_PAGE.columns.lastMatched,
            accessor: 'last_matched',
            headerClassName: 'text-nowrap',
            style: { minWidth: '150px' },
        },
        {
            id: 'enabled_automation',
            Header: STRINGS.MAPPING_CONFIGURATION_PAGE.columns.enableAutomation,
            accessor: 'enabled_automation',
            headerClassName: 'text-nowrap',
            showFilter: true,
            className: 'text-center',
            filterControl: CheckboxColumnFilterControl,
            formatter: row => row.enabled_automation_ui
        },
        ...(envIsSupported ? [{
            id: 'action',
            Header: '',
            accessor: 'action',
        }] : []),
        {
            id: 'more',
            Header: '',
            accessor: 'more',
        },
    ];
};

export enum TriggerTypes {
    INCIDENT = 'incident',
    LIFECYCLE = 'lifecycle',
    EXTERNAL = 'external',
}

// shape of mutation
interface EditMappingMutationInput {
    mapping: {
        id: string;
        name: string;
        runbookId: string;
        description: string;
        enabled: boolean;
    },
    expression?: {}
}

type MappingCondition = {
    condition: {
        id: string;
        category: string;
        key: string;
        operation: string;
        value: string;
    };
};

// shape of create/duplicate mutation
interface EditMappingCreateMutationInput {
    mapping: {
        name: string;
        runbookId: string;
        triggerType: string;
        description: string;
        enabled: boolean;
        order: number;
    };
    expression?: {
        block: {
            operator: string;
            expressions: Array<MappingCondition>;
        };
    };
}
// shape of delete mutation
interface EditMappingDeleteMutationInput {
    ids: string[];
}
// shape of order mutation
interface orderMappingMutationInput {
    id: string;
    order: number;
}

// New component starts
const MappingConfigurationView = (): JSX.Element => {
    const [dialogState, setDialogState] = useState<any>({
        showDialog: false,
        title: 'Mapping Dialog',
        loading: false,
        dialogContent: null,
        dialogFooter: null,
    });
    const appInsightsContext = useAppInsightsContext();
    const AuthService = AuthServiceProvider.getService();
    const { ENV } = window['runConfig'] ? window['runConfig'] : { ENV: '' };
    const SUPPORTED_ENVS = ['dev', 'staging', 'prod']; // Available options 'staging', 'prod', 'LOCALHOST_TENANT'
    const envIsSupported = SUPPORTED_ENVS.includes(ENV);

    // graphql data query
    const { loading, data, error, run } = useQuery({
        query: new Query(loader('./../automation.graphql')),
        name: 'automation',
    });

    // graphql data mutation (enable/disable)
    const [setAutomationMapping] = useMutation<any, EditMappingMutationInput>(
        loader('./../automation-mutation.graphql'),
        {
            onCompleted: (data) => {
                SuccessToaster({
                    message:
                        STRINGS.MAPPING_CONFIGURATION_PAGE.messages
                            .mappingUpdated,
                });
            },
            onError: (err) => {
                ErrorToaster({
                    message:
                        STRINGS.MAPPING_CONFIGURATION_PAGE.messages
                            .mappingUpdateFailed,
                });
                console.error(err?.message);
            },
        }
    );

    // graphql data mutation (create/duplicate)
    const [createAutomationMapping] = useMutation<any, EditMappingCreateMutationInput>(
        loader('./../automation-create-mutation.graphql'), {
            onCompleted: (data) => {
                setTimeout(()=> {
                    SuccessToaster({
                        message: STRINGS.MAPPING_CONFIGURATION_PAGE.messages.mappingCreated,
                    });
                }, 2000);
                run({ noCache: true });
            },
            onError: (err) => {
                ErrorToaster({
					message: err.message === STRINGS.MAPPING_CONFIGURATION_PAGE.messages.mappingCreatedFailedDupMessage ? STRINGS.MAPPING_CONFIGURATION_PAGE.messages.mappingCreatedFailedDup : STRINGS.MAPPING_CONFIGURATION_PAGE.messages.mappingCreatedFailed,
                });
                console.error(err?.message);
            },
            refetchQueries: ['automation'],
        });

    // graphql data mutation (delete)
    const [deleteAutomationMappings] = useMutation<
        any,
        EditMappingDeleteMutationInput
    >(loader('./../automation-delete-mutation.graphql'), {
        onCompleted: () => {
            setTimeout(() => {
                SuccessToaster({
                    message:
                        STRINGS.MAPPING_CONFIGURATION_PAGE.messages.mappingDeleted,
                });
            }, 2000);
        },
        onError: (err) => {
            ErrorToaster({
                message:
                    STRINGS.MAPPING_CONFIGURATION_PAGE.messages
                        .mappingDeletedFailed,
            });
            console.error(err?.message);
        },
        refetchQueries: ['automation'],
    });

    // graphql data mutation (order)
    const [setAutomationMappingOrder] = useMutation<
        any,
        orderMappingMutationInput
    >(loader('./../automation-order-mutation.graphql'), {
        onCompleted: () => {
            setTimeout(() => {
                SuccessToaster({
                    message:
                        STRINGS.MAPPING_CONFIGURATION_PAGE.messages.mappingOrdered,
                });
            }, 2000);
            run({ noCache: true });
        },
        onError: (err) => {
            ErrorToaster({
                message:
                    STRINGS.MAPPING_CONFIGURATION_PAGE.messages
                        .mappingOrderFailed,
            });
            console.error(err?.message);
        },
        refetchQueries: ['automation'],
    });

    // SubPage navigation
    const { activeView, updateView, vars } = useViewCollection({
        defaultActiveView: 'categories',
        viewURLKey: 'view',
        varToQueryParamMap: {
            triggerCategory: 'cat',
            triggerType: 'trigger',
        },
    });

    // Creating a Row of cards
    const triggersRow = (category: Array<string>, categoryType): Array<any> => {
        const rowData: any[] = data
            ? data['automationMappings'].filter((trigger) =>
                category.includes(trigger.triggerType)
            )
            : [];
        rowData.sort((a, b) => {
            const indexA: number = EVENT_CATEGORY_INDEX[a.triggerType];
            const indexB: number = EVENT_CATEGORY_INDEX[b.triggerType];
            return indexA < indexB ? -1 : indexA > indexB ? 1 : 0;
        });
        return uniqBy(rowData, 'triggerType').map((item, i) => (
            <MetricMiniCard
                key={item.id}
                value={countBy(rowData, 'triggerType')[item.triggerType]}
                title={
                    STRINGS.MAPPING_CONFIGURATION_PAGE.subTypes[
                    item.triggerType
                    ]
                }
                onClick={() =>
                    updateView('triggers', {
                        triggerCategory: categoryType,
                        triggerType: item.triggerType,
                    })
                }
            />
        ));
    };

    // Creating a list of Rows (with some extras)
    const buildCategories = CATEGORIES.map((categoryType, i) => (
        <div
            key={'card-row-' + i}
            className="runbook-card-row"
            aria-label="runbook-card-row"
        >
            <div className="display-8 font-weight-bold d-inline-block mt-3">
                <span>{`${categoryType === 'INCIDENT'
                    ? ' NEW'
                    : categoryType === 'LIFECYCLE'
                        ? 'INCIDENT'
                        : ''
                    } ${categoryType} TRIGGERS`}</span>
                <span className="pl-2">{`(${triggersRow(
                    CATEGORY_TO_TRIGGER_MAP[categoryType],
                    ''
                )
                    .map((item) => item.props.value)
                    .reduce((a, b) => a + b, 0)})`}</span>
            </div>
            <hr />
            <div className="display-9 pb-4 w-max-8">
                {
                    STRINGS.MAPPING_CONFIGURATION_PAGE.categoryDescription[
                    categoryType
                    ]
                }
            </div>
            <CardsHolder className="mb-4">
                {triggersRow(
                    CATEGORY_TO_TRIGGER_MAP[categoryType],
                    categoryType
                )}
                {envIsSupported && <MetricMiniCard
                    className="hollow"
                    info={`Add automation for ${categoryType} triggers`}
                    value=""
                    onClick={() => handleAddMappingOpen(categoryType)}
                >
                    <div>
                        <Icon icon={IconNames.PLUS} />
                    </div>
                </MetricMiniCard>}
            </CardsHolder>
        </div>
    ));

    // Modal to enable automation
    const enableMapping = async (mapping: MappingConfig): Promise<void> => {
        const newDialogState: any = {
            showDialog: true,
            loading: false,
            title: STRINGS.MAPPING_CONFIGURATION_PAGE.enableMappingModal.title,
        };
        newDialogState.dialogContent = (
            <div className="mb-3">
                <span>
                    {!mapping.enabled
                        ? STRINGS.MAPPING_CONFIGURATION_PAGE.enableMappingModal
                            .activateBodyText
                        : STRINGS.MAPPING_CONFIGURATION_PAGE.enableMappingModal
                            .activateDisabledBodyText}
                </span>
            </div>
        );
        newDialogState.dialogFooter = (
            <>
                <Button
                    active={true}
                    outlined={true}
                    text={STRINGS.runbooks.okBtnText}
                    onClick={async (evt) => {
                        try {
                            setDialogState(
                                updateDialogState(
                                    newDialogState,
                                    true,
                                    true,
                                    []
                                )
                            );
                            await setAutomationMapping({
                                variables: {
                                    mapping: {
                                        id: mapping.id,
                                        name: mapping.name || 'Generic Name',
                                        runbookId: mapping.runbookId,
                                        description: mapping.description,
                                        enabled: !mapping.enabled,
                                    },
                                    ...(mapping.expression && {expression: convertConditionTreeToDALFormat(
                                        getPropFreeConditionTree(JSON.parse(mapping.expression))
                                    )})
                                },
                            });
                            setDialogState(
                                updateDialogState(
                                    newDialogState,
                                    false,
                                    false,
                                    []
                                )
                            );
                        } catch (error) {
                            const errorMsg =
                                (error as any)?.response?.status === 412
                                    ? STRINGS.formatString(STRINGS.runbooks.activateDialog.etagErrorText, {variant: STRINGS.runbooks.runbookTextForVariant[Variant.INCIDENT]})
                                    : STRINGS.formatString(STRINGS.runbooks.activateDialog.generalErrorText, {variant: STRINGS.runbooks.runbookTextForVariant[Variant.INCIDENT]});
                            setDialogState(
                                updateDialogState(newDialogState, true, false, [
                                    errorMsg,
                                ])
                            );
                        }
                    }}
                />
                <Button
                    active={true}
                    outlined={true}
                    text={STRINGS.runbooks.cancelBtnText}
                    onClick={async (evt) =>
                        setDialogState(
                            updateDialogState(newDialogState, false, false, [])
                        )
                    }
                />
            </>
        );
        setDialogState(newDialogState);
    };

    // Modal to delete automation
    const deleteMapping = async (mapping: MappingConfig): Promise<void> => {
        const newDialogState: any = {
            showDialog: true,
            loading: false,
            title: STRINGS.MAPPING_CONFIGURATION_PAGE.deleteMappingModal.title,
        };
        newDialogState.dialogContent = (
            <div className="mb-3">
                <span>
                    {
                        STRINGS.MAPPING_CONFIGURATION_PAGE.deleteMappingModal
                            .contentText
                    }
                </span>
            </div>
        );
        newDialogState.dialogFooter = (
            <>
                <Button
                    active={true}
                    outlined={true}
                    text={STRINGS.runbooks.okBtnText}
                    onClick={async (evt) => {
                        try {
                            setDialogState(
                                updateDialogState(
                                    newDialogState,
                                    true,
                                    true,
                                    []
                                )
                            );
                            await deleteAutomationMappings({
                                variables: {
                                    ids: [mapping.id],
                                },
                                notifyOnNetworkStatusChange: true,
                            });
                            setDialogState(
                                updateDialogState(
                                    newDialogState,
                                    false,
                                    false,
                                    []
                                )
                            );
                        } catch (error) {
                            setDialogState(
                                updateDialogState(newDialogState, true, false, [
                                    STRINGS.MAPPING_CONFIGURATION_PAGE
                                        .deleteMappingModal.errorMsg,
                                ])
                            );
                        }
                        if (appInsightsContext) {
                            const properties = {
                                name: EventNames.AUTOMATION_MAPPING_ACTION,
                                properties: {
                                    topic: STRINGS.MAPPING_CONFIGURATION_PAGE
                                        .actions.delete,
                                },
                            };
                            trackEvent(
                                appInsightsContext,
                                AuthService,
                                properties
                            );
                        }
                    }}
                />
                <Button
                    active={true}
                    outlined={true}
                    text={STRINGS.runbooks.cancelBtnText}
                    onClick={async (evt) =>
                        setDialogState(
                            updateDialogState(newDialogState, false, false, [])
                        )
                    }
                />
            </>
        );
        setDialogState(newDialogState);
    };

    // Modal to set specific order number
    const DialogContent = function (props) {
        const [order, setOrder] = useState(1);
        return (
            <div className="mb-3">
                <div className="mb-2">{STRINGS.MAPPING_CONFIGURATION_PAGE.orderMappingModal.contentText}</div>
                <input id="new-order" className="bp3-input bp3-fill w-25"
                    type="number"
                    min="1"
                    max={props.itemsAmount}
                    defaultValue={props.row.order}
                    onChange={(e) => {
                        newDialogState!.dialogFooter = <DialogFooter
                            itemsAmount={props.itemsAmount}
                            row={props.row}
                            order={Number(e.target.value)} />
                        setDialogState(updateDialogState(newDialogState, true, false, []));
                        setOrder(Number(e.target.value));
                    }}
                    onKeyPress={(e) =>
                        !/[0-9]/.test(e.key) && e.preventDefault()
                    }
                />
                {((order > props.itemsAmount) || order === 0) &&
                    <div className="text-danger display-9 pt-1">
                        {STRINGS.formatString(
							STRINGS.MAPPING_CONFIGURATION_PAGE.orderMappingModal
								.validationErrorMsg,
                            props.itemsAmount
						)}
                    </div>}
            </div>
        )
    }

    let newDialogState:DialogState | undefined;
    const DialogFooter = function (props) {
        return (
            <>
                <Button
                    active={true}
                    outlined={true}
                    disabled={(props.order > props.itemsAmount) || props.order === 0}
                    text={STRINGS.runbooks.okBtnText}
                    onClick={async () => {
                        try {
                            setDialogState(updateDialogState(newDialogState, true, true, []));
                            await setAutomationMappingOrder({
                                variables: {
                                    id: props.row.id,
                                    order: Number(props.order),
                                },
                                notifyOnNetworkStatusChange: true,
                            });
                            setDialogState(updateDialogState(newDialogState, false, false, []));
                        } catch (error) {
                            setDialogState(updateDialogState(newDialogState, true, false, [
                                STRINGS.MAPPING_CONFIGURATION_PAGE
                                    .orderMappingModal.errorMsg,
                            ])
                            );
                        }
                        if (appInsightsContext) {
                            const properties = {
                                name: EventNames.AUTOMATION_MAPPING_ACTION,
                                properties: {
                                    topic: STRINGS.MAPPING_CONFIGURATION_PAGE
                                        .actions.reOrder,
                                },
                            };
                            trackEvent(
                                appInsightsContext,
                                AuthService,
                                properties
                            );
                        }
                    }}
                />
                <Button
                    active={true}
                    outlined={true}
                    text={STRINGS.runbooks.cancelBtnText}
                    onClick={async () =>
                        setDialogState(updateDialogState(newDialogState, false, false, []))
                    }
                />
            </>
        )
    }
    const orderMapping = async (row: MappingConfig, itemsAmount: number): Promise<void> => {
        newDialogState = { showDialog: true, loading: false, title: STRINGS.MAPPING_CONFIGURATION_PAGE.orderMappingModal.title } as DialogState;
        newDialogState.dialogContent = <DialogContent itemsAmount={itemsAmount} row={row} />
        newDialogState.dialogFooter = <DialogFooter itemsAmount={itemsAmount} row={row} order={1} />
        setDialogState(newDialogState);
    };

    // Modal to edit mapping
    const editMappingModal = useRef();
    const handleMappingEdit = (mapping: MappingConfig) => {
        if (envIsSupported) {
            if (addMappingModal.current) {
                // @ts-ignore
                addMappingModal.current.setEdit(true);
                // @ts-ignore
                addMappingModal.current.setMapping(mapping);
                // @ts-ignore
                addMappingModal.current.setMappingData(data['automationMappings']);
                // @ts-ignore
                addMappingModal.current.handleOpen();
            }
        } else {
            if (editMappingModal.current) {
                // @ts-ignore
                editMappingModal.current.setMapping(mapping);
                // @ts-ignore
                editMappingModal.current.handleOpen();
            }
        }
    };

    // Modal to create mapping
    const addMappingModal = useRef();
    const handleAddMappingOpen = (triggerType: string, trigger: string = '') => {
        if (addMappingModal.current) {
            // @ts-ignore
            addMappingModal.current.setEdit(false);
            if (trigger) {
                // @ts-ignore
                addMappingModal.current.setTrigger(trigger);
            }
            // @ts-ignore
            addMappingModal.current.setTriggerType(triggerType);
            // @ts-ignore
            addMappingModal.current.setMappingData(data['automationMappings']);
            // @ts-ignore
            addMappingModal.current.handleOpen();
        }
    };

    // Table 'more' ... items
    const getMoreMenuItems = (item: MappingConfig, itemsAmount: number): Array<JSX.Element> => {
        return [
            <MenuItem
                text={envIsSupported ? STRINGS.MAPPING_CONFIGURATION_PAGE.actions.edit : 'Edit Mapping'}
                active={false}
                key={'edit'}
                onClick={() => handleMappingEdit(item)}
            />,
            ...(envIsSupported ? [
                <MenuItem
                    text={STRINGS.MAPPING_CONFIGURATION_PAGE.actions.duplicate}
                    active={false}
                    key={'duplicate'}
                    onClick={async (evt) => {
                        try {
                            await createAutomationMapping({
                                variables: {
                                    mapping: {
                                        name: STRINGS.formatString(STRINGS.MAPPING_CONFIGURATION_PAGE.duplicatePrefix, `${item.name}`),
                                        runbookId: item.runbookId,
                                        triggerType: item.triggerType,
                                        description: item.description,
                                        enabled: item.enabled,
                                        order: item.order || 1,
                                    },
                                    ...(item.expression && {
                                        expression: convertConditionTreeToDALFormat(
                                            getPropFreeConditionTree(JSON.parse(item.expression))
                                        )
                                    })
                                },
                                notifyOnNetworkStatusChange: true,
                            });
                        } catch (error) {
                            console.error('Create Query Error: ', error);
                        }
                        if (appInsightsContext) {
                            const properties = {
                                name: EventNames.AUTOMATION_MAPPING_ACTION,
                                properties: {
                                    topic: 'Duplicate',
                                },
                            };
                            trackEvent(appInsightsContext, AuthService, properties);
                        }
                    }}
                />,
                <MenuItem
                    text={STRINGS.MAPPING_CONFIGURATION_PAGE.actions.delete}
                    active={false}
                    key={'delete'}
                    onClick={() => deleteMapping(item)}
                />,
                <MenuItem
                    text={STRINGS.MAPPING_CONFIGURATION_PAGE.actions.reOrder}
                    active={false}
                    key={'orderTo'}
                    onClick={() => orderMapping(item, itemsAmount)}
                />
            ] : []),
            <MenuItem
                text={STRINGS.MAPPING_CONFIGURATION_PAGE.actions.openRunbook}
                active={false}
                key={'open_runbook'}
                onClick={() => {
                    window.open(
                        `${getURLPath('create-runbook')}?fid=${item.runbookId
                        }&fname=${item.runbookName}&variant=${Variant[item.variant]
                        }`,
                        '_blank'
                    );
                    if (appInsightsContext) {
                        const properties = {
                            name: EventNames.AUTOMATION_MAPPING_ACTION,
                            properties: {
                                topic: STRINGS.MAPPING_CONFIGURATION_PAGE.actions.openRunbook,
                            },
                        };
                        trackEvent(appInsightsContext, AuthService, properties);
                    }
                }}
            />,
        ];
    };

    // Table data
    const getDisplayData = (data: Array<any>, filterBy: string): Array<any> => {
        const selectedCategoryItems = data &&
            data['automationMappings']
                .filter(trigger => trigger.triggerType === filterBy);
        const displayData = data && filterBy ?
            selectedCategoryItems.map((item) => {
                return {
                    order: item.order,
                    ...(envIsSupported && {
                        name: item.name,
                        name_ui: (
                            <Popover2 usePortal lazy
                                content={
                                    item.expression && (
                                        <MappingDecisionBranchView
                                            className="pt-3 p-2"
                                            triggerType={TRIGGER_TYPE_MAP[item.triggerType]}
                                            expression={
                                                convertExpressionToTreeFormat(JSON.parse(item.expression))
                                            }
                                            readonly={true}
                                        />
                                    )
                                }
                                interactionKind="click"
                                className="w-100"
                                placement="bottom-start"
                                hoverOpenDelay={500}
                                transitionDuration={150}
                            >
                                {item.expression ? <button type="button" className='link-style'>{item.name}</button> : item.name}
                            </Popover2>
                        )
                    }),
                    description: item.description,
                    runbook_name: item.runbookName,
                    runbook_name_ui: (item.runbookName ?
                        <a
                            href={`${getURLPath(
                                'create-runbook'
                            )}?fid=${item.runbookId}&fname=${item.runbookName
                                }&variant=${Variant[item.variant]}`}
                            className="text-decoration-underline"
                            target="_blank"
                            rel="noreferrer"
                        >
                            {item.runbookName}
                        </a>
                    : <span>
                        <Icon icon={IconNames.WARNING_SIGN} intent={Intent.WARNING} />
                        <span className='ml-1'>{STRINGS.MAPPING_CONFIGURATION_PAGE.noRunbookName}</span>
                    </span>),
                    last_updated_by: item.lastUpdatedBy,
                    last_updated_on: (
                        <div>
                            <div>
                                {moment.utc(item.lastUpdatedOn * 1000).local().fromNow()}
                            </div>
                            <div className="display-9">
                                {moment.utc(item.lastUpdatedOn * 1000).local().format('lll')}
                            </div>
                        </div>
                    ),
                    last_matched: (item.lastHitOn &&
                        <div>
                            <div>
                                {moment.utc(item.lastHitOn * 1000).local().fromNow()}
                            </div>
                            <div className="display-9">
                                {moment.utc(item.lastHitOn * 1000).local().format('lll')}
                            </div>
                        </div>
                    ),
                    enabled_automation: item.enabled,
                    enabled_automation_ui: (
                        <Switch
                            checked={item.enabled}
                            disabled={!item.runbookId}
                            className="mb-0"
                            innerLabel={
                                item.enabled
                                    ? STRINGS.MAPPING_CONFIGURATION_PAGE
                                        .enableMappingModal.onText
                                    : STRINGS.MAPPING_CONFIGURATION_PAGE
                                        .enableMappingModal.offText
                            }
                            onChange={() => enableMapping(item)}
                        />
                    ),
                    ...(envIsSupported && {
                        action: (
                            <>
                                <WrapInTooltip
                                    tooltip="Move up"
                                    orientation="lhs"
                                >
                                    <Button
                                        minimal
                                        disabled={item.order === 1}
                                        icon={IconNames.CHEVRON_UP}
                                        onClick={() => {
                                            setAutomationMappingOrder({
                                                variables: {
                                                    id: item.id,
                                                    order: item.order - 1,
                                                },
                                                notifyOnNetworkStatusChange:
                                                    true,
                                            });
                                        }}
                                    />
                                </WrapInTooltip>
                                <WrapInTooltip
                                    tooltip="Move down"
                                    orientation="lhs"
                                >
                                    <Button
                                        minimal
                                        disabled={
                                            item.order ===
                                            selectedCategoryItems.length
                                        }
                                        icon={IconNames.CHEVRON_DOWN}
                                        onClick={() => {
                                            setAutomationMappingOrder({
                                                variables: {
                                                    id: item.id,
                                                    order: item.order + 1,
                                                },
                                                notifyOnNetworkStatusChange:
                                                    true,
                                            });
                                        }}
                                    />
                                </WrapInTooltip>
                            </>
                        )
                    }),
                    more: (
                        <Popover2
                            position={Position.BOTTOM_RIGHT}
                            interactionKind={
                                Popover2InteractionKind.CLICK
                            }
                            content={
                                <Menu>
                                    {getMoreMenuItems(
                                        item,
                                        selectedCategoryItems.length
                                    )}
                                </Menu>
                            }
                        >
                            <Button
                                aria-label="data-source-more-button"
                                icon={IconNames.MORE}
                                minimal
                                className="data_source-action-icon"
                                disabled={false}
                            />
                        </Popover2>
                    ),
                };
            })
            : [];
        return displayData;
    };

    // Start of component return
    return (
        <>
            <BasicDialog
                dialogState={dialogState}
                onClose={() => setDialogState(updateDialogState(dialogState, false, false, []))}
            />
            <ViewCollection activeView={activeView}>
                <DataLoadFacade
                    loading={loading}
                    data={data}
                    error={error}
                    key="categories"
                    showContentsWhenLoading={true}
                    className={'basic'}
                >
                    {buildCategories}
                </DataLoadFacade>
                <OneColumnContainer
                    key="triggers"
                    noPadding={true}
                    showBackButton={true}
                    onBackClicked={() => updateView('categories', {
                        triggerCategory: '',
                        triggerType: '',
                    })
                    }
                >
                    <span className="display-8 font-weight-bold text-uppercase">
                        <span className="pl-2">{`${vars.triggerCategory} TRIGGERS `}</span>
                        <span className="px-2">{` > `}</span>
                        <span>{` ${STRINGS.MAPPING_CONFIGURATION_PAGE.subTypes[vars.triggerType]} TRIGGERS`}</span>
                    </span>
                    <div aria-label="mappings list" className="mt-3">
                        <DataLoadFacade
                            loading={loading}
                            showContentsWhenLoading={true}
                            className={'basic'}
                        >
                            {envIsSupported && 
                            <ButtonGroup>
                                <Button
                                    data-testid={'add-mapping-button'}
                                    icon={IconNames.ADD}
                                    className="user-btn mb-2"
                                    onClick={() => handleAddMappingOpen(Variant[vars.triggerCategory], vars.triggerType)}
                                >
                                    {STRINGS.MAPPING_CONFIGURATION_PAGE.addButtonText}
                                </Button>
                            </ButtonGroup>
                            }
                            <Table
                                id="MappingConfigTbl"
                                className="sticky-table-header"
                                columns={getColumns(envIsSupported)}
                                data={getDisplayData(data, vars.triggerType)}
                                enablePagination={false}
                                bordered={true}
                                removeOverflow={true}
                            />
                        </DataLoadFacade>
                    </div>
                </OneColumnContainer>
            </ViewCollection>
            <AddMappingModal ref={addMappingModal} />
            <EditMappingModal ref={editMappingModal} />
        </>
    );
};

export { MappingConfigurationView };
