/** This module contains utilities for editing data ocean nodes and validating data ocean nodes.
 *  @module
 */
import { Elements, Node } from "react-flow-renderer";
import {
    dataOceanNodes, getParents, getParentsFromGraphDef, logicalNodes, triggerNodes, getProperty, getProperties, 
    aggregatorNodes, decisionNodes, transformNodes, variableNodes, subflowNodes
} from "utils/runbooks/RunbookUtils";
import { NodeLibrary } from "pages/create-runbook/views/create-runbook/NodeLibrary";
import { GraphDef, NodeDef, Variant } from "components/common/graph/types/GraphTypes";
import { RunbookNode } from "utils/services/RunbookApiService";
import { ApiService } from "utils/services/MockApiService";
import { 
    getDataOceanFiltersMap, NODE_OPTION_VALUE_DP, NODE_OPTION_VALUE_CP, NODE_OPTION_VALUE_AP, TRIGGER_OPTION_VALUE, 
    VARIABLE_OPTION_VALUE, SUBFLOW_INPUT_OPTION_VALUE, 
    ON_DEMAND_INPUT_OPTION_VALUE
} from "./DataOceanFilters";
import do_api from "./data_ocean_ui_library_mock.json";
import { STRINGS } from "app-strings";
import { UniversalNode } from "../../UniversalNode";
import { ContextMode, ContextSource, CONTEXT_MODE, RunbookContext, VariableContextByScope } from "utils/runbooks/RunbookContext.class";
import { DataOceanKey, DataOceanMetadata, DataOceanMetric, DataOceanObjectType } from "./DataOceanMetadata.type";
import { getArDataSources, getTypes } from "utils/stores/GlobalDataSourceTypeStore";
import { NodeUtils } from "utils/runbooks/NodeUtil";
import { dataSourceTypeOptions } from "utils/services/DataSourceApiService";

/** the environment, dev, staging or production. */
let { ENV } = window['runConfig'] ? window['runConfig'] : { ENV: '' };
export function setEnv(envValue: string): void {
    ENV = envValue;
}

/** if this is true, the data ocean hookup routing will only consider exact matches. */
const AUTO_HOOKUP_EXACT_MATCH: boolean = false;

/** this flag enables the new vantage point controls in the DO and runbook output. */
export const ENABLE_VANTAGE_POINT: boolean = true;

export class DataOceanUtils {
    /** this map maps the metric categories to a priority that is used to sort the metric categories.  A lower number gets sorted
     *  before a higher number.  The max value is 100.  All categories do not need to be added to this list. */
    static METRIC_CATEGORY_PRIORITIES: Record<string, number> = {
        "Throughput": 1.0,
        "Traffic (Bits/Bytes)": 1.1,
        "Traffic (Packets)": 1.2,
        "Utilization": 1.3,
        "Packet Errors": 1.4,
        "Packet Drops": 1.5,
        "Status": 1.6,
        "Changes": 1.7,
        "Connections": 1.8,
        "Retransmissions": 1.9,
    };

    /** the list of valid data ocean parents. */
    static DATA_OCEAN_PARENTS: Array<string> = [
        ...triggerNodes, ...logicalNodes, ...dataOceanNodes, ...aggregatorNodes, ...decisionNodes, ...transformNodes, ...variableNodes, ...subflowNodes
    ];
    /** the object with the data ocean meta data.  This comes from a file right now, but in the future we should call a data ocean API. */
    private static _dataOceanMetaData: DataOceanMetadata;

    /** returns the data ocean metadata.
     *  @returns the data ocean metadata. */
    static get dataOceanMetaData(): DataOceanMetadata {
        return DataOceanUtils._dataOceanMetaData;
    }

    /** sets the data ocean metadata.
     *  @param value the data ocean metadata. */
    static set dataOceanMetaData(value: DataOceanMetadata) {
        DataOceanUtils._dataOceanMetaData = value;
    }

    /** calls the data ocean objects api and assigns the response to dataOceanMetaData property
     *  @returns a Promise that resolves to the data ocean metadata. */
    static init(): Promise<DataOceanMetadata> {
        return DataOceanUtils.getMetaData().then(response => {
            DataOceanUtils._dataOceanMetaData = response;
            return response;
        }, error => {
            console.error(error);
            throw new Error(STRINGS.formatString(STRINGS.runbookEditor.errors.dataOceanInitFailed, error) || error);
        });
    }

    /** returns true if the data ocean metadata has all the valid properties.
     *  @returns a boolean value, this is true if the data ocean metadata has been initialized
     *      and false otherwise. */
    static isDataOceanMetaDataInitialized(): boolean {
        return Boolean(DataOceanUtils.dataOceanMetaData) && Boolean(DataOceanUtils.dataOceanMetaData['obj_types']) && 
            Boolean(DataOceanUtils.dataOceanMetaData['metrics']) && Boolean(DataOceanUtils.dataOceanMetaData['keys']);
    }

    /**
     * TODO: Replace this call with an actual API call. Currently its a fakeapi call returning static data
     * @returns Promise .*/
    static getMetaData(): Promise<any> {
        const metaData = do_api;
        return ApiService.get("/", undefined, false, metaData);
    }

    /** returns true if the data ocean metadata is initialized and give data ocean object type exists
     *  @param type a string with the object type.
     *  @returns a boolean value, true if the data ocean object type if found in the data ocean meta data. */
    static isValidDataOceanObjectType(type: string): boolean {
        return this.isDataOceanMetaDataInitialized() && DataOceanUtils.dataOceanMetaData["obj_types"].hasOwnProperty(type);
    }

    /** returns whether or not the specified object type supports the specified data sources.
     *  @param objType the String with the object type to check.
     *  @param availableDataSources the array of dataSourceTypeOptions with the data sources to check.
     *  @returns a boolean value, true if the specfied object type supports the specified data sources. */
    static isObjectTypeSupported(objType: string, availableDataSources: dataSourceTypeOptions[]): boolean {
        if (this.isDataOceanMetaDataInitialized()) {
            const doObjectType = DataOceanUtils.dataOceanMetaData.obj_types[objType];
            if (doObjectType && (
                (
                    DataOceanUtils.objectTypeSupportsDataSources(doObjectType, availableDataSources) && 
                    !doObjectType.deprecated
                )
            )) {
                return true;
            }
        }
        return false; 
    }

    /** returns the array of supported metrics for the specified object type and the specified data sources.
     *  @param objType the DataOceanObjectType to check.
     *  @param displayedMetrics any metrics currently displayed, these will be listed as available even if they are not supported.
     *  @param availableDataSources the array of dataSourceTypeOptions with the data sources to check.
     *  @param queryType a String with the query type for which the metrics are requested.
     *  @returns an array of DataOceanMetrics that are supported by the data sources. */
    static getAvailableMetricsForObjectType(
        objType: string, displayedMetrics: string[], availableDataSources: dataSourceTypeOptions[],
        queryType: "summary" | "time_series" = "summary"
    ): DataOceanMetric[] {
        if (this.isDataOceanMetaDataInitialized()) {
            const metrics: string[] = DataOceanUtils.dataOceanMetaData.obj_types[objType].metrics;
            return DataOceanUtils.getAvailableMetrics(metrics, displayedMetrics, availableDataSources, false, queryType);
        }
        return []; 
    }

    /** returns the array of supported metrics for the specified object type metrics and the specified data sources.
     *  @param metrics the list of metrics for the current object type.
     *  @param displayedMetrics any metrics currently displayed, these will be listed as available even if they are not supported.
     *  @param availableDataSources the array of dataSourceTypeOptions with the data sources to check.
     *  @param checkTop a boolean value, which if true, specifies that this function should check if the metrics
     *     are valid top metrics.
     *  @param queryType a String with the query type for which the metrics are requested.
     *  @returns an array of DataOceanMetrics that are supported by the data sources. */
    static getAvailableMetrics(
        metrics: string[], displayedMetrics: string[], availableDataSources: dataSourceTypeOptions[], checkTop: boolean = false,
        queryType: "summary" | "time_series" = "summary"
    ): DataOceanMetric[] {
        if (this.isDataOceanMetaDataInitialized()) {
            if (metrics?.length) {
                const supportedMetrics: DataOceanMetric[] = [];
                for (const metric of metrics) {
                    const metricDef: DataOceanMetric = DataOceanUtils.dataOceanMetaData.metrics[metric];
                    const supportedEnvs = metricDef.supported_envs || ["dev", "staging", "prod"];
                    if (metricDef && (
                        (
                            DataOceanUtils.metricSupportsDataSources(metricDef, availableDataSources) && supportedEnvs.includes(ENV) && 
                            (!metricDef.supported_query_types || metricDef.supported_query_types.includes(queryType)) &&
                            !metricDef.deprecated && (!checkTop || metricDef.supports_top === undefined || metricDef.supports_top)
                        ) || 
                        displayedMetrics.includes(metricDef.id)
                    )) {
                        supportedMetrics.push(metricDef);
                    }
                }
                supportedMetrics.sort(DataOceanUtils.metricSortFunction);
                return supportedMetrics;
            }
        }
        return []; 
    }

    /** the sort function for the metric list which returns a -1 if a metric is sorted before another metric, 
     *  +1 if it is after, and 0 if it is the same.
     *  @param mA the first DataOceanMetric.
     *  @param mB the second DataOceanMetric.
     *  @returns a -1 if a metric is sorted before another metric, 
     *      +1 if it is after, and 0 if it is the same. */
    static metricSortFunction(mA: DataOceanMetric, mB: DataOceanMetric): number {
        const pA: number = DataOceanUtils.METRIC_CATEGORY_PRIORITIES[mA.category || ""] || 100;
        const pB: number = DataOceanUtils.METRIC_CATEGORY_PRIORITIES[mB.category || ""] || 100;

        if (mA.category === mB.category) {
            if (DataOceanUtils.metricIsInOrOut(mA) === DataOceanUtils.metricIsInOrOut(mB)) {
                return mA.label.localeCompare(mB.label);
            } else if (DataOceanUtils.metricIsInOrOut(mA)) {
                return 1;
            } else {
                return 1;
            }
        } else if (pA !== pB) {
            return pA < pB ? -1 : 1;
        } else {
            return mA.category?.localeCompare(mB.category || "") || 0;
        }
    }

    /** returns true if the metric label has an "In" or "Out" prefix.
     *  @param metric the DataOceanMetric to check for the "In" or "Out" in the label.
     *  @returns a boolean which is true if the metric has an "In" or "Out" prefix in its label. */
    static metricIsInOrOut(metric: DataOceanMetric): boolean {
        return metric.label.startsWith("In") || metric.label.startsWith("Out");
    }

    /** returns whether or not the specified metric supports the specified data sources.
     *  @param metric the DataOceanMetric to check.
     *  @param availableDataSources the array of dataSourceTypeOptions with the data sources to check.
     *  @returns a boolean value, true if the specfied metric supports the specified data sources. */
    static metricSupportsDataSources(metric: DataOceanMetric, availableDataSources: dataSourceTypeOptions[]): boolean {
        if (metric.supported_data_sources?.length && availableDataSources?.length) {
            for (const dataSource of availableDataSources) {
                if (metric.supported_data_sources.includes(dataSource)) {
                    return true;
                }
            }

        }
        return false;
    }

    /** returns whether or not the specified object type supports the specified data sources.
     *  @param objType the DataOceanObjectType to check.
     *  @param availableDataSources the array of dataSourceTypeOptions with the data sources to check.
     *  @returns a boolean value, true if the specfied object type supports the specified data sources. */
    static objectTypeSupportsDataSources(objType: DataOceanObjectType, availableDataSources: dataSourceTypeOptions[]): boolean {
        if (objType.supported_data_sources?.length && availableDataSources?.length) {
            for (const dataSource of availableDataSources) {
                if (objType.supported_data_sources.includes(dataSource)) {
                    return true;
                }
            }

        }
        return false;
    }

    /** converts the list of metrics into list of label and value pair for display purposes using the metrics information
     *      received from the data ocean objects api
     *  @param metrics
     *  @returns an array with all the data ocean metric definitions. */
    static getMetricsDisplayData(metrics: Array<string>): Array<{ value: string, label: string }> {
        const typeMetrics = new Array<{ value: string, label: string }>();
        if (this.isDataOceanMetaDataInitialized()) {
            const metricsMap = this.dataOceanMetaData.metrics;
            metrics?.forEach(metric => {
                if (metricsMap[metric] && metricsMap[metric].label) {
                    typeMetrics.push({
                        value: metric,
                        label: metricsMap[metric].label
                    });
                }
            });
        }

        return typeMetrics;
    };

    /** converts the list of keys into list of label and value pair for display purposes using the keys information
     *      received from the data ocean objects api
     *  @param keys
     *  @returns an array with all the data ocean metric definitions. */
    static getKeysDisplayData(keys: Array<string>): Array<{ value: string, label: string }> {
        const typeKeys = new Array<{ value: string, label: string }>();
        if (this.isDataOceanMetaDataInitialized()) {
            const keysMap = this.dataOceanMetaData.keys;
            keys?.forEach(key => {
                if (keysMap[key] && keysMap[key].label) {
                    typeKeys.push({
                        value: key,
                        label: keysMap[key].label
                    });
                }
            });
        }

        return typeKeys;
    };

    /** Checks if the parent node is a valid data ocean parent
     *  @param node the runbook node that is being checked.
     *  @param parents
     *  @param errors
     *  @returns a boolean value, true if the parent is valid, false otherwise. */
    static isValidParent(node: RunbookNode, parents: Array<RunbookNode>, errors?: Array<string>): boolean {
        if (parents.length !== 1 || DataOceanUtils.DATA_OCEAN_PARENTS.indexOf(parents[0].type) < 0) {
            errors?.push(STRINGS.runbookEditor.errors.doNode.dataOceanOnlyOneParentError);
            return false;
        }
        return true;
    }

    /** Validates if filter objects have correct values based on the trigger node and closes data ocean parent node
     *  @param node
     *  @param triggerNode
     *  @param closestDOParent
     *  @param variables the map of variables by scope.
     *  @param errors . */
    /* istanbul ignore next */
    static validateFilters(
        node: RunbookNode, triggerNode: RunbookNode | null, closestDOParent: RunbookNode | null, 
        variables: VariableContextByScope, errors: Array<string>
    ): void {
        const dataOceanFiltersMap = getDataOceanFiltersMap(Variant.INCIDENT);
        const objectTypes = this.dataOceanMetaData.obj_types;
        const filterKeys = this.dataOceanMetaData.keys;
        const currentNodeMetaData = objectTypes[node.properties.objType];
        if (!(currentNodeMetaData)) {
            errors.push(`${node.label} is not a valid data ocean node. Delete the node and re-configure`);
            return;
        }
        const availableFilters = currentNodeMetaData.filters;
        const requiredFilters = currentNodeMetaData.required_filters;

        const structuredRuntimeVariables: string[] = NodeUtils.getStructuredVariablesList(variables);

        availableFilters?.forEach(filter => {
            if (dataOceanFiltersMap[filter]) {
                const filterUIMap = dataOceanFiltersMap[filter];
                const filterName = filterUIMap.key;
                const filterValue = node.properties.filters[filterName] ? node.properties.filters[filterName][0] : "";

                //Push to errors if filter is required and the value is empty
                if (requiredFilters.includes(filter) && !(filterValue)) {
                    errors.push(STRINGS.formatString(
                        STRINGS.runbookEditor.errors.doNode.requiredFilterError,
                        filterKeys[filter].label
                    ).toString());
                }

                if (filterValue) {
                    // Check if the value is set to runbook trigger and the trigger node has the same type as the filter
                    if (triggerNode && triggerNode.properties && triggerNode.properties.triggerType !== filter
                        && (filterUIMap.options.trigger && filterUIMap.options.trigger.value === filterValue)) {

                        errors.push(STRINGS.formatString(
                            STRINGS.runbookEditor.errors.doNode.dataOceanValueFromTriggerError,
                            filterKeys[filter].label,
                            triggerNode.label
                        ).toString());
                    }

                    if (filterUIMap.options.node.value === filterValue) {
                        let isValid = false;
                        if (closestDOParent) {
                            const closestDOParentMetaData = objectTypes[closestDOParent.properties.objType];
                            if (closestDOParentMetaData && closestDOParentMetaData.keys.includes(filter)) {
                                isValid = true;
                            }
                        }
                        if (!isValid) {
                            errors.push(STRINGS.formatString(
                                STRINGS.runbookEditor.errors.doNode.dataOceanValueFromNodeError,
                                filterKeys[filter].label
                            ).toString());
                        }
                    }

                    // Check if the value is set to get filter from a variable and the variables that filter
                    if (filterUIMap.options.variable.value === filterValue || filterValue.startsWith(VARIABLE_OPTION_VALUE)) {
                        let variableName: string | undefined = undefined;
                        if (filterValue.startsWith(VARIABLE_OPTION_VALUE + ":")) {
                            variableName = filterValue.substring(filterValue.indexOf(":") + 1, filterValue.length);
                        }
                        if (variableName && !structuredRuntimeVariables.includes(variableName)) {
                            errors.push(STRINGS.formatString(
                                STRINGS.runbookEditor.errors.doNode.dataOceanValueFromVariableError,
                                filterKeys[filter].label
                            ).toString());
                        }
                    }                
                }
            }
        });
    }

    /** Validates given data ocean node
     *  @param nodeLibrary a reference to the NodeLibrary.
     *  @param node
     *  @param parents
     *  @param runbookNodes
     *  @param triggerNode
     *  @param variables the map of variables by scope.
     *  @param errors
     *  @returns an array of strings with all the errors encountered. */
    /* istanbul ignore next */
    static validateNode(
        nodeLibrary: NodeLibrary, node: RunbookNode, parents: Array<RunbookNode>, runbookNodes: Array<RunbookNode>, triggerNode: RunbookNode | null, 
        variables: VariableContextByScope, errors: Array<string>
    ): Array<string> {
        if (this.isValidParent(node, parents, errors)) {
            // Data Ocean UI is driven by backend data ocean objects api. If its not available then no point validating

            if (!this.isDataOceanMetaDataInitialized()) {
                errors.push("Data Ocean metadata configuration was not initialized");
            } else {
                if (!node.properties || !node.properties.objType || !this.dataOceanMetaData.obj_types[node.properties.objType]) {
                    // Do not go any further you cannot error check a DO node that no longer has a correct object type
                    errors.push(STRINGS.runbookEditor.errors.doNode.dataOceanInvalidObjType);
                    return errors;
                }
                const nodeLibraryNode = nodeLibrary.getNodeUsingProperties(node.type, node.properties);
                const closestDOParent = this.getClosestDataOceanParent(node, runbookNodes);
                this.validateFilters(node, triggerNode, closestDOParent, variables, errors);
                this.validateNodePropertiesFromTypeAndSubtype(
                    nodeLibrary, node.type, nodeLibraryNode?.subType, node.properties, errors, 
                    node.label || STRINGS.runbookEditor.nodeLibrary.nodes.data_ocean.name
                );
            }
        }

        return errors;
    }

    /** Checks if the parent node is a valid data ocean parent
     *  @param node the runbook node that is being checked.
     *  @param parents
     *  @param errors
     *  @returns a boolean value true if the parents are valid, false otherwise. */
    static isValidParentFromGraphDef(node: NodeDef, parents: Array<NodeDef>, errors?: Array<string>): boolean {
        if (parents.length !== 1 || DataOceanUtils.DATA_OCEAN_PARENTS.indexOf(parents[0].type) < 0) {
            errors?.push(STRINGS.runbookEditor.errors.doNode.dataOceanOnlyOneParentError);
            return false;
        }
        return true;
    }

    /** Validates if filter objects have correct values based on the trigger node and closes data ocean parent node
     *  @param node the NodeDef object for the DO node.
     *  @param triggerNode the NodeDef object for the trigger node, if any.
     *  @param closestDOParent the NodeDef object for the closest DO parent of this DO node, if any.
     *  @param graphDef the GraphDef object with all the nodes and edges in the graph.
     *  @param variables the map of variables by scope.
     *  @param errors the list of errors. */
    static validateFiltersFromGraphDef(
        node: NodeDef, triggerNode: NodeDef | null, closestDOParent: NodeDef | null, graphDef: GraphDef, 
        variables: VariableContextByScope, errors: Array<string>
    ): void {
        const dataOceanFiltersMap = getDataOceanFiltersMap(Variant.INCIDENT);
        const objectTypes = this.dataOceanMetaData.obj_types;
        const filterKeys = this.dataOceanMetaData.keys;
        const currentNodeMetaData = objectTypes[getProperty(node, "objType")];
        if (!(currentNodeMetaData)) {
            errors.push(`${node.name} is not a valid data ocean node. Delete the node and re-configure`);
            return;
        }
        const availableFilters = currentNodeMetaData.filters;
        const requiredFilters = currentNodeMetaData.required_filters;

        const context = new RunbookContext(node, graphDef, DataOceanUtils.dataOceanMetaData);
        
        const structuredRuntimeVariables: string[] = [];
        if (variables) {
            for (const key in variables) {
                const varCollection = variables[key];
                if (varCollection.structuredVariables) {
                    for (const variable of varCollection.structuredVariables) {
                        structuredRuntimeVariables.push(variable.name);
                    }
                }
            }    
        }
        
        availableFilters?.forEach(filter => {
            if (dataOceanFiltersMap[filter]) {
                const filterUIMap = dataOceanFiltersMap[filter];
                const filterName = filterUIMap.key;
                const filterValue = getProperty(node, "filters")[filterName] ? getProperty(node, "filters")[filterName][0] : "";
                const filterValueArray = getProperty(node, "filters")[filterName] ? getProperty(node, "filters")[filterName] : [];

                //Push to errors if filter is required and the value is empty
                if (requiredFilters.includes(filter) && !(filterValue)) {
                    errors.push(STRINGS.formatString(
                        STRINGS.runbookEditor.errors.doNode.requiredFilterError,
                        filterKeys[filter].label
                    ).toString());
                }

                // A value to be from trigger or from a connected node needs to be validated only if the field type
                // is select
                if (filterValue && typeof filterValue === "string" && filterUIMap.fieldType === "select") {
                    // Check if the value is set to runbook trigger and the trigger node has the same type as the filter
                    if (
                        triggerNode && triggerNode.properties &&
                        !context.triggerProvidesFilter(filter) && 
                        (filterUIMap.options.trigger && filterUIMap.options.trigger.value === filterValue)
                    ) {
                        const nodeType = triggerNode && getProperty(triggerNode, "triggerType") ? 
                            getProperty(triggerNode, "triggerType") : 
                            "Unknown";
                        errors.push(STRINGS.formatString(
                            STRINGS.runbookEditor.errors.doNode.dataOceanValueFromTriggerError,
                            filterKeys[filter].label,
                            nodeType
                        ).toString());
                    }

                    // Check if the value is set to get filter from a parent the node and the node supports that filter
                    if (filterUIMap.options.node.value === filterValue || filterValue.startsWith(NODE_OPTION_VALUE_AP)) {
                        let nodeId: string | undefined = undefined;
                        if (filterValue.startsWith(NODE_OPTION_VALUE_AP + ":")) {
                            nodeId = filterValue.substring(filterValue.indexOf(":") + 1, filterValue.lastIndexOf("."));
                        }
                        if (!context.nodeProvidesFilter(filter, nodeId)) {
                            errors.push(STRINGS.formatString(
                                STRINGS.runbookEditor.errors.doNode.dataOceanValueFromNodeError,
                                filterKeys[filter].label
                            ).toString());
                        }
                    }

                    // Check if the value is set to get filter from a variable and the variables that filter
                    if (filterUIMap.options.variable.value === filterValue || filterValue.startsWith(VARIABLE_OPTION_VALUE)) {
                        let variableName: string | undefined = undefined;
                        if (filterValue.startsWith(VARIABLE_OPTION_VALUE + ":")) {
                            variableName = filterValue.substring(filterValue.indexOf(":") + 1, filterValue.length);
                        }
                        if (variableName && !structuredRuntimeVariables.includes(variableName)) {
                            errors.push(STRINGS.formatString(
                                STRINGS.runbookEditor.errors.doNode.dataOceanValueFromVariableError,
                                filterKeys[filter].label
                            ).toString());
                        }
                    }
                }

                // Check for missing data sources
                if (filterName === "dataSource" && filterValueArray?.length) {
                    const availableAr11s: string[] = (getArDataSources() || []).map(ds => ds.id);
                    const notFoundAr11s = filterValueArray.filter(idToken => {
                        if (!idToken.includes("$id:")) {
                            return false;
                        } else {
                            const id: string = idToken.includes("$id:") ? idToken.substring(4, idToken.length) : idToken;
                            return !availableAr11s.includes(id);    
                        }
                    });
                    if (notFoundAr11s.length) {
                        errors.push(STRINGS.runbookEditor.errors.doNode.dataOceanDataSourceMissing);
                    }
                }
            }
        });
    }

    /** Validates given data ocean node
     *  @param nodeLibrary a reference to the NodeLibrary.
     *  @param node
     *  @param parents
     *  @param runbookNodes
     *  @param triggerNode
     *  @param variables the map of variables by scope.
     *  @param errors
     *  @returns an array of strings with all the errors encountered. */
    static validateNodeFromGraphDef(
        nodeLibrary: NodeLibrary, node: NodeDef, parents: Array<NodeDef>, graphDef: GraphDef, triggerNode: NodeDef | null, 
        variables: VariableContextByScope, errors: Array<string>
    ): Array<string> {
        if (this.isValidParentFromGraphDef(node, parents, errors)) {
            // Data Ocean UI is driven by backend data ocean objects api. If its not available then no point validating

            if (!this.isDataOceanMetaDataInitialized()) {
                errors.push("Data Ocean metadata configuration was not initialized");
            } else {
                if (!node.properties || !getProperty(node, "objType") || !this.dataOceanMetaData.obj_types[getProperty(node, "objType")]) {
                    // Do not go any further you cannot error check a DO node that no longer has a correct object type
                    errors.push(STRINGS.runbookEditor.errors.doNode.dataOceanInvalidObjType);
                    return errors;
                }
                const nodeLibraryNode = nodeLibrary.getNodeUsingProperties(node.type, node.properties);
                const closestDOParent = this.getClosestDataOceanParentFromGraphDef(node, graphDef);
                this.validateFiltersFromGraphDef(node, triggerNode, closestDOParent, graphDef, variables, errors);
                this.validateNodePropertiesFromTypeAndSubtype(
                    nodeLibrary, node.type, nodeLibraryNode?.subType, getProperties(node), errors, 
                    node.name || STRINGS.runbookEditor.nodeLibrary.nodes.data_ocean.name
                );
            }
        }

        return errors;
    }

    /** Validates the property values for a given data ocean node
     *  @param nodeLibrary a reference to the NodeLibrary.
     *  @param selectedNode the UniversalNode that wraps the react-flow node.
     *  @param currentProperties the key/value pairs with the node properties.
     *  @param errors the array of strings with any errors that have been encountered.
     *  @param label a string with the node label.
     *  @returns an array of strings with all the errors that were found. */
    static validateNodeProperties(
        nodeLibrary: NodeLibrary, selectedNode: UniversalNode | undefined, currentProperties: Record<string, any>, 
        errors: Array<string>, label: string
    ): Array<string> {
        return this.validateNodePropertiesFromTypeAndSubtype(
            nodeLibrary, (selectedNode?.node as Node)?.data?.type, (selectedNode?.node as Node)?.data?.subType, currentProperties, errors, label
        );
    }

    /** Validates the property values for a given data ocean node
     *  @param nodeLibrary a reference to the NodeLibrary.
     *  @param type a String with the data ocean node type.
     *  @param subType a String with the data ocean node sub-type
     *  @param currentProperties the key/value pairs with the node properties.
     *  @param errors the array of strings with any errors that have been encountered.
     *  @param label a string with the node label.
     *  @returns an array of strings with all the errors that were found. */
    static validateNodePropertiesFromTypeAndSubtype(
        nodeLibrary: NodeLibrary, type: string = "", subType: string = "", currentProperties: Record<string, any>, errors: Array<string>, label: string
    ): Array<string> {
        const currentFilters: Array<Array<any>> = Object.entries(currentProperties["filters"]);

        currentFilters.forEach(([key, value]) => {
            // Only scenario the below condition could happen is when the User specific option is selected but user
            // hasn't specified any entries to filter
            //TODO: Move this section to validateFilters function
            if (!(value && value.length)) {
                errors.push(STRINGS.runbookEditor.errors.doNode[`${key}EmptyUserInput`]);
            }
        });

        this.validateMetricsAndTimeSeries(nodeLibrary, type, subType, currentProperties, errors);

        if (currentProperties.hasOwnProperty("limit")) {
            const limit: string = currentProperties.limit;
            if (!limit) {
                errors.push(STRINGS.runbookEditor.errors.doNode.dataOceanLimitError);
            } else if (parseInt(limit) <= 0 || Number.isNaN(parseInt(limit))) {
                errors.push(STRINGS.runbookEditor.errors.doNode.dataOceanLimitValueError);
            }
        }
        return errors;
    }

    /** Validate selected metrics and its quantities based on othe node properties
     *  @param nodeLibrary a reference to the NodeLibrary.
     *  @param type a String with the data ocean node type.
     *  @param subType a String with the data ocean node sub-type
     *  @param currentProperties the key/value pairs with the node properties.
     *  @param errors the array of strings with any errors that have been encountered. */
    static validateMetricsAndTimeSeries(
        nodeLibrary: NodeLibrary, type: string = "", subType: string = "", currentProperties: Record<string, any>, errors: Array<string>
    ): void {
        // Each of the selected metrics should exist in the accepted metrics list for that object type
        // It can happen that an old runbook may have a data ocean node where metrics are set and the given data ocean node
        // currently doesnt support it. So we need to warn the user about it
        if (currentProperties["metrics"]) {
            const metadataMetrics = DataOceanUtils.getAvailableMetricsForObjectType(
                currentProperties["objType"], [], 
                [dataSourceTypeOptions.NetIm, dataSourceTypeOptions.NetProfiler, dataSourceTypeOptions.Aternity, dataSourceTypeOptions.AppResponse], 
                currentProperties["timeSeries"] ? "time_series" : "summary"
            ).map((metric) => metric.id);

            currentProperties["metrics"].forEach(metric => {
                if (!metadataMetrics.includes(metric)) {
                    const metricName = this.dataOceanMetaData.metrics[metric] ? this.dataOceanMetaData.metrics[metric].label : metric;
                    errors.push(STRINGS.formatString(STRINGS.runbookEditor.errors.doNode.invalidMetricError, metricName).toString());
                }
            });
        }

        // When time series is true and the query returns multiple entities, it can only be for a single metric
        // Time series with multiple entities and multiple metrics isnt supported
        if (currentProperties["timeSeries"]) {
            if (currentProperties["metrics"] && currentProperties["metrics"].length) {
                // const hasAllPrimaryFilters = DataOceanUtils.hasAllPrimaryFilters(type, subType, currentProperties);
                // DO now supports the top N time series query so remove the if else, but keep it in case we need to 
                // re-enable this check
                //if (!hasAllPrimaryFilters) {
                    // Right now the data ocean cannot query for the top n objects to do a time series so this must be 
                    // passed in from another node either a trigger or a parent do node, or specified by the user
                    //errors.push(STRINGS.runbookEditor.errors.doNode.timeSeriesTopNError);
                //} else {
                    const isSingleEntity = DataOceanUtils.doesPrimaryFilterReturnsSingleEntity(nodeLibrary, type, subType, currentProperties);
                    // console.log("hasAllPrimaryFilters: " + hasAllPrimaryFilters);
                    if (!isSingleEntity && (!currentProperties["limit"] || (currentProperties["limit"] && parseInt(currentProperties["limit"]) > 1))) {
                        if (currentProperties["metrics"] && currentProperties["metrics"].length > 1) {
                            errors.push(STRINGS.runbookEditor.errors.doNode.timeSeriesMetricsError);
                        }
                    }    
                //}
            } else {
                errors.push(STRINGS.runbookEditor.errors.doNode.metricsRequiredError);
            }
        }

        if (currentProperties["comparedTo"]) {
            // We must have at least on metric selected when comparisons are enabled
            if (!currentProperties["metrics"] || currentProperties["metrics"].length === 0) {
                errors.push(STRINGS.runbookEditor.errors.doNode.metricsRequiredError);
            }
        }
    }

    /** Checks whether thee primary filters if selected returns only a single entity.
     *  @param nodeLibrary a reference to the NodeLibrary.
     *  @param type a String with the data ocean node type.
     *  @param subType a String with the data ocean node sub-type
     *  @param currentProperties the key/value pairs with the node properties.
     *  @returns a boolean value, true if a single entity is returned, false otherwise. */
    static doesPrimaryFilterReturnsSingleEntity(
        nodeLibrary: NodeLibrary, type: string = "", subType: string = "", currentProperties: Record<string, any>
    ): boolean {
        const dataOceanFiltersMap = getDataOceanFiltersMap(Variant.INCIDENT);
        const libraryNode = nodeLibrary.getNode(type, subType);
        const primaryFilters = libraryNode?.uiAttrs?.primaryFilters ? libraryNode?.uiAttrs?.primaryFilters : null;
        if (!(primaryFilters && primaryFilters.length)) {
            return false;
        }
        let isSingleEntity = true;

        primaryFilters?.forEach(primaryFilter => {
            const filterKey = dataOceanFiltersMap[primaryFilter].key;
            const currentFilterValues = currentProperties["filters"][filterKey];
            // set to false if filter value doesnt exist
            if (!currentFilterValues || !currentFilterValues.length) {
                isSingleEntity = false;
            }

            if (currentFilterValues && currentFilterValues.length) {
                if (
                    currentFilterValues.length > 1 ||
                    (typeof (currentFilterValues[0]) === "string" && (
                        currentFilterValues[0].startsWith(NODE_OPTION_VALUE_DP) ||
                        currentFilterValues[0].startsWith(NODE_OPTION_VALUE_CP) ||
                        currentFilterValues[0].startsWith(NODE_OPTION_VALUE_AP)
                    ))
                ) {
                    isSingleEntity = false;
                }
            }
        });

        return isSingleEntity;
    }

    /** checks whether all primary filters have been specified.  If there are no primary filters
     *      this function returns false.
     *  @param nodeLibrary a reference to the NodeLibrary.
     *  @param type a String with the data ocean node type.
     *  @param subType a String with the data ocean node sub-type
     *  @param currentProperties the key/value pairs with the node properties.
     *  @param variant the Variant of runbook that is being edited.
     *  @returns a boolean value, true if all primary filters have been specified, false otherwise. */
    static hasAllPrimaryFilters(
        nodeLibrary: NodeLibrary, type: string = "", subType: string = "", currentProperties: Record<string, any>,
        variant: Variant
    ): boolean {
        const dataOceanFiltersMap = getDataOceanFiltersMap(variant);
        const libraryNode = nodeLibrary.getNode(type, subType);
        const primaryFilters = libraryNode?.uiAttrs?.primaryFilters ? libraryNode?.uiAttrs?.primaryFilters : null;
        if (!(primaryFilters && primaryFilters.length)) {
            return false;
        }

        let hasAllPrimaryFilters = true;
        for (const filter of primaryFilters) {
            const filterKey = dataOceanFiltersMap[filter].key;
            const filterValues = currentProperties["filters"][filterKey];
            if (filterValues === undefined) {
                hasAllPrimaryFilters = false;
                break;
            }
        }
        return hasAllPrimaryFilters;
    }

    /** Returns a closest data ocean parent for a given data ocean node
     *  @param node the RunbookNode
     *  @param runbookNodes the array of RunbookNodes with all the nodes defined in the runbook.
     *  @returns the closest data ocean parent or null if none can be found. */
    static getClosestDataOceanParent(node: RunbookNode, runbookNodes: Array<RunbookNode>): RunbookNode | null {
        const parents = getParents(node, runbookNodes);
        if (parents[0].type === "data_ocean") {
            return parents[0];
        } else if (triggerNodes.indexOf(parents[0].type) > -1) {
            return null;
        } else {
            return this.getClosestDataOceanParent(parents[0], runbookNodes);
        }
    }

    /** Returns a closest data ocean parent for a given data ocean node using the GraphDef object.
     *  @param node the NodeDef with the runbook node definition
     *  @param graphDef the GraphDef object with all the nodes defined in the runbook.
     *  @returns the closed data ocean parent or null if none can be found. */
    static getClosestDataOceanParentFromGraphDef(node: NodeDef, graphDef: GraphDef): NodeDef | null {
        const parents = getParentsFromGraphDef(node, graphDef);
        if (parents && parents.length > 0) {
            if (parents[0].type === "data_ocean") {
                return parents[0];
            } else if (triggerNodes.indexOf(parents[0].type) > -1) {
                return null;
            } else {
                return this.getClosestDataOceanParentFromGraphDef(parents[0], graphDef);
            }
        }
        return null;
    }

    /** Set default properties for a data ocean node element based on the trigger node and parent data ocean node if any
     *  @param nodeLibrary a reference to the NodeLibrary.
     *  @param node data ocean node whose property needs to be updated
     *  @param graphDef Graph Definition object to get information on trigger and parent nodes
     *  @param elements Properties will be updated on the element in the elements array matching the node.
     * `@param variant the runbook Variant that is being edited. */
    static setDefaultPropertiesOnConnect(
        nodeLibrary: NodeLibrary, node: NodeDef, graphDef: GraphDef, elements: Elements, variant: Variant
    ): void {
        const dataOceanFiltersMap = getDataOceanFiltersMap(variant);
        const doNodeObjectType = node.properties?.filter(prop => prop.key === "objType")[0].value;
        const filters = DataOceanUtils.dataOceanMetaData["obj_types"][doNodeObjectType]["filters"];
        const context = new RunbookContext(node, graphDef, DataOceanUtils.dataOceanMetaData);

        elements.forEach(element => {
            if (element.id === node.id) {
                const filterValue = element.data.properties.filter(prop => prop.key === "filters")[0].value;
                // update react graph element
                const appliedFilters: Array<string> = [];

                // Let's sort the filters by the filters that have exact matches and those that do not, we want exact 
                // matches to get applied before compatible filters.  Also for efficiency only do the sort if we are 
                // allowing compatible filters.  If we are just allowing exact matches we don't need the sort step
                const sortedFilters: string[] = [...filters];
                if (!AUTO_HOOKUP_EXACT_MATCH) {
                    sortedFilters.sort((filterA, filterB) => {
                        const aExact = context.nodeProvidesFilter(filterA, undefined, true) || context.triggerProvidesFilter(filterA, true);
                        const bExact = context.nodeProvidesFilter(filterB, undefined, true) || context.triggerProvidesFilter(filterB, true);
                        if (aExact !== bExact) {
                            return aExact && !bExact ? 1 : -1;
                        } else {
                            const aCompat = context.nodeProvidesFilter(filterA, undefined, false) || context.triggerProvidesFilter(filterA, false);
                            const bCompat = context.nodeProvidesFilter(filterB, undefined, false) || context.triggerProvidesFilter(filterB, false);
                            if (aCompat !== bCompat) {
                                return aCompat && !bCompat ? 1 : -1;
                            } else {
                                return 0;
                            }
                        }
                    });
                    sortedFilters.reverse();
                }

                sortedFilters.forEach(filter => {
                    if (!AUTO_HOOKUP_EXACT_MATCH) {
                        // Check to see if a compatible filter has already been applied
                        let hasCompatibleFilter: boolean = false;
                        for (const appliedFilter of appliedFilters) {
                            if (RunbookContext.areFiltersCompatible(filter, appliedFilter)) {
                                hasCompatibleFilter = true;
                                break;
                            }
                        }
                        if (hasCompatibleFilter) {
                            return;
                        }    
                    }

                    const filterProps = dataOceanFiltersMap[filter];
                    if (filterProps && context) {
                        if (filter !== "data_source" && context.nodeProvidesFilter(filter, undefined, AUTO_HOOKUP_EXACT_MATCH)) {
                            let sources: Array<ContextSource> = context.getFilterNodeSources(filter, true);
                            if (!AUTO_HOOKUP_EXACT_MATCH && !sources.length) {
                                // If there is no exact match, then check for a compatible filter.
                                sources = context.getFilterNodeSources(filter, false);
                            }
                            if (sources && sources.length > 0) {
                                // Choose the source closest to this DO node, that is the last source in the 
                                // list since it is sorted from farthest to closest
                                switch (CONTEXT_MODE) {
                                    case ContextMode.DIRECT_PARENT:
                                        filterValue[filterProps.key] = [`${ NODE_OPTION_VALUE_DP }.${ filter }`];
                                        break;
                                    case ContextMode.CLOSEST_PARENT:
                                        filterValue[filterProps.key] = [`${ NODE_OPTION_VALUE_CP }.${ filter }`];
                                        break;
                                    case ContextMode.ANY_PARENT:
                                        filterValue[filterProps.key] = [`${ NODE_OPTION_VALUE_AP }:${ sources[sources.length - 1].id }.${ filter }`];
                                        break;
                                }
                            }
                            appliedFilters.push(filter);
                        } else if (context.triggerProvidesFilter(filter, AUTO_HOOKUP_EXACT_MATCH)) {
                            filterValue[filterProps.key] = [
                                (variant !== Variant.SUBFLOW && variant !== Variant.ON_DEMAND 
                                    ? TRIGGER_OPTION_VALUE 
                                    : variant === Variant.SUBFLOW ? SUBFLOW_INPUT_OPTION_VALUE + `.${ filter }` : ON_DEMAND_INPUT_OPTION_VALUE + `.${ filter }`)
                            ];
                            appliedFilters.push(filter);
                        }
                    }
                });

                // update graph def
                if (node.properties) {
                    node.properties.filter(prop => prop.key === "filters")[0].value = filterValue;

                    // Set the limit if it is needed
                    const nodeLibraryNode = nodeLibrary.getNodeUsingProperties(node.type, node.properties);
                    const hasAllPrimaryFilters = DataOceanUtils.hasAllPrimaryFilters(
                        nodeLibrary, node.type, nodeLibraryNode?.subType, {filters: filterValue}, variant
                    );
                    if (!hasAllPrimaryFilters) {
                        // If we don't have the primary filters set then the limit control will show
                        let nodeLimits = node.properties.filter(prop => prop.key === "limit");
                        if (!nodeLimits.length) {
                            node.properties.push({ key: "limit", value: 10 });
                        } else {
                            nodeLimits[0].value = 10;
                        }
                        nodeLimits = element.data.properties.filter(prop => prop.key === "limit");
                        if (!nodeLimits.length) {
                            element.data.properties.push({ key: "limit", value: 10 });
                        } else {
                            nodeLimits[0].value = 10;
                        }
                    } else {
                        // If all the primary filters are set, we don't need the limit
                        for (let index = 0; index < node.properties.length; index++) {
                            if (node.properties[index].key === "limit") {
                                node.properties.splice(index, 1);
                                break;
                            }
                        }
                        for (let index = 0; index < element.data.properties.length; index++) {
                            if (element.data.properties[index].key === "limit") {
                                element.data.properties.splice(index, 1);
                                break
                            }
                        }
                    }
                }
            }
        });
    }

    /** Clear the default properties set for the given node. currently only filters are being cleared
     *  @param node
     *  @param elements .*/
    static clearDefaultProperties(node: NodeDef, elements: Elements): void {
        elements.forEach(element => {
            if (element.id === node.id) {
                if (node.properties) {
                    //remove from graoh def
                    node.properties.filter(prop => prop.key === "filters")[0].value = {};
                }
                // remove from react graph element
                element.data.properties.filter(prop => prop.key === "filters")[0].value = {};
            }
        });
    }

    /** Set default properties when DO node is added
     *  @param nodeLibrary a reference to the NodeLibrary.
     *  @param node the node that is being added to the runbook.
     *  @param variant the runbook Variant that is being edited. */
    static setDefaultPropertiesOnAdd(nodeLibrary: NodeLibrary, node: Node, variant: Variant): void {
        const doNodeObjectType = node.data.properties?.filter(prop => prop.key === "objType")[0].value;
        const doTypeMetadata = DataOceanUtils.dataOceanMetaData["obj_types"][doNodeObjectType];

        // Set time series flag
        const supportedQueryTypes = doTypeMetadata?.supported_query_types;
        const isTimeseries: boolean = this.isOnlyTimeSeriesSupported(supportedQueryTypes);
        node.data.properties.filter(prop => prop.key === "timeSeries")[0].value = isTimeseries;
        if (this.isOnlyTimeSeriesSupported(supportedQueryTypes)) {
            const durationProp = node.data.properties.filter(prop => prop.key === "duration");
            if (durationProp?.length === 1) {
                durationProp[0].value = 3600;
            }
        }

        // Get the metrics that are currently supported
        const supportedMetrics: DataOceanMetric[] = DataOceanUtils.getAvailableMetricsForObjectType(
            doNodeObjectType, [], getTypes(), isTimeseries ? "time_series" : "summary"
        );

        // Set metrics if there is only one metric in the metadata for the given data ocean type
        const metrics = supportedMetrics.map(metric => metric.id);
        if (metrics && metrics.length === 1) {
            let nodeMetrics = node.data.properties.filter(prop => prop.key === "metrics");
            if (!nodeMetrics.length) {
                node.data.properties.push({ key: "metrics", value: metrics });
            } else {
                nodeMetrics[0].value = metrics;
            }
        }

        // Set the limit if it is needed
        const nodeLibraryNode = nodeLibrary.getNodeUsingProperties(node.data.type, node.data.properties);
        const hasAllPrimaryFilters = DataOceanUtils.hasAllPrimaryFilters(
            nodeLibrary, node.data.type, nodeLibraryNode?.subType, {filters: {}}, variant
        );
        if (!hasAllPrimaryFilters) {
            // If we don't have the primary filters set then the limit control will show
            let nodeLimits = node.data.properties.filter(prop => prop.key === "limit");
            if (!nodeLimits.length) {
                node.data.properties.push({ key: "limit", value: 10 });
            } else {
                nodeLimits[0].value = 10;
            }
        }
    }

    /** Check if only time series is supported
     *  @param supportedQueryTypes
     *  @returns a boolean value which is true if only time series queries are supported. */
    static isOnlyTimeSeriesSupported(supportedQueryTypes: Array<string>): boolean {
        return supportedQueryTypes.length === 1 && supportedQueryTypes[0] === 'time_series';
    }

    /** returns the keys from the specified object type..
     *  @param objType a string with the object type for which the keys are to be returned.
     *  @returns returns an array with the keys or an empty array if no keys are found. */
    static getKeys(objType: string): Array<string> {
        let keys: Array<string> = [];
        if (objType && DataOceanUtils.dataOceanMetaData.obj_types[objType]) {
            const queryKeys: Array<string> = DataOceanUtils.dataOceanMetaData.obj_types[objType].keys;
            if (queryKeys) {
                keys = keys.concat(queryKeys);
            }
        }
        return keys;
    }

    /** returns whether or not the specified object type has any of the specified keys.
     *  @param objType a string with the object type to check.
     *  @param keys a string array with the keys to check for.  If any match then the function should return true.
     *  @returns returns true if any of the keys are used by this object type. */
    static hasAnyKey(objType: string, keys: Array<string>): boolean {
        if (keys && objType && DataOceanUtils.dataOceanMetaData.obj_types[objType]) {
            const queryKeys: Array<string> = DataOceanUtils.dataOceanMetaData.obj_types[objType].keys;
            for (const queryKey of queryKeys) {
                if (keys.includes(queryKey)) {
                    return true;
                } else if (DataOceanUtils.hasAnySubKey(DataOceanUtils.dataOceanMetaData.keys[queryKey].properties, keys)) {
                    return true;
                }
            }
        }
        return false;
    }

    /** returns whether any of the sub keys in the properties object matches any of the specified keys.
     *  @param properties the properties object within a key definition.
     *  @param keys a string array with the keys to check for.  If any match then the function should return true.
     *  @returns a boolean value true if any of the keys in the properties object matches any of the specified keys. */
    static hasAnySubKey(properties: Record<string, DataOceanKey> | undefined, keys: Array<string>): boolean {
        if (properties && keys) {
            for (const queryKey in properties) {
                if (keys.includes(queryKey)) {
                    return true;
                } else if (DataOceanUtils.hasAnySubKey(properties[queryKey].properties, keys)) {
                    return true;
                }
            }
        }
        return false;
    }

    /** returns whether or not the specified object type has any of the specified filter keys.
     *  @param objType a string with the object type to check.
     *  @param filterKeys a string array with the keys to check for.  If any match then the function should return true.
     *  @returns returns true if any of the filters are supported by this object type. */
    static hasAnyFilter(objType: string, filterKeys: Array<string>): boolean {
        if (filterKeys && objType && DataOceanUtils.dataOceanMetaData.obj_types[objType]) {
            const queryFilters: Array<string> = DataOceanUtils.dataOceanMetaData.obj_types[objType].filters;
            for (const queryFilterKey of queryFilters) {
                if (filterKeys.includes(queryFilterKey)) {
                    return true;
                }
                for (const filterKey of filterKeys) {
                    if (RunbookContext.areFiltersCompatible(filterKey, queryFilterKey)) {
                        return true;
                    }
                }
            }
        }
        return false;
    }
}
